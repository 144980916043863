import * as React from "react";
import { ImportCCHAxcess } from "../../helper/Constants";
import { CloseIcon } from "./Icons";
import bootbox from "bootbox";
import ReactDOMServer from "react-dom/server";  

interface AlertDialogSlideProps {
  deleteDialogOpen: boolean;
  onDeleteCancel: () => void;
  onDeleteProceed: () => void;
}

const AlertDialogSlide: React.FC<AlertDialogSlideProps> = ({
  deleteDialogOpen,
  onDeleteCancel,
  onDeleteProceed,
}) => {
  const DeleteDialogConstants = ImportCCHAxcess.TableHeader;
  const closeIconRef = React.useRef<HTMLSpanElement | null>(null);

  React.useEffect(() => {
    if (deleteDialogOpen) {
      const closeIconHtml = ReactDOMServer.renderToString(<CloseIcon />);
      bootbox.confirm({
        title: `<div class="bootbox-dialog-title" data-test-auto="65d8c792-4fa1-4f3d-bcb2-8cf32e7e8777"><span class="bootbox-dialog-title-text">${DeleteDialogConstants.deleteReturnsDialogTitle}</span><span id="custom-close-icon" style="cursor: pointer;"  data-test-auto="91dec935-fc37-4239-8787-1c5caf761938">${closeIconHtml}</span></div>`,
        message: `<div class="bootbox-dialog-content-text" data-test-auto="4658bff1-3a4c-4f0f-b978-edc59fca4831">${DeleteDialogConstants.deleteReturnsDialogText}</div>`,
        buttons: {
          cancel: {
            label: `<span class="cancel-btn-text" data-test-auto="2a667f6e-5420-42e2-abe2-2afe8360a7d1">${DeleteDialogConstants.deleteReturnsCancel}</span>`,
            className: "bootbox-button bootbox-button-cancel",
          },
          confirm: {
            label: `<span class="proceed-btn-text" data-test-auto="a70651db-b6a6-479f-8d99-f991bf7dae15">${DeleteDialogConstants.deleteReturnsProceed}</span>`,
            className: "bootbox-button bootbox-button-proceed",
          },
        },
        callback: (result: boolean) => {
          if (result) {
            onDeleteProceed();
          } else {
            onDeleteCancel();
          }
        },
        closeButton: false,
        className: "CCH-delete-confirm-modal", 
      });

      const closeDialog = ()=>{
        bootbox.hideAll();
          onDeleteCancel();
      }

      const closeIconElement = document.querySelector("#custom-close-icon");
      if (closeIconElement) {
        closeIconElement.addEventListener("click", closeDialog);
      }

      const backdrop = document.querySelector('.modal');
      if (backdrop) {
        (backdrop as HTMLDivElement).onclick = () => {
          if (event.target === backdrop) {
          bootbox.hideAll();
          onDeleteCancel();}
        };
      }
      return () => {
       
      };
    }
  }, [deleteDialogOpen, onDeleteCancel, onDeleteProceed, DeleteDialogConstants]);

  return null;
};

export default AlertDialogSlide;