import * as React from 'react';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import * as TaxDocument from '../../../common/TaxReturn';
import { TaxpayerSidebar } from '../RightSidebar/TaxpayerSidebar';
import { PartnershipSidebar } from '../RightSidebar/PartnershipSidebar';
import { ClientInfoProperty } from '../../ClientInfoEnum';
import * as Constants from '../../../helper/Constants';
import { getAuthorityData, isIndividualEnagagementType } from '../../../helper/HelperFunctions';
import { IFormGroupViewProps, IFormGroupViewState, NO_INDEX, RefundType } from '../ProcessReturnModels';
import { BookmarksPane } from '../BookmarksPane';
import { AddedRefundList } from '../LeftSidebar/AddedRefundList';
import {
    DocumentGroups, IEFile, SignatureControlRole, initailTaxpayerModel, IGroup,
    IFormBase, CustomType, ITaxReturn, IVoucher, ITaxingAuthority, TaxFormType, IPrefilledClientInfo, IOfficeLocation
} from '../../../common/TaxReturn';
import { Guid } from 'guid-typescript';
import { ClientReturnInfo } from '../RightSidebar/ClientReturnInfo';
import * as TaxRetrun from '../../TaxReturn';
import { IParterUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { ClientRefundPaymentDue } from '../RightSidebar/ClientRefundPaymentDue';
import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import { ITaxClient, ITaxpayer } from '../../../common/TaxClient';
import { ICountryState } from '../../../../Core/ViewModels/Company/CompanyViewModel';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { VoucherScript } from '../Voucher/VoucherScript';
import { UploadFunctions } from '../../../common/UploadDocument/UploadFunctions';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import { PDFJSStatic } from 'pdfjs-dist';
import { AddedOverPaymentsList } from '../LeftSidebar/AddedOverPayments';
import { AwesomePdfViewer } from './../AwesomePdfViewer';
import { generateBase64, PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { HideLoader, ShowLoader } from '../../../helper/Loader';
import { IDropdown } from '../../../../Core/Common/Dropdown';
const PDFJS: PDFJSStatic = require('pdfjs-dist');

export interface ITabClientProps extends IFormGroupViewProps {
    allSignerList: IParterUserModel[],
    authorities: TaxRetrun.ITaxingAuthority[],
    getAllTaxingAuthorities: TaxRetrun.ITaxingAuthority[],
    states: ICountryState[],
    checkDeceased: (isMutual: boolean, taxpayerDeceased: boolean, spouseDeceased: boolean, isUpdated: boolean) => void
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    requestEroSignaturePath: (userId: number, callback?: (data?: any) => void) => void;
    company: CompanyStore.ICompanyData;
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty | TaxDocument.ClientReturn) => void;
    locationDropdown: IDropdown[],
    isGroupedReturn?: boolean,
    deletedVouchers: IVoucher[],
    saveDeletedVouchers: (deletedVouchers: IVoucher[]) => void;
    deliverFirstToUpdated: (updated: boolean) => void;
}

export interface ITabClientStates extends IFormGroupViewState {
    selectedEngagement: any,
    spouse: ITaxpayer,
    isJointReturn: boolean,
    clientId: string,
    partenID: string,
    taxYear: number,
    isClientRefundPaymentDueEnabled: boolean,
    expandTaxpayerCard: boolean;
    expandSpouseCard: boolean;
    expandReturnInformation: boolean;
    expandRefundPaymentInformation: boolean;
    expandPartnershipCard: boolean;
    selectedAuthority: number,
    amount: number,
    isPayment: boolean,
    isRefund: boolean,
    overPayAppliedAmount: number,
    netRefund: number,
    penalty: number,
    showPaymentDueAlert: boolean,
    paymentDueAlertMessage: string,
    enableUpdate: boolean
    scale: number;
    locationId: number,
}

export enum ClientInfoRightSideCardType {
    ReturnInformationCard,
    TaxperInformationCard,
    SpouseInformationCard,
    PartnershipInformationCard,
    RefundPaymentInfoCard
}

const isEqual = require("react-fast-compare");

export class TabClientInfo extends React.Component<ITabClientProps, ITabClientStates>{
    constructor(props: ITabClientProps) {
        super(props);
        console.log('props.states in TabClientInfo: ', props.states)
        this.state = {
            currentPage: INVALID_PAGE,
            actionCardShow: true,
            zoomEnabled: false,
            isJointReturn: false,
            selectedEngagement: 0,
            spouse: initailTaxpayerModel,
            clientId: "",
            partenID: "",
            taxYear: 0,
            isClientRefundPaymentDueEnabled: false,
            expandReturnInformation: true,
            expandRefundPaymentInformation: true,
            expandTaxpayerCard: true,
            expandSpouseCard: true,
            expandPartnershipCard: true,
            selectedAuthority: 0,
            amount: 0,
            isPayment: true,
            overPayAppliedAmount: 0,
            isRefund: false,
            netRefund: 0,
            penalty: 0,
            showPaymentDueAlert: false,
            paymentDueAlertMessage: "",
            enableUpdate: false,
            scale: PdfProperties.DefaultScale,
            locationId: 0
        };

        //Fix for bug:53083
        this.state.spouse.clientGuid = Guid.create().toString();

        let years = [];
        for (let i = 2015; i <= new Date().getFullYear(); i++) {
            years.push({ label: i.toString(), value: i });
        }
        this.handleFieldChange = this.handleFieldChange.bind(this);

        this.engagementChange = this.engagementChange.bind(this);
        this.handleClientFieldChange = this.handleClientFieldChange.bind(this);
        this.onChangeMJFCheckbox = this.onChangeMJFCheckbox.bind(this);
    }
    public engagementChange(selectedOption: any) {
        this.setState({ selectedEngagement: selectedOption })
    }
    public onChangeMJFCheckbox(event: any) {
        this.setState({ isJointReturn: event.target.checked });
        if (event.currentTarget.checked == true) {
            let tempTaxReturn = { ...this.props.taxReturn } as TaxRetrun.IMarriedJointTaxReturn
            if (tempTaxReturn.spouse == undefined || tempTaxReturn.spouse == null) {
                tempTaxReturn.spouse = this.state.spouse;
            }
            let tempEvent = event.target.checked;
            this.props.updateTaxDocument(tempTaxReturn, () => {
                this.props.checkDeceased(tempEvent, tempTaxReturn.taxpayer.isDeceased, tempTaxReturn.spouse ? tempTaxReturn.spouse.isDeceased : false, true);
            });

        }
        else {
            let tempTaxReturn: any = { ...this.props.taxReturn };
            tempTaxReturn.spouse = undefined;

            let tempFormsGorup: TaxDocument.IMarriedJointTaxReturn = { ...this.props.taxReturn as TaxDocument.IMarriedJointTaxReturn };
            let formIndex = -1;
            formIndex = tempFormsGorup.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
            if (formIndex > -1) {
                let efileForms: IEFile[] = tempFormsGorup.formGroups[formIndex].forms as IEFile[];
                efileForms.map(efileForm => {
                    efileForm.signatureControls = efileForm.signatureControls.filter(x => x.signatureControlRole != SignatureControlRole.Spouse);
                })
                tempFormsGorup.formGroups[formIndex].forms = efileForms;
                tempFormsGorup.spouse = tempTaxReturn.spouse;
                this.props.updateTaxDocument(tempFormsGorup, () => {
                    this.props.checkDeceased(false, tempTaxReturn.taxpayer.isDeceased, tempTaxReturn.spouse ? tempTaxReturn.spouse.isDeceased : false, true);
                });

            }
        }
    }
    private handleClientFieldChange = (item: TaxDocument.ClientReturn, value: any) => {
        let taxReturn: TaxRetrun.ITaxReturn = { ...this.props.taxReturn };
        taxReturn.isModified = true
        if (item === TaxDocument.ClientReturn.ClientId) {
            this.setState({ clientId: value });
            taxReturn.clientId = value;

        } else if (item === TaxDocument.ClientReturn.Signer) {
            let selectedSignerDetail = this.props.allSignerList.filter(x => x.id == parseInt(value))[0]
            this.setState({ partenID: value });
            taxReturn.partnerId = parseInt(value);
            taxReturn.partner.userId = parseInt(value);
            taxReturn.partner.firstName = selectedSignerDetail ? selectedSignerDetail.firstName : "";
            taxReturn.partner.lastName = selectedSignerDetail ? selectedSignerDetail.lastName : "";
            taxReturn.partner.email = selectedSignerDetail ? selectedSignerDetail.emailAddress : "";
            taxReturn.partner.inactive = selectedSignerDetail ? selectedSignerDetail.inactive : false;
            if (value > 0) {
                this.props.requestEroSignaturePath(parseInt(value), (data) => {
                    let imageUri: string = data;
                    if (imageUri && imageUri.trim() != "") {
                        taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId = parseInt(value);
                    }
                    else {
                        taxReturn.documentSettings.documentSignatureSetting.signatureStampUser.userId = 0; // default to company if no signature uploaded for an ero
                        imageUri = this.props.company.signatureUploadLink
                    }
                    let formIndex = taxReturn.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
                    if (formIndex > -1) {
                        let efileForms: IEFile[] = taxReturn.formGroups[formIndex].forms as IEFile[];
                        efileForms.map(efileForm => {
                            efileForm.signatureControls.map(x => {
                                if (x.signatureControlRole == SignatureControlRole.ERO)
                                    x.selectedEroImage = imageUri
                            });
                        })
                        taxReturn.formGroups[formIndex].forms = efileForms;
                    }
                    this.props.updateTaxDocument(taxReturn);
                });
            }


        } else if (item === TaxDocument.ClientReturn.TaxYear) {
            this.setState({ taxYear: value as number });
            taxReturn.taxYear = value;
        }
        else if (item === TaxDocument.ClientReturn.OfficeLocation) {
            this.setState({ locationId: value as number });
            taxReturn.locationId = value == 0 ? null : value;
        }
        this.props.updateTaxDocument(taxReturn);
    }

    private onSavePaymentRefundInfo = () => {
        const penaltyAmount = this.state.penalty || 0;
        if (this.state.selectedAuthority == 0) {
            this.setState({ showPaymentDueAlert: true, paymentDueAlertMessage: Constants.ClientInfoTab.AddedRefund.AuthorityWarning });
            return false;
        }
        if (this.state.amount == null) {
            this.setState({ showPaymentDueAlert: true, paymentDueAlertMessage: Constants.ClientInfoTab.AddedRefund.AmountEmptyWarning })
            return false;
        }
        if (this.state.amount - this.state.overPayAppliedAmount - penaltyAmount < 0) {
            this.setState({ showPaymentDueAlert: true, paymentDueAlertMessage: Constants.ClientInfoTab.AddedRefund.OverpaymentwithPenaltyWarning });
            return false;
        }
        this.updatePaymentRefundStatement();
        this.setState({ showPaymentDueAlert: false, paymentDueAlertMessage: "", enableUpdate: false })
    }

    private getVoucherUploadLinkCallback = (uploadData: any, voucher: IVoucher) => {
        let voucherScript = new VoucherScript(voucher, this.props.taxReturn);
        voucherScript.prepareVoucher(voucher)
            .then((blob: any): void => {
                this.onVoucherPrepareSuccess(blob, uploadData, voucher);
            }, this.onVoucherPrepareError);
    }

    private onVoucherPrepareSuccess = (blob: any, uploadData: any, propVoucher: IVoucher): void => {
        PDFJS.getDocument(URL.createObjectURL(blob)).then((pdf) => {
            let documentFacade: IPdfDocumentFacade =
                (this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade);
            generateBase64(URL.createObjectURL(blob)).then(base64data => {
                documentFacade.append(pdf, uploadData.fileGuid, base64data);
                let tempVoucher: IVoucher = propVoucher;
                let voucher = this.props.taxReturn.formGroups.find((value) => value.group === DocumentGroups.Vouchers);
                tempVoucher.pageNo = [documentFacade?.getLastPage()];
                tempVoucher.bookmark = tempVoucher.customType == CustomType.None ? tempVoucher.formName :
                    this.setBookmark(tempVoucher.authorityID, tempVoucher.formName)

                if (voucher === undefined) {
                    voucher = {} as IGroup;
                    voucher.group = DocumentGroups.Vouchers;
                    voucher.forms = [] as IFormBase[];
                    voucher.forms.push(tempVoucher);
                    this.props.taxReturn.formGroups.push(voucher);
                }
                else {
                    this.props.taxReturn.formGroups.map((value: any) => {
                        if (value.group === DocumentGroups.Vouchers) {
                            value.forms.push(tempVoucher);
                        }
                    });
                }
                this.props.updateTaxDocument(this.props.taxReturn);

                this.setState({
                    currentPage: tempVoucher.pageNo[0]
                });

                let uploadHelperFunctions = new UploadFunctions();
                uploadHelperFunctions.uploadedFile(blob, uploadData, propVoucher.formName, null, this.onFileUploadSuccess);
            });
        });
    }
    private onFileUploadSuccess = () => {
        HideLoader();
        this.onSuccessEvent();
    }

    private onVoucherPrepareError = (error: any) => {
        HideLoader();
        VenusNotifier.Error(Constants.VoucherTabConstants.AddVoucher.voucherPdfPrepareMessageFailed,
            "Error");
    }

    public setBookmark = (authorityID: number, formName: string) => {
        var authorities: ITaxingAuthority[] = this.props.getAllTaxingAuthorities;
        for (let i in authorities) {
            if (authorities[i].Id === authorityID) {
                return authorities[i].AuthorityName + ' ' + formName;
            }
        }
        return formName;
    }
    public updatePaymentRefundStatement = () => {

        let matchedForms: any;
        var groupId = 0;
        let taxDocument: TaxRetrun.ITaxReturn = { ...this.props.taxReturn };
        let foundIndex: number = -1;
        const { selectedAuthority } = this.state;
        const authority: number = Number(selectedAuthority);
        const { getAllTaxingAuthorities } = this.props;
        let authorityData: { name: string, image: string } =
            getAuthorityData(authority, getAllTaxingAuthorities);
        let voucherIndex = this.props.taxReturn.formGroups
            .findIndex(x => x.group == DocumentGroups.Vouchers);

        taxDocument.formGroups.map((formGroups) => {
            let tmpGroupID = formGroups.group;
            formGroups.forms.map((forms, index) => {
                if (forms.pageNo[0] == this.state.currentPage) {
                    matchedForms = forms;
                    groupId = tmpGroupID;
                    foundIndex = index;
                }
            });
        });
        switch (groupId) {
            case DocumentGroups.Vouchers://payment
                let voucherForm: TaxRetrun.IVoucher = matchedForms as TaxRetrun.IVoucher;

                if (this.state.isPayment) {
                    voucherForm.amount = this.state.amount;
                    voucherForm.paymentType = TaxDocument.VoucherTypes.PaymentVoucher;
                    voucherForm.voucherNo = TaxDocument.VoucherNo.PaymentVoucher;
                    voucherForm.authorityImage = authorityData.image;
                    voucherForm.authorityName = authorityData.name;
                    voucherForm.bookmark = this.setBookmark(authority, voucherForm.formName);
                    voucherForm.authorityID = authority;

                    if (voucherForm.customType !== undefined &&
                        voucherForm.customType !== CustomType.None) {
                        let slicedVoucher = taxDocument.formGroups[voucherIndex].forms.splice(foundIndex, 1);
                        ShowLoader();
                        this.props.getVoucherUploadLink(taxDocument,
                            (uploadData: any) => { this.getVoucherUploadLinkCallback(uploadData, voucherForm) });
                    } else {
                        taxDocument.formGroups.map((formGroups) => {
                            if (formGroups.group == DocumentGroups.Vouchers) {
                                formGroups.forms[foundIndex] = voucherForm;
                            }
                        });
                        this.props.updateTaxDocument(taxDocument, this.onSuccessEvent)
                    }
                }
                else if (this.state.isRefund) {
                    let tempNumber: number[] = [voucherForm.pageNo[0]];
                    var newRefundForm: TaxRetrun.IRefund = {
                        authorityID: authority,
                        bookmark: this.setBookmark(authority, voucherForm.formName),
                        formName: voucherForm.formName,
                        groupId: DocumentGroups.TaxReturns,
                        overPaymentApplied: this.state.overPayAppliedAmount,
                        penalty: this.state.penalty,
                        pageNo: voucherForm.pageNo,
                        overPayment: this.state.amount,
                        formType: TaxDocument.TaxFormType.Refund,
                        refundAmount: this.state.netRefund,
                        isMultipart: false,
                        signatureControls: []
                    };

                    let tempRefundForm: any = (voucherForm.dueDate) ? { ...newRefundForm, dueDate: voucherForm.dueDate } : newRefundForm;
                    taxDocument.formGroups.map((formGroups) => {
                        if (formGroups.group === DocumentGroups.Vouchers) {
                            const deletedVouchers = [...this.props.deletedVouchers];
                            const [deletedForm] = formGroups.forms.splice(foundIndex, 1);
                            if (!this.props.deletedVouchers.find(x => x.pageNo[0] === deletedForm.pageNo[0])) {
                                deletedVouchers.push(deletedForm as TaxRetrun.IVoucher);
                                this.props.saveDeletedVouchers(deletedVouchers);
                            }
                        }
                    });

                    taxDocument.formGroups.map((formGroups) => {
                        if (formGroups.group == DocumentGroups.TaxReturns) {
                            formGroups.forms.push(newRefundForm);
                        }
                    });

                    this.props.updateTaxDocument(taxDocument, this.onSuccessEvent)
                }
                break;
            case DocumentGroups.TaxReturns://Returns
                let refund: TaxRetrun.IRefund = matchedForms as TaxRetrun.IRefund;

                if (this.state.isRefund) {
                    refund.overPayment = this.state.amount;
                    refund.authorityID = authority;
                    refund.penalty = this.state.penalty;
                    refund.overPaymentApplied = this.state.overPayAppliedAmount;
                    refund.bookmark = this.setBookmark(authority, refund.formName);
                    taxDocument.formGroups.map((formGroups) => {
                        if (formGroups.group == DocumentGroups.TaxReturns) {
                            formGroups.forms[foundIndex] = refund;
                        }
                    });
                    this.props.updateTaxDocument(taxDocument, this.onSuccessEvent)
                }
                else if (this.state.isPayment) {

                    var newVoucherForm: TaxRetrun.IVoucher = { ...matchedForms } as TaxRetrun.IVoucher;
                    newVoucherForm.amount = this.state.amount;
                    newVoucherForm.paymentType = TaxDocument.VoucherTypes.PaymentVoucher;
                    newVoucherForm.voucherNo = TaxDocument.VoucherNo.PaymentVoucher;
                    newVoucherForm.onlinePaymentUri = "";
                    newVoucherForm.customType = TaxDocument.CustomType.None;
                    newVoucherForm.authorityImage = authorityData.image;
                    newVoucherForm.authorityName = authorityData.name;
                    newVoucherForm.bookmark = this.setBookmark(authority, newVoucherForm.formName);
                    newVoucherForm.authorityID = authority;
                    newVoucherForm.vocherMode = this.props.deletedVouchers.find(x => x.pageNo[0] === matchedForms.pageNo[0])?.vocherMode ?? TaxDocument.VoucherMode.Recognized;
                    newVoucherForm.formType = TaxFormType.Voucher;

                    if (newVoucherForm.customType !== undefined &&
                        newVoucherForm.customType !== CustomType.None) {
                        let slicedVoucher = taxDocument.formGroups[voucherIndex].forms.splice(foundIndex, 1);
                        ShowLoader();
                        this.props.getVoucherUploadLink(taxDocument,
                            (uploadData: any) => {
                                this.getVoucherUploadLinkCallback(uploadData, newVoucherForm)
                            });
                    } else {


                        taxDocument.formGroups.map((formGroups) => {
                            if (formGroups.group == DocumentGroups.TaxReturns) {
                                formGroups.forms.splice(foundIndex, 1);
                            }
                        });
                        var isVoucherExist: boolean = (taxDocument.formGroups.findIndex(formGroups => {
                            return formGroups.group == DocumentGroups.Vouchers
                        }) == -1) ? false : true;
                        if (!isVoucherExist) {
                            var voucherFormGroup: IGroup = {
                                group: DocumentGroups.Vouchers,
                                forms: [] as IFormBase[]
                            };
                            taxDocument.formGroups.push(voucherFormGroup);
                        }
                        taxDocument.formGroups.map((formGroups) => {
                            if (formGroups.group == DocumentGroups.Vouchers) {
                                formGroups.forms.push(newVoucherForm);
                            }
                        });
                        this.props.updateTaxDocument(taxDocument, this.onSuccessEvent)
                    }
                }
                break;
        }
    }
    private onSuccessEvent = (): void => {
        VenusNotifier.Success(Constants.ClientInfoTab.UpdateRefund.RefundUpdateSuccess, "");
    }

    UNSAFE_componentWillMount() {
        this.setState({
            clientId: this.props.taxReturn.clientId,
            partenID: this.props.taxReturn.partner?.userId.toString(),
            taxYear: this.props.taxReturn.taxYear,
            locationId: this.props.taxReturn.locationId ? this.props.taxReturn.locationId : 0,
        });
    }
    componentDidMount() {
        this.setState({
            isJointReturn: TaxRetrun.isMutual(this.props.taxReturn),
            currentPage: this.props.pages ? this.props.pages[0] : INVALID_PAGE
        });
        if (TaxRetrun.isMutual(this.props.taxReturn)) {
            if (this.props.taxReturn.documentSettings.deliverySettings.deliverTo == TaxRetrun.ClientTypes.Spouse) {
                this.props.deliverFirstToUpdated(true);
            }
            this.setState({ spouse: this.props.taxReturn.spouse }, () => {
                var mutualTaxreturn = this.props.taxReturn as TaxRetrun.IMarriedJointTaxReturn;
                this.props.checkDeceased(true, mutualTaxreturn.taxpayer.isDeceased, mutualTaxreturn.spouse.isDeceased, false);
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<ITabClientProps>, prevState: Readonly<ITabClientStates>, snapshot?: any): void {
        if (this.props.taxReturn.partner?.userId.toString() != this.state.partenID) {
            this.setState({
                partenID: this.props.taxReturn.partner?.userId.toString(),
            });
        }

        if (this.props.taxReturn.locationId && this.props.taxReturn.locationId != this.state.locationId) {
            this.setState({
                locationId: this.props.taxReturn.locationId ? this.props.taxReturn.locationId :
                    this.props.locationDropdown.length === 1 ? this.props.locationDropdown[0].value : 0
            });
        }
        else if (this.state.locationId === 0 && this.props.locationDropdown && this.props.locationDropdown.length === 1) {
            this.handleClientFieldChange(TaxDocument.ClientReturn.OfficeLocation, this.props.locationDropdown[0].value);
        }
    }

    shouldComponentUpdate(nextProps: ITabClientProps, nextState: ITabClientStates) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    onCardToggle = (CardType: ClientInfoRightSideCardType) => {
        switch (CardType) {
            case ClientInfoRightSideCardType.ReturnInformationCard:
                this.setState({
                    expandReturnInformation: !this.state.expandReturnInformation,
                });
                break;
            case ClientInfoRightSideCardType.TaxperInformationCard:
                this.setState({
                    expandTaxpayerCard: !this.state.expandTaxpayerCard
                });
                break;
            case ClientInfoRightSideCardType.SpouseInformationCard:
                this.setState({
                    expandSpouseCard: !this.state.expandSpouseCard
                });
                break;
            case ClientInfoRightSideCardType.PartnershipInformationCard:
                this.setState({
                    expandPartnershipCard: !this.state.expandPartnershipCard
                });
                break;
            case ClientInfoRightSideCardType.RefundPaymentInfoCard:
                this.setState({
                    expandRefundPaymentInformation: !this.state.expandRefundPaymentInformation
                });
                break;
        }

    }

    createLeftPanel = () => {
        return <>
            <BookmarksPane
                title="Pages"
                docId={this.props.docId}
                pdfDocuments={this.props.pdfDocuments}
                sections={this.props.bookmarks}
                onPageDelete={this.onPageDelete}
                onPageSelect={this.onPageSelect}
                tabType={this.props.tabType}
                getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
            />
            <AddedRefundList
                taxReturn={this.props.taxReturn}
                onAddedRefundDelete={this.onAddedRefundDelete}
                onUpdateAddedRefund={this.onUpdateAddedRefund}
                onAddRefund={this.onAddRefund}
                getAllTaxingAuthorities={this.props.getAllTaxingAuthorities} />
            <AddedOverPaymentsList
                taxReturn={this.props.taxReturn}
                onPageSelect={this.onPageSelect}
                getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                authorities={this.props.authorities} />
        </>
    }

    createRightPanel = () => {
        return <>
            <div className={this.state.actionCardShow ? "Card-expand expand" : "Card-collapse collapse"}>
                <ClientReturnInfo
                    allSignerList={this.props.allSignerList}
                    clientId={this.state.clientId}
                    handleChange={this.handleClientFieldChange}
                    selectedEngagement={this.props.taxReturn.engagementType}
                    selectedTaxYear={this.state.taxYear}
                    taxReturn={this.props.taxReturn}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    isMarriedJointly={this.state.isJointReturn}
                    onChangeMJFCheckbox={(e) => this.onChangeMJFCheckbox(e)}
                    partnerId={this.state.partenID}
                    expandReturnInformation={this.state.expandReturnInformation}
                    onCardToggle={this.onCardToggle}
                    prefilledClientInfo={this.props.prefilledClientInfo}
                    resetPrefillInfo={this.props.resetPrefillInfo}
                    locationDropdown={this.props.locationDropdown}
                    selectedLocationId={this.state.locationId}
                    isGroupedReturn={this.props.isGroupedReturn}
                />
                {this.createTaxpayerSidebar()}
                {this.createRefundViewPanel()}
            </div>
        </>
    }

    createTaxpayerSidebar = () => {
        return (TaxDocument.isMutual(this.props.taxReturn) ?
            <div>
                <TaxpayerSidebar
                    title="Taxpayer"
                    model={this.props.taxReturn.taxpayer}
                    prefilledClientInfo={this.props.prefilledClientInfo}
                    resetPrefillInfo={this.props.resetPrefillInfo}
                    handleValueChange={this.handleFieldChange}
                    states={this.props.states}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    expandTaxpayerCard={this.state.expandTaxpayerCard}
                    onCardToggle={this.onCardToggle}
                    expandSpouseCard={this.state.expandSpouseCard}
                />
                <div>
                    {this.state.isJointReturn ?
                        <TaxpayerSidebar
                            title="Spouse"
                            model={this.props.taxReturn.spouse}
                            handleValueChange={this.handleFieldChange}
                            states={this.props.states}
                            isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                            expandTaxpayerCard={this.state.expandTaxpayerCard}
                            onCardToggle={this.onCardToggle}
                            expandSpouseCard={this.state.expandSpouseCard}
                            prefilledClientInfo={this.props.prefilledClientInfo}
                            resetPrefillInfo={this.props.resetPrefillInfo}
                        /> : <div></div>}

                </div>
            </div> : TaxDocument.isIndividual(this.props.taxReturn) ?

                <TaxpayerSidebar
                    title="Taxpayer"
                    model={this.props.taxReturn.taxpayer}
                    handleValueChange={this.handleFieldChange}
                    states={this.props.states}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    expandTaxpayerCard={this.state.expandTaxpayerCard}
                    onCardToggle={this.onCardToggle}
                    expandSpouseCard={this.state.expandSpouseCard}
                    prefilledClientInfo={this.props.prefilledClientInfo}
                    resetPrefillInfo={this.props.resetPrefillInfo}
                />
                : TaxDocument.isPartnership(this.props.taxReturn) &&

                <PartnershipSidebar
                    clientId={this.props.taxReturn.clientId}
                    model={this.props.taxReturn.partnership}
                    handleValueChange={this.handleFieldChange}
                    isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
                    expandPartnershipCard={this.state.expandPartnershipCard}
                    onCardToggle={this.onCardToggle}
                    engagementType={this.props.taxReturn.engagementType}
                    prefilledClientInfo={this.props.prefilledClientInfo}
                    resetPrefillInfo={this.props.resetPrefillInfo}
                />);
    }

    createRefundViewPanel = () => {
        return (this.state.isClientRefundPaymentDueEnabled && <ClientRefundPaymentDue
            currentPage={this.state.currentPage}
            onCardToggle={this.onCardToggle}
            expandRefundPaymentInformation={this.state.expandRefundPaymentInformation}
            amount={this.state.amount}
            isPayment={this.state.isPayment}
            isRefund={this.state.isRefund}
            overPayAppliedAmount={this.state.overPayAppliedAmount}
            selectedAuthority={this.state.selectedAuthority}
            amountChanged={this.amountChanged}
            authorityChange={this.authorityChange}
            overPayAppliedAmountChanged={this.overPayAppliedAmountChanged}
            onChangePenaltyAmount={this.onChangePenaltyAmount}
            paymentChanged={this.paymentChanged}
            refundChanged={this.refundChanged}
            onSaveClick={this.onSavePaymentRefundInfo}
            showPaymentDueAlert={this.state.showPaymentDueAlert}
            paymentDueAlertMessage={this.state.paymentDueAlertMessage}
            netRefund={this.state.netRefund}
            penalty={this.state.penalty}
            onCancel={this.onPageSelect}
            taxingAuthority={this.props.getAllTaxingAuthorities}
            enableUpdate={this.state.enableUpdate}
        />);
    }

    public render() {
        return (
            <>
                <AwesomePdfViewer
                    pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
                    leftPanel={this.createLeftPanel()}
                    rightPanel={this.createRightPanel()}
                    currentPage={this.state.currentPage}
                    pages={this.props.pages}
                    scale={this.state.scale}
                    onScaleChange={(s) => this.setState({ scale: s })}
                    onCurrentPageChange={this.onPaginationClick}
                />
            </>);
    }



    private handleFieldChange = (item: ClientInfoProperty, value: any) => {
        if (item === ClientInfoProperty.clientId) {
            this.props.taxReturn.isModified = true;
            this.props.taxReturn.clientId = value;
            return this.props.updateTaxDocument(this.props.taxReturn);
        }
        if (TaxDocument.isPartnership(this.props.taxReturn)) {
            this.props.taxReturn.partnership.isModified = true;
            switch (item) {
                case ClientInfoProperty.partnerName: this.props.taxReturn.partnership.name = value; break;
                case ClientInfoProperty.partnerEmail: this.props.taxReturn.partnership.email = value; break;
                case ClientInfoProperty.partnerEin: this.props.taxReturn.partnership.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.partnerMobileNumber: this.props.taxReturn.partnership.mobileNumber = value; break;
                case ClientInfoProperty.partnerCountryCode: this.props.taxReturn.partnership.countryCode = value; break;

            }
            return this.props.updateTaxDocument(this.props.taxReturn);
        }

        if (TaxDocument.isMutual(this.props.taxReturn)) {
            this.props.taxReturn.taxpayer.isModified = true;
            this.props.taxReturn.spouse.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName: this.props.taxReturn.taxpayer.name = value; break;
                case ClientInfoProperty.taxpayerEmail: this.props.taxReturn.taxpayer.email = value; break;
                case ClientInfoProperty.taxpayerDateOfBirth: this.props.taxReturn.taxpayer.dob = value; break;
                case ClientInfoProperty.taxpayerAddress: this.props.taxReturn.taxpayer.address = value; break;
                case ClientInfoProperty.taxpayerCity: this.props.taxReturn.taxpayer.city = value; break;
                case ClientInfoProperty.taxpayerState: this.props.taxReturn.taxpayer.state = value; break;
                case ClientInfoProperty.taxpayerZip: this.props.taxReturn.taxpayer.zip = value; break;
                case ClientInfoProperty.taxpayerSSN: this.props.taxReturn.taxpayer.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.taxpayerDeceased: this.props.taxReturn.taxpayer.isDeceased = value;
                    this.props.deliverFirstToUpdated(false);
                    this.props.checkDeceased(true, this.props.taxReturn.taxpayer.isDeceased,
                        this.props.taxReturn.spouse.isDeceased, true);
                    break;
                case ClientInfoProperty.taxpayerMobileNumber: this.props.taxReturn.taxpayer.mobileNumber = value; break;
                case ClientInfoProperty.taxpayerCountryCode: this.props.taxReturn.taxpayer.countryCode = value; break;
                //Spouse
                case ClientInfoProperty.spouseName: this.props.taxReturn.spouse.name = value; break;
                case ClientInfoProperty.spouseEmail: this.props.taxReturn.spouse.email = value; break;
                case ClientInfoProperty.spouseDateOfBirth: this.props.taxReturn.spouse.dob = value; break;
                case ClientInfoProperty.spouseAddress: this.props.taxReturn.spouse.address = value; break;
                case ClientInfoProperty.spouseCity: this.props.taxReturn.spouse.city = value; break;
                case ClientInfoProperty.spouseState: this.props.taxReturn.spouse.state = value; break;
                case ClientInfoProperty.spouseZip: this.props.taxReturn.spouse.zip = value; break;
                case ClientInfoProperty.spouseSSN: this.props.taxReturn.spouse.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.spouseDeceased: this.props.taxReturn.spouse.isDeceased = value;
                    this.props.deliverFirstToUpdated(false);
                    this.props.checkDeceased(true, this.props.taxReturn.taxpayer.isDeceased,
                        this.props.taxReturn.spouse.isDeceased, true);
                    break;
                case ClientInfoProperty.spouseMobileNumber: this.props.taxReturn.spouse.mobileNumber = value; break;
                case ClientInfoProperty.spouseCountryCode: this.props.taxReturn.spouse.countryCode = value; break;
            }
            return this.props.updateTaxDocument(this.props.taxReturn);
        }

        if (TaxDocument.isIndividual(this.props.taxReturn)) {
            this.props.taxReturn.taxpayer.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName: this.props.taxReturn.taxpayer.name = value; break;
                case ClientInfoProperty.taxpayerEmail: this.props.taxReturn.taxpayer.email = value; break;
                case ClientInfoProperty.taxpayerDateOfBirth: this.props.taxReturn.taxpayer.dob = value; break;
                case ClientInfoProperty.taxpayerAddress: this.props.taxReturn.taxpayer.address = value; break;
                case ClientInfoProperty.taxpayerCity: this.props.taxReturn.taxpayer.city = value; break;
                case ClientInfoProperty.taxpayerState: this.props.taxReturn.taxpayer.state = value; break;
                case ClientInfoProperty.taxpayerZip: this.props.taxReturn.taxpayer.zip = value; break;
                case ClientInfoProperty.taxpayerSSN: this.props.taxReturn.taxpayer.ssn = value.replace(/-/g, ''); break;
                case ClientInfoProperty.taxpayerDeceased: this.props.taxReturn.taxpayer.isDeceased = value;
                    this.props.checkDeceased(false, this.props.taxReturn.taxpayer.isDeceased, false, true); break;
                case ClientInfoProperty.taxpayerMobileNumber: this.props.taxReturn.taxpayer.mobileNumber = value; break;
                case ClientInfoProperty.taxpayerCountryCode: this.props.taxReturn.taxpayer.countryCode = value; break;
            }
            return this.props.updateTaxDocument(this.props.taxReturn);
        }
    }

    private onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: pageNo });
        this.getPageDetails(pageNo);
    }

    private onPageSelect = (page: number, voucherNo?: number, isViewClientInfo?: boolean, isClientInfo?: boolean) => {
        this.getPageDetails(page, isViewClientInfo, isClientInfo);
        this.setState({ currentPage: page, enableUpdate: false });
    }

    getPageDetails = (page: number, isViewClientInfo?: boolean, isClientInfo?: boolean) => {
        let matchedForms: any;
        var groupId = 0;

        this.props.taxReturn.formGroups.map((formGroups) => {
            let tmpGroupID = formGroups.group;
            formGroups.forms.map((forms) => {
                if (forms.pageNo.some(x => page == x)) {
                    matchedForms = forms;
                    groupId = tmpGroupID;
                }
            });
        });
        let engagementType: any = (typeof this.props.taxReturn.engagementType == "number") ? TaxRetrun.EngagementType[this.props.taxReturn.engagementType] : this.props.taxReturn.engagementType;
        if (matchedForms?.formName == 'ClientInfo' && isViewClientInfo && isClientInfo) {
            this.setState({
                isClientRefundPaymentDueEnabled: false,
                expandReturnInformation: false,
                expandRefundPaymentInformation: false,
            });
            if (isIndividualEnagagementType(engagementType)) {
                this.setState({
                    expandTaxpayerCard: true,
                    expandSpouseCard: true,
                });
            }
            else {
                this.setState({
                    expandPartnershipCard: true
                });
            }
        } else {
            this.setState({
                isClientRefundPaymentDueEnabled: true,
                expandReturnInformation: false,
                expandRefundPaymentInformation: true,
                expandTaxpayerCard: false,
                expandSpouseCard: false,
                expandPartnershipCard: false,
            });
        }
        if (groupId != DocumentGroups.Vouchers && groupId != DocumentGroups.TaxReturns) {
            this.setState({ isClientRefundPaymentDueEnabled: false })
        }
        switch (groupId) {
            case DocumentGroups.Vouchers://payment
                {
                    let voucherForm: TaxDocument.IVoucher = matchedForms as TaxDocument.IVoucher;

                    this.setState({
                        isPayment: true,
                        isRefund: false,
                        amount: voucherForm.amount,
                        overPayAppliedAmount: 0,
                        netRefund: voucherForm.amount,
                        selectedAuthority: voucherForm.authorityID
                    });
                }
                break;
            case DocumentGroups.TaxReturns://Returns
                {
                    let refund: TaxDocument.IRefund = matchedForms as TaxDocument.IRefund;
                    const penaltyAmount = refund.penalty || 0;
                    this.setState({
                        isPayment: false,
                        isRefund: true,
                        amount: refund.overPayment,
                        overPayAppliedAmount: refund.overPaymentApplied,
                        penalty: penaltyAmount,
                        netRefund: refund.overPayment - refund.overPaymentApplied - penaltyAmount,
                        selectedAuthority: refund.authorityID
                    });
                }
                break;
        }
    }
    private onPageDelete = (page: number) => {
        let pages: number[] = [];
        let isRefund = false;
        pages.push(page);
        const itemRefundIndex: number = this.props.bookmarks.findIndex(x => x.heading === 'Refunds & Payments Due');
        isRefund = itemRefundIndex !== - 1 && this.props.bookmarks[itemRefundIndex].pages.filter(x => x.pageNo == page)[0]?.refundType == RefundType.Refund;

        if (isRefund) {
            this.props.deletePages(pages);
            VenusNotifier.Success(Constants.ClientInfoTab.DeleteRefund.RefundDeleteSuccess, null);
        }
        else {
            this.props.reGroupPreview(pages, DocumentGroups.Deleted);
        }
        this.setState({ isClientRefundPaymentDueEnabled: false })
    }

    private onAddedRefundDelete = (refund: TaxDocument.IRefundInfo) => {
        let refundIndex = this.props.taxReturn.refundInfo.findIndex(x => x.id === refund.id);
        //if (refund.id > 0) {
        //	let refundIndex = this.props.taxReturn.refundInfo.findIndex(x => x.id === refund.id);
        //	this.props.taxReturn.refundInfo.splice(refundIndex, 1);
        //	this.props.updateTaxDocument(this.props.taxReturn);
        //}
        if (refund.id === 0 && refund.customRefundGUID !== undefined) {
            refundIndex = this.props.taxReturn.refundInfo.findIndex(x => x.customRefundGUID == refund.customRefundGUID);
        }
        this.props.taxReturn.refundInfo.splice(refundIndex, 1);
        this.props.updateTaxDocument(this.props.taxReturn);

    }
    private onUpdateAddedRefund = (refund: TaxDocument.IRefundInfo) => {
        let refundIndex: number = -1;
        if (refund.id !== 0) {
            refundIndex = this.props.taxReturn.refundInfo.findIndex(x => x.id === refund.id);
        }
        else if (refund.customRefundGUID !== undefined) {
            refundIndex = this.props.taxReturn.refundInfo.findIndex(x => x.customRefundGUID === refund.customRefundGUID);
        }
        this.props.taxReturn.refundInfo[refundIndex] = refund;
        this.props.updateTaxDocument(this.props.taxReturn);
    }

    private onAddRefund = (refund: TaxDocument.IRefundInfo) => {
        refund.customRefundGUID = Guid.create();
        refund.documentId = this.props.taxReturn.id;
        this.props.taxReturn.refundInfo.push(refund);
        this.props.updateTaxDocument(this.props.taxReturn);
    }

    authorityChange = (event: any) => {
        this.setState({ selectedAuthority: event.currentTarget.value, enableUpdate: true });
    }

    amountChanged = (value: number) => {
        const penaltyAmount = this.state.penalty || 0;
        const netRefund = value - this.state.overPayAppliedAmount - penaltyAmount;
        this.setState({
            amount: value,
            netRefund: netRefund,
            showPaymentDueAlert: netRefund < 0 ? true : false,
            paymentDueAlertMessage: netRefund < 0 ?
                Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning : "",
            enableUpdate: true
        });
    }

    paymentChanged = (event: any) => {
        this.setState({ isPayment: true, isRefund: false, enableUpdate: true });
    }

    refundChanged = (event: any) => {
        this.setState({ isPayment: false, isRefund: true, enableUpdate: true });
    }

    overPayAppliedAmountChanged = (value: number) => {
        const penaltyAmount = this.state.penalty || 0;
        const netRefund = this.state.amount - value - penaltyAmount;
        this.setState({
            overPayAppliedAmount: value,
            netRefund: netRefund,
            enableUpdate: true,
            showPaymentDueAlert: netRefund < 0 ? true : false,
            paymentDueAlertMessage: netRefund < 0 ?
                Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning : ""
        });
    }

    onChangePenaltyAmount = (value: number) => {
        const netRefund = this.state.amount - this.state.overPayAppliedAmount - value;
        this.setState({
            penalty: value,
            netRefund: netRefund,
            enableUpdate: true,
            showPaymentDueAlert: netRefund < 0 ? true : false,
            paymentDueAlertMessage: netRefund < 0 ?
                Constants.ClientInfoTab.AddedRefund.OverpaymentLessThanWarning : ""
        });
    }

    onSaveClick = () => {

    }
};
//=============================================================================
