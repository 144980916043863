import * as bootbox from 'bootbox';
import * as React from 'react';
import { Button, Modal, OverlayTrigger } from 'react-bootstrap';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import { ClientInfoConstants } from '../helper/Constants';
import * as Helper from '../helper/HelperFunctions';
import { ClientInfoProperty } from './ClientInfoEnum';
import { Partnership } from './Partnership';
import { Spouse } from './Spouse';
import { Taxpayer } from './Taxpayer';
import { ClientTypesNumber, ISignerModel, isIndividual, isMutual, isPartnership, ITaxingAuthority, ITaxReturn, SignatureStatus, DocumentStatus } from './TaxReturn';
import { ICountryState } from '../../Core/ViewModels/Company/CompanyViewModel';
import { Overlay, Popover } from 'react-bootstrap';
import { isValidEmailAddress } from '../helper/Validations';
import { Guid } from '../../Core/Utilities/Guid';
import { logger } from '../../routes/LoggedIn';
import { IDropdown } from '../../Core/Common/Dropdown';
import { ISMSNotificationDetails } from '../../store/common/SMSNotificationDetailsStore';

export interface IClientInfoProps {
    show: boolean;
    model: any;     //ITaxReturn, IDeliveredTaxReturns
    onCancel(id: number): void;
    onSave(id: number, isMailSend: boolean, clientGUIDs: string[], isEmailUpdated: boolean, signerInfo: ISignerModel, callback?: any): void;
    updateTaxDocument: (taxDocument: ITaxReturn) => void;
    isDeliveredReturn: boolean;
    nextSignerDetails?: ISignerModel;
    states: ICountryState[];
    locationDropdown: IDropdown[];
    isGroupedReturn: boolean;
    skipEroValidation: boolean;
    loadingMessage?: boolean;
    setLoadingMessageToInitialState?: () => void;
    showSMSTextNotifications: boolean,
    isTPOpted: boolean,
    isSpouseOpted: boolean,
    smsNotificationDetails: ISMSNotificationDetails,
    oldClientId: string,
    updateTPOpted: (tpOpted: boolean) => void,
    updateSpouseOpted: (spouseOpted: boolean) => void,
    mobileNumberChanged?: ()=>void,
}

export interface IEditClientInfoState {
    loadingMessage: string | undefined;
    taxPayerEmailAddress: string;
    spouseEmailAddress: string;
    isEmailUpdated: boolean;
    clientGUIDs: string[];
    showClosePopover: boolean;
    isMailSend: boolean;
    signerInfo: ISignerModel;
}
const msg = {
    saving: 'Saving Client Info ,Please Wait...',
    loading: 'Loading, Please Wait...'
}

export class ClientInfo extends React.Component<IClientInfoProps, IEditClientInfoState> {
    constructor(props: IClientInfoProps) {
        super(props);
        this.state = {
            loadingMessage: undefined,
            taxPayerEmailAddress: "",
            spouseEmailAddress: "",
            isEmailUpdated: false,
            clientGUIDs: [],
            showClosePopover: false,
            isMailSend: false,
            signerInfo: undefined,
        };

        this.onPopupClose = this.onPopupClose.bind(this);
    }

    onPopupClose = () => {
        this.setState({
            loadingMessage: undefined,
            clientGUIDs: [],
            isEmailUpdated: false,
            isMailSend: false,
        }, () => {
            this.props.model && this.props.onCancel(this.props.model.id);
        })
    }

    private filterClientGUID = (clientGUID: string) => {
        let tempClientGUIDs: string[] = Object.assign([], this.state.clientGUIDs);
        if (this.state.clientGUIDs.length > 0) {
            if (tempClientGUIDs.indexOf(clientGUID) > -1) {
                tempClientGUIDs.splice(tempClientGUIDs.indexOf(clientGUID), 1);
            }
        }
        tempClientGUIDs.push(clientGUID);
        return tempClientGUIDs;
    }

    componentDidUpdate(prevProps: IClientInfoProps) {
        if (this.props.loadingMessage !== prevProps.loadingMessage && this.props.loadingMessage == true && this.props.isDeliveredReturn && !(this.props.isGroupedReturn)) {
            this.setState({
                loadingMessage: undefined
            });
            this.props.setLoadingMessageToInitialState && this.props.setLoadingMessageToInitialState();
        }
    }

    public render() {
        const popover = (
            <Popover id="email-updated-confirmation">
                <Popover.Title as="h3">Confirmation</Popover.Title>
                <Popover.Content>
                    {ClientInfoConstants.emailUpdatedConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={this.onConfirm}
                            title="OK"
                            data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                            style={{ marginLeft: "5px" }}>
                            Yes
                        </button>
                        <button
                            onClick={this.onEmailUpdateConfirmationCancel}
                            title="Cancel"
                            data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                        </button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        const isDataLoaded = this.props.isDeliveredReturn ? this.props.show && this.props.model &&
            Helper.isClientInfoLoaded(this.props.model) && this.props.nextSignerDetails :
            this.props.show && this.props.model && Helper.isClientInfoLoaded(this.props.model);

        let loadingMessage: string | undefined = !isDataLoaded && isDataLoaded != '' ? msg.loading : this.state.loadingMessage;
        const taxReturn: ITaxReturn = this.props.model as ITaxReturn;
        return (<Modal className='edit-client-info' show={this.props.show} onHide={this.onPopupClose}>
            <Modal.Header closeButton>
                <Modal.Title className='custom-modal-header'>
                    <span className='modalIcon text-secondary fa fa-address-card'>
                    </span>Edit Client Info
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingOverlay style={{ height: '100%' }}>
                    <div>
                        {
                            this.props.model && isMutual(this.props.model) ?
                                (<div className="row">
                                    <Taxpayer
                                        clientId={this.props.model.clientId}
                                        model={this.props.model.taxpayer}
                                        handleValueChange={this.handleFieldChange}
                                        states={this.props.states}
                                        taxReturn={this.props.model}
                                        isDeliveredReturn={this.props.isDeliveredReturn}
                                        locationDropdown={this.props.locationDropdown}
                                        isGroupedReturn={this.props.isGroupedReturn}
                                        isMandatory={!this.props.model.taxpayer?.isDeceased}
                                        showSMSTextNotifications={this.props.showSMSTextNotifications}
                                        isTPOpted={this.props.isTPOpted}
                                        smsNotificationDetails={this.props.smsNotificationDetails}
                                        updateTPOpted={this.props.updateTPOpted} />
                                    <Spouse
                                        model={this.props.model.spouse}
                                        states={this.props.states}
                                        handleValueChange={this.handleFieldChange}
                                        taxReturn={this.props.model}
                                        isMandatory={!this.props.model.spouse?.isDeceased}
                                        showSMSTextNotifications={this.props.showSMSTextNotifications}
                                        isSpouseOpted={this.props.isSpouseOpted}
                                        smsNotificationDetails={this.props.smsNotificationDetails}
                                        updateSpouseOpted={this.props.updateSpouseOpted} />
                                </div>)
                                : this.props.model && isIndividual(this.props.model) ?
                                    (<div className="row">
                                        <Taxpayer
                                            clientId={this.props.model.clientId}
                                            model={this.props.model.taxpayer}
                                            handleValueChange={this.handleFieldChange}
                                            states={this.props.states}
                                            taxReturn={this.props.model}
                                            isDeliveredReturn={this.props.isDeliveredReturn}
                                            locationDropdown={this.props.locationDropdown}
                                            isGroupedReturn={this.props.isGroupedReturn}
                                            isMandatory={true}
                                            showSMSTextNotifications={this.props.showSMSTextNotifications}
                                            smsNotificationDetails={this.props.smsNotificationDetails}
                                            isTPOpted={this.props.isTPOpted}
                                            updateTPOpted={this.props.updateTPOpted} />
                                    </div>)
                                    : this.props.model && isPartnership(this.props.model) &&
                                    (<div className="row">
                                        <Partnership
                                            clientId={this.props.model.clientId}
                                            model={this.props.model.partnership}
                                            handleValueChange={this.handleFieldChange}
                                            taxReturn={this.props.model}
                                            isDeliveredReturn={this.props.isDeliveredReturn}
                                            locationDropdown={this.props.locationDropdown}
                                            isGroupedReturn={this.props.isGroupedReturn}
                                            engagementType={this.props.model.engagementType}
                                            showSMSTextNotifications={this.props.showSMSTextNotifications}
                                            smsNotificationDetails={this.props.smsNotificationDetails}
                                            isTPOpted={this.props.isTPOpted}
                                            updateTPOpted={this.props.updateTPOpted} />
                                    </div>)
                        }
                    </div>
                    <Loader loading={loadingMessage} text={loadingMessage} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={this.onPopupClose} >
                    <i className="fas fa-times" />Cancel
                </Button>
                {(this.props.model && this.Validate() === true && this.props.isDeliveredReturn && this.state.isEmailUpdated) ?
                    <OverlayTrigger rootClose trigger="click" onEnter={() => document.body.click()} placement="top" overlay={popover} >
                        <Button
                            variant='info'
                            disabled={loadingMessage ? true : false || this.props.model && this.props.model.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY}
                            onClick={() => { this.setState({ showClosePopover: false }) }} >
                            <i className='fas fa-save'></i>Save
                        </Button>
                    </OverlayTrigger>
                    : <Button
                        variant='info'
                        disabled={loadingMessage ? true : false || this.props.model && this.props.model.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY}
                        onClick={this.onConfirm} >
                        <i className='fas fa-save'></i>Save
                    </Button>
                }
            </Modal.Footer>
        </Modal>)
    }

    private Validate() {
        const taxReturn: ITaxReturn = this.props.model as ITaxReturn;
        let isValid: boolean = true;
        if (isPartnership(taxReturn)) {
            if (!isValidEmailAddress(taxReturn.partnership?.email)) {
                isValid = false;
            }
        } else if (isMutual(taxReturn)) {
            if ((!taxReturn.taxpayer.email) || (!isValidEmailAddress(taxReturn.taxpayer?.email))) {
                isValid = false;
            } else if ((!taxReturn.spouse.email) || !isValidEmailAddress(taxReturn.spouse?.email)) {
                isValid = false;
            }
        } else if (isIndividual(taxReturn)) {
            if (!isValidEmailAddress(taxReturn.taxpayer?.email)) {
                isValid = false;
            }
        }
        return isValid;
    }

    private onConfirm = () => {
        this.updateReturn(this.state.isMailSend);
    }

    private updateReturn = (isSendMail: boolean) => {
        const taxReturn: ITaxReturn = this.props.model as ITaxReturn;
        let returnValue = true;
        if (this.props.isDeliveredReturn && !(this.props.isGroupedReturn)) {
            returnValue = Helper.validateDocumentLocation(taxReturn);
        }
        if (Helper.validateClientInfo(taxReturn, true) && Helper.validateEditClientInfoEmails(taxReturn, true) && returnValue) {
            if (this.props.isDeliveredReturn && this.state.isEmailUpdated) {
                let userThis = this;
                userThis.setState({ loadingMessage: msg.loading, isEmailUpdated: false, showClosePopover: false }, () => {
                    this.props.onSave(userThis.props.model.id, isSendMail, userThis.state.clientGUIDs, true, this.state.signerInfo, () => {
                        userThis.setState({ loadingMessage: undefined, clientGUIDs: [], isMailSend: false, signerInfo: undefined, showClosePopover: false })
                        logger.trackTrace(`Edit client info email address updated successfully for documentId: ${taxReturn.id?.toString()} and documentGuid: ${taxReturn.documentGuid}`, { "DocumentId": taxReturn.id?.toString(), "DocumentGuid": taxReturn.documentGuid });
                    });
                });
                return true;
            }
            else {
                let userThis = this;
                userThis.setState({ loadingMessage: msg.loading, isEmailUpdated: false, showClosePopover: false }, () => {
                    this.props.onSave(userThis.props.model.id, isSendMail, userThis.state.clientGUIDs, false, this.state.signerInfo, () => {
                        userThis.setState({ loadingMessage: undefined, clientGUIDs: [], isMailSend: false, signerInfo: undefined, showClosePopover: false })
                        logger.trackTrace(`Edit client info updated successfully for documentId: ${taxReturn.id?.toString()} and documentGuid: ${taxReturn.documentGuid}`, { "DocumentId": taxReturn.id?.toString(), "DocumentGuid": taxReturn.documentGuid });
                    });
                });
                return false;
            }
        }
    }

    private onEmailUpdateConfirmationCancel = () => {
        this.updateReturn(false);
    }

    private setGUIDState = (signerType: ClientTypesNumber, clientGuid: string) => {
        const taxReturn: any = this.props.model;
        if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingESign] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AwaitingUpload]) {
            this.props.nextSignerDetails && Number(this.props.nextSignerDetails.signerType) == signerType && this.setState({
                isEmailUpdated: true,
                isMailSend: true
            });
        }
        else if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.SignedAndESigned]) {
            this.props.nextSignerDetails && Number(this.props.nextSignerDetails.signerType) == signerType && this.setState({
                isEmailUpdated: true,
                clientGUIDs: this.filterClientGUID(clientGuid),
                isMailSend: true
            });
        }
        else if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ESigned] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ESignedInOffice] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.Uploaded] ||
            taxReturn.signatureStatus == SignatureStatus[SignatureStatus.ManuallySigned]) {
            this.setState({
                isEmailUpdated: true,
                clientGUIDs: this.filterClientGUID(clientGuid),
                isMailSend: true
            });
        } else if (taxReturn.signatureStatus == SignatureStatus[SignatureStatus.PartiallySigned]) {
            if (this.props.nextSignerDetails && Number(this.props.nextSignerDetails.signerType) == signerType) {
                this.setState({
                    isEmailUpdated: true,
                    isMailSend: true
                });
            } else {
                this.setState({
                    isEmailUpdated: true,
                    signerInfo: this.props.model !== undefined && this.props.model.signedDetails && this.props.model.signedDetails.find((x: { signerType: ClientTypesNumber; }) => x.signerType == signerType)
                });
            }
        }
    }

    private handleFieldChange = (item: ClientInfoProperty, value: any) => {
        const taxReturn: any = this.props.model;

        if (item === ClientInfoProperty.clientId) {
            this.props.model.isModified = true;
            this.props.model.clientId = value;
            return this.props.updateTaxDocument(this.props.model);
        }
        if (item === ClientInfoProperty.officeLocation) {
            this.props.model.isModified = true;
            this.props.model.locationId = value == 0 ? null : value;
            return this.props.updateTaxDocument(this.props.model);
        }
        if (item === ClientInfoProperty.contactPerson) {
            this.props.model.isModified = true;
            if (this.props.model?.documentSettings?.deliverySettings) {
                this.props.model.documentSettings.deliverySettings.contactPerson = value == 0 ? null : value;
            }
            return this.props.updateTaxDocument(this.props.model);
        }
        if (isPartnership(this.props.model)) {
            this.props.model.partnership.isModified = true;
            switch (item) {
                case ClientInfoProperty.partnerName: this.props.model.partnership.name = value; break;
                case ClientInfoProperty.partnerEmail: {
                    this.props.model.partnership.email = value || '';
                    if (isValidEmailAddress(value)) {
                        this.props.model.partnership.clientGuid = Guid.newGuid().toString();
                    }
                    this.setGUIDState(ClientTypesNumber.Partnership, this.props.model.partnership.clientGuid);
                    break;
                }
                case ClientInfoProperty.partnerEin: this.props.model.partnership.ssn = value; break;
                case ClientInfoProperty.partnerCountryCode: {
					this.props.model.partnership.countryCode = value;
					this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
				}
                case ClientInfoProperty.partnerMobileNumber:
                    { 
                        this.props.model.partnership.mobileNumber = value; 
                        this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                        break;
                    }

            }
            return this.props.updateTaxDocument(this.props.model);
        }

        if (isMutual(this.props.model)) {
            this.props.model.taxpayer.isModified = true;
            this.props.model.spouse.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName: this.props.model.taxpayer.name = value; break;
                case ClientInfoProperty.taxpayerEmail: {
                    this.props.model.taxpayer.email = value || '';
                    if (isValidEmailAddress(value)) {
                        this.props.model.taxpayer.clientGuid = Guid.newGuid().toString();
                    }
                    this.setGUIDState(ClientTypesNumber.Taxpayer, this.props.model.taxpayer.clientGuid);
                    break;
                }
                case ClientInfoProperty.taxpayerDateOfBirth: this.props.model.taxpayer.dob = value; break;
                case ClientInfoProperty.taxpayerAddress: this.props.model.taxpayer.address = value; break;
                case ClientInfoProperty.taxpayerCity: this.props.model.taxpayer.city = value; break;
                case ClientInfoProperty.taxpayerState: this.props.model.taxpayer.state = value; break;
                case ClientInfoProperty.taxpayerZip: this.props.model.taxpayer.zip = value; break;
                case ClientInfoProperty.taxpayerSSN: this.props.model.taxpayer.ssn = value; break;
                case ClientInfoProperty.taxpayerCountryCode: {
					this.props.model.taxpayer.countryCode = value; 
					this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
				}
                case ClientInfoProperty.taxpayerMobileNumber: {
                    this.props.model.taxpayer.mobileNumber = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                     break;
                    }
                //Spouse
                case ClientInfoProperty.spouseName: this.props.model.spouse.name = value; break;
                case ClientInfoProperty.spouseEmail: {
                    this.props.model.spouse.email = value || '';
                    if (isValidEmailAddress(value)) {
                        this.props.model.spouse.clientGuid = Guid.newGuid().toString();
                    }
                    this.setGUIDState(ClientTypesNumber.Spouse, this.props.model.spouse.clientGuid);
                    break;
                }
                case ClientInfoProperty.spouseDateOfBirth: this.props.model.spouse.dob = value; break;
                case ClientInfoProperty.spouseAddress: this.props.model.spouse.address = value; break;
                case ClientInfoProperty.spouseCity: this.props.model.spouse.city = value; break;
                case ClientInfoProperty.spouseState: this.props.model.spouse.state = value; break;
                case ClientInfoProperty.spouseZip: this.props.model.spouse.zip = value; break;
                case ClientInfoProperty.spouseSSN: this.props.model.spouse.ssn = value; break;
                case ClientInfoProperty.spouseCountryCode:{
					this.props.model.spouse.countryCode = value;
					this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
				}
                case ClientInfoProperty.spouseMobileNumber: {
                    this.props.model.spouse.mobileNumber = value; 
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
                }
            }
            return this.props.updateTaxDocument(this.props.model);
        }

        if (isIndividual(this.props.model)) {
            this.props.model.taxpayer.isModified = true;
            switch (item) {
                case ClientInfoProperty.taxpayerName: this.props.model.taxpayer.name = value; break;
                case ClientInfoProperty.taxpayerEmail: {
                    this.props.model.taxpayer.email = value || '';
                    if (isValidEmailAddress(value)) {
                        this.props.model.taxpayer.clientGuid = Guid.newGuid().toString();
                    }
                    this.setGUIDState(ClientTypesNumber.Taxpayer, this.props.model.taxpayer.clientGuid);
                    break;
                }
                case ClientInfoProperty.taxpayerDateOfBirth: this.props.model.taxpayer.dob = value; break;
                case ClientInfoProperty.taxpayerAddress: this.props.model.taxpayer.address = value; break;
                case ClientInfoProperty.taxpayerCity: this.props.model.taxpayer.city = value; break;
                case ClientInfoProperty.taxpayerState: this.props.model.taxpayer.state = value; break;
                case ClientInfoProperty.taxpayerZip: this.props.model.taxpayer.zip = value; break;
                case ClientInfoProperty.taxpayerSSN: this.props.model.taxpayer.ssn = value; break;
                case ClientInfoProperty.taxpayerCountryCode: {
					this.props.model.taxpayer.countryCode = value;
					this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                    break;
				}					
				case ClientInfoProperty.taxpayerMobileNumber: {
                    this.props.model.taxpayer.mobileNumber = value;
                    this.props.mobileNumberChanged && this.props.mobileNumberChanged();
                     break;
                    }
            }
            return this.props.updateTaxDocument(this.props.model);
        }
    }
}
export default ClientInfo;
