import * as React from "react";
import { FormGroup, FormLabel, FormControl, Col, Card, Accordion } from "react-bootstrap";
import { ClientInfoProperty } from "../../ClientInfoEnum";
import { ITaxClient } from "../../TaxClient";
import { ClientInfoRightSideCardType } from "../Tabs/TabClientInfo";
import { FilterSSN, handleInvalidCountryCodeEntered } from "../../../helper/HelperFunctions";
import { TaxIdentificationNumber } from "../../../common/controls/TaxIdentificationNumber";
import { EngagementType, IPrefilledClientInfo } from "../../TaxReturn";
import { isEqual } from "lodash";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, { CountryData } from "@sssuite-component-ui/react-phone-input-2";
import { CompanySettingsConstants } from "../../../helper/Constants";

export interface IPartnershipProps {
    clientId: string;
    model: ITaxClient;
    handleValueChange(item: ClientInfoProperty, value: string): void;
    isAssignedToLoggedinUser?: boolean;
    expandPartnershipCard: boolean;
    onCardToggle: (cardType: ClientInfoRightSideCardType) => void;
    engagementType: EngagementType;
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (item: ClientInfoProperty) => void;
}

export interface IPartnershipState {
    id: number;
    name: string;
    email: string;
    eIN: string;
    mobileNumber: string;
    countryCode: string;
    mobileNumberFullValue: string;
}

export class PartnershipSidebar extends React.Component<IPartnershipProps, IPartnershipState> {
    constructor(props: IPartnershipProps) {
        super(props);
        this.state = {
            name: props.model.name !== undefined ? props.model.name : "",
            id: props.model.id !== undefined ? props.model.id : 0,
            email: props.model.email !== undefined ? props.model.email : "",
            eIN: props.model.ssn !== undefined ? props.model.ssn : "",
            mobileNumber: props.model.mobileNumber !== undefined ? props.model.mobileNumber : "",
            countryCode: props.model.countryCode !== undefined ? props.model.countryCode : "",
            mobileNumberFullValue: `${props.model.countryCode || "+1"}${props.model.mobileNumber || ""}`
        };
        this.handleChangeId = this.handleChangeId.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeEIN = this.handleChangeEIN.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: IPartnershipProps) {
        if (nextProps.model && isEqual(this.props.prefilledClientInfo, nextProps.prefilledClientInfo)) {
            this.setState({
                name: nextProps.model.name,
                id: nextProps.model.id,
                email: nextProps.model.email,
                eIN: nextProps.model.ssn,
                mobileNumber: nextProps.model.mobileNumber || "",
                countryCode: nextProps.model.countryCode || "+1",
                mobileNumberFullValue: `${nextProps.model.countryCode || "+1"}${nextProps.model.mobileNumber || ""}`
            });
        }
    }

    handleResetPrefillInfo = (item: ClientInfoProperty) => {
        this.props.resetPrefillInfo && this.props.resetPrefillInfo(item);
    };

    handleChangeId(event: any) {
        this.props.handleValueChange(ClientInfoProperty.clientId, event.target.value);
    }

    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerName);
    }
    handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
        this.handleResetPrefillInfo(ClientInfoProperty.partnerEmail);
    }
    handleChangeEIN(value: string) {
        this.props.handleValueChange(ClientInfoProperty.partnerEin, value);
        this.handleResetPrefillInfo(ClientInfoProperty.partnerEin);
    }

    handleBlurMobileNumber = (event: any) => {
        this.props.handleValueChange(ClientInfoProperty.partnerMobileNumber, this.state.mobileNumber);
        this.props.handleValueChange(ClientInfoProperty.partnerCountryCode, this.state.countryCode);
    };

    onMobileChange = (fullValue: string, countryObject: CountryData): void => {
        fullValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
        this.setState({
            mobileNumber: fullValue.slice(countryObject.dialCode.length),
            countryCode: fullValue ? `+${countryObject.dialCode}` : "",
            mobileNumberFullValue: fullValue
        });
        this.props.handleValueChange(ClientInfoProperty.partnerMobileNumber, fullValue.slice(countryObject.dialCode.length));
        this.props.handleValueChange(ClientInfoProperty.partnerCountryCode, fullValue ? `+${countryObject.dialCode}` : "");
        this.handleResetPrefillInfo(ClientInfoProperty.partnerMobileNumber);
        this.handleResetPrefillInfo(ClientInfoProperty.partnerCountryCode);
    };

    handleBlurName(event: any) {
        this.props.handleValueChange(ClientInfoProperty.partnerName, event.target.value);
    }
    handleBlurEmail(event: any) {
        this.props.handleValueChange(ClientInfoProperty.partnerEmail, event.target.value);
    }

    getpreFilledClassName = (clientInfoProperty: ClientInfoProperty): string => {
        let isPrefilled = false;
        if (this.props.prefilledClientInfo) {
            switch (clientInfoProperty) {
                case ClientInfoProperty.partnerName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerName];
                    break;
                case ClientInfoProperty.partnerEin:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerEin];
                    break;
                case ClientInfoProperty.partnerEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerEmail];
                    break;
                case ClientInfoProperty.partnerCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerCountryCode];
                    break;
                case ClientInfoProperty.partnerMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.partnerMobileNumber];
                    break;
            }
        }
        return isPrefilled ? "prefilledField" : "";
    };

    public render() {
        let icon = this.props.expandPartnershipCard ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion
                activeKey={this.props.expandPartnershipCard ? "0" : ""}
                onSelect={() => {
                    this.props.onCardToggle(ClientInfoRightSideCardType.PartnershipInformationCard);
                }}
            >
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}>
                            <Card.Title as="h3">
                                <i className={icon}></i>{" "}
                                {this.props.engagementType.toString() === EngagementType[EngagementType.E1041]
                                    ? "Trust"
                                    : this.props.engagementType.toString() === EngagementType[EngagementType.E1065] ||
                                      this.props.engagementType.toString() === EngagementType[EngagementType.E1120] ||
                                      this.props.engagementType.toString() === EngagementType[EngagementType.E1120S]
                                    ? "Entity"
                                    : "Company"}
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Name
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerName)}
                                        value={this.state.name}
                                        onBlur={this.handleBlurName}
                                        onChange={this.handleChangeName}
                                        placeholder="Company Name"
                                        data-test-auto="9F18CE2E-7161-4242-B7FB-261F6FA3C5B5"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    EIN
                                </Col>
                                <Col sm={9}>
                                    <TaxIdentificationNumber
                                        onChange={this.handleChangeEIN}
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerEin)}
                                        value={FilterSSN(this.props.model.ssn)}
                                        placeholder="Company EIN"
                                        mask="00-0000000"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08 padR00 div-flex" sm={3} as={FormLabel}>
                                    <Col sm={9} className="padL00" style={{ whiteSpace: "nowrap" }}>
                                        Mobile
                                    </Col>
                                    <Col sm={3} className="padR00">
                                        <i
                                            className="help-icon fas fa-question-circle padR00 fontS15"
                                            data-toggle="tooltip"
                                            title={CompanySettingsConstants.MFASettings.HelpText}
                                        ></i>
                                    </Col>
                                </Col>
                                <Col sm={9} className="dispFlex">
                                    <PhoneInput
                                        specialLabel=""
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerMobileNumber)}
                                        country={"us"}
                                        autoFormat={true}
                                        value={this.state.mobileNumberFullValue}
                                        onChange={this.onMobileChange}
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                        onBlur={this.handleBlurMobileNumber}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Email
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="email"
                                        value={this.state.email}
                                        className={this.getpreFilledClassName(ClientInfoProperty.partnerEmail)}
                                        onChange={this.handleChangeEmail}
                                        onBlur={this.handleBlurEmail}
                                        placeholder="Company Email"
                                        data-test-auto="42FD5F59-7326-457F-B929-85246CAB6B56"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
export default PartnershipSidebar;
