import * as bootbox from "bootbox";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as TaxDocumentStore from "../../store/common/TaxDocumentStore";
import * as FilterStore from "../../store/reports/FilterStore";
import * as InUseReturnsState from "../../store/reports/InUseReturnsState";
import * as InUseReturnsStore from "../../store/reports/InUseReturnsStore";
import * as UserStore from "../../store/userManagement/UserStore";
import { IInUseTaxReturns } from "../common/InUseTaxReturns";
import { VenusNotifier } from "../helper/VenusNotifier";
import { IFilters, SortDirections, ReportFilterType } from "./Filters";
import { InUseReturnsHeader } from "./InUseReturns/InUseReturnsHeader";
import { InUseReturnsTable } from "./InUseReturns/InUseReturnsTable";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import { DeliveredReturnsConstants, InUseReturnsConstants, ReportFilterConstants } from "../helper/Constants";
import * as CompanyStore from "../../store/company/CompanyStore";
import { IUserModel } from "../../Core/ViewModels/User/UserViewModel";
import * as UploadTaxReturnStore from "../../store/common/uploadTaxReturn";
import * as Helper from "../helper/HelperFunctions";
import { separatePositiveAndNegativeValues } from "../helper/HelperFunctions";
import { initialCompanySettings, ISuiteTaxSoftwareSetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import * as GroupedReturnStore from "../../store/groupedreturns/GroupedReturnStore";
import { IUserProfile } from "./../navigation/profile/ProfileObjects";
import { CommonResources, InUseReturnAndInUseGroupedReturnResources } from "../../components/helper/ResourceIdConstants";
import * as ClientManagementSettingStore from "../../store/company/SuiteClientManagementSettingStore";
import { DownloadTypeOption } from "./DeliveredReturns/DownloadTypeOption";
import { DefaultDownloadInfoType } from "../navigation/profile/MySettings";
import * as BulkOperationsStore from "../../store/common/BulkOperationsStore";
import { BulkOperationQuery } from "../../Core/ViewModels/Common/BulkOperationQuery";
import { SetAccessType } from "../../store/reports/GroupedReturns/SendGroupedReturnsState";
import { ArrangementList, TypeDropdownValues } from "../../Core/Common/Common";

let moment = require("moment");
const pageSize: number = 20;
const NO_INDEX = -1;

export interface INameValue {
    [index: string]: string;
}

const successMsg: INameValue = {
    makeAvailableState: "Return unlocked successfully."
    // uploadFormsState: 'Successfully uploaded the form for the tax-return'
};

const errorMsg: INameValue = {
    makeAvailableState: "Failed to unlock tax return(s)"
    // uploadFormsState: 'Failed to upload the form for the tax-return'
};

// At runtime, Redux will merge together...
type InUseReturnsProps = {
    InUse: InUseReturnsState.InUseReturnsState; // ... state we've requested from the Redux store
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    userStore: UserStore.ISSRUserData;
    reportFilters: FilterStore.IFilterState;
    userSettings: UserSettingStore.UserSettings;
    company: CompanyStore.ICompanyData;
    uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
    groupedReturnStore: GroupedReturnStore.IGroupInfoState;
    userProfile: IUserProfile;
    ssrUsers: UserStore.ISSRUserListData;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    clientManagementSetting: ClientManagementSettingStore.IClientManagementSetting;
    getStorageAccountIdByFileId: (fileId: string, callback?: (data?: any) => void) => void;
} & typeof InUseReturnsStore.actionCreators & // ... plus action creators we've requested
    typeof TaxDocumentStore.actionCreators &
    typeof UserStore.actionCreators &
    typeof FilterStore.actionCreators &
    typeof UserSettingStore.actionCreators &
    typeof CompanyStore.actionCreators &
    typeof UploadTaxReturnStore.actionCreators &
    typeof GroupedReturnStore.actionCreators &
    typeof ClientManagementSettingStore.actionCreators &
    typeof BulkOperationsStore.actionCreators &
    RouteComponentProps<{ page: string }>; // ... plus incoming routing parameters

export class InUseReturns extends React.Component<InUseReturnsProps, InUseReturnsState.InUseReturnsPageState> {
    private proxyFilter =
        ReportFilterType[ReportFilterType.InUseReturn].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
    private reportFilterType = ReportFilterType.InUseReturn;
    private taxYearList: number[] = [];
    private customGroupNameFilter: any;

    constructor(props: InUseReturnsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            filter: {
                name: "",
                searchText: "",
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.InUseReturn,
                isMasterFilter: false
            },
            selectedRows: [],
            sortName: "",
            sortOrder: "desc",
            filterTaxYear: 0,
            saveFilterShow: false,
            filterClientId: "",

            filterInUseOn: undefined,
            filterEngagementType: 0,
            filterArrangementType: -1,
            userId: 0,
            filterName: "",
            filterPartner: "",
            filterStatus: [],

            signatureStatusList: [],
            selectedStatus: 0,
            //makeAvailable states
            makeAvailableState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            refreshDelay: false,
            defaultGroup: "",
            selectedDocumentGuid: "",
            filterLocation: [],
            filterGroupType: -1,
            downloadTypeOptionState: false,
            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            selectedDefaultDownloadType: false,
            loadSpinner: false
        };

        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onRequestUserSettingsSuccess = this.onRequestUserSettingsSuccess.bind(this);
        //

        this.onClickTaxpayerView = this.onClickTaxpayerView.bind(this);
        this.onClickRemoveReport = this.onClickRemoveReport.bind(this);

        //Reload Table after Make Available
        this.onMakeAvailableRecall = this.onMakeAvailableRecall.bind(this);

        //Grid Operation Handlers
        this.onExportToExcel = this.onExportToExcel.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);

        //MakeAvailable Event Handlers
        this.onMakeAvailableOpen = this.onMakeAvailableOpen.bind(this);
        this.onMakeAvailableSave = this.onMakeAvailableSave.bind(this);

        //Support Methods
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.setSelectedDocumentsGuid = this.setSelectedDocumentsGuid.bind(this);
        this.selectedDocumentIdsAndLoadingStatus = this.selectedDocumentIdsAndLoadingStatus.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.lazyLoadSelected = this.lazyLoadSelected.bind(this);
        //  this.updateStates = this.updateStates.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchInUseReturns = this.fetchInUseReturns.bind(this);
        this.loadInUseReturns = this.loadInUseReturns.bind(this);
        this.onPageReload = this.onPageReload.bind(this);
    }

    static getInUseStateFromProps(props: InUseReturnsState.InUseReturnsState, state: InUseReturnsState.InUseReturnsPageState) {
        return state;
    }

    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary) {
        this.onUpdateCompletionMultiple(taxDocuments, "makeAvailableState", this.state.makeAvailableState);
    }

    OnMakeAvailable(rowIndex: number = NO_INDEX) {}
    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.props.requestAllSSRUsers(true);
        this.props.getAllReportFilter(true, this.reportFilterType);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.taxYearList = Helper.PrepareTaxYear().map((x) => x.value);
        this.props.requestCompanySettings(false);
        this.props.requestInUseReturns(this.buildQuery(this.state.page, pageSize), true);
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestSSRUsers(true);
    }

    onRequestUserSettingsSuccess() {
        let defaultReportFilter = this.props.userSettings.settings.defaultSettings.reportFilter;
        if (!defaultReportFilter || defaultReportFilter == "") {
            this.loadInUseReturns();
        }
        this.props.userSettings.settings &&
            this.props.userSettings.settings.defaultSettings &&
            this.props.userSettings.settings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: this.props.userSettings.settings.defaultSettings.download.downloadType
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps: InUseReturnsProps) {
        //  this.updateStates(nextProps);
        this.props.userSettings.settings &&
            this.props.userSettings.settings.defaultSettings &&
            this.props.userSettings.settings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: this.props.userSettings.settings.defaultSettings.download.downloadType
            });
        this.checkForPopupCompletion(nextProps.taxDocuments);
    }

    private getBulkOperationQuery = (): BulkOperationQuery => {
        let query: BulkOperationQuery = {
            sortBy: this.state.sortName,
            sortOrder: this.state.sortOrder,
            searchText: this.state.filter.searchText,
            clientId: this.state.filterClientId,
            name: this.state.filterName,
            partner: this.state.filterPartner,
            officeLocation: this.state.filterLocation.length > 0 ? this.state.filterLocation.toString() : "",
            engagementType: this.state.filterEngagementType,
            uploadedOn: this.state.filterInUseOn,
            sentBy: "",
            status: this.state.filterStatus.toString(),
            isArchived: false,
            taxYear: this.state.filterTaxYear.toString() === "0" ? "" : this.state.filterTaxYear.toString(),
            unSelectedIds: [],
            assignedTo: "",
            reportType: ReportFilterType.InUseReturn,
            documentFilingType: 0,
            isPaperFileEnabled: false,
            quickReportStatus: "",
            groupName: Helper.getGroupName(this.state.filterGroupType, this.props.groupedReturnStore.groupInfo),
            taxSoftware: "",
            createdBy: "",
            globalSearch: "",
            customColumn: "",
            downloadedBy: "",
            setAccess: SetAccessType.None,
            filterGroupType: this.state.filterGroupType,
            arrangement: this.state.filterArrangementType
        };
        return query;
    };

    onClickTaxpayerView() {}

    onClickRemoveReport() {}

    //======================Grid Operation Starts here===============================

    private onExportToExcel(onExportToExcelComplete: () => void, resourceId: string) {
        let downloadType = this.state.selectedDownloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        if (downloadType == DefaultDownloadInfoType.DownloadNow) {
            let queryString = this.buildQuery(this.state.page, pageSize);
            this.props.exportInUseReturnsAsExcel(queryString, onExportToExcelComplete, resourceId);
        } else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            this.props.exportToExcelBulk(
                this.getBulkOperationQuery(),
                () => {
                    this.props.getMyDownloadsListAsync();
                    onExportToExcelComplete();
                },
                resourceId
            );
        } else {
            onExportToExcelComplete();
            this.setState({
                downloadTypeOptionState: true,
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                loadSpinner: true,
                selectedDefaultDownloadType: false
            });
        }
    }

    private onRowSelect(row: any, isSelected: any, e: any) {
        let newList = [...this.state.selectedRows];
        if (isSelected) {
            newList.push(row.rowIndex);
        } else {
            var index = newList.indexOf(row.rowIndex);
            if (index > -1) {
                newList.splice(index, 1);
            }
        }
        this.setState({ selectedRows: newList }, this.setSelectedDocumentsGuid);
    }

    private onSelectAll(isSelected: any, rows: any, e: any) {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = pageSize;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows }, this.setSelectedDocumentsGuid);
    }

    private onPageChange(page: number, sizePerPage: number) {
        this.setState(
            {
                page: page,
                selectedRows: [],
                selectedDocumentGuid: ""
            },
            this.loadInUseReturns
        );
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        let temp = this.state.filter;
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState(
            {
                filter: temp,
                sortName: sortName,
                sortOrder: sortOrder == "asc" ? "asc" : "desc",
                selectedDocumentGuid: ""
            },
            () => this.fetchInUseReturns(1, pageSize)
        );
    }

    private onFilterNameChange(event: any) {
        let temp = this.state.filter;
        temp.name = event.target.value;
        this.setState({
            filter: temp
        });
    }

    private setSelectedDocumentsGuid() {
        let documentGuids: string[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.InUse.InUseReturnTableModel.inUseReturnModel[row];
            if (selectedDocument) {
                documentGuids.push(selectedDocument.documentGuid);
            }
        });

        this.setState({
            selectedDocumentGuid: documentGuids.join(",")
        });
    }

    //======================Grid Operation Ends here=================================

    //======================Filter Operation Starts here=================================

    private onFilterChange(dataField: any, filterType: ReportFilterType) {
        let newState: InUseReturnsState.InUseReturnsPageState = {
            filterName: "",
            filterClientId: "",
            filterPartner: "",
            filterInUseOn: undefined,
            filterEngagementType: 0,
            filterArrangementType: -1,
            filterTaxYear: 0,
            filterLocation: [] as any,
            filterGroupType: -1
        } as InUseReturnsState.InUseReturnsPageState;
        let filterStatus: any;
        let isClearFilter = true;
        var dict: { [columnName: string]: string } = {};
        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data;
            switch (field) {
                case "taxDocumentName":
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "clientId":
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "partner":
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "locationName":
                    let filterLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    filterLocation = filterLocation == "-1" ? "" : filterLocation;
                    newState.filterLocation = filterLocation;
                    break;
                case "documentStatus":
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = filterStatus == "-1" ? "" : filterStatus;
                    this.setState({ filterStatus: filterStatus });
                    break;
                case "uploadedOn":
                    newState.filterInUseOn = Helper.GetNumberOfDays(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    );
                    break;
                case "engagementType":
                    let filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    let filterArrangementType = -1;
                    const { positiveValues, negativeValues } = separatePositiveAndNegativeValues(filterEngagementType);
                    filterEngagementType = positiveValues;
                    filterArrangementType =
                        negativeValues == TypeDropdownValues.FullReturn
                            ? ArrangementList.FullReturn
                            : negativeValues == TypeDropdownValues.StateOnly
                            ? ArrangementList.StateOnly
                            : ArrangementList.All;
                    newState.filterEngagementType = filterEngagementType;
                    newState.filterArrangementType = filterArrangementType;
                    break;
                case "taxYear":
                    newState.filterTaxYear = +this.taxYearList[dataField[field].value];
                    break;
                case "groupName":
                    newState.filterGroupType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }

        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterStatus)) {
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize, selectedDocumentGuid: "" }, () => {
                let inUseReturnsTable: any = this.refs.InUseReturnsTable;
                if (!inUseReturnsTable.isAppliedFilter)
                    this.props.requestInUseReturns(this.buildQuery(this.state.page, this.state.pageSize));
            });
        }
    }

    private isFilterChanged(newState: InUseReturnsState.InUseReturnsPageState, filterStatus: any): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            filterStatus !== this.state.filterStatus ||
            newState.filterInUseOn !== this.state.filterInUseOn ||
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterArrangementType !== this.state.filterArrangementType ||
            newState.filterTaxYear !== this.state.filterTaxYear ||
            newState.filterLocation !== this.state.filterLocation ||
            newState.filterGroupType !== this.state.filterGroupType
        );
    }

    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter }, () => this.fetchInUseReturns(1, pageSize));
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {
        if (this.state.filter.name.trim() != "") {
            let filter = this.props.reportFilters.filters.find((x) => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            } else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter);
                onApplyFilter(this.state.filter);
                this.onSaveFilterHide();
            }
        } else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find((x) => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        } else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
        }
    }

    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => {
            this.onSetDefaultFilterSuccess();
        });
    }

    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => {
            this.onRemoveDefaultFilterSuccess();
        });

        let inUseReturnsTable: any = this.refs.InUseReturnsTable;
        inUseReturnsTable.refs.Filters?.setState({ activeFilter: "", appliedFilters: [] });
        this.onPageReload;
    }

    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        let temp = this;
        temp.props.deleteReportFilter(filterName, filterType);
    }

    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true
        });
    }

    onMakeAvailableRecall() {
        this.setState({ selectedRows: [], selectedDocumentGuid: "" }, this.onPageReload);
        let inUseReturnsTable: any = this.refs.InUseReturnsTable;

        inUseReturnsTable.refs.table.setState({ sizePerPage: 20 });
    }

    private onPageReload() {
        this.setState({ refreshDelay: true }, () => {
            let query = this.buildQuery(this.state.page, this.state.pageSize);
            this.props.requestInUseReturns(query, true, () => {
                this.setState({
                    refreshDelay: false
                });
            });
        });
    }

    //======================Filter Operation Ends here=================================

    //====================MakeAvailable Starts Here=================================

    private onMakeAvailableOpen(rowIndex: number = NO_INDEX, resourceId: string) {
        let ids = this.onPopupOpen(rowIndex);
        if (ids.length == 0) {
            VenusNotifier.Warning(InUseReturnsConstants.ValidationMessage.NoDocumentSelectedWarning, null);
            return;
        }
        if (this.state.selectedRows.length <= 1) {
            let documentIndex: number = this.state.selectedRows.length == 0 ? rowIndex : this.state.selectedRows[0];
            let lockedBy = this.props.InUse.InUseReturnTableModel.inUseReturnModel[documentIndex].lockedBy;
            let user = this.props.userStore.users.find((x) => x.userId == lockedBy) as IUserModel;
            let obj = this;
            const userName = user?.firstName ?? "user";
            bootbox.confirm({
                title: "Unlock Tax Return",
                message: "Please ensure " + userName + " is not currently working on these returns before unlocking.",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Confirm',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.onMakeAvailableSave(ids, resourceId);
                    } else {
                        this.setState({ selectedRows: [], selectedDocumentGuid: "" });
                        let inUseReturnsTable: any = this.refs.InUseReturnsTable;
                        inUseReturnsTable.refs.table.reset();
                    }
                }
            });
        } else {
            let obj = this;
            bootbox.confirm({
                title: "Unlock Tax Return",
                message: "Please ensure all users are not currently working on these returns before unlocking.",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Confirm',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.onMakeAvailableSave(ids, resourceId);
                    } else {
                        this.setState({ selectedRows: [], selectedDocumentGuid: "" });
                        let inUseReturnsTable: any = this.refs.InUseReturnsTable;
                        inUseReturnsTable.refs.table.reset();
                    }
                }
            });
        }
    }

    private onMakeAvailableSave(ids: number[], resourceId: string) {
        this.props.makeAvailableTaxDocument(ids, this.onMakeAvailableRecall, resourceId);
        this.setState({
            makeAvailableState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            }
        });
    }

    //====================MakeAvailable Ends Here=================================

    //====================Download Option starts Here=================================

    private onDownloadOptionChange = (value: any) => {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads });
        } else {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadNow });
        }
    };

    private onDownloadOptionSave = (value: any) => {
        var settings = this.props.userSettings.settings;
        if (this.state.selectedDefaultDownloadType == true) {
            settings.defaultSettings.download.rememberDefaultDownload = true;
            settings.defaultSettings.download.downloadType = this.state.selectedDownloadType;
            this.props.saveUserSettings(settings, true);
        }

        this.setState({
            downloadTypeOptionState: false
        });
        let downloadType =
            this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.onExportToExcel(() => {
            this.setState({
                loadSpinner: false,
                selectedDownloadType: downloadType
            });
        }, InUseReturnAndInUseGroupedReturnResources.InUseHeaderPrefix + CommonResources.ExportToExcel);
    };
    private onDownloadTypeCancel = () => {
        let downloadType =
            this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.setState({
            selectedDownloadType: downloadType,
            downloadTypeOptionState: false,
            loadSpinner: false
        });
    };
    private useDefaultDownloadType = (event: any) => {
        if (this.state.selectedDefaultDownloadType == false) {
            this.setState({ selectedDefaultDownloadType: true });
        } else {
            this.setState({ selectedDefaultDownloadType: false });
        }
    };
    //=====================Download Option Ends Here=================================

    buildQuery(page: number, pageSize: number) {
        return (
            "?PageNo=" +
            page +
            "&SortBy=" +
            this.state.sortName +
            "&SortOrder=" +
            this.state.sortOrder +
            "&selectedTaxYear=" +
            this.state.filterTaxYear +
            "&PageSize=" +
            pageSize +
            "&filterName=" +
            encodeURIComponent(this.state.filterName) +
            "&filterClientId=" +
            encodeURIComponent(this.state.filterClientId) +
            "&filterPartner=" +
            encodeURIComponent(this.state.filterPartner) +
            "&filterStatus=" +
            this.state.filterStatus +
            "&filterInUseOn=" +
            (this.state.filterInUseOn || "") +
            "&filterEngagementType=" +
            this.state.filterEngagementType +
            "&filterArrangementType=" +
            this.state.filterArrangementType +
            "&filterLocation=" +
            this.state.filterLocation +
            "&filterGroupType=" +
            this.state.filterGroupType
        );
    }

    loadInUseReturns() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);
        this.props.requestInUseReturns(queryString);
    }

    fetchInUseReturns(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page, pageSize: sizePerPage }, () => {
            this.props.requestInUseReturns(queryString);
        });
    }

    private selectedDocumentIds() {
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.InUse.InUseReturnTableModel.inUseReturnModel[row];
            if (selectedDocument) {
                ids.push(selectedDocument.documentId);
            }
        }
        return ids;
    }

    private selectedDocumentIdsAndLoadingStatus(): InUseReturnsState.ISelectedDocument[] {
        let selectedDocuments: InUseReturnsState.ISelectedDocument[] = [];
        let ids = this.selectedDocumentIds();
        ids.map((id, index) => {
            selectedDocuments.push({ id: id, isfullyLoaded: this.props.taxDocuments[id].isFullyLoaded ? true : false });
        });
        return selectedDocuments;
    }

    private selectedDocuments() {
        let docs: IInUseTaxReturns[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.InUse.InUseReturnTableModel.inUseReturnModel[row];
            if (selectedDocument) {
                docs.push(selectedDocument);
            }
        }
        return docs;
    }

    private lazyLoadSelected(ids: number[]) {
        ids.forEach((id, i) => {
            this.props.requestTaxDocument(id, false);
        });
    }

    private isLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, ids: number[]) {
        for (var i in ids) {
            if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
                return false;
            }
        }
        return true;
    }

    private onPopupOpen(rowIndex: number) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.InUse.InUseReturnTableModel.inUseReturnModel[rowIndex].documentId];
        }
        if (this.state.selectedRows.length == 0) {
            return [];
        }
        return this.selectedDocumentIds();
    }

    private getStoreItem(index: number) {
        let id = this.props.InUse.InUseReturnTableModel.inUseReturnModel[index].documentId;
        return this.props.taxDocuments[id];
    }

    private onUpdateCompletion(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: InUseReturnsState.IPopupStateSingle
    ) {
        if (state.saveCheckId && this.isLoaded(taxDocuments, [state.saveCheckId])) {
            if (taxDocuments[state.saveCheckId] && taxDocuments[state.saveCheckId].error) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
                //this.props.requestTaxDocument(state.saveCheckId, true);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private onUpdateCompletionMultiple(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: InUseReturnsState.IPopupStateMultiple
    ) {
        if (state.saveCheckIds && this.isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    this.props.requestTaxDocument(id, true);
                    err = true;
                }
            });
            if (err) {
                VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }
    private getDefaultFilter = (filters: IFilters[]): string | undefined => {
        let existsMasterFilter = filters.findIndex((x) => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex((x) => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find((x) => x.isMasterFilter);
            return mName ? mName.name : undefined;
        } else if (existsDefaultFilter) {
            let dName = filters.find((x) => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    };

    private setRefOnGroupNameFilter = (ref: any) => {
        this.customGroupNameFilter = ref;
    };

    public render() {
        const { InUseHeaderPrefix, InUseGridPrefix } = InUseReturnAndInUseGroupedReturnResources;

        return (
            <div className="user-assignment-content">
                <InUseReturnsHeader
                    pageTitle="Locked Returns"
                    selectedDocumentCount={this.selectedDocumentIds().length}
                    onMakeAvailable={this.onMakeAvailableOpen}
                    users={this.props.userStore.users}
                    selectedERO={
                        this.props.userSettings.settings && this.props.userSettings.settings.defaultSettings
                            ? this.props.userSettings.settings.defaultSettings.eroUser
                            : 0
                    }
                    onPageReload={this.onPageReload}
                    company={this.props.company}
                    uploadTaxReturn={this.props.uploadTaxReturnStore}
                    getUploadLink={this.props.getUploadLink}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    companySettings={
                        this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings
                    }
                    userDefaultSettings={this.props.userSettings.settings}
                    defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ""}
                    groupInfo={this.props.groupedReturnStore.groupInfo}
                    groupAccess={this.props.groupedReturnStore.groupAccess}
                    updateGroupInfoState={this.props.requestGroupConfiguration}
                    userProfile={this.props.userProfile}
                    headerResourceIdentifier={InUseHeaderPrefix}
                    ssrUsers={this.props.ssrUsers.userslist}
                    taxSoftwareSetting={this.props.taxSoftwareSetting}
                    clientManagementSetting={this.props.clientManagementSetting}
                    requestClientManagementSetting={this.props.requestClientManagementSetting}
                    getStorageAccountIdByFileId={this.props.getStorageAccountIdByFileId}
                />
                <br />
                <input type="hidden" id="selectedDocumentsGuid" value={this.state.selectedDocumentGuid} />
                <InUseReturnsTable
                    ref="InUseReturnsTable"
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onFilterChange={this.onFilterChange}
                    pageNo={this.state.page}
                    totalRows={this.props.InUse.totalRowCount}
                    onExportToExcel={this.onExportToExcel}
                    onFilterNameChange={this.onFilterNameChange}
                    loadGrid={this.loadInUseReturns}
                    onFilterSave={this.onFilterSave}
                    onFilterUpdate={this.onFilterUpdate}
                    onFilterDelete={this.onFilterDelete}
                    filterList={this.props.reportFilters.filters}
                    onSetDefaultFilter={this.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                    onSaveFilterHide={this.onSaveFilterHide}
                    onSaveFilterShow={this.onSaveFilterShow}
                    currentFilter={this.state.filter}
                    onMakeAvailable={this.onMakeAvailableOpen}
                    defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                    isLoading={this.props.InUse.loading}
                    pageSize={pageSize}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    InUse={this.props.InUse}
                    saveFilterShow={this.state.saveFilterShow}
                    taxYearList={this.taxYearList}
                    proxyFilter={this.proxyFilter}
                    filterType={this.reportFilterType}
                    onPageReload={this.onPageReload}
                    refreshDelay={this.state.refreshDelay}
                    parentControllerResourceIdentifier={InUseGridPrefix}
                    groupInfo={this.props.groupedReturnStore.groupInfo}
                    customGroupNameFilter={this.customGroupNameFilter}
                    setRefOnGroupNameFilter={this.setRefOnGroupNameFilter}
                />
                <DownloadTypeOption
                    show={this.state.downloadTypeOptionState}
                    onCancel={this.onDownloadTypeCancel}
                    selectedDownloadType={this.state.selectedDownloadType}
                    onDownloadOptionChange={this.onDownloadOptionChange}
                    onDownloadOptionSave={this.onDownloadOptionSave}
                    useDefaultDownloadType={this.useDefaultDownloadType}
                    selectedDefaultDownloadType={this.state.selectedDefaultDownloadType}
                />
            </div>
        );
    }
}
