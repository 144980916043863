import * as React from "react";
import * as Bootstrap from "react-bootstrap";
import { UploadDocumentModal } from "../../common/UploadDocumentModal";
import { IDropzoneConfigState } from "../../common/UploadDocument/DropzoneConfigModel";
import { Link } from "react-router-dom";
import { SignatureStatus } from "../../common/TaxReturn";
import { ISSRUserModel, IUserBaseModel, IUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import {
    ICompanySettings,
    ISuiteTaxSoftwareSetting,
    TaxSoftwareType
} from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import * as CompanyStore from "../../../store/company/CompanyStore";
import Select from "react-select";
import { PrepareTaxYear } from "../../helper/HelperFunctions";
import * as UploadTaxReturnStore from "../../../store/common/uploadTaxReturn";
import { UploadFabButton } from "../../../components/common/UploadFabButton";
import { IUserDefaultSettingsStates } from "../../navigation/profile/MySettings";
import { IGroupAccess } from "../../GroupedReturns/GroupConfiguration";
import { IGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";
import { IUserProfile } from "../../navigation/profile/ProfileObjects";
import { InUseReturnAndInUseGroupedReturnResources } from "../../../components/helper/ResourceIdConstants";
import { IClientManagementSetting } from "../../../store/company/SuiteClientManagementSettingStore";

interface InUseReturnsHeaders {
    pageTitle: string;
    users: IUserModel[];
    selectedERO: number;
    selectedDocumentCount: number;
    onMakeAvailable: (rowIndex: number, resourceId: string) => void;
    onPageReload: () => void;
    company: CompanyStore.ICompanyData;
    uploadTaxReturn: UploadTaxReturnStore.IUploadTaxReturnState;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void) => void;
    getStorageAccountIdByFileId: (fileId: string, callback?: (data?: any) => void) => void;
    userDefaultSettings: IUserDefaultSettingsStates;
    companySettings: ICompanySettings;
    defaultGroup: string;
    groupInfo: IGroupInfo[];
    groupAccess: IGroupAccess[];
    updateGroupInfoState: (reload: boolean) => void;
    userProfile: IUserProfile;
    headerResourceIdentifier: string;
    ssrUsers: ISSRUserModel[];
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    requestClientManagementSetting: () => void;
    clientManagementSetting: IClientManagementSetting;
}
interface InUseReturnsHeadersState {
    show: boolean;
    selectedTaxSoftware: TaxSoftwareType;
}
const NO_INDEX = -1;
export class InUseReturnsHeader extends React.Component<InUseReturnsHeaders, InUseReturnsHeadersState> {
    constructor(props: InUseReturnsHeaders) {
        super(props);
        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.HandleClose = this.HandleClose.bind(this);
        this.state = {
            show: false,
            selectedTaxSoftware: TaxSoftwareType.None
        };
    }

    private onUploadReturnsOpen(taxSoftware: TaxSoftwareType) {
        this.setState({ show: true, selectedTaxSoftware: taxSoftware });
    }

    HandleClose() {
        this.setState({ show: false });
    }

    public render() {
        const unlockReturnResourceId =
            this.props.headerResourceIdentifier + InUseReturnAndInUseGroupedReturnResources.HeaderUnlockReturnButton;

        return (
            <div>
                <div className="row" style={{ marginBottom: 5 }}>
                    <div className="col-sm-8">
                        <h3>{this.props.pageTitle}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-10">
                        <Bootstrap.Button
                            variant="default"
                            className="btn btn-white"
                            disabled={this.props.selectedDocumentCount === 0}
                            onClick={(e) => this.props.onMakeAvailable(NO_INDEX, unlockReturnResourceId)}
                            data-test-auto="32211A05-F0BF-418B-8123-3943D06DDFE2"
                            data-resource-id={unlockReturnResourceId}
                        >
                            <i className="fas fa-lock-open"></i>Unlock Return
                        </Bootstrap.Button>
                    </div>
                    <div className="col-sm-2 txt-ar">
                        <UploadFabButton
                            parentResourceIdentifier={this.props.headerResourceIdentifier}
                            taxSoftwareSetting={this.props.taxSoftwareSetting}
                            userDefaultSettings={this.props.userDefaultSettings}
                            onUploadReturnsClick={this.onUploadReturnsOpen}
                        />
                    </div>
                </div>
                <UploadDocumentModal
                    onShow={this.state.show}
                    onHide={this.HandleClose}
                    users={this.props.users}
                    taxSoftware={this.state.selectedTaxSoftware}
                    selectedERO={this.props.selectedERO}
                    isRedirect={true}
                    getUploadLink={this.props.getUploadLink}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    onPageReload={this.props.onPageReload}
                    selectedGroup={this.props.defaultGroup}
                    groupInfo={this.props.groupInfo}
                    groupAccess={this.props.groupAccess}
                    updateGroupInfoState={this.props.updateGroupInfoState}
                    userProfile={this.props.userProfile}
                    returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                    parentResourceIdentifier={InUseReturnAndInUseGroupedReturnResources.InUsePrefix}
                    ssrUsers={this.props.ssrUsers}
                    getStorageAccountIdByFileId={this.props.getStorageAccountIdByFileId}
                    clientManagementSetting={this.props.clientManagementSetting}
                    requestClientManagementSetting={this.props.requestClientManagementSetting}
                />
            </div>
        );
    }
}
export default InUseReturnsHeader;
