import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { CCHReturnState,CCHReturnTableModel,CCHUnitStatusResponse,ImportReturns,DeleteReturns, SyncReturnModel } from "./CCHReturnsState";
import { actionTypes } from "src/types/ActionTypes";
import { AppThunkAction } from '../../';
import { API_BASE_URL } from '../../../utils/constants';
import { handleResponse } from '../../Library';
import { StatusType, NotificationAction } from '../../common/NotificationStore';
import { ImportCCHAxcess } from '../../../components/helper/Constants';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';
import { ImportCCHAxcessResources } from '../../../components/helper/ResourceIdConstants';
import {
    RequestCCHReturns,
    ReceiveCCHReturns,
    ResponseCCHAuthentication,
    ResponseCCHConnectionStatus, 
    CCHShowLoadingIcon,
    UpdateGetReturnsJobStatus,
    RequestCCHUnitStatus,
    ReceiveCCHUnitStatus,
  } from "./KnownTypes";

type KnownAction =
  | RequestCCHReturns
  | ReceiveCCHReturns
  | NotificationAction
  | ResponseCCHAuthentication
  | ResponseCCHConnectionStatus 
  | CCHShowLoadingIcon
  | UpdateGetReturnsJobStatus
  | RequestCCHUnitStatus
  | ReceiveCCHUnitStatus;

export const actionCreators = {

    pullReturns: (queryString : string,callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) =>  {        
        dispatch({ type: actionTypes.UPDATE_CCH_GETRETRUNS_JOB_STATUS , headerLoadingIcon : true ,data :false });

        const fetchTask = fetch(`${API_BASE_URL}api/cch/pull-returns?${queryString}`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'X-Resource-Id': ImportCCHAxcessResources.ViewEdit }
        })
            .then(handleResponse)
            .then((response)=>{                                     
                dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: ImportCCHAxcess.TableHeader.pullReturnsMsg, statusType: StatusType.Success })                          
                if(callback)
                    callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);        
    },

    getReturns: (queryString: string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) =>  {
        dispatch({ type: actionTypes.REQUEST_CCH_RETRURNS , loading : true });
        const fetchTask = fetch(API_BASE_URL + 'api/cch/get-returns'+ queryString, {
            method: 'GET',
            credentials: 'include',
            headers: { 'X-Resource-Id': ImportCCHAxcessResources.ViewEdit }
        })
        .then(handleResponse)
        .then(response => response as Promise<CCHReturnTableModel>)
        .then((response)=>{    
                dispatch({ type: actionTypes.RECEIVE_CCH_RETURNS , data : response.result, count:response.totalCount , loading : false });
                if(callback)callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.REQUEST_CCH_RETRURNS , loading : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);        
    },

    importReturns: (importReturnsModel:ImportReturns , callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : true });
        const fetchTask = fetch(API_BASE_URL + 'api/cch/import-returns', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(importReturnsModel),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',      
                'X-Resource-Id': ImportCCHAxcessResources.ViewEdit 
            },        
        })
            .then(handleResponse)
            .then((response)=>{
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: ImportCCHAxcess.TableHeader.importReturnsMsg, statusType: StatusType.Success })
                dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                if(callback)
                    callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);     
    }, 

    deleteReturns: (deleteReturnsModel:DeleteReturns , callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : true });
            const fetchTask = fetch(API_BASE_URL + 'api/cch/delete-returns', {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(deleteReturnsModel),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',      
                'X-Resource-Id': ImportCCHAxcessResources.ViewEdit 
            },        
        })
        .then(handleResponse)
        .then((response)=>{
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: ImportCCHAxcess.TableHeader.deleteReturnsMsg, statusType: StatusType.Success })
                dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                if(callback)
                    callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);     
    }, 

    syncReturn: (syncReturnsModel:SyncReturnModel, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) =>{        
        const fetchTask = fetch(API_BASE_URL + 'api/cch/sync-return', {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(syncReturnsModel),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',                    
                'X-Resource-Id': ImportCCHAxcessResources.ViewEdit 
                },                    
            })
            .then(handleResponse)
            .then((response)=>{                                
                if(callback)
                    callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);     
    },   
    accessToken : (authCode:string, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : true });
        const fetchTask = fetch(API_BASE_URL + 'api/cchauth/token', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
                    "authCode" : authCode,                   
                }),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8',                    
                    'X-Resource-Id': ImportCCHAxcessResources.ViewEdit 
                },                
            })
            .then(handleResponse)
            .then((response)=>{                
                console.log(response);                
                dispatch({ type: actionTypes.RESPONSE_CCH_AUTHENTICATION , headerLoadingIcon : false});                                
                if(response)
                   if(callback)callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);     
    },
    getConnectionStatus : (callback?: () => void):AppThunkAction<KnownAction> => (dispatch,getState) => {
        dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : true });
        const fetchTask = fetch(API_BASE_URL + 'api/cchauth/status', {
            method: 'GET',
            credentials: 'include',
            headers : {
                'X-Resource-Id': ImportCCHAxcessResources.ViewEdit 
            }
        })
            .then(handleResponse)
            .then((response)=>{                
                dispatch({ type: actionTypes.RESPONSE_CCH_CONNECTIONSTATUS , headerLoadingIcon : false , data:response});
                if(callback)callback();
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);   
    }, 

    getJobStatus : (callback?: (boolean) => void): AppThunkAction<KnownAction> => (dispatch,getState) => {
        dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : true });
        const fetchTask = fetch(API_BASE_URL + 'api/cch/get-job-status', {
            method: 'GET',
            credentials: 'include',
            headers : {
                'X-Resource-Id': ImportCCHAxcessResources.ViewEdit 
            }
        })
        .then(handleResponse)
        .then((response)=>{                
            dispatch({ type: actionTypes.UPDATE_CCH_GETRETRUNS_JOB_STATUS , headerLoadingIcon : false ,data :response });     
            if(callback)                                  
                callback(response);
        })                        
        .catch((error) => {
            const statusMessage: any = error.statusText?.message ?? error.statusText;
            if (typeof(statusMessage) === "string") {
                dispatch({ type: actionTypes.CCH_SHOW_LOADING_ICON , loading : false , headerLoadingIcon : false });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
            }
        });
        addTask(fetchTask);   
    },

    fetchCchUnitStatuses : (id: number, queryString: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) =>  {
        dispatch({ type: actionTypes.REQUEST_CCH_UNIT_STATUSES , loading : true, data:[], count:0 });
        const fetchTask = fetch(`${API_BASE_URL}api/efile/unitstatuses?Documentid=${id}&${queryString}`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'X-Resource-Id': ImportCCHAxcessResources.ViewEdit }
        })
        .then(handleResponse)
        .then(response => response as Promise<CCHUnitStatusResponse>)
        .then((response)=>{    
                dispatch({ type: actionTypes.RESPONSE_CCH_UNIT_STATUSES , data : response.data, count:response.totalCount , loading : false });
            })                        
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof(statusMessage) === "string") {
                    dispatch({ type: actionTypes.RESPONSE_CCH_UNIT_STATUSES , loading : false, data:[], count:0 });
                }
            });

        addTask(fetchTask);        
    },
};

const unloadedState : CCHReturnState = {
    returns:[],    
    totalReturns : 0,
    connectionDetail : {
        userName:'',
        status:false
    },            
    isLoading : false,
    headerLoadingIcon : false,
    isGetCCHReturnsCompleted :false,
    cchEFileUnitStatus : {
        data : [],
        totalCount : 0,
        isLoading : false
    }
}

export const reducer: Reducer<CCHReturnState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_CCH_RETRURNS:
            return ({
                ...state,
                returns:[],
                isLoading : true                                
            }) as CCHReturnState;      
        case actionTypes.RECEIVE_CCH_RETURNS:
            return ({
                ...state,
                returns:action.data,
                totalReturns:action.count,
                isLoading : false 
            }) as CCHReturnState;                            
        case actionTypes.RESPONSE_CCH_AUTHENTICATION:
            return ({
                ...state,                                   
                headerLoadingIcon : action.headerLoadingIcon
            }) as CCHReturnState;          
        case actionTypes.RESPONSE_CCH_CONNECTIONSTATUS:
            return ({
                ...state,                                   
                headerLoadingIcon : action.headerLoadingIcon,
                connectionDetail : action.data
            }) as CCHReturnState;    
        case actionTypes.CCH_SHOW_LOADING_ICON:
            return ({
                ...state,                                   
                isLoading : action.loading,
                headerLoadingIcon : action.headerLoadingIcon
            }) as CCHReturnState;  
        case actionTypes.UPDATE_CCH_GETRETRUNS_JOB_STATUS:
            return ({
                ...state,                                   
                headerLoadingIcon: action.headerLoadingIcon,
                isGetCCHReturnsCompleted : action.data
            }) as CCHReturnState;   
        case actionTypes.REQUEST_CCH_UNIT_STATUSES:
                return ({
                    ...state,
                    cchEFileUnitStatus:{
                        data:[],
                        totalCount:0,
                        isLoading:action.loading,
                    }
                }) as CCHReturnState;   
        case actionTypes.RESPONSE_CCH_UNIT_STATUSES:
                return ({
                    ...state,
                    cchEFileUnitStatus:{
                        data:action.data,
                        totalCount:action.count,
                        isLoading:action.loading,
                    }
                }) as CCHReturnState;  
                
        default:
            return state;
    }   
};
