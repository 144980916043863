import * as React from "react";
import { ProgressBar, Modal, Button, FormControl, Col, Row } from "react-bootstrap";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from "./../UploadDocument/DocumentUploadDropzoneComponent";
import { DropdownComponent } from "../../../components/common/controls/DropdownComponent";
import { RevisionDocumentsConstant } from "../../../components/helper/Constants";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ButtonFormatter } from "../../../components/common/UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { VenusNotifier } from "../../../components/helper/VenusNotifier";
import {
    UploadFunctions,
    isFileExist,
    getFileExtension,
    removeSpecialCharacters,
    validateFileSize,
    GetFileMagicNumber,
    validatePdfFile
} from "@sssuite-js-packages/file-utility";
import { getFileSize, PrepareOrganizerTaxYear, isClientInfoLoaded } from "../../../components/helper/HelperFunctions";
import * as TaxDocument from "../../common/TaxReturn";
import { UploadStatus, SasContainer } from "../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { IDocumentUploadData, IRevisionDocumentUploadData } from "./RevisionDocuments";
import * as bootbox from "bootbox";
import { TaxpayerDetails } from "../../common/TaxClientDetails/TaxpayerDetails";
import { SpouseDetails } from "../../common/TaxClientDetails/SpouseDetails";
import { PartnerDetails } from "../../common/TaxClientDetails/PartnerDetails";
import * as TaxClient from "../../common/TaxClient";
import Select from "react-select";
import { VoucherTabConstants } from "../../helper/Constants";
import { ICompanySettings, DueDateType } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { IBlobFile } from "../../../store/common";

const djsConfig: any = {
    uploadMultiple: true,
    addRemoveLinks: true,
    acceptedFiles: "application/pdf",
    headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
    previewsContainer: false,
    autoProcessQueue: false,
    autoDiscover: false
};

let tableOptions = {
    noDataText: RevisionDocumentsConstant.emptyFileListMessage
};

interface UploadRevisionDocumentProps {
    model: TaxDocument.ITaxReturn;
    showUploadModal: boolean;
    onUploadRevisionDocumentCancel: () => void;
    getRevisionDocumentUploadLink: (url: string, callback?: (data?: IBlobFile) => void, resourceId?: string) => void;
    maxFileLimitUpload: number;
    onSubmit: (revisionDocumentUploadData: IRevisionDocumentUploadData, callback?: () => void) => void;
    deleteUploadedRevisionDocumentBlobFile: (
        documentGuid: string,
        fileName: string,
        taxYear: number,
        isDeleteOnCancel?: boolean,
        callback?: () => void
    ) => void;
    companySettings: ICompanySettings;
    resourceId?: string;
}

interface UploadRevisionDocumentState {
    documentUploadData: IDocumentUploadData[];
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    isFileUploadInProgress: boolean;
    saving: boolean;
    showLoader: boolean;
    taxReturnModel: TaxDocument.ITaxReturn;
    voucherDuedateType: DueDateType;
}
const initialState: UploadRevisionDocumentState = {
    documentUploadData: [],
    sasContainer: [],
    config: {
        dropzoneSelector: "div.filepicker",
        iconFiletypes: [".pdf"],
        showFiletypeIcon: true,
        postUrl: "/api/"
    },
    isFileUploadInProgress: false,
    saving: false,
    showLoader: false,
    taxReturnModel: TaxDocument.initialTaxReturnState,
    voucherDuedateType: DueDateType.None
};
export class UploadRevisionDocumentModal extends React.Component<UploadRevisionDocumentProps, UploadRevisionDocumentState> {
    constructor(props: UploadRevisionDocumentProps) {
        super(props);

        this.state = initialState;
    }

    UNSAFE_componentWillReceiveProps(nextProps: UploadRevisionDocumentProps) {
        let model = { ...this.state.taxReturnModel };
        this.setState({
            showLoader: nextProps.model === undefined ? true : false,
            saving: false,
            taxReturnModel: model
        });
        if (nextProps.model) {
            if (TaxDocument.isMutual(nextProps.model)) {
                let marriedJointTaxReturn: TaxDocument.IMarriedJointTaxReturn = nextProps.model;
                marriedJointTaxReturn.spouse = marriedJointTaxReturn.spouse
                    ? marriedJointTaxReturn.spouse
                    : TaxClient.initiaITaxpayerState;
                marriedJointTaxReturn.taxpayer = marriedJointTaxReturn.taxpayer
                    ? marriedJointTaxReturn.taxpayer
                    : TaxClient.initiaITaxpayerState;
                this.setState({
                    taxReturnModel: marriedJointTaxReturn
                });
            } else if (TaxDocument.isIndividual(nextProps.model)) {
                let individualTaxReturn: TaxDocument.IIndividualTaxReturn = nextProps.model;
                individualTaxReturn.taxpayer = individualTaxReturn.taxpayer
                    ? individualTaxReturn.taxpayer
                    : TaxClient.initiaITaxpayerState;
                this.setState({
                    taxReturnModel: individualTaxReturn
                });
            } else if (TaxDocument.isPartnership(nextProps.model)) {
                this.setState({
                    taxReturnModel: nextProps.model
                });
            }
        }
        if (this.props.companySettings && this.props.companySettings.displaySettingsModel) {
            this.setState({
                voucherDuedateType:
                    this.props.companySettings.displaySettingsModel.voucherDueDateType == 0
                        ? DueDateType.SSRDefault
                        : this.props.companySettings.displaySettingsModel.voucherDueDateType
            });
        }
    }

    eventHandlers = {
        addedfiles: (files: any) => {
            try {
                let _self = this;
                _self.validateFileCount(files);
                _self.convertToModel(files).then((result) => {
                    let documentUploadData: IDocumentUploadData[] = result;
                    if (documentUploadData.length > 0) {
                        _self.setState(
                            {
                                documentUploadData: _self.state.documentUploadData.concat(result),
                                isFileUploadInProgress: true
                            },
                            () => {
                                _self.getUploadLink();
                            }
                        );
                    }
                });
            } catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    };

    private validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.documentUploadData.length > this.props.maxFileLimitUpload) {
            throw new Error("You cannot upload more than " + this.props.maxFileLimitUpload + " files.");
        }
    };

    private convertToModel = (uploadedFiles: any): Promise<IDocumentUploadData[]> => {
        var promise: any = null;
        let documentUploadData: IDocumentUploadData[] = [];
        let tempGridData = this.state.documentUploadData;
        for (let i = 0; i < uploadedFiles.length; i++) {
            let uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                let tmpFileName = removeSpecialCharacters(uploadedFiles[i].name);
                let fileExtension = getFileExtension(tmpFileName);

                promise = new Promise((resolve) => {
                    GetFileMagicNumber(uploadedFile).then((result) => {
                        if (validatePdfFile(fileExtension, result)) {
                            let filecount = 1;

                            while (isFileExist(tmpFileName, tempGridData)) {
                                tmpFileName = uploadedFile.name;
                                tmpFileName = tmpFileName.replace("." + fileExtension, "");
                                tmpFileName = tmpFileName + " - Copy (" + filecount + ")." + fileExtension;
                                filecount++;
                            }

                            documentUploadData.push({
                                name: tmpFileName,
                                number: documentUploadData.length + this.state.documentUploadData.length + 1,
                                progressBar: 0,
                                size: getFileSize(uploadedFile?.size),
                                status: UploadStatus.Wait,
                                guid: "",
                                gridRowDisable: true,
                                rowIndex: documentUploadData.length + this.state.documentUploadData.length + 1,
                                file: uploadedFile,
                                sasUrl: "",
                                isPDFloaded: false,
                                isDeleted: false
                            });
                        }
                        resolve(result);
                    });
                });
            }
        }
        return promise.then(() => {
            return documentUploadData;
        });
    };

    private getUploadLink = () => {
        let _self = this;
        let uploadHelperFunctions = new UploadFunctions();
        this.state.documentUploadData
            .filter((x) => x.status == UploadStatus.Wait)
            .forEach((file: IDocumentUploadData, index: number) => {
                file.status = UploadStatus.Initiating;
                let param = "?documentGuid=" + this.props.model.documentGuid + "&taxYear=" + this.props.model.taxYear + "&storageAccountId=" + this.props.model.storageAccountId;
                this.props.getRevisionDocumentUploadLink(
                    "/api/Upload/GetRevisionDocumentUploadLinkAsync" + param,
                    (data?: IBlobFile) => {
                        try {
                            if (data) {
                                file.sasUrl = data ? data.sas : "";
                                file.guid = data ? data.guid : "";
                                file.status = UploadStatus.Uploading;
                                _self.setState({ documentUploadData: _self.state.documentUploadData }, () =>
                                    uploadHelperFunctions.uploadFile(
                                        file.file,
                                        data,
                                        file.guid ? file.guid : "",
                                        _self.uploadProgressCallback,
                                        _self.uploadCommittCallBack,
                                        _self.uploadFailureCallback
                                    )
                                );
                            } else {
                                throw new Error("Upload link not found !!");
                            }
                        } catch (error) {
                            _self.state.documentUploadData.splice(index, 1);
                            _self.setState({ documentUploadData: _self.state.documentUploadData });
                        }
                    },
                    this.props.resourceId
                );
            });
    };

    public uploadProgressCallback = (percent: number, fileToUpload: any) => {
        let tempGridData = this.state.documentUploadData;
        tempGridData.map((tempGridDataValue: IDocumentUploadData, index: number) => {
            if (tempGridDataValue.guid == fileToUpload.fileName) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            documentUploadData: tempGridData
        });
    };

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.documentUploadData.length > 0) {
            let tempGridData: IDocumentUploadData[] = this.state.documentUploadData;
            tempGridData.map((tempGridDataValue: IDocumentUploadData, index: number) => {
                if (tempGridDataValue.guid == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.gridRowDisable = false;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    this.updateDocumentData(tempGridDataValue);
                }
            });
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private updateDocumentData = (documentData: IDocumentUploadData) => {
        let uploadedDocumentData: IDocumentUploadData[] = this.state.documentUploadData;
        uploadedDocumentData.map((item, index) => {
            if (item.name == documentData.name) {
                item = documentData;
            }
        });
        this.setState({ documentUploadData: uploadedDocumentData });
    };

    private deleteDocumentData = (fileName: string) => {
        var documentUploadData: IDocumentUploadData[] = this.state.documentUploadData;
        var filteredDocumentData = documentUploadData.filter((x) => x.name != fileName);
        this.setState({ documentUploadData: filteredDocumentData });
    };

    deleteAllDocumentDataOnCancel = () => {
        var documentUploadData: IDocumentUploadData[] = this.state.documentUploadData;
        for (let i = 0; i < documentUploadData.length; i++) {
            let uploadedFile = documentUploadData[i];
            this.props.deleteUploadedRevisionDocumentBlobFile(
                this.props.model.documentGuid,
                uploadedFile.guid,
                this.props.model.taxYear,
                true
            );
        }
    };

    private isUploadOnGoing = (documentUploadData: IDocumentUploadData[]): boolean => {
        let isuploading: boolean = false;
        documentUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    deleteDocument = (row: any) => {
        let _self = this;
        bootbox.confirm({
            message: RevisionDocumentsConstant.deleteConfirmMessage,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> OK',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.deleteUploadedRevisionDocumentBlobFile(
                        _self.props.model.documentGuid,
                        row.guid,
                        _self.props.model.taxYear,
                        false,
                        () => {
                            _self.deleteDocumentData(row.guid);
                            var _gridData = [..._self.state.documentUploadData];
                            _gridData = _gridData.filter((i) => i.name != row.name);

                            //Update row index
                            for (let i = 0; i < _gridData.length; i++) {
                                _gridData[i].rowIndex = i;
                                _gridData[i].number = i + 1;
                            }

                            _self.setState(
                                {
                                    documentUploadData: _gridData
                                },
                                () => {
                                    _self.forceUpdate();
                                }
                            );
                        }
                    );
                }
            }
        });
    };
    defaultType(cell: any, row: any) {
        return cell;
    }

    progressbar(cell: any, row: any) {
        return <ProgressBar striped variant={cell != undefined && cell != 100 ? "warning" : "success"} now={cell} />;
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter
                deleteReturn={() => this.deleteDocument(row)}
                disabled={row.progressBar != undefined && row.progressBar != 100 ? true : false}
                data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
            />
        );
    }

    onCancel = () => {
        if (this.isUploadOnGoing(this.state.documentUploadData)) {
            VenusNotifier.Warning(RevisionDocumentsConstant.uploadInProgress, null);
            return;
        }
        let _self = this;
        if (this.state.documentUploadData.length > 0) {
            bootbox.confirm({
                message: RevisionDocumentsConstant.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.deleteAllDocumentDataOnCancel();
                        _self.setState({ documentUploadData: [] }, () => {
                            _self.props.onUploadRevisionDocumentCancel();
                        });
                    }
                }
            });
        } else {
            _self.setState({ documentUploadData: [] }, () => {
                _self.props.onUploadRevisionDocumentCancel();
            });
        }
    };

    private onHide = () => {
        this.onCancel();
    };

    private onSubmit = () => {
        if (this.state.documentUploadData.length < 1) {
            VenusNotifier.Warning(RevisionDocumentsConstant.documentRequired, null);
            return;
        }
        if (this.isUploadOnGoing(this.state.documentUploadData)) {
            VenusNotifier.Warning(RevisionDocumentsConstant.uploadInProgress, null);
            return;
        }
        if (!this.state.saving) {
            let revisionDocumentData: IRevisionDocumentUploadData = {
                documentId: this.state.taxReturnModel.id,
                revisionDocumentsUploadData: this.state.documentUploadData,
                voucherDuedateType: this.state.voucherDuedateType,
                storageAccountId: this.props.model.storageAccountId
            };

            this.setState({ saving: true, showLoader: true }, () => {
                this.props.onSubmit(revisionDocumentData, () => {
                    this.setState(initialState);
                });
            });
            return true;
        }
    };

    handleVoucherDueDateChange = (event: any) => {
        this.setState({ voucherDuedateType: parseInt(event.value) });
    };

    public render() {
        const isDataLoaded = isClientInfoLoaded(this.props.model);
        let loadingMessage: string = !isDataLoaded ? RevisionDocumentsConstant.OverlayMessage.ApplicationLoading : "";

        const columns = [
            {
                header: "Included Files",
                key: "name",
                isKey: true,
                dataFormat: this.defaultType,
                width: "40%",
                columnClassName: "word-Visible text-left-align"
            },
            {
                header: "Upload Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "20%",
                columnClassName: ""
            },
            {
                header: "Size",
                key: "size",
                isKey: false,
                dataFormat: this.defaultType,
                width: "20%",
                hidden: false,
                columnClassName: ""
            },
            {
                header: "Action",
                key: "button",
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: "20%",
                columnClassName: ""
            }
        ];

        return (
            <Modal
                className="upload-revisiondoc-modal upload-doc-modal"
                show={this.props.showUploadModal}
                onHide={this.onHide}
                enforceFocus={false}
            >
                <LoadingOverlay>
                    <Modal.Header closeButton data-test-auto="5F8747F0-ED0C-454A-BA4A-F5EBD0FCCD4E">
                        <Modal.Title className="custom-modal-header">
                            {" "}
                            <span className="fas fa-file-upload" style={{ color: "grey", marginRight: "7px" }} />
                            Upload New Estimates
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="revision-upload-doc-body">
                        <Row className="report-problem-field">
                            <div style={{ display: "contents" }}>
                                <Col sm={2}>
                                    <div className="font700">Client Id</div>
                                </Col>
                                <Col sm={4}>
                                    <FormControl
                                        type="text"
                                        value={this.state.taxReturnModel.clientId}
                                        placeholder="Enter Client Id"
                                        disabled={true}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <div className="font700">Tax year</div>
                                </Col>
                                <Col sm={4}>
                                    <DropdownComponent
                                        id="form-field-taxYear"
                                        selectedValue={this.state.taxReturnModel.taxYear}
                                        customPlaceHolder="Select Tax year..."
                                        options={PrepareOrganizerTaxYear(this.state.taxReturnModel.taxYear)}
                                        disabled={true}
                                        clearable={false}
                                    />
                                </Col>
                            </div>
                        </Row>
                        {this.props.model && TaxDocument.isMutual(this.state.taxReturnModel) ? (
                            <div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.taxReturnModel.taxpayer.name}
                                    taxpayerEmail={this.state.taxReturnModel.taxpayer.email}
                                    disabled={true}
                                />

                                <SpouseDetails
                                    spouseName={this.state.taxReturnModel.spouse.name}
                                    spouseEmail={this.state.taxReturnModel.spouse.email}
                                    disabled={true}
                                />
                            </div>
                        ) : this.props.model && TaxDocument.isIndividual(this.state.taxReturnModel) ? (
                            <div>
                                <TaxpayerDetails
                                    taxpayerName={this.state.taxReturnModel.taxpayer.name}
                                    taxpayerEmail={this.state.taxReturnModel.taxpayer.email}
                                    disabled={true}
                                />
                            </div>
                        ) : (
                            this.props.model &&
                            TaxDocument.isPartnership(this.state.taxReturnModel) && (
                                <div>
                                    <PartnerDetails
                                        partnerName={this.state.taxReturnModel.partnership.name}
                                        partnerEmail={this.state.taxReturnModel.partnership.email}
                                        disabled={true}
                                    />
                                </div>
                            )
                        )}
                        <Row className="report-problem-field">
                            <Col sm={2}>
                                <div style={{ display: "flex" }}>
                                    <div className="font700">Select Due date</div>
                                    <i
                                        className="help-icon fas fa-question-circle"
                                        data-toggle="tooltip"
                                        title={RevisionDocumentsConstant.DueDateSelectionToolTip}
                                    ></i>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <Select
                                    name="form-field-name"
                                    value={this.state.voucherDuedateType}
                                    onChange={this.handleVoucherDueDateChange}
                                    options={VoucherTabConstants.voucherDueDateTypes}
                                    data-test-auto="52A69016-2B50-4B88-84B4-3E82ED02586A"
                                    clearable={false}
                                />
                            </Col>
                        </Row>
                        <div id="uploadDocumentTable" className="row uploaded-documents-table-container">
                            <Col sm={12} style={{ maxHeight: "150px" }}>
                                <BootstrapTable
                                    data={this.state.documentUploadData}
                                    bodyContainerClass={"overflowVisible"}
                                    striped
                                    bordered
                                    options={tableOptions}
                                >
                                    {columns.map((value, index) => {
                                        return (
                                            <TableHeaderColumn
                                                key={index}
                                                isKey={value.isKey}
                                                dataField={value.key}
                                                dataFormat={value.dataFormat}
                                                width={value.width}
                                                columnClassName={value.columnClassName}
                                                dataAlign={"center"}
                                            >
                                                {value.header}
                                            </TableHeaderColumn>
                                        );
                                    })}
                                </BootstrapTable>
                            </Col>
                        </div>
                        <div className="row">
                            <Col sm={4}>
                                <DocumentUploadDropzoneComponent
                                    componentConfig={this.state.config}
                                    djsConfig={djsConfig}
                                    eventHandlers={this.eventHandlers}
                                    autoTestId={"71433406-732D-457C-BE45-DC7AAC3907C6"}
                                />
                            </Col>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn-default"
                            variant="white"
                            onClick={this.onCancel}
                            data-test-auto="4235E285-CED0-489C-8D67-92D7A2DA95D1"
                        >
                            <i className="fas fa-times"></i>Close
                        </Button>
                        <Button variant="info" onClick={this.onSubmit} data-test-auto="C2C2D177-0005-4F95-9FD0-725AAE037BE9">
                            <i className="fas fa-check"></i>Submit
                        </Button>
                    </Modal.Footer>
                    <Loader
                        loading={this.state.showLoader || !isDataLoaded}
                        text={!isDataLoaded ? loadingMessage : RevisionDocumentsConstant.OverlayMessage.ApplicationLoading}
                    />
                </LoadingOverlay>
            </Modal>
        );
    }
}
