import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { handleResponse } from '../Library';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
import { API_BASE_URL } from '../../utils/constants';

export interface IUploadTaxReturnState {
    sas: string;
    guid: string;
    storageAccountId: number;
}

export interface RequestTaxReturnAction {
    type: actionTypes.REQUEST_TAX_RETURN;
    message: string;
}

export interface ReceiveTaxReturnUploadLinkAction {
    type: actionTypes.RECEIVE_TAX_RETURN_LINK;
    sasUrl: string;
    documentGuid: string;
}

export interface RequestProcessTaxReturnAction {
    type: actionTypes.PROCESS_TAX_RETURN;
}

export interface UpdateTaxReturnAction {
    type: actionTypes.UPDATE_TAX_RETURN;
}

type DispatchAction = RequestTaxReturnAction |
    ReceiveTaxReturnUploadLinkAction |
    RequestProcessTaxReturnAction |
    UpdateTaxReturnAction;


type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {

    submitTaxReturn: (url: string, taxReturnData: string, callback: () => void, resourceId: string = ""):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            const fetchTask = fetch(API_BASE_URL + url, {
                method: 'POST',
                credentials: 'include',
                body: taxReturnData,
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                }
            }).then(handleResponse)
                .then(() => {
                    callback();
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.UpdateTaxReturnError,
                        statusType: StatusType.Error
                    })
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_TAX_RETURN, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });;
        },

    getUploadLink: (url: string, callback?: (data?: any) => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, {credentials: 'include', headers: { 'X-Resource-Id': resourceId }
        }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_TAX_RETURN_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.TaxReturnLinkError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_TAX_RETURN, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },

    processTaxReturn: (url: string, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain,*/*',
                'Content-Type': 'application/json',
                'traditional': 'true',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then(() => {
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.ProcessTaxReturnError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_TAX_RETURN, message: Constants.UploadtaxReturnConstants.OverlayMessage.ApplicationLoading });
    },
    getStorageAccountIdByFileId: (fileId: string, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Upload/GetStorageAccountIdByFileId/' +fileId, 
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                }
            }
        ).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.UploadtaxReturnConstants.StatusMessage.TaxReturnLinkError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
    },  
}


const unloadedState: IUploadTaxReturnState =
    {
        sas: '',
        guid: '',
    } as IUploadTaxReturnState;

export const reducer: Reducer<IUploadTaxReturnState> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.RECEIVE_TAX_RETURN_LINK:
            return {
                ...state,
                sas: action.sasUrl,
                guid: action.documentGuid,
            };
        case actionTypes.PROCESS_TAX_RETURN:
            return { ...state };
        case actionTypes.UPDATE_TAX_RETURN:
            return { ...state };
    }
    return state;
};
