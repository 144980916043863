import * as React from "react";
import * as Bs from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    SignatureStatus,
    ITaxReturn,
    ClientTypes,
    isMutual,
    isIndividual,
    isPartnership,
    DocumentStatus,
    TaxDocumentRevisionStatus,
    CCHEFileStatus
} from "../../common/TaxReturn";
import { IDeliveredTaxDocument } from "../../../components/common/DeliveredTaxReturns";
import { DeliveredReturnReportResourceId } from "../../helper/ResourceIdConstants";
import { isDeliveredReturnStatusChangeable } from "../../helper/HelperFunctions";
import { IControllerInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";
import { isNumber } from "lodash";
import { TaxSoftwareType } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IEFileSettings } from "../../../Core/ViewModels/Company/Settings/EFileSettingsViewModel"; 

interface DeliveredReportDropdownPros {
    title: string;
    rowIndex: number;
    signatureStatus: string;
    onArchiveTaxReturn: (rowIndex: number) => void;
    onEditClientInfoOpen: (rowIndex: number) => void;
    onDownloadEfileFormsOpen: (rowIndex: number) => void;
    onDownloadReturnOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number, isGroupReturn?: boolean) => void;
    onTaxpayerView: (rowIndex: number) => void;
    onRecallReturnOpen: (rowIndex: number, resourceId: string) => void;
    onUploadRevisionDocumentOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onDeleteTaxReturnOpen: (rowIndex: number, resourceId: string) => void;
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onRetentionPolicyOpen: (rowIndex: number) => void;
    onRemoveReport: (event: any) => void;
    onUnlockReturnOpen: (event: any) => void;
    onUploadFormsOpen: (rowIndex: number) => void;
    onClientViewClick: (rowIndex: number) => void;
    onClientViewCancel: (id: number) => void;
    onClientViewLinkClick: (rowIndex: number, clientType: ClientTypes) => void;
    taxReturn: ITaxReturn;
    showClientSubMenu: boolean;
    deliveredReturnData: IDeliveredTaxDocument[];
    onRestoreReturnOpen: (rowIndex: number, resourceId: string) => void;
    onScreenShareViewClick: (rowIndex: number) => void;
    onRevisedProcessReturnOpen: (rowIndex: number) => void;
    onDiscardRevisedDocumentOpen: (rowIndex: number) => void;
    onScreenShareOpen: (clientId: string, rowIndex: number, isControllerScreenShare: boolean) => void;
    showScreenShareSubMenu: boolean;
    isArchived: boolean;
    isScreenShareEnabled: boolean;
    parentResourceIdentifier: string;
    userResources: string[];
    onSendReminderValidateIndividualReturn: (rowIndex: number) => void;
    onEditGroupInfoOpen: (groupId: number) => void;
    onRowChangeStatusOpen: (rowIndex: number) => void;
    onGroupReturnViewAccessCodeOpen: (rowIndex: number) => void;
    onGroupedReportProblemOpen: (rowIndex: number) => void;
    onGroupReturnResendAccessLinkOpen: (rowIndex: number) => void;
    onGroupSendReminderOpen: (rowIndex: number) => void;
    isGroupReturn: boolean;
    controllerInfo: IControllerInfo;
    onControllerScreenShareViewClick: (rowIndex: number, groupId: number) => void;
    onControllerScreenShareOpen: (
        controllerGuid: string,
        groupId: number,
        clientId: string,
        clientGuid: string,
        isControllerScreenShare: boolean
    ) => void;
    onRecallGroupReturnOpen: (rowIndex: number, groupId: number) => void;
    onGroupRecallReturnOpen: (groupId: number, taxDocumentId: number) => void;
    showControllerSubMenu: boolean;
    onControllerViewClick: (groupId: number) => void;
    onControllerViewOpen: (groupId: number) => void;
    onReleaseEFileOpen: (rowIndex: number) => void;
    efileSettings: IEFileSettings
}

export class DeliveredReportAction extends React.Component<DeliveredReportDropdownPros, {}> {
    private showGRReportAProblem = false;
    private showGRRecall = false;
    private createPrimaryActions = () => {
        let elements: any[] = [];
        if (this.props.deliveredReturnData[this.props.rowIndex].hasUploadedRevisionDocument === true) {
            elements.push(
                <button
                    title={"Process Uploaded Estimates"}
                    type="button"
                    className="btn-white"
                    style={{ paddingLeft: "2px", paddingRight: "2px" }}
                >
                    <span className="fa-stack fas fa-spinner fa-spin text-white bg-transparent">
                        <i className="fas fa-circle fa-stack-2x text-violet" style={{ padding: "0px" }}></i>
                        <i
                            className="fas fa-redo-alt fa-inverse fa-stack-1x"
                            style={{ padding: "0px" }}
                            data-fa-transform="shrink-4"
                        ></i>
                    </span>
                </button>
            );

            elements.push(
                <button
                    data-resource-id={`${this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.EditClientInfo}`}
                    title={"Edit Client Info"}
                    type="button"
                    className="btn-white"
                    onClick={() => this.props.onEditClientInfoOpen(this.props.rowIndex)}
                >
                    <i className="fas fa-address-card text-success"></i>
                </button>
            );

            elements.push(this.createSecondaryActions());
        } else if (this.props.deliveredReturnData[this.props.rowIndex].hasRevisionDocumentReady === true) {
            elements.push(
                <div>
                    <button
                        title={"Process Uploaded Estimates"}
                        data-resource-id={`${
                            this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.UploadEstimate
                        }`}
                        type="button"
                        className="btn-white"
                        style={{ paddingLeft: "2px", paddingRight: "2px" }}
                        onClick={(e) => {
                            this.props.onRevisedProcessReturnOpen(this.props.rowIndex);
                        }}
                    >
                        <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x text-violet" style={{ padding: "0px" }}></i>
                            <i
                                className="fas fa-redo-alt fa-inverse fa-stack-1x"
                                style={{ padding: "0px" }}
                                data-fa-transform="shrink-4"
                            ></i>
                        </span>
                    </button>

                    <button
                        key="deleteReturn"
                        title={"Discard Uploaded Estimates"}
                        type="button"
                        className="btn-white"
                        data-resource-id={`${
                            this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.UploadEstimate
                        }`}
                        onClick={(e) => this.props.onDiscardRevisedDocumentOpen(this.props.rowIndex)}
                    >
                        <i className="text-danger glyphicon glyphicon-remove" />
                    </button>
                </div>
            );
        } else {
            elements.push(
                <button
                    data-resource-id={`${this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.EditClientInfo}`}
                    title={"Edit Client Info"}
                    type="button"
                    className="btn-white"
                    onClick={() => this.props.onEditClientInfoOpen(this.props.rowIndex)}
                >
                    <i className="fas fa-address-card text-success"></i>
                </button>
            );

            elements.push(this.createSecondaryActions());
        }

        return elements;
    };

    private checkIfClientViewSubMenuIsEnabled = () => {
        if (this.props.showClientSubMenu) {
            this.props.onClientViewCancel(this.props.taxReturn.id);
        }
    };

    private onControllerViewClick = () => {
        const document = { ...this.props.deliveredReturnData[this.props.rowIndex].document };
        let groupId = document.groupId;
        if (
            groupId &&
            groupId > 0 &&
            ((this.props.controllerInfo.groupId > 0 && groupId !== this.props.controllerInfo.groupId) ||
                this.props.controllerInfo.groupId === 0)
        ) {
            this.props.onControllerViewClick(groupId);
        }
    };

    private onScreenShareViewClick = () => {
        const document = { ...this.props.deliveredReturnData[this.props.rowIndex].document };
        let groupId = document.groupId;
        if (groupId && groupId > 0) {
            this.props.onControllerScreenShareViewClick(this.props.rowIndex, groupId);
        } else {
            this.props.onScreenShareViewClick(this.props.rowIndex);
        }
    };

    private getScreenshareMenu = (isGroupedReturn: boolean) => {
        let taxReturnModel: any = this.props.taxReturn;
        let clientInfoModelScreenShare = isGroupedReturn ? <></> : <div className="marL10">Loading...</div>;
        {
            taxReturnModel && taxReturnModel.isfullyLoaded && taxReturnModel.taxpayer && isMutual(taxReturnModel)
                ? (clientInfoModelScreenShare = (
                      <div>
                          {taxReturnModel.taxpayer && !taxReturnModel.taxpayer.isDeceased && !isGroupedReturn ? (
                              <li>
                                  <Link
                                      to={"#"}
                                      onClick={() =>
                                          this.props.onScreenShareOpen(
                                              taxReturnModel.taxpayer.clientGuid,
                                              this.props.rowIndex,
                                              isGroupedReturn
                                          )
                                      }
                                      data-test-auto="82B47EBD-B661-44D5-87EC-6A953572BB0C"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.taxpayer.name +
                                              (taxReturnModel.taxpayer.email ? " (" + taxReturnModel.taxpayer.email + ") " : "")}
                                      </span>
                                  </Link>
                              </li>
                          ) : null}
                          {taxReturnModel.spouse && !taxReturnModel.spouse.isDeceased ? (
                              <li>
                                  <Link
                                      to={"#"}
                                      onClick={() =>
                                          this.props.onScreenShareOpen(
                                              taxReturnModel.spouse.clientGuid,
                                              this.props.rowIndex,
                                              false
                                          )
                                      }
                                      data-test-auto="42ACE989-6786-41EB-B494-D0EBD738E0DB"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.spouse.name +
                                              (taxReturnModel.spouse.email ? " (" + taxReturnModel.spouse.email + ") " : "")}
                                      </span>
                                  </Link>
                              </li>
                          ) : null}
                      </div>
                  ))
                : taxReturnModel &&
                  taxReturnModel.isfullyLoaded &&
                  taxReturnModel.taxpayer &&
                  taxReturnModel.taxpayer.email &&
                  isIndividual(taxReturnModel) &&
                  !isGroupedReturn
                ? (clientInfoModelScreenShare = (
                      <div>
                          {
                              <li>
                                  <Link
                                      to={"#"}
                                      onClick={() =>
                                          this.props.onScreenShareOpen(
                                              taxReturnModel.taxpayer.clientGuid,
                                              this.props.rowIndex,
                                              isGroupedReturn
                                          )
                                      }
                                      data-test-auto="BF4D23E8-206A-4A2C-8D99-A6E872025BEC"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.taxpayer.name +
                                              (taxReturnModel.taxpayer.email ? " (" + taxReturnModel.taxpayer.email + ") " : "")}
                                      </span>
                                  </Link>
                              </li>
                          }
                      </div>
                  ))
                : taxReturnModel &&
                  taxReturnModel.isfullyLoaded &&
                  taxReturnModel.partnership &&
                  taxReturnModel.partnership.email &&
                  isPartnership(taxReturnModel) &&
                  !isGroupedReturn
                ? (clientInfoModelScreenShare = (
                      <div>
                          <li>
                              <Link
                                  to={"#"}
                                  onClick={() =>
                                      this.props.onScreenShareOpen(
                                          taxReturnModel.partnership.clientGuid,
                                          this.props.rowIndex,
                                          isGroupedReturn
                                      )
                                  }
                                  data-test-auto="CBAADAF2-9C74-48B0-8D7E-CBC55BA61766"
                              >
                                  <span className="marL05 marR05">
                                      {taxReturnModel.partnership.name +
                                          (taxReturnModel.partnership.email
                                              ? " (" + taxReturnModel.partnership.email + ") "
                                              : "")}
                                  </span>
                              </Link>
                          </li>
                      </div>
                  ))
                : null;
        }
        return clientInfoModelScreenShare;
    };

    private getTaxDocumentClientGuid = () => {
        let taxReturn: any = this.props.deliveredReturnData[this.props.rowIndex].document;
        if (taxReturn) {
            if (isIndividual(taxReturn)) {
                return taxReturn.taxpayer.clientGuid;
            }
            if (isMutual(taxReturn)) {
                return taxReturn.taxpayer.clientGuid;
            }

            if (isPartnership(taxReturn)) {
                return taxReturn.partnership.clientGuid;
            }
        }
        return "";
    };

    private getControllerViewMenu = () => {
        const groupId = this.props.deliveredReturnData[this.props.rowIndex].document.groupId;
        const controllerInfo = this.props.controllerInfo;
        return controllerInfo && controllerInfo.name && controllerInfo.emailAddress && controllerInfo.groupId === groupId ? (
            <div>
                <li>
                    <Link
                        to={"#"}
                        onClick={() => {
                            this.props.onControllerViewOpen(controllerInfo.groupId);
                        }}
                        data-test-auto="4F3A9A6D-412D-43BC-993A-0878ED472598"
                    >
                        <span className="marL05 marR05">{controllerInfo.name + " (" + controllerInfo.emailAddress + ") "}</span>
                    </Link>
                </li>
            </div>
        ) : (
            <div className="marL10">Loading...</div>
        );
    };

    private getControllerScreenshareMenu = () => {
        const groupId = this.props.deliveredReturnData[this.props.rowIndex].document.groupId;
        const controllerInfo = this.props.controllerInfo;
        const clientGuid = this.getTaxDocumentClientGuid();
        return controllerInfo && controllerInfo.name && controllerInfo.emailAddress && controllerInfo.groupId === groupId ? (
            <>
                <div>
                    <li>
                        <Link
                            to={"#"}
                            onClick={() => {
                                this.props.onControllerScreenShareOpen(
                                    controllerInfo.clientGuid.toString(),
                                    controllerInfo.groupId,
                                    this.props.taxReturn?.clientId,
                                    clientGuid,
                                    true
                                );
                            }}
                            data-test-auto="4F3A9A6D-412D-43BC-993A-0878ED472598"
                        >
                            <span className="marL05 marR05">
                                {controllerInfo.name + " (" + controllerInfo.emailAddress + ") "}
                            </span>
                        </Link>
                    </li>
                </div>
                {this.getScreenshareMenu(true)}
            </>
        ) : (
            <div className="marL10">Loading...</div>
        );
    };

    private showGroupedReturnReportAProblemSubMenu = () => {
        this.showGRReportAProblem = true;
    };

    private showGroupedReturnRecallSubMenu = () => {
        this.showGRRecall = true;
    };

    private createSecondaryActions = () => {
        let signatureStatus: any = SignatureStatus[SignatureStatus.ManuallySigned];
        let signatureStatusPrinted: any = SignatureStatus[SignatureStatus.AlternateDelivery];
        let taxReturnModel: any = this.props.taxReturn;
        const groupId = this.props.deliveredReturnData[this.props.rowIndex].document.groupId;
        let deceasedCount = 0;

        if (taxReturnModel && taxReturnModel.taxpayer && isMutual(taxReturnModel)) {
            if (taxReturnModel.taxpayer.isDeceased) {
                deceasedCount += 1;
            }
            if (taxReturnModel.spouse && taxReturnModel.spouse.isDeceased) {
                deceasedCount += 1;
            }
        }

        let clientInfoModelsli = <div className="marL10">Loading...</div>;
        {
            taxReturnModel && taxReturnModel.isfullyLoaded && taxReturnModel.taxpayer && isMutual(taxReturnModel)
                ? (clientInfoModelsli = (
                      <div>
                          {taxReturnModel.taxpayer && (deceasedCount == 2 || !taxReturnModel.taxpayer.isDeceased) ? (
                              <li>
                                  <Link
                                      to={"#"}
                                      onClick={() => this.props.onClientViewLinkClick(this.props.rowIndex, ClientTypes.Taxpayer)}
                                      data-test-auto="82B47EBD-B661-44D5-87EC-6A953572BB0C"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.taxpayer.name +
                                              (taxReturnModel.taxpayer.email ? " (" + taxReturnModel.taxpayer.email + ") " : "")}
                                      </span>
                                  </Link>
                              </li>
                          ) : null}
                          {taxReturnModel.spouse && (deceasedCount == 2 || !taxReturnModel.spouse.isDeceased) ? (
                              <li>
                                  <Link
                                      to={"#"}
                                      onClick={() => this.props.onClientViewLinkClick(this.props.rowIndex, ClientTypes.Spouse)}
                                      data-test-auto="42ACE989-6786-41EB-B494-D0EBD738E0DB"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.spouse.name +
                                              (taxReturnModel.spouse.email ? " (" + taxReturnModel.spouse.email + ") " : "")}
                                      </span>
                                  </Link>
                              </li>
                          ) : null}
                      </div>
                  ))
                : taxReturnModel &&
                  taxReturnModel.isfullyLoaded &&
                  taxReturnModel.taxpayer &&
                  taxReturnModel.taxpayer.email &&
                  isIndividual(taxReturnModel)
                ? (clientInfoModelsli = (
                      <div>
                          {
                              <li>
                                  <Link
                                      to={"#"}
                                      onClick={() => this.props.onClientViewLinkClick(this.props.rowIndex, ClientTypes.Taxpayer)}
                                      data-test-auto="BF4D23E8-206A-4A2C-8D99-A6E872025BEC"
                                  >
                                      <span className="marL05 marR05">
                                          {taxReturnModel.taxpayer.name +
                                              (taxReturnModel.taxpayer.email ? " (" + taxReturnModel.taxpayer.email + ") " : "")}
                                      </span>
                                  </Link>
                              </li>
                          }
                      </div>
                  ))
                : taxReturnModel &&
                  taxReturnModel.isfullyLoaded &&
                  taxReturnModel.partnership &&
                  taxReturnModel.partnership.email &&
                  isPartnership(taxReturnModel)
                ? (clientInfoModelsli = (
                      <div>
                          <li>
                              <Link
                                  to={"#"}
                                  onClick={() => this.props.onClientViewLinkClick(this.props.rowIndex, ClientTypes.PartnerShip)}
                                  data-test-auto="CBAADAF2-9C74-48B0-8D7E-CBC55BA61766"
                              >
                                  <span className="marL05 marR05">
                                      {taxReturnModel.partnership.name +
                                          (taxReturnModel.partnership.email
                                              ? " (" + taxReturnModel.partnership.email + ") "
                                              : "")}
                                  </span>
                              </Link>
                          </li>
                      </div>
                  ))
                : null;
        }

        let reportAProblemSubMenu = (
            <div>
                <li title="Report Group">
                    <Link
                        to={"#"}
                        onClick={() => this.props.onGroupedReportProblemOpen(this.props.rowIndex)}
                        data-test-auto="CBAADAF2-9C74-48B0-8D7E-CBC55BA61766"
                    >
                        <span className="marL05 marR05">Report Group</span>
                    </Link>
                </li>
                <li title="Report Return">
                    <Link
                        to={"#"}
                        onClick={() => this.props.onReportProblemOpen(this.props.rowIndex)}
                        data-test-auto="42ACE989-6786-41EB-B494-D0EBD738E0DB"
                    >
                        <span className="marL05 marR05">Report Return</span>
                    </Link>
                </li>
            </div>
        );

        let clientInfoModelScreenShare;
        let controllerViewMenu;

        if (groupId && isNumber(groupId) && groupId > 0) {
            clientInfoModelScreenShare = this.getControllerScreenshareMenu();
            controllerViewMenu = this.getControllerViewMenu();
        } else {
            clientInfoModelScreenShare = this.getScreenshareMenu(false);
        }

        return (
            <Bs.ButtonToolbar className="btn-group-vertical" style={{ float: "none" }} title={"More.."}>
                <Bs.DropdownButton
                    size="sm"
                    title={<span className="fa fa-ellipsis-h" style={{ verticalAlign: "inherit" }}></span>}
                    id="delivered-return-dropdown"
                    alignRight
                    //onMouseOut={() => this.props.taxReturn && this.props.onClientViewCancel(this.props.taxReturn.id)}
                    data-test-auto="53B48F5B-1E2C-416D-8283-19E553B5900B"
                >
                    {
                        //ToDo: Add on open event for the Edit Group Name and add guid for data-test-auto
                        this.props.deliveredReturnData[this.props.rowIndex].document.groupId > 0 && (
                            <li key="EditGroup" title="Edit Group Name">
                                <Link
                                    to={"#"}
                                    onClick={(e: any) =>
                                        this.props.onEditGroupInfoOpen(
                                            this.props.deliveredReturnData[this.props.rowIndex].document.groupId
                                        )
                                    }
                                    data-resource-id={
                                        this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.EditGroupName
                                    }
                                    data-test-auto="6295DB44-0324-4C34-8AD0-C89FD1AA9224"
                                >
                                    <i className="fas fa-edit ddl-icon"></i>Edit Group Name
                                </Link>
                            </li>
                        )
                    }
                    {this.props.signatureStatus == signatureStatus || this.props.signatureStatus == signatureStatusPrinted ? (
                        <li
                            title="Upload Forms"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.UploadForms
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onUploadFormsOpen(this.props.rowIndex)}
                                data-test-auto="0FD60540-B537-4E21-B5C6-DBB298A61792"
                            >
                                <i className="fas fa-file-upload ddl-icon"></i>Upload Forms
                            </Link>
                        </li>
                    ) : this.props.signatureStatus != SignatureStatus[SignatureStatus.AwaitingUpload] &&
                      this.props.signatureStatus != SignatureStatus[SignatureStatus.AwaitingESign] &&
                      this.props.signatureStatus != SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] &&
                      this.props.signatureStatus != SignatureStatus[SignatureStatus.PartiallySigned] &&
                      this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="Download e-file Forms"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.DownloadEfileForm
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onDownloadEfileFormsOpen(this.props.rowIndex)}
                                data-test-auto="4C16B288-E85D-44FA-8941-A4D2F07022B7"
                            >
                                <i className="fas fa-file-signature ddl-icon"></i>Download e-file Forms
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex]?.isDocumentLocked ? (
                        <li title="Unlock Return">
                            <Link to={"#"} onClick={() => this.props.onUnlockReturnOpen(this.props.rowIndex)}>
                                <i className="fas fa-unlock ddl-icon"></i>Unlock Return
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}

                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="Resend Access Link"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ResendAccessLink
                            }`}
                        >
                            <Link
                                to={"#"}
                                    onClick={() =>
                                    !this.props.deliveredReturnData[this.props.rowIndex].document.groupId === true ||
                                    this.props.deliveredReturnData[this.props.rowIndex].document.groupId === 0
                                        ? this.props.onResendAccessLinkOpen(this.props.rowIndex)
                                        : this.props.onGroupReturnResendAccessLinkOpen(this.props.rowIndex)
                                }
                                data-test-auto="9EBB29A8-2B5A-45CA-82F8-4498FA18D7F0"
                            >
                                <i className="fas fa-link ddl-icon"></i>Resend Access Link
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}

                    {groupId > 0 && (
                        <li className="dropdown-submenu dropdown-menu-right dropdown-submenu-right" title="Controller View">
                            <Link
                                to={"#"}
                                onMouseOver={() => {
                                    this.props.userResources.findIndex(
                                        (x) =>
                                            x ==
                                            this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.EditClientInfo
                                    ) > -1 && this.onControllerViewClick();
                                }}
                                onClick={() => this.onControllerViewClick()}
                                data-test-auto="991D6283-1C58-4F47-9082-B8F2959E2013"
                            >
                                <i className="fas fa-eye ddl-icon"></i>Controller View
                            </Link>
                            {this.props.showControllerSubMenu && (
                                <ul className="dropdown-menu dropdown-menu-ul">{controllerViewMenu}</ul>
                            )}
                        </li>
                    )}

                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            className="dropdown-submenu dropdown-menu-right dropdown-submenu-right"
                            title="Client View"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ClientView
                            }`}
                            onMouseEnter={() => this.checkIfClientViewSubMenuIsEnabled()}
                        >
                            <Link
                                onClick={() => this.props.onClientViewClick(this.props.rowIndex)}
                                to={"#"}
                                id="cpa-client-view"
                                data-test-auto="2498F069-A899-4392-B96A-AD8DAFAF0D99"
                            >
                                <i className="fas fa-eye ddl-icon"></i>Client View
                            </Link>
                            {this.props.showClientSubMenu && (
                                <ul
                                    className="dropdown-menu dropdown-menu-ul"
                                    onMouseLeave={() => {
                                        this.props.onClientViewCancel(this.props.taxReturn?.id);
                                    }}
                                >
                                    {clientInfoModelsli}
                                </ul>
                            )}
                        </li>
                    ) : (
                        ""
                    )}

                    {
                        <li
                            title="Change Status"
                            hidden={!isDeliveredReturnStatusChangeable(this.props.signatureStatus)}
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ChangeStatus
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onRowChangeStatusOpen(this.props.rowIndex)}
                                data-test-auto="2DDC4F62-C843-448D-B629-1F09B32E0814"
                            >
                                <i className="fas fa-random ddl-icon"></i>Change Status
                            </Link>
                        </li>
                    }

                    {!this.props.deliveredReturnData[this.props.rowIndex].document.groupId === true ||
                        this.props.deliveredReturnData[this.props.rowIndex].document.groupId === 0 ? (
                        this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                            DocumentStatus.DELIVEREDTOTAXCADDY &&
                        this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                            DocumentStatus.DOWNLOADPDF ? (
                            <li
                                title="Recall Return"
                                data-resource-id={`${
                                    this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.RecallReturn
                                }`}
                            >
                                <Link
                                    to={"#"}
                                    onClick={() =>
                                        this.props.onRecallReturnOpen(
                                            this.props.rowIndex,
                                            `${
                                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.RecallReturn
                                            }`
                                        )
                                    }
                                    data-test-auto="D4716DFB-8359-4BE1-8C66-1825BF0C3D3F"
                                >
                                    <i className="fa fa-retweet ddl-icon"></i>Recall Return
                                </Link>
                            </li>
                        ) : (
                            ""
                        )
                    ) : this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                          DocumentStatus.DELIVEREDTOTAXCADDY &&
                      this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                          DocumentStatus.DOWNLOADPDF ? (
                        <li
                            className="dropdown-submenu dropdown-menu-right dropdown-submenu-right"
                            title="Recall Return"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.RecallReturn
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.showGroupedReturnRecallSubMenu()}
                                data-test-auto="7D5E7B82-1980-431B-A1CF-10CC4AE24A73"
                            >
                                <i className="fa fa-retweet ddl-icon"></i>Recall Return
                            </Link>
                            {this.showGRRecall && (
                                <ul className="dropdown-menu dropdown-menu-ul">
                                    <div>
                                        {
                                            <li title="Recall Group">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        this.props.onRecallGroupReturnOpen(
                                                            this.props.rowIndex,
                                                            this.props.deliveredReturnData[this.props.rowIndex].document.groupId
                                                        )
                                                    }
                                                    data-test-auto="4027B951-694F-4A28-95B7-91BC612A7495"
                                                >
                                                    <span className="marL05 marR05">Recall Group</span>
                                                </Link>
                                            </li>
                                        }
                                        {
                                            <li title="Recall Return">
                                                <Link
                                                    to={"#"}
                                                    onClick={() =>
                                                        this.props.onGroupRecallReturnOpen(
                                                            this.props.deliveredReturnData[this.props.rowIndex].document.groupId,
                                                            this.props.deliveredReturnData[this.props.rowIndex].document.id
                                                        )
                                                    }
                                                    data-test-auto="0B5397A8-DA3C-4C32-808A-7AB89F0B2AAA"
                                                >
                                                    <span className="marL05 marR05">Recall Return</span>
                                                </Link>
                                            </li>
                                        }
                                    </div>
                                </ul>
                            )}
                        </li>
                    ) : (
                        ""
                    )}

                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus ===
                    DocumentStatus.DOWNLOADPDF ? (
                        <li
                            title="Reprocess Return"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ReprocessReturn
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() =>
                                    this.props.onRecallReturnOpen(
                                        this.props.rowIndex,
                                        `${this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ReprocessReturn}`
                                    )
                                }
                                data-test-auto="A8FB7580-85F9-4B73-92FB-D88351EBFD68"
                            >
                                <i className="fa fa-retweet ddl-icon"></i>Reprocess Return
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="Voucher Reminder"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.AutomaticReminder
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() =>
                                    !this.props.deliveredReturnData[this.props.rowIndex].document.groupId === true ||
                                    this.props.deliveredReturnData[this.props.rowIndex].document.groupId === 0
                                        ? this.props.onSendReminderValidateIndividualReturn(this.props.rowIndex)
                                        : this.props.onGroupSendReminderOpen(this.props.rowIndex)
                                }
                                data-test-auto="31B64CC4-F86D-4334-B144-DBF5A4E83FEC"
                            >
                                <i className="fas fa-paper-plane ddl-icon"></i>Send Reminder
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="Upload Estimate"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.UploadEstimate
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onUploadRevisionDocumentOpen(this.props.rowIndex)}
                                data-test-auto="31B64CC4-F86D-4334-B144-DBF5A4E83FED"
                            >
                                <i className="fas fa-file-upload ddl-icon"></i>Upload Estimates
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="View Access Code"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ViewAccessCode
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() =>
                                    !this.props.deliveredReturnData[this.props.rowIndex].document.groupId === true ||
                                    this.props.deliveredReturnData[this.props.rowIndex].document.groupId === 0
                                        ? this.props.onViewAccessCodeOpen(this.props.rowIndex)
                                        : this.props.onGroupReturnViewAccessCodeOpen(this.props.rowIndex)
                                }
                                data-test-auto="139D6AF7-5987-4672-AB6D-D71506B01CBD"
                            >
                                <i className="fas fa-user-lock ddl-icon"></i>View Access Code
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}
                    {this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    !this.props.isGroupReturn &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="Modify Retention Policy"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.RetentionPolicy
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onRetentionPolicyOpen(this.props.rowIndex)}
                                data-test-auto="D1A92FFE-A8E6-4F89-BFEE-D800DABF4DAF"
                            >
                                <i className="fas fa-magnet ddl-icon"></i>Retention Policy
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}
                    {!this.props.deliveredReturnData[this.props.rowIndex].document.groupId === true ||
                    this.props.deliveredReturnData[this.props.rowIndex].document.groupId === 0 ? (
                        <li
                            title="Report a Problem"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ReportAProblem
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onReportProblemOpen(this.props.rowIndex)}
                                data-test-auto="DACDF3C2-7F85-4817-B0F0-75452219F158"
                            >
                                <i className="fas fa-bullhorn ddl-icon"></i>Report a Problem
                            </Link>
                        </li>
                    ) : (
                        <li
                            className="dropdown-submenu dropdown-menu-right dropdown-submenu-right"
                            title="Report a Problem"
                            onMouseEnter={() => {
                                this.props.userResources.findIndex(
                                    (x) =>
                                        x == this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ReportAProblem
                                ) > -1 && this.showGroupedReturnReportAProblemSubMenu();
                            }}
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ReportAProblem
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.showGroupedReturnReportAProblemSubMenu()}
                                data-test-auto="991D6283-1C58-4F47-9082-B8F2959E2013"
                            >
                                <i className="fas fa-desktop ddl-icon"></i>Report a Problem
                            </Link>
                            {this.showGRReportAProblem && (
                                <ul className="dropdown-menu dropdown-menu-ul">{reportAProblemSubMenu}</ul>
                            )}
                        </li>
                    )}

                    <li
                        title="User Tracking"
                        data-resource-id={`${
                            this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ClientTracking
                        }`}
                    >
                        <Link
                            to={"#"}
                            onClick={() => this.props.onClientTrackingOpen(this.props.rowIndex, this.props.isGroupReturn)}
                            data-test-auto="28BAB651-1B87-4E56-AC3A-78A5EFB8FF04"
                        >
                            <i className="fas fa-user-clock ddl-icon"></i>User Tracking
                        </Link>
                    </li>
                    {this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            title="Download Returns"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.DownloadReturns
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.props.onDownloadReturnOpen(this.props.rowIndex)}
                                data-test-auto="10423BBB-194E-448D-A03D-487D5C98B73B"
                            >
                                <i className="fas fa-file-download ddl-icon"></i>Download Returns
                            </Link>
                        </li>
                    ) : (
                        ""
                    )}

                    {this.props.isScreenShareEnabled &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !==
                        DocumentStatus.DELIVEREDTOTAXCADDY &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.documentStatus !== DocumentStatus.DOWNLOADPDF &&
                    this.props.signatureStatus != SignatureStatus[SignatureStatus.SignatureStampingFailed] ? (
                        <li
                            className="dropdown-submenu dropdown-menu-right dropdown-submenu-right"
                            title="Request Screen Share"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ScreenShare
                            }`}
                        >
                            <Link
                                to={"#"}
                                onMouseOver={() => {
                                    this.props.userResources.findIndex(
                                        (x) =>
                                            x == this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ScreenShare
                                    ) > -1 && this.onScreenShareViewClick();
                                }}
                                onClick={() => this.onScreenShareViewClick()}
                                data-test-auto="991D6283-1C58-4F47-9082-B8F2959E2013"
                            >
                                <i className="fas fa-desktop ddl-icon"></i>Screen Share
                            </Link>
                            {this.props.showScreenShareSubMenu && (
                                <ul className="dropdown-menu dropdown-menu-ul">{clientInfoModelScreenShare}</ul>
                            )}
                        </li>
                    ) : (
                        ""
                    )}
                    {this.props.isArchived ? (
                        <li
                            title="Restore Return"
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.RestoreReturn
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() =>
                                    this.props.onRestoreReturnOpen(
                                        this.props.rowIndex,
                                        this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.RestoreReturn
                                    )
                                }
                                data-test-auto="59381856-4CA6-4A69-B036-E3EE14226FE3"
                            >
                                <i className="fas fa-sync-alt ddl-icon"></i>Restore Return
                            </Link>
                        </li>
                    ) : (
                        <li title="Archive Return">
                            <Link
                                to={"#"}
                                onClick={() => this.props.onArchiveTaxReturn(this.props.rowIndex)}
                                data-test-auto="F106F2A5-8794-4DB7-85FA-6291D042E0E3"
                            >
                                <i className="fas fa-archive ddl-icon" />
                                Archive Return
                            </Link>
                        </li>
                    )}
                    {
                    this.props.efileSettings.eFileSettingsEnabled && 
                    this.props.deliveredReturnData[this.props.rowIndex].document.isCCHImported &&
                    (
                          this.props.deliveredReturnData[this.props.rowIndex].document.eFileStatus == CCHEFileStatus.ReadyToRelease ||
                          this.props.deliveredReturnData[this.props.rowIndex].document.eFileStatus == CCHEFileStatus.ReleaseFailed
                    ) &&
                    ( this.props.signatureStatus == SignatureStatus[SignatureStatus.Uploaded] ||
                    this.props.signatureStatus == SignatureStatus[SignatureStatus.ESigned] ||
                    this.props.signatureStatus == SignatureStatus[SignatureStatus.SignedAndESigned] ||
                    this.props.signatureStatus == SignatureStatus[SignatureStatus.ESignedInOffice] ) &&
                    this.props.deliveredReturnData[this.props.rowIndex].document.taxSoftware ==
                    TaxSoftwareType.ProSystems ? (
                            <li
                                title="Release E-file"
                                data-resource-id={`${this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.ReleaseEFile}`}
                            >
                                <Link
                                    to={"#"}
                                    onClick={() =>
                                        this.props.onReleaseEFileOpen(this.props.rowIndex)
                                    }
                                    data-test-auto="E7C4922E-008E-4E12-8DD8-5AB44B24D17F"
                                >
                                <i className="fas fa-file-upload ddl-icon"></i>
                                Release E-file
                                </Link>
                            </li>
                        ) : ("") }
                    
                    <li
                        title="Delete Return"
                        data-resource-id={`${this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.DeleteReturn}`}
                    >
                        <Link
                            to={"#"}
                            onClick={() =>
                                this.props.onDeleteTaxReturnOpen(
                                    this.props.rowIndex,
                                    this.props.parentResourceIdentifier + DeliveredReturnReportResourceId.DeleteReturn
                                )
                            }
                            data-test-auto="8E85187D-D9F3-4F07-AF47-F8F4E0F49755"
                        >
                            <i className="text-danger glyphicon glyphicon-remove ddl-icon" />
                            Delete Return
                        </Link>
                    </li>                    
                </Bs.DropdownButton>
            </Bs.ButtonToolbar>
        );
    };

    public render() {
        return <div className="button-group">{this.createPrimaryActions()}</div>;
    }
}
