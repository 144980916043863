import * as React from "react";
import { TabContentContainer } from '../../../common/ProcessReturnModal/Tabs/TabContentContainer/TabContentContainer';
import { FormLabel, Row, FormGroup, Button, Col } from 'react-bootstrap';
import ToggleSwitch from '../../../common/ToggleSwitch';
import { ITaxReturn, DocumentStatus } from "../../../common/TaxReturn";
import { CheckBoxComponent } from '../../../common/CheckBoxComponent';
import { IUserProfile } from "../../../navigation/profile/ProfileObjects";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import { ProcessReturnConstants } from "../../../helper/Constants";
export interface TabGroupDeliveryProps {
	markAsReadyForDelivery: boolean,
	onMarkedAsReadyForDeliveryChecked: (checked: boolean) => void;
	onMarkedAsReadyForDeliveryClicked: (id: number) => void;
	onProcessReturn: (id: number, groupId: number) => void;
	groupedTaxDocuments: ITaxReturn[],
	taxReturn: ITaxReturn,
	groupId: number,
	userBasicProfile: IUserProfile,
	onDocumentSelectedForDelivery?: (taxDocument: ITaxReturn) => void;
}

export interface TabGroupDeliveryState {
	isProcessing: boolean;
	processingTaxDocumentId: number;
}

export class TabGroupDelivery extends React.Component<TabGroupDeliveryProps, TabGroupDeliveryState>{
	constructor(props: TabGroupDeliveryProps) {
		super(props);
		this.state = {
			isProcessing: false,
			processingTaxDocumentId: 0
		}
	}

	onProcessing = (taxReturn: ITaxReturn) => {
		if (taxReturn.lockedBy > 0 && taxReturn.assignTo !== this.props.userBasicProfile?.userId) {
			// locked, assigned to other user 			
			VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
			return;
		}

		this.setState({ isProcessing: true, processingTaxDocumentId: taxReturn.id },
			() => { this.props.onProcessReturn(taxReturn.id, this.props.groupId) });
	}

	onDocumentSelectedForDelivery = (event: any, taxReturn: ITaxReturn) => {
		if (event.target.checked) {
			taxReturn.isSelectedForDelivery = true;
		}
		else {
			taxReturn.isSelectedForDelivery = false;
		}

		this.setState({ isProcessing: false});
		
		this.props.onDocumentSelectedForDelivery && this.props.onDocumentSelectedForDelivery(taxReturn);
	}


	checkDocumentStatus = (taxReturn: ITaxReturn) => {
		return (taxReturn.documentStatus == DocumentStatus.READYFORDELIVERY);
	}

	
	getTaxReturnCard = (taxReturn: ITaxReturn) => {

		let cardBorderColor = 'grouped-card-default-border-color';
		let cardBgColor = "grouped-card-default-color";
		let flexCenter = "";

		if (this.checkDocumentStatus(taxReturn)) {
			cardBorderColor = 'grouped-card-ready-border-color';
			cardBgColor = "grouped-card-ready-color";
			flexCenter = " flex-justify-center";

		}

		return (<>
			<div className={"col-lg-2 col-md-2 col-sm-2 "} style={{marginTop:"10px"}}>
				<div className={"grouped-preview-card grouped-card-dafault-border-color " + cardBorderColor}>
					{
						this.checkDocumentStatus(taxReturn) &&
						<CheckBoxComponent id={"page-thumbnail-checkbox-" + taxReturn.id}
							text={""} className={"grouped-preview-checkbox"}
							checked={(taxReturn.isSelectedForDelivery && taxReturn.isSelectedForDelivery === true) ? true : false}
							onChange={(event: any) => { this.onDocumentSelectedForDelivery(event, taxReturn) }}
							datatestAuto={"a5d4d52c-d9f5-4602-969f-4ae3090c9082"}
							setCustomColor={true}
						/>
					}
					<div className="grouped-preview-content">

						<div>
							<div className="grouped-preview-title">Client ID :</div>
							<div className="grouped-preview-text" title={taxReturn.clientId}>{taxReturn.clientId}</div>
						</div>
						<div>
							<div className="grouped-preview-title">Tax Year :</div>
							<div className="grouped-preview-text" title={taxReturn.taxYear+""}>{taxReturn.taxYear}</div>
						</div>
						<div>
							<div className="grouped-preview-title">Return Type :</div>
							<div className="grouped-preview-text" title={taxReturn.engagementType.toString().replace('E', '')}>{taxReturn.engagementType.toString().replace('E', '')}</div>
						</div>
					</div>
					<div className={"grouped-preview-card-footer " + cardBgColor + flexCenter}>
						{
							taxReturn.documentStatus != DocumentStatus.READYFORDELIVERY &&
							<span onClick={() => { this.props.onMarkedAsReadyForDeliveryClicked(taxReturn.id) }}
								title="Set as Ready for Delivery">
								<i className="fas fa-check-double grouped-preview-card-icon" aria-hidden="true"></i></span>}
						
						<span title="Process" aria-disabled={this.state.isProcessing} onClick={() => { this.onProcessing(taxReturn) }} >
							<i className={this.state.isProcessing && this.state.processingTaxDocumentId === taxReturn.id ? 'fas fa-spinner fa-spin text-white bg-transparent' : "fa fa-rocket grouped-preview-card-icon"} aria-hidden="true"></i></span>
					</div>
				</div>
			</div>

			
			
		</>)

	}

	
	public render() {
		const groupedReturns = this.props.groupedTaxDocuments && this.props.groupedTaxDocuments.filter(taxReturn => taxReturn.documentStatus != DocumentStatus.UPLOADED
			&& taxReturn.documentStatus != DocumentStatus.ERROR
			&& taxReturn.documentStatus != DocumentStatus.DELIVERED
			&& taxReturn.documentStatus != DocumentStatus.PREPARINGFORDELIVERY
			&& taxReturn.documentStatus != DocumentStatus.DELIVERYFAILED
			&& taxReturn.documentStatus != DocumentStatus.DELETED
			&& taxReturn.documentStatus != DocumentStatus.DELIVEREDTOTAXCADDY
			&& taxReturn.documentStatus != DocumentStatus.DOWNLOADPDF
			&& taxReturn.documentStatus != DocumentStatus.NONSUPPORTED
		);

		return (<TabContentContainer enableScroll={true}>
			<Col sm={12}>
				<div className="content-box">
					<Row style={{ marginTop: "20px" }}>
						<Col className="padT07" sm={3} as={FormLabel}>
							<strong>Mark this return as "Ready for Delivery"</strong>
						</Col>
						<Col className="padT07" sm={3}>
							<ToggleSwitch switched={this.props.markAsReadyForDelivery} handleChange={(event: any) => { this.props.onMarkedAsReadyForDeliveryChecked(event.currentTarget.checked) }} />
						</Col>

					</Row>
					{
						groupedReturns.length > 1 && <Row>
							<Col sm={12} md={12} lg={12}>
								<hr />
							</Col>

							<Col sm={4} className="new-main-content">
								<h4>
									Navigate to Process Return
								</h4>
								<span><i className="fas fa-question-circle" style={{ marginRight: '5px' }} />
                                    Click on any one of the return to process
							</span>
							</Col>
							<Col sm={12}>
								<Row style={{ marginTop: "20px" }}>
								
									{
                                      groupedReturns.map((taxReturn, i) => {
										  return taxReturn.id != this.props.taxReturn.id && this.getTaxReturnCard(taxReturn)

										})
										}
                                   
								</Row>
							</Col>

						</Row>
					}
				</div>
			</Col>
		</TabContentContainer>)
	}
}
