import { VenusNotifier } from "./VenusNotifier";
import {
    ValidationContants,
    TabEFileConstants,
    FinishProcessReturn,
    VoucherTabConstants,
    PaperFile,
    FileConstants,
    FileSizeExceedsLimit,
    SFDocument,
    MailMergeDocument,
    TabAdditionalEsignDocumentConstants,
    InvoiceReplaceConstants
} from "./Constants";
import { IUserModel } from "../../Core/ViewModels/User/UserViewModel";
import { ICompanySettings, PaymentOption } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import {
    ITaxReturn,
    isPartnership,
    isIndividual,
    isMutual,
    SignatureType,
    SignatureControlRole,
    SignatureControlTypes,
    ISignatureControl,
    ITaxingAuthority,
    DocumentGroups,
    VoucherTypes,
    VoucherNo,
    IEFile
} from "../common/TaxReturn";
import {
    validateClientInfo,
    filterSignatureByRole,
    validateDeliveryOption,
    validatePaperFile,
    validateK1,
    isProcessable,
    validateDocumentLocation,
    validateTaxReturnMobileNumber,
    validateEmailAddressFormat,
    validateClientId
} from "../helper/HelperFunctions";
import { EfileInfoView, ISignatureControlsDictionary, AdditionalESignView } from "../common/ProcessReturnModal/ProcessReturnModels";
import { IValidationResult } from "./ValidationResult";
import {
    IDocumentData,
    IAdditionalESignDocumentPage,
    IAdditionalESignDocumentControl
} from "../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument";
import { ISSRSettings } from "../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import { IPasswordSettings, PasswordOptions } from "../navigation/profile/ProfileObjects";

export function isValidEmailAddress(emailAddress: any) {
    if (emailAddress) {
        var pattern = new RegExp(
            /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
        );
        return pattern.test(emailAddress);
    }
    return false;
}
export function isValidIdentityServerEmailAddress(emailAddress: any) {
    var pattern = new RegExp(
        /^((([a-z]|\d|[\'\-_{\|}]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[\'\-_{\|}]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
    );
    return pattern.test(emailAddress);
}
export function validateZip(ZipNoId: any) {
    var ZipNo = ZipNoId?.trim();
    if (ZipNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.ZipWarning, "");
        return false;
    } else if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
        VenusNotifier.Warning(ValidationContants.ZipLengthWarning, "");
        return false;
    } else return true;
}
export function validateExtension(ExtensionId: any) {
    var Extension = ExtensionId?.trim();
    if (Extension.trim().length > 6 || /^[0-9]{1,10}$/.test(Extension) == false) {
        VenusNotifier.Warning(ValidationContants.ExtensionWarning, "");
        return false;
    } else return true;
}
export function validateFax(FaxId: any) {
    var Fax = FaxId?.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        VenusNotifier.Warning(ValidationContants.FaxWarning, "");
        return false;
    } else return true;
}
export function validateState(stateId: number) {
    if (stateId == 0) {
        VenusNotifier.Warning(ValidationContants.StateWarning, "");
        return false;
    }
}
export function validateCity(CityId: any) {
    var City = CityId?.trim();
    if (City == "") {
        VenusNotifier.Warning(ValidationContants.CityWarning, "");
        return false;
    } else if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
        VenusNotifier.Warning(ValidationContants.ValidCityWarning, "");
        return false;
    } else return true;
}
export function validatePhone(phoneNoId: any) {
    var phoneNo = phoneNoId?.trim();
    if (phoneNo.trim() == "") {
        VenusNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
        return false;
    } else if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
        return false;
    } else return true;
}
export function validatePassword(passwordId: any) {
    var password = passwordId?.trim();
    if (password.trim().length < 8) {
        VenusNotifier.Warning(ValidationContants.PasswordLengthWarning, "");
        return false;
    } else if (password.trim().indexOf(" ") >= 0) {
        VenusNotifier.Warning(ValidationContants.PasswordWithoutSpaceWarning, "");
        return false;
    }
}

export function validatePasswordBasedOnPolicy(password: any, passwordSettings: IPasswordSettings): IValidationResult {
    var password = password?.trim();
    const passwordOptions = passwordSettings.passwordOptions;
    if (password.trim().length < passwordSettings.length) {
        return {
            isError: true,
            errorDescription: "Password must be minimum " + passwordSettings.length + " characters."
        };
    } else if (password.trim().indexOf(" ") >= 0) {
        return {
            isError: true,
            errorDescription: ValidationContants.PasswordWithoutSpaceWarning
        };
    } else if ((passwordOptions & PasswordOptions.UpperCase) === PasswordOptions.UpperCase && !hasUpperCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.UpperCaseValidationError
        };
    } else if ((passwordOptions & PasswordOptions.LowerCase) === PasswordOptions.LowerCase && !hasLowerCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.LowerCaseValidationError
        };
    } else if ((passwordOptions & PasswordOptions.Numbers) === PasswordOptions.Numbers && !hasNumber(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.NumbersValidationError
        };
    } else if (
        (passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters &&
        !hasSpecialCharacter(password)
    ) {
        return {
            isError: true,
            errorDescription: ValidationContants.SpecialCharactersValidationError
        };
    }

    return {
        isError: false,
        errorDescription: "Success"
    };
}

function hasLowerCase(data: string) {
    var pattern = new RegExp(".*[a-z].*");
    return pattern.test(data);
}

function hasUpperCase(data: string) {
    var pattern = new RegExp(".*[A-Z].*");
    return pattern.test(data);
}

function hasNumber(data: string) {
    var pattern = new RegExp(/[\d]/i);
    return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
    var pattern = new RegExp(/[~!@#$%^&*_]/i);
    return pattern.test(data);
}

export function bootboxAlert(message: string, callback: any) {
    bootbox.alert(message, callback);
}
export function isAttachedURLValid(payOption: PaymentOption, url: string) {
    if (payOption === PaymentOption.AttachedURL) {
        if (url && url.trimEnd() !== "") {
            let isValid = validateUrl(url);
            return isValid;
        }
        return false;
    }
    return true;
}
export function validateUrl(url: string) {
    const urlParts = {
        protocol: /^(?:https?|ftp):\/\//,
        auth: /^(?:\S+(?::\S*)?@)?/,
        domainOrIP: /(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))/,
        port: /(?::\d{2,5})?/,
        path: /(?:\/[^\s]*)?/
    };

    const isValidProtocol = urlParts.protocol.test(url);
    const isValidAuth = urlParts.auth.test(url);
    const isValidDomainOrIP = urlParts.domainOrIP.test(url);
    const isValidPort = urlParts.port.test(url);
    const isValidPath = urlParts.path.test(url);

    return isValidProtocol && isValidAuth && isValidDomainOrIP && isValidPort && isValidPath;
}

export function getFileExtension(fileName: string) {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function getContentType(file: File): string {
    let contentType: string = file.type.slice(0, file.type.indexOf("/"));
    if (contentType == "application") {
        return contentType + "/" + getFileExtension(file.name);
    }
    return file.type;
}

export function validateFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "pdf") {
        VenusNotifier.Warning(ValidationContants.PdfFileValidation, "");
        return false;
    }
    return true;
}

export function validateFileSize(file: any): boolean {
    if (file?.size > FileConstants.MaximumFileSize) {
        VenusNotifier.Error(FileSizeExceedsLimit(file.name), null);
        return false;
    }
    return true;
}

export function validateIndividualFileSize(file: any): boolean {
    if (file?.size > FileConstants.FileSizeForEncryptionValidation) {
        return false;
    }
    return true;
}
export function GetSafeFilename(fileName: string) {
    var pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
    return fileName.replace(pattern, "_");
}
export function isValidSSN(ssn: string) {
    if (ssn.replace(/[*-]/g, "").length == 9 || ssn.match(/\*/g)?.length === 5) {
        return true;
    }
}

export function isValidEIN(ein: string) {
    if (ein.replace(/[*-]/g, "").length === 9) {
        return true;
    }
}

export function isValidDate(date: any) {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
}

export function isDigit(zip: any) {
    var date_regex = /^[0-9]{1,10}$/;
    return date_regex.test(zip);
}

export function NullandEmptyCheck(text: string) {
    if (text == undefined || text == null || text.trim() == "") {
        return false;
    }
    return true;
}

export function isValidatePTIN(ptin: string) {
    var ptin_regex = /^[Pp][0-9]{8}$/;
    return ptin_regex.test(ptin);
}

export function validateExistingPTIN(users: IUserModel[], userValue: IUserModel): boolean {
    const ptin = users.find((x) => {
        if (x.ptin && userValue.ptin && x.ptin.toLowerCase() === userValue.ptin.toLowerCase() && x.userId !== userValue?.userId)
            return true;
        else return false;
    });
    if (ptin != null) {
        return false;
    }
    return true;
}

export function validateUser(
    userDetails: IUserModel,
    enterPassword: string,
    confirmPassword: string,
    passwordValidation: boolean = true
): boolean {
    if (!NullandEmptyCheck(userDetails.firstName)) {
        VenusNotifier.Warning(ValidationContants.FirstNameWarning, "");
        return false;
    } else if (!NullandEmptyCheck(userDetails.lastName)) {
        VenusNotifier.Warning(ValidationContants.LastNameWarning, "");
        return false;
    } else if (!NullandEmptyCheck(userDetails.email)) {
        VenusNotifier.Warning(ValidationContants.EmailAdrressWarning, "");
        return false;
    } else if (!isValidEmailAddress(userDetails.email.trim()) || userDetails.email.trim() == "") {
        VenusNotifier.Warning(ValidationContants.ValidEmailAddressWarning, "");
        return false;
    } else {
        if (NullandEmptyCheck(userDetails.phoneNumber)) {
            if (validatePhone(userDetails.phoneNumber) == false) {
                return false;
            }
        }
        if (NullandEmptyCheck(userDetails.faxNumber)) {
            if (validateFax(userDetails.faxNumber) == false) {
                return false;
            }
        }

        if (NullandEmptyCheck(userDetails.extension)) {
            if (validateExtension(userDetails.extension) == false) {
                return false;
            }
        }
        if (NullandEmptyCheck(userDetails.ptin)) {
            if (!isValidatePTIN(userDetails.ptin)) {
                VenusNotifier.Warning(ValidationContants.PTINWarning, "");
                return false;
            }
        }
        if (passwordValidation) {
            if (!NullandEmptyCheck(enterPassword) || !NullandEmptyCheck(confirmPassword)) {
                if (!NullandEmptyCheck(enterPassword)) {
                    VenusNotifier.Warning(ValidationContants.EnterPasswordWarning, "");
                    return false;
                }
            }
            if (NullandEmptyCheck(enterPassword)) {
                if (NullandEmptyCheck(confirmPassword)) {
                    if (validatePassword(confirmPassword) == false) {
                        return false;
                    }

                    if (enterPassword.trim() == confirmPassword.trim()) {
                        return true;
                    } else {
                        VenusNotifier.Warning(ValidationContants.PasswordNotMatchedWarning, "");
                        return false;
                    }
                } else {
                    VenusNotifier.Warning(ValidationContants.EnterPasswordWarning, "");
                    return false;
                }
            }
        }
    }
    return true;
}

export function isValidFirmKey(firmKey: string) {
    var guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(firmKey);
}

export function isValidGuid(guid: string) {
    var guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(guid);
}

export function validatePhoneLength(phoneNoId: any) {
    var phoneNo = phoneNoId?.trim();
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    } else return true;
}

export function validateFaxLength(FaxId: any) {
    var Fax = FaxId?.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        return false;
    } else return true;
}

export function validateValidCity(CityId: any) {
    var City = CityId?.trim();
    if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(City) == false) {
        return false;
    } else return true;
}
export function validateZipLength(ZipNoId: any) {
    var ZipNo = ZipNoId?.trim();
    if (ZipNo.trim().length > 5 || ZipNo.trim().length < 5 || /^[0-9]{5}$/.test(ZipNo) == false) {
        return false;
    } else return true;
}
export function ValidateWebsite(website: string) {
    if (
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z A-Z 0-9]+([\-\.]{1}[a-z A-Z 0-9]+)*\.[a-z A-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            website
        ) == false
    ) {
        return false;
    } else {
        return true;
    }
}

export function validateExtensionLength(ExtensionId: any) {
    var Extension = ExtensionId?.trim();
    if (Extension.trim().length > 6 || /^[0-9]{1,10}$/.test(Extension) == false) {
        return false;
    } else return true;
}
export function validatePasswordLength(passwordId: any) {
    var password = passwordId?.trim();
    if (password.length < 8) {
        return false;
    }
    return true;
}
export function validatePasswordSpace(passwordId: any) {
    var password = passwordId?.trim();
    if (password.indexOf(" ") >= 0) {
        return false;
    }
    return true;
}

export function validateCompanySettings(companySetting: ICompanySettings) {
    if (companySetting && companySetting.isDefault) {
        return false;
    }
    return true;
}

export function validateSSRSettings(ssrSetting: ISSRSettings) {
    if (ssrSetting && ssrSetting.isDefault) {
        return false;
    }
    return true;
}

export function validateTaxReturn(
    taxReturn: ITaxReturn,
    useSignatureStamp: boolean,
    allTaxingAuthorities: ITaxingAuthority[],
    compnaySettings: ICompanySettings,
    isGroupedReturn: boolean = false,
    excludeRetentionValidation: boolean = false
): boolean {
    let isValid: boolean = true;
    //validate delivery option
    let isValidDeliveryOption = validateDeliveryOption(taxReturn, compnaySettings);    
    if (!isGroupedReturn) {
        // Validate client info
        isValid = (validateClientInfo(taxReturn) && isValidDeliveryOption) === true ? true : false;
    } else {
        const isValidClientId = validateClientId(taxReturn);
        let canBeMarkedAsReadyForDelivery = isProcessable(taxReturn);
        if (canBeMarkedAsReadyForDelivery) isValid = canBeMarkedAsReadyForDelivery && isValidDeliveryOption && isValidClientId;
        else {
            isValid = false;
            VenusNotifier.Error(FinishProcessReturn.StatusMessage.MarkAsDeliverNotAllowedError, "Error");
        }
    }

    // Validate custom authorities in added refund
    if (isValid) {
        isValid = validateRefudInfo(taxReturn, allTaxingAuthorities);
    }

    // Validate efile tab only if previous validation is successful
    if (isValid) {
        isValid = validateEfileTab(taxReturn, useSignatureStamp);
    }

    // Validate Additional Efile tab only if previous validation is successful
    if (isValid) {
        isValid = validateAdditionalEsignDocumentTab(taxReturn);
    }

    if (isValid) {
        isValid = validateRecognizedAdditionalESign(taxReturn);
    }

    // Validate system recognised vouchers with null values value
    if (isValid) {
        isValid = validateRecognisedVoucher(taxReturn);
    }

    // validates Retention Period i.e Value should be >0
    if (isValid && !excludeRetentionValidation) {
        isValid = validateRetentionPeriod(taxReturn);
    }

    //validate invoice amount
    if (isValid) {
        isValid = validateInvoiceAmount(taxReturn);
    }

    //validate authority info in paper file
    if (isValid) {
        isValid = validatePaperFile(taxReturn.paperFiles, allTaxingAuthorities);
    }

    //validate ssn and entity type for K1s
    if (isValid) {
        isValid = validateK1(taxReturn);
    }

    //validate emailAddress format
    if (isValid) {
        isValid = validateEmailAddressFormat(taxReturn);
    }

    if (isValid) {
        isValid = validateTaxReturnMobileNumber(taxReturn);
    }
    return isValid;
}

export function validateRefudInfo(taxReturn: ITaxReturn, allTaxingAuthorities: ITaxingAuthority[]) {
    let index: number = -1;
    const numberOfRefunds: number = taxReturn.refundInfo.length;
    if (numberOfRefunds == 0) return true;
    for (let i = 0; i < numberOfRefunds; i++) {
        const authorityID: number = taxReturn.refundInfo[i].authorityID;
        index = allTaxingAuthorities.findIndex((authority) => authorityID === authority.Id);
        if (index != -1) continue;
        else break;
    }
    if (index != -1) return true;
    else VenusNotifier.Error(FinishProcessReturn.StatusMessage.SelectDeactivatedDeletedAuthority, "Error");
    return false;
}

function validateEfileTab(taxReturn: ITaxReturn, useSignatureStamp: boolean): boolean {
    let isValid: boolean = true;
    const eFileInfo = new EfileInfoView(taxReturn);
    let signatureControls: ISignatureControlsDictionary = eFileInfo.getSignatureControls();
    let signatureControlstoArray: any = Object.keys(signatureControls).map((key: any) => signatureControls[key]);

    let taxpayerSignatures = filterSignatureByRole(
        signatureControlstoArray,
        "signatureControlRole",
        SignatureControlRole.Taxpayer
    ).filter((i: ISignatureControl) => i.type == SignatureControlTypes.Signature);
    let spouseSignatures = filterSignatureByRole(
        signatureControlstoArray,
        "signatureControlRole",
        SignatureControlRole.Spouse
    ).filter((i: ISignatureControl) => i.type == SignatureControlTypes.Signature);
    let eroSignatures = filterSignatureByRole(signatureControlstoArray, "signatureControlRole", SignatureControlRole.ERO).filter(
        (i: any) => i.type == SignatureControlTypes.Signature
    );
    let partnerSignatures = filterSignatureByRole(
        signatureControlstoArray,
        "signatureControlRole",
        SignatureControlRole.PartnerShip
    ).filter((i: ISignatureControl) => i.type == SignatureControlTypes.Signature);

    if (taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !== SignatureType.ManualSign) {
        if (isPartnership(taxReturn) && partnerSignatures.length < 1) {
            VenusNotifier.Warning(TabEFileConstants.SignatureControlWarning.Partner, "");
            isValid = false;
        } else if (
            (isIndividual(taxReturn) || isMutual(taxReturn)) &&
            taxpayerSignatures.length < 1 &&
            !taxReturn.taxpayer.isDeceased
        ) {
            VenusNotifier.Warning(TabEFileConstants.SignatureControlWarning.Taxpayer, "");
            isValid = false;
        } else if (isMutual(taxReturn) && spouseSignatures.length < 1 && !taxReturn.spouse.isDeceased) {
            VenusNotifier.Warning(TabEFileConstants.SignatureControlWarning.Spouse, "");
            isValid = false;
        } else if (useSignatureStamp && eroSignatures.length < 1) {
            VenusNotifier.Warning(TabEFileConstants.SignatureControlWarning.EroStamp, "");
            isValid = false;
        }
    } else {
        let index = taxReturn.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
        if (index === -1 || (taxReturn.formGroups[index] && taxReturn.formGroups[index].forms.length === 0)) {
            VenusNotifier.Warning(TabEFileConstants.SignatureControlWarning.ManualSign, "");
            isValid = false;
        }
    }

    if (isValid) {
        isValid = ValidateEfileAuthority(taxReturn);
    }

    return isValid;
}

function validateRecognizedAdditionalESign(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    const additionalESign = new AdditionalESignView(taxReturn);

    if (!additionalESign?.additionalESignForms?.length) return true;

    const sigControls: ISignatureControlsDictionary = additionalESign.getSignatureControls();
    const sigArray = Object.values(sigControls);
    
    sigArray.forEach((forms) => {
        if (!isValid) return;

        const getSigs = (role: SignatureControlRole, type: SignatureControlTypes) =>
            filterSignatureByRole([forms], "signatureControlRole", role).filter(
                (sig: ISignatureControl) => sig.type === type
            );

        const taxpayerSigs = getSigs(SignatureControlRole.Taxpayer, SignatureControlTypes.Signature);
        const spouseSigs = getSigs(SignatureControlRole.Spouse, SignatureControlTypes.Signature);
        const partnerSigs = getSigs(SignatureControlRole.PartnerShip, SignatureControlTypes.Signature);

        const sigFormType = taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;

        if (sigFormType !== SignatureType.ManualSign) {
            if (
                (isPartnership(taxReturn) && partnerSigs.length > 0) ||
                (taxpayerSigs.length > 0 || spouseSigs.length > 0)
            ) {
                isValid = true;
            } else {
                isValid = false;
            }
        } else {
            isValid = true;
        }
    });
    if (!isValid) {
        VenusNotifier.Warning(TabAdditionalEsignDocumentConstants.SignatureControlWarning.WarningMessage, null);
        return isValid;
    }
    return isValid;
}



export function filterAdditionalDocumentSignatureByRole(signatureData: IAdditionalESignDocumentPage[], role: number) {
    let filtered: IAdditionalESignDocumentControl[] = [];
    for (var i = 0; i < signatureData.length; i++) {
        let obj: IAdditionalESignDocumentPage = signatureData[i];
        obj.controls.forEach((item) => {
            if (item.signatureControlRole == role) {
                filtered.push(item);
            }
        });
    }
    return filtered;
}

function validateAdditionalEsignDocumentTab(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = false;
    let warningMsg: string = "";

    if (taxReturn.additionalEsignDocuments != undefined && taxReturn.additionalEsignDocuments.length > 0) {
        for (var i = 0; i < taxReturn.additionalEsignDocuments.length; i++) {
            var item: IDocumentData = taxReturn.additionalEsignDocuments[i];
            let taxpayerSignatures = filterAdditionalDocumentSignatureByRole(
                item.documentControls,
                SignatureControlRole.Taxpayer
            ).filter((i: any) => i.type == SignatureControlTypes.Signature);
            let spouseSignatures = filterAdditionalDocumentSignatureByRole(
                item.documentControls,
                SignatureControlRole.Spouse
            ).filter((i: any) => i.type == SignatureControlTypes.Signature);
            let partnerSignatures = filterAdditionalDocumentSignatureByRole(
                item.documentControls,
                SignatureControlRole.PartnerShip
            ).filter((i: any) => i.type == SignatureControlTypes.Signature);

            if (taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType !== SignatureType.ManualSign) {
                if (
                    (isPartnership(taxReturn) && partnerSignatures.length < 1) ||
                    ((isIndividual(taxReturn) || isMutual(taxReturn)) &&
                        taxpayerSignatures.length < 1 &&
                        !taxReturn.taxpayer.isDeceased &&
                        isMutual(taxReturn) &&
                        spouseSignatures.length < 1 &&
                        !taxReturn.spouse.isDeceased) ||
                    (isMutual(taxReturn) && taxpayerSignatures.length < 1 && taxReturn.spouse.isDeceased) ||
                    (isMutual(taxReturn) && spouseSignatures.length < 1 && taxReturn.taxpayer.isDeceased) ||
                    (isIndividual(taxReturn) && taxpayerSignatures.length < 1 && !taxReturn.taxpayer.isDeceased)
                ) {
                    isValid = false;
                    warningMsg = TabAdditionalEsignDocumentConstants.SignatureControlWarning.WarningMessage;
                } else {
                    isValid = true;
                }
            } else {
                isValid = true;
            }

            if (!isValid && warningMsg !== "") {
                VenusNotifier.Warning(warningMsg, null);
                return isValid;
            }
        }
    } else {
        isValid = true;
    }
    return isValid;
}

export function ValidateEfileAuthority(taxReturn: ITaxReturn) {
    let isValid: boolean = true;

    taxReturn.formGroups.map((formGroups) => {
        if (formGroups.group == DocumentGroups.EFile) {
            formGroups.forms.map((form) => {
                let efileform = form as IEFile;
                if (efileform.authorityID == 0) {
                    VenusNotifier.Warning("Please select taxing authority for " + form.formName + " in e-file tab ", "");
                    isValid = false;
                }
            });
        }
    });

    return isValid;
}

export function validateFileTypes(fileExtension: string): boolean {
    if (
        fileExtension.toLowerCase() !== "pdf" &&
        fileExtension.toLowerCase() !== "docx" &&
        fileExtension.toLowerCase() !== "doc" &&
        fileExtension.toLowerCase() !== "png" &&
        fileExtension.toLowerCase() !== "jpeg" &&
        fileExtension.toLowerCase() !== "xlsx" &&
        fileExtension.toLowerCase() !== "jpg" &&
        fileExtension.toLowerCase() !== "xls"
    ) {
        VenusNotifier.Warning(ValidationContants.FileFormatNotSupported, "");
        return false;
    }
    return true;
}

function validateRecognisedVoucher(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;

    taxReturn.formGroups.map((formGroups) => {
        if (formGroups.group === DocumentGroups.Vouchers) {
            formGroups.forms.map((form: any) => {
                if (form.dueDate === undefined || new Date(form.dueDate).getFullYear() == 1) {
                    VenusNotifier.Warning(VoucherTabConstants.VoucherDueDateInvalid + form.formName, "");
                    isValid = false;
                }
                if (form.paymentType === VoucherTypes.None || form.voucherNo === VoucherNo.None) {
                    VenusNotifier.Warning(VoucherTabConstants.VoucherPaymentType + form.formName, "");
                    isValid = false;
                }
                if (form.authorityID === 0) {
                    VenusNotifier.Warning(VoucherTabConstants.VoucherAuthorityWarning + form.formName, "");
                    isValid = false;
                }
            });
        }
    });

    return isValid;
}

function validateInvoiceAmount(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    if (taxReturn.formGroups.find((x) => x.group == DocumentGroups.Invoice) != undefined && taxReturn.invoiceAmount >= 1000000) {
        VenusNotifier.Warning(InvoiceReplaceConstants.InvoiceAmount, "");
        isValid = false;
    }
    return isValid;
}

export function validatePdfFileContent(signature: any) {
    if (signature === "25504446") {
        // 'application/pdf'
        return true;
    }
    return false;
}
export function validatePdfFile(fileExtension: string, signature: any) {
    if (fileExtension.toLowerCase() === "pdf" && validatePdfFileContent(signature)) {
        return true;
    }
    VenusNotifier.Warning(ValidationContants.PdfFileValidation, "");
    return false;
}

export function validateSFUploadFileType(fileExtension: string): boolean {
    if (
        fileExtension.toLowerCase() !== "pdf" &&
        fileExtension.toLowerCase() !== "doc" &&
        fileExtension.toLowerCase() !== "docx"
    ) {
        VenusNotifier.Warning(SFDocument.sfUploadFileWarning, "");
        return false;
    }
    return true;
}

export function validatePaperFileUploadFileType(fileExtension: string): boolean {
    if (
        fileExtension.toLowerCase() !== "pdf" &&
        fileExtension.toLowerCase() !== "doc" &&
        fileExtension.toLowerCase() !== "docx" &&
        fileExtension.toLowerCase() !== "xlsx"
    ) {
        VenusNotifier.Warning(PaperFile.uploadPaperFileWarning, "");
        return false;
    }
    return true;
}

export function validateMailMergeTemplateUploadFileType(fileExtension: string): boolean {
    if (fileExtension.toLowerCase() !== "csv") {
        VenusNotifier.Warning(MailMergeDocument.CsvUploadFileWarning, "");
        return false;
    }
    return true;
}

export function validateWordAndPDFFileContent(signature: any) {
    if (signature === "25504446") {
        // 'application/pdf'
        return true;
    } else if (signature === "D0CF11E0") {
        return true;
    } else if (signature === "504B34") {
        return true;
    }
    return false;
}

export function isWordFile(signature: any) {
    return signature === "D0CF11E0" || signature === "504B34";
}

export function ValidateCSVFileContent(signature: any): boolean {
    return signature === "EFBBBF22" || signature === "5369676E" || signature === "53656E64" ? true : false;
}

export function fileContentValidation(signature: any) {
    if (
        signature === "25504446" || //.pdf
        signature === "D0CF11E0" || //.doc
        signature === "504B34" || //.docx
        signature === "89504E47" || //.png
        signature === "FFD8FFE1" || //.jpg
        signature === "504B0304" || //.xlsx
        signature === "D0CF11E0" || //.xls
        signature === "FFD8FFE0" || //.jpeg
        signature === "FFD8FFE2" //.jpeg
    ) {
        return true;
    }
    VenusNotifier.Warning(ValidationContants.FileFormatNotSupported, "");
    return false;
}

export function ConvertDateToUtc(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
}

export function ValidateTenDigitNumber(event: any) {
    var pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
    var pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
    return pattern.test(value);
}

export function ValidateInputTextCharacterMaxLength(value: string) {
    var pattern = new RegExp(/^(.{0,100})$/);
    return pattern.test(value);
}

export function validateRetentionPeriod(taxReturn: ITaxReturn): boolean {
    if (taxReturn.documentSettings.documentRetentionSetting.retentionPeriod > 0) {
        return true;
    } else {
        VenusNotifier.Warning(FinishProcessReturn.StatusMessage.RetentionValidationMessage, "");
        return false;
    }
}

export function ValidateTrustedDeviceExpiryDays(value: number) {
    if (value > 0 && value < 61) {
        return true;
    }
}

export function ValidateIPAdress(ipAddress: string): boolean {
    var ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
    return ipRegex.test(ipAddress);
}

export function ValidateFontSizeValue(value: number) {
    if (value > 0 && value < 73) {
        return true;
    }
}

export function isValidClientId(clientId: any) { 
    var date_regex = /^([\w\s.()-])+$/gi; 
    return date_regex.test(clientId);
}

export const validateName = (name: string) => {
    const nameRegex = /[^\w\s-]/gi;
    return nameRegex.test(name);
}
