import * as React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col, Button, FormLabel } from "react-bootstrap";
import { CheckBoxComponent } from "../../CheckBoxComponent";
import * as TaxDocument from "../../../common/TaxReturn";
import { OverlayLoader } from "../../../helper/OverlayLoader";
import { RadioButtonComponent } from "../../../common/RadioButtonComponent";
import { ReorderList, IOrderList } from "../../../common/Generic/ReorderList";

interface IPaperDelivery {
    taxreturn: TaxDocument.ITaxReturn;
    cancelFinish: () => void;
    onPrintForPaperDelivery: () => void;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    updateDocumentGroupOrder: (engagementType: TaxDocument.EngagementType, order: TaxDocument.DocumentGroups[]) => void;
    handleSaveAsDefaultOrderChange: (event: any) => void;
    isDefaultOrder: boolean;
}

interface IPaperDeliveryState {
    multipleFilesChecked: boolean;
    singleFileChecked: boolean;
    listContent: IOrderList[];
}

const DocumentGroupsList: any = {
    None: "None",
    EFile: "E-File Forms",
    Vouchers: "Vouchers",
    TaxReturns: "Tax Returns",
    Transmittals: "Transmittals",
    Invoice: "Invoice",
    K1: "K-1 Package",
    AdditionalEsignDocument: "Additional E-sign"
};

export class PrintForPaperDelivery extends React.Component<IPaperDelivery, IPaperDeliveryState> {
    state: IPaperDeliveryState = {
        multipleFilesChecked: false,
        singleFileChecked: false,
        listContent: []
    };

    UNSAFE_componentWillReceiveProps(nextProps: IPaperDelivery) {
        this.checkAndUpdateState(nextProps);
    }

    UNSAFE_componentWillMount() {
        this.checkAndUpdateState(this.props);
    }

    checkAndUpdateState = (nextProps: IPaperDelivery) => {
        let taxreturn = nextProps.taxreturn;

        let listContents: IOrderList[] = [];
        let newList: IOrderList[] = [];

        if (nextProps.taxreturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder !== undefined) {
            let order = nextProps.taxreturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder;

            listContents = this.getGroupOrder(order);
        } else {
            listContents = this.getDefaultGroupOrder();
        }

        listContents.forEach((value, index) => {
            if (
                taxreturn.formGroups.find((m) => m.group === value.id) !== undefined &&
                taxreturn.formGroups.filter((m) => m.group === value.id)[0] &&
                taxreturn.formGroups.filter((m) => m.group === value.id)[0].forms.length !== 0
            ) {
                if (value.id === TaxDocument.DocumentGroups.Invoice) {
                    if (taxreturn.documentSettings.documentDisplaySetting.isSendWithoutInvoice === false) {
                        newList.push(value);
                    }
                } else {
                    newList.push(value);
                }
            }
        });

        let documentGroups = taxreturn.formGroups.filter((m) => m.group);

        documentGroups.forEach((value, index) => {
            if (value.group !== TaxDocument.DocumentGroups.Deleted) {
                const groupName = TaxDocument.DocumentGroups[value.group];

                if (
                    taxreturn.formGroups.find((m) => m.group === value.group) !== undefined &&
                    taxreturn.formGroups.filter((m) => m.group === value.group)[0] &&
                    taxreturn.formGroups.filter((m) => m.group === value.group)[0].forms.length !== 0 &&
                    newList.find((m) => m.id == value.group) === undefined
                ) {
                    if (value.group === TaxDocument.DocumentGroups.Invoice) {
                        if (taxreturn.documentSettings.documentDisplaySetting.isSendWithoutInvoice === false) {
                            let group: IOrderList = { id: value.group, value: DocumentGroupsList[groupName] };
                            newList.push(group);
                        }
                    } else {
                        let group: IOrderList = { id: value.group, value: DocumentGroupsList[groupName] };
                        newList.push(group);
                    }
                }
            }
        });

        const filteredNewList = newList.filter((value) => !!value.value);

        this.setState({
            listContent: filteredNewList,
            multipleFilesChecked:
                taxreturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType ===
                    TaxDocument.PaperReturnDeliveryType.MultipleFiles ||
                taxreturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType.toString() ===
                    TaxDocument.PaperReturnDeliveryType[TaxDocument.PaperReturnDeliveryType.MultipleFiles]
                    ? true
                    : false,

            singleFileChecked:
                taxreturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType ===
                    TaxDocument.PaperReturnDeliveryType.SingleFile ||
                taxreturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType.toString() ===
                    TaxDocument.PaperReturnDeliveryType[TaxDocument.PaperReturnDeliveryType.SingleFile]
                    ? true
                    : false
        });
    };

    public onChangePaperDeliveryType(event: any, paperDeliveryType: TaxDocument.PaperReturnDeliveryType) {
        this.props.taxreturn.documentSettings.deliverySettings.paperReturnSettings.deliveryType = paperDeliveryType;
        this.props.updateTaxDocument(this.props.taxreturn);

        this.setState({
            multipleFilesChecked: paperDeliveryType === TaxDocument.PaperReturnDeliveryType.MultipleFiles ? true : false,
            singleFileChecked: paperDeliveryType === TaxDocument.PaperReturnDeliveryType.SingleFile ? true : false
        });
    }

    private reorderList = (updatedList: IOrderList[]) => {
        let taxReturn = this.props.taxreturn;
        taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder = updatedList.map((value, index) => {
            return value.id;
        });
        this.props.updateTaxDocument(taxReturn);

        this.setState({ listContent: updatedList });
    };

    private handleNotifyUserChange = (event: any) => {
        let taxReturn = this.props.taxreturn;
        taxReturn.documentSettings.deliverySettings.paperReturnSettings.isNotifyUserEnabled = event.target.checked;
        this.props.updateTaxDocument(taxReturn);
    };

    private getDefaultGroupOrder = (): IOrderList[] => {
        let listContents: IOrderList[] = [
            { id: TaxDocument.DocumentGroups["EFile"], value: "E-File Forms" },
            { id: TaxDocument.DocumentGroups["Vouchers"], value: "Vouchers" },
            { id: TaxDocument.DocumentGroups["Invoice"], value: "Invoice" },
            { id: TaxDocument.DocumentGroups["K1"], value: "K-1 Package" },
            { id: TaxDocument.DocumentGroups["Transmittals"], value: "Transmittals" },
            { id: TaxDocument.DocumentGroups["TaxReturns"], value: "Tax Returns" },
            { id: TaxDocument.DocumentGroups["AdditionalEsignDocument"], value: "Additional E-sign" }
        ];

        return listContents;
    };

    private getGroupOrder = (order: TaxDocument.DocumentGroups[]): IOrderList[] => {
        let listContents: IOrderList[] = [];

        order.map((value, index) => {
            const groupName = TaxDocument.DocumentGroups[value];
            let group: IOrderList = { id: value, value: DocumentGroupsList[groupName] };
            listContents.push(group);
        });

        return listContents;
    };

    private onPrintForPaperDelivery = () => {
        let taxReturn = this.props.taxreturn;
        taxReturn.documentSettings.deliverySettings.paperReturnSettings.documentOrder = this.state.listContent.map(
            (value, index) => {
                return value.id;
            }
        );

        this.props.updateTaxDocument(taxReturn);
        this.props.onPrintForPaperDelivery();
    };

    public render() {
        let multipleFilesChecked =
            this.props.taxreturn?.documentSettings?.deliverySettings.paperReturnSettings.deliveryType ===
                TaxDocument.PaperReturnDeliveryType.MultipleFiles ||
            this.props.taxreturn?.documentSettings?.deliverySettings.paperReturnSettings.deliveryType.toString() ===
                TaxDocument.PaperReturnDeliveryType[TaxDocument.PaperReturnDeliveryType.MultipleFiles]
                ? true
                : false;

        let singleFileChecked =
            this.props.taxreturn?.documentSettings?.deliverySettings.paperReturnSettings.deliveryType ===
                TaxDocument.PaperReturnDeliveryType.SingleFile ||
            this.props.taxreturn?.documentSettings?.deliverySettings.paperReturnSettings.deliveryType.toString() ===
                TaxDocument.PaperReturnDeliveryType[TaxDocument.PaperReturnDeliveryType.SingleFile]
                ? true
                : false;

        return (
            <Card>
                <Card.Body>
                    {
                        <div>
                            <Row className="marB10">
                                <Col sm={11}>
                                    <RadioButtonComponent
                                        text="Download Multiple PDF files (one for each document grouping)"
                                        id="multiple-files"
                                        checked={multipleFilesChecked}
                                        onClick={(event) => {
                                            this.onChangePaperDeliveryType(
                                                event,
                                                TaxDocument.PaperReturnDeliveryType.MultipleFiles
                                            );
                                        }}
                                        disabled={false}
                                    />
                                </Col>
                            </Row>
                            <Row className="marB10">
                                <Col sm={6}>
                                    <RadioButtonComponent
                                        text="Download Single PDF file"
                                        id="single-file"
                                        checked={singleFileChecked}
                                        onClick={(event) => {
                                            this.onChangePaperDeliveryType(event, TaxDocument.PaperReturnDeliveryType.SingleFile);
                                        }}
                                        disabled={false}
                                    />
                                </Col>
                            </Row>
                            {singleFileChecked ? (
                                <div>
                                    <Row
                                        className="marB10"
                                        style={{
                                            border: "1px solid #ddd",
                                            marginLeft: "2px",
                                            marginRight: "2px",
                                            borderTopLeftRadius: "4px",
                                            borderTopRightRadius: "4px",
                                            paddingBottom: "10px"
                                        }}
                                    >
                                        <Col sm={12} style={{ borderBottom: "1px solid #ddd" }}>
                                            <FormLabel style={{ padding: "10px 0px" }}>
                                                Select Document Order in the Single PDF file
                                            </FormLabel>
                                        </Col>
                                        {
                                            <div style={{ display: "contents" }}>
                                                <OverlayLoader
                                                    show={this.state.listContent.length > 0 ? false : true}
                                                    text={"Loading..."}
                                                />
                                                <Col sm={10} style={{ paddingTop: "11px" }}>
                                                    <ReorderList
                                                        listContent={this.state.listContent}
                                                        reorderList={this.reorderList}
                                                    />
                                                </Col>
                                                <Col sm={2} style={{ marginTop: "49px" }}>
                                                    <Link
                                                        to={"#"}
                                                        style={{ marginLeft: "-13px" }}
                                                        className="help-icon marL05"
                                                        data-placement="right"
                                                        data-toggle="tooltip"
                                                        title="Select a document type in the window. Use the Move Up and Move Down arrow keys to change the order of the documents "
                                                    >
                                                        <i className="fas fa-question-circle"></i>
                                                    </Link>
                                                </Col>
                                                <Col sm={6} style={{ marginLeft: "129px" }}>
                                                    <CheckBoxComponent
                                                        id="saveAsDefaultorder"
                                                        checked={this.props.isDefaultOrder}
                                                        onChange={this.props.handleSaveAsDefaultOrderChange}
                                                        text="Save as my default order"
                                                    />
                                                </Col>
                                            </div>
                                        }
                                    </Row>
                                </div>
                            ) : (
                                ""
                            )}
                            <Row className="marB10">
                                <Col sm={12} style={{ fontSize: "11px" }}>
                                    <i className="fas fa-info-circle"></i>
                                    <span style={{ marginLeft: 5 }}>
                                        It may take some time to prepare your files for download. Please download your files from
                                        the Delivered Return Report.
                                    </span>
                                </Col>
                            </Row>
                            <Row className="marB10">
                                <Col sm={11}>
                                    <CheckBoxComponent
                                        id="notify"
                                        checked={
                                            this.props.taxreturn?.documentSettings?.deliverySettings.paperReturnSettings
                                                .isNotifyUserEnabled
                                        }
                                        onChange={this.handleNotifyUserChange}
                                        text="Notify me by Email when the file(s) are available for Download"
                                    />
                                </Col>
                            </Row>
                            <div>
                                <Col sm={12}>
                                    <Button
                                        type="button"
                                        variant="info"
                                        className="float-right"
                                        data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33883"
                                        onClick={this.onPrintForPaperDelivery}
                                    >
                                        <i className="fa fa-paper-plane"></i>Prepare PDF File(s) For Printing
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="default"
                                        className="float-right marR05 btn-white btn-default"
                                        data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5002"
                                        onClick={this.props.cancelFinish}
                                    >
                                        <i className="fas fa-times"></i>Cancel
                                    </Button>
                                </Col>
                            </div>
                        </div>
                    }
                </Card.Body>
            </Card>
        );
    }
}

export default PrintForPaperDelivery;
