import * as React from "react";
import Select from "react-select";
import { RenderEngagementOptions, RenderOtherOptions, TypeDropdownValues } from "../../Core/Common/Common";
import TickIcon from "../../assets/images/TickIcon";

export class CustomEngagementTypeFilter extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedOptions: this.props.selectedOptions,
            options: [],
            clearable: true
        };

        this.applyFilter = this.applyFilter.bind(this);
        this.waitSet = this.waitSet.bind(this);
    }

    componentDidMount() {
        this.dropDownDataCreation();
    }

    // method prevents the value of Tax year dropdown from getting over ridden during update --- change if problem arises
    componentDidUpdate(prevProps: any, prevState: any) {
        if (this.props.options !== prevProps.options) {
            this.dropDownDataCreation();
        }
    }

    // Common method segrigation for componentDidMount and componentDidMount
    private dropDownDataCreation = () => {
        this.props.onRef(this);
        var dropDownData: any = [];
        this.props.options.map((data: any) => {
            dropDownData.push(data);
        });
        this.setState({ options: dropDownData });
    };

    public cleanFiltered = (clearAll?: boolean) => {
        this.handleSelectChange(`${TypeDropdownValues.All}`);
    };

    waitSet(value: any) {
        return new Promise((res) => {
            this.setState(
                {
                    selectedOptions: value
                },
                () => {
                    res("done");
                    this.props.filterHandler(value);
                }
            );
        });
    }

    async applyFilter(value: any) {
        //temporary fix to resolve setState implementation by promises--55813
        await this.waitSet(value);
        this.setState(
            {
                selectedOptions: value
            },
            () => {
                this.props.filterHandler(value);
            }
        );
    }

    private handleSelectChange(value: string) {
        let selectedValues = value.split(",");
        let positiveNumbers = selectedValues.filter((option: string) => parseInt(option) > 0);
        let negativeNumbers = selectedValues.filter((option: string) => parseInt(option) < 0);
        let newValue: string;
        if (positiveNumbers.length > 1 || negativeNumbers.length > 1) {
            newValue = [negativeNumbers[negativeNumbers.length - 1], positiveNumbers[positiveNumbers.length - 1]]
                .filter(Boolean)
                .join(",");
        } else {
            newValue = value;
        }
        if (newValue?.trim()?.length === 0) {
            newValue = `${TypeDropdownValues.All}`;
        }
        this.setState({ selectedOptions: newValue }, () => {
            this.props.filterHandler(this.state.selectedOptions);
        });
    }

    private createCustomOption = (props: any, handleTextOverflow: boolean) => {
        if (props.option?.value === TypeDropdownValues.FullReturn) {
            return (
                <>
                    <div className="typeContainer type_selectType">Select Type</div>
                    <div className="typeContainer">
                        <label
                            onMouseDown={(e) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                !props.isSelected && props.onSelect(props.option, e);
                            }}
                            className="typeRadio_label type_FullReturn"
                        >
                            <input
                                type="radio"
                                name={`option-${props.option.label}`}
                                value={props.option.value}
                                checked={this.state.selectedOptions.includes(props.option.value)}
                            />
                            {props.option.label}
                        </label>
                    </div>
                </>
            );
        } else if (props.option?.value === TypeDropdownValues.StateOnly) {
            return (
                <div className="typeContainer">
                    <label
                        onMouseDown={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            !props.isSelected && props.onSelect(props.option, e);
                        }}
                        className="typeRadio_label type_StateOnly"
                    >
                        <input
                            type="radio"
                            name={`option-${props.option.label}`}
                            value={props.option.value}
                            checked={this.state.selectedOptions.includes(props.option.value)}
                        />
                        {props.option.label}
                    </label>
                </div>
            );
        } else if (props.option?.value === TypeDropdownValues.All) {
            return (
                <>
                    <div className="typeContainer typeAll">
                        <label
                            onMouseDown={(e) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                !props.isSelected && props.onSelect(props.option, e);
                            }}
                            className="typeRadio_label"
                        >
                            <input
                                type="radio"
                                name={`option-${props.option.label}`}
                                value={props.option.value}
                                checked={this.state.selectedOptions.includes(props.option.value)}
                            />
                            {props.option.label}
                        </label>
                    </div>
                    <div className="typeHorizontalLine">
                        <hr />
                    </div>
                </>
            );
        } else if (Array.from({ length: 9 }, (_, i) => i + 1).includes(props.option?.value)) {
            return (
                <>
                    <div
                        className={`typeContainer ${props.isSelected ? "selected-control" : ""}`}
                        onMouseDown={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            !props.isSelected && props.onSelect(props.option, e);
                        }}
                    >
                        <span className="typeText" title={props.option.label}>
                            {props.option.label}
                        </span>
                        {props.isSelected && (
                            <span className="typeTickIcon">
                                <TickIcon />
                            </span>
                        )}
                    </div>
                    {props.option?.value === RenderEngagementOptions[RenderEngagementOptions.length - 1].value ? (
                        <div
                            className="typeClearAll typeContainer"
                            onMouseDown={(e: any) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                this.handleSelectChange(`${TypeDropdownValues.All}`);
                                props.onSelect(RenderOtherOptions[2], e);
                            }}
                        >
                            Clear All
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            );
        } else {
            return <div></div>;
        }
    };

    private createCustomTag = (props: any) => {
        if (props.values.length === 1 && props.values[0].value === TypeDropdownValues.All && this.state.clearable) {
            this.setState({ clearable: false });
        } else if (props.values.length === 1 && props.values[0].value !== TypeDropdownValues.All && !this.state.clearable) {
            this.setState({ clearable: true });
        } else if (props.values.length > 1 && !this.state.clearable) {
            this.setState({ clearable: true });
        }
        let nbValues: string;
        let element: React.ReactNode;
        if (props.values.length === 1 && props.values[0].value < 1) {
            nbValues = props.values[0].label;
            if (nbValues === "All") {
                element = (
                    <span className="custom-tag-styles custom-option-all-style" title={nbValues}>
                        {nbValues}
                    </span>
                );
            } else {
                element = (
                    <span className="custom-tag-styles" title={nbValues}>
                        {nbValues}
                    </span>
                );
            }
        } else if (props.values.length > 1) {
            nbValues = `${props.values[0].label} - ${props.values[1].label}`;
            element = (
                <span className="custom-tag-styles" title={nbValues}>
                    {nbValues}
                </span>
            );
        } else {
            nbValues = "All";
            element = (
                <span className="custom-tag-styles custom-option-all-style" title={nbValues}>
                    {nbValues}
                </span>
            );
        }
        return element;
    };

    render() {
        const self = this;
        return (
            <Select
                ref="OptionsSelect"
                className={
                    this.props.isMultiSelectCheckbox
                        ? "filter select-filter multi-select-control"
                        : this.props.enableAllFilter
                        ? "filter select-filter multi-select-width100"
                        : "filter select-filter multi-select-widthauto"
                }
                options={this.state.options}
                onChange={(e: any) => {
                    this.handleSelectChange(e);
                }}
                value={this.state.selectedOptions}
                optionComponent={(options: any) => {
                    return this.createCustomOption(options, self.props.handleTextOverflow);
                }}
                valueComponent={this.createCustomTag}
                removeSelected={false}
                multi
                simpleValue
                searchable={false}
                backspaceRemoves={false}
                clearable={this.state.clearable}
                placeholder={<span className="filter select-filter placeholder-selected">{this.props.placeholder}</span>}
            />
        );
    }
}
