let moment = require('moment');
import * as bootbox from 'bootbox';
import * as React from 'react';
import {
    BootstrapTable, CustomSelectProps, DateFilter, SelectFilter,
    SelectFilterOptionsType, TableHeaderColumn, TextFilter, ToolBarProps, CustomFilter
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as InUseReturnsState from '../../../store/reports/InUseReturnsState';
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { engagementType, SignatureStatus, DocumentStatus, DaysRangeOptionsList, getFormattedClientName, EngagementType } from '../../common/TaxReturn';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { VenusNotifier } from '../../helper/VenusNotifier';
import { InUseReturnsPageState } from '../../reports/DeliveredReturns/DeliveredReturnsTable';
import { Filters, IFilters, ReportFilterType } from '../Filters';
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { InUseReturnAndInUseGroupedReturnResources } from '../../../components/helper/ResourceIdConstants';
import { IOfficeLocation } from '../../common/TaxReturn';
import { RenderTypeFilterOptions, TypeDropdownValues} from '../../../Core/Common/Common';
import SingleSelectSearchableDropdown from '../../../components/common/SingleSelectSearchableDropdown';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState';
import { CustomEngagementTypeFilter } from '../../../components/common/CustomEngagementTypeFilter';

interface TableData {
    InUse: InUseReturnsState.InUseReturnsState
    saveFilterShow: boolean,
    onPageChange: any,
    onSortChange: any,
    onFilterChange: any,
    pageNo: number,
    onExportToExcel(onExportToExcelComplete: () => void , resourceId: string ): void;
    onFilterNameChange(event: any): void;
    loadGrid(): void;
    totalRows: number;
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[],
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;
    currentFilter: IFilters,
    onMakeAvailable: (rowIndex: number, resourceId: string) => void;
    isLoading: boolean,
    pageSize: number,
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    taxYearList: any;
    proxyFilter: string;
	filterType: ReportFilterType,
    onPageReload: () => void;
    refreshDelay?: boolean;
    parentControllerResourceIdentifier: string;
    groupInfo: IGroupInfo[];
    customGroupNameFilter: any,
    setRefOnGroupNameFilter: (ref: any) => void;
}

export class InUseReturnsTable extends React.Component<TableData, InUseReturnsPageState> {
    private filterChanged: boolean = false;
    private customMultiSelect: any;
    private customOfficeSelect :any;
    private customEngagementTypeFilter: any;
    public isAppiedFilter: boolean = false;
    constructor(props: TableData) {
        super(props);
        this.state = {
            officeLocationList:[],
            isHiddenExportExcel: false,
        };
        this.onClearFilter = this.onClearFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);
    }

    UNSAFE_componentWillMount() {
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
    }

    buttonFunction(cell: any, row: any) {
        const btnResourceId = this.props.parentControllerResourceIdentifier + InUseReturnAndInUseGroupedReturnResources.TableUnlockReturnButton;
        return (
            <ButtonFormatter
                MakeAvailableReturn={() => this.props.onMakeAvailable(row, btnResourceId)}
                disabled={false}
                data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
                btnResourceId={btnResourceId}
            />
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {

        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
        }
    }

    private defaultType(cell: any, row: any) {
        return cell;
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }

    public onClearFilter(clearAll?:boolean) {
        this.filterChanged = true;
        (this.refs.taxDocumentName as TableHeaderColumn)?.cleanFiltered();
        (this.refs.clientId as TableHeaderColumn)?.cleanFiltered();
        (this.refs.engagementType as TableHeaderColumn)?.cleanFiltered();
        (this.refs.partner as TableHeaderColumn)?.cleanFiltered();
        (this.refs.uploadedOn as TableHeaderColumn)?.cleanFiltered();
        (this.refs.taxYear as TableHeaderColumn)?.cleanFiltered();
        this.customMultiSelect?.cleanFiltered(clearAll);
        this.customOfficeSelect?.cleanFiltered(clearAll);
        this.customEngagementTypeFilter?.cleanFiltered(clearAll);
        this.props.customGroupNameFilter?.cleanFiltered && this.props.customGroupNameFilter?.cleanFiltered(clearAll);
        this.filterChanged = false;
    }
    private getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder} />
        );
    }
    
    private getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customOfficeSelect = ref)} 
                filterHandler={filterHandler}  
                options={options} 
                placeholder={placeholder} 
                handleTextOverflow = {true}
            />
        );
    }
  
    private onFilterSaveClick(filterName: string) {
        if (!Helper.validateFilter(this.props.currentFilter)) {
            ((this.props.currentFilter.searchText.length > 0) ? "" : (VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null)));
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.confirm({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: DeliveredReturnsTableConstants.FormLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: DeliveredReturnsTableConstants.FormLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.props.onSaveFilterShow();
                    }
                    else {
                        this.props.onFilterUpdate(filterName);
                    }
                }
            });
        }
    }

    public onLoadSelectedFilter(filter?: IFilters) {
        this.isAppiedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxDocumentName':
                        (this.refs.taxDocumentName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'partner':
                        (this.refs.partner as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentStatus':
                        this.customMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                    case 'uploadedOn':
                        let uploadedDateFilter: any = filter.fields[field];
                        if (uploadedDateFilter.date) {
                            (this.refs.uploadedOn as TableHeaderColumn)?.applyFilter(uploadedDateFilter);
                        }
                        break;
                    case 'locationName':
                        (this.customOfficeSelect as TableHeaderColumn)?.applyFilter(filter.fields[field]);                       
                        break;
                    case 'engagementType':
                        (this.refs.engagementType as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;

                    case 'testColumn':
                        (this.refs.testColumn as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        (this.refs.taxYear as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'groupName':
                        (this.props.customGroupNameFilter as TableHeaderColumn)?.applyFilter && (this.props.customGroupNameFilter as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                }
                this.filterChanged = false;
            }
            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"inuse-returns-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

	private createCustomToolBar = () => {
		return (
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: 33, position: 'unset' }}>
                <Filters ref='Filters'
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showExportExcel={this.state.isHiddenExportExcel}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                    totalRows={this.props.totalRows}
                    parentResourceIdentifier={InUseReturnAndInUseGroupedReturnResources.InUseHeaderPrefix}
                />
			</div>
		);        
    }

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        let list;
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
        }
        else {
            list = [];
        }
        let blank = { label: 'Blanks', value: -100 };
        list.push(blank);
        this.setState({ officeLocationList: list });
    }

    private getGroupNameDropdown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;

        return (
            <SingleSelectSearchableDropdown
                id="groupNameFilter"
                onRef={(ref: any) => this.props.setRefOnGroupNameFilter(ref)}
                filterHandler={filterHandler}
                options={options}
                customPlaceHolder={placeholder}
                className={"groupNameFilter"}
            />
        );
    };

    private getCustomEngagementTypeFilter = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomEngagementTypeFilter
                onRef={(ref: any) => (this.customEngagementTypeFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
                selectedOptions={`${TypeDropdownValues.All}`}
            />
        );
    };

    private setGroupNameList = (groupNameList: IGroupInfo[]) => {
        const list = groupNameList.map((x: IGroupInfo) => ({ label: x.name, value: x.id }));
        const options = [{ label: "Standard Returns", value: -2 }, { label: "Grouped Returns", value: -3 }, ...list];
        return options;
    };
    
    public render() {
        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;
   
          const DocumentStatusType =
            [
                { label: 'UPLOADED', value: 1 },
                { label: 'ERROR', value: 2 },
                { label: 'PROCESSING', value: 4 },
                { label: 'REVIEW', value: 5 },
                { label: 'APPROVED FOR DELIVERY', value: 8 },
                { label: 'PREPARING FOR DELIVERY', value: 9 },
                { label: 'DELIVERY FAILED', value: 10 },
                { label: 'NON SUPPORTED', value: 11 },
                { label: 'RECALLED', value: 12 }
            ];

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };
       

        const searchEnable = true;

        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: "Group Name",
                key: "groupName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: "CustomFilter",
                    getElement: this.getGroupNameDropdown,
                    customFilterParameters: {
                        options: this.setGroupNameList(this.props.groupInfo),
                        enableAllFilter: false,
                        placeholder: "Group Name"
                    }
                }
            },
            {
                header: 'Name',
                key: 'taxDocumentName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Name', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: '80px',
                filter: {
                    type: "CustomFilter",
                    getElement: this.getCustomEngagementTypeFilter,
                    customFilterParameters: {
                        options: RenderTypeFilterOptions,
                        enableAllFilter: false,
                        placeholder: "Select Type..."
                    }
                }
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter', getElement: this.getOfficeSelectDropDown,
                    customFilterParameters: { options: this.state.officeLocationList, enableAllFilter: false, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Status',
                key: 'documentStatus', // String-based value accessors!
                isKey: false,
                dataFormat: "DocumentStatus",
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: { type: 'CustomFilter', getElement: this.getMultiSelectDropDown, customFilterParameters: { options: DocumentStatusType, enableAllFilter: false, placeholder: 'Select Status...'  } as any } as CustomFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '90px',
                filter: { type: 'SelectFilter', placeholder: 'Select Tax Year...', options: this.props.taxYearList } as SelectFilter
            },
            {
                header: '',
                key: 'button', // String-based value accessors!
                isKey: false,
                dataFormat: '',
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '135px',
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];


        let data:any = [];

        if (this.props.InUse.InUseReturnTableModel.inUseReturnModel) {
            data = this.props.InUse.InUseReturnTableModel.inUseReturnModel.map((model, index) => {
                return {
                    taxDocumentName: getFormattedClientName(model.clientName, EngagementType[model.engagementType.toString()]),
                    clientId: model.clientId,
                    engagementType: engagementType(model.engagementType),
                    partner: model.eroSignerFirstName ? model.eroSignerFirstName + " " + model.eroSignerLastName : "",
                    uploadedOn: moment(model.uploadedOn).format('MM/DD/YYYY'),
                    documentStatus: Helper.GetDocumentStatus(DocumentStatus, model.documentStatus),
                    downloded: 0,
                    button: 'Unlock Return',
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    signedCount: 0,
                    id: model.documentId,
                    documentKey: model.documentGuid,
                    taxYear: model.taxYear,
                    rowIndex: index,
                    locationName:model.locationName,
                    groupName:model.groupName
                }
            });

        }

        return <div className="pos-relative">

            <div className="inUseReturns-table" data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}
                    search={searchEnable}>
                    {columns.map((value, index) => {
                        const btnResourceId = this.props.parentControllerResourceIdentifier + InUseReturnAndInUseGroupedReturnResources.TableUnlockReturnButton;

                        return (
                            <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={(cell, row) => {
                                    return value.dataFormat ? (
                                        <div title={cell} className="ellipsis">
                                            {cell}
                                        </div>
                                    ) : (
                                        <ButtonFormatter
                                            MakeAvailableReturn={() => this.props.onMakeAvailable(row.rowIndex, btnResourceId)}
                                            disabled={false}
                                            data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
                                            btnResourceId={btnResourceId}
                                        />
                                    );
                                }}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                            >
                                {
                                    (value.width === 'auto') ?
                                        <span title={value.header} className="table-text-sort">{value.header}</span> :
                                        value.header
                                }
                            </TableHeaderColumn>
                        );
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}

interface IButtonFormatterProps {
    MakeAvailableReturn: any,
    disabled: boolean,
    btnResourceId: string
}

class ButtonFormatter extends React.Component<IButtonFormatterProps, {}> {
    render() {
        return (
            <button
                type="button"
                className="btn btn-info"
                disabled={this.props.disabled}
                value="Unlock Return"
                onClick={this.props.MakeAvailableReturn}
                data-resource-id={this.props.btnResourceId}
            >
                <i className="fas fa-lock-open" data-test-auto="C166BDDC-ADE5-46F2-8B94-7ABAFFC4BED7"></i>Unlock Return
            </button>
        );
    }
}

export default InUseReturnsTable
