export enum TaxreturnType {
    None = 0,
    IndividualTaxReturn = 1,
    MarriedJointTaxReturn = 2,
    CorporateTaxReturn = 3,
}

export interface IReportProblemDetails {
    productType: string;
    taxSoftware: string;
    cpaPhoneNumber: string;
    cpaEmail: string;
    documentId: number;
    taxreturnType: TaxreturnType;
    returnType: string;
    taxYear: number;
    clientId: string;
    clientName: string;
    documentName: string;
    status: string;
    sentBy: string;
    taxpayerName: string;
    taxpayerEmail: string;
    spouseName: string;
    spouseEmail: string;
    partnershipName: string;
    partnershipEmail: string;
    description: string;
    isAttachmentPresent: boolean;
    fileName: string;
    storageAccountId: number;
}

export const initialState: IReportProblemDetails = {
    productType: "",
    taxSoftware: "",
    cpaPhoneNumber: "",
    cpaEmail: "",
    documentId: 0,
    taxreturnType: TaxreturnType.None,
    returnType: "",
    taxYear: 0,
    clientId: "",
    clientName: "",
    documentName: "",
    status: "",
    sentBy: "",
    taxpayerName: "",
    taxpayerEmail: "",
    spouseName: "",
    spouseEmail: "",
    partnershipName: "",
    partnershipEmail: "",
    description: '',
    isAttachmentPresent: false,
    fileName: '',
    storageAccountId: 0
}


export enum ReportProblemProperty {
    productType,
    clientId,
    returnType,
    taxYear,
    cpaEmail,
    cpaPhoneNumber,
    taxSoftware,
    clientName,
    documentType,
    documentName,
    sentBy,
    status,
    //Taxpayer
    taxpayerName,
    taxpayerEmail,
    //Spouse
    spouseName,
    spouseEmail,
    //Partner
    partnerName,
    partnerEmail,
    description,
}

export enum ReportProblemTypes {
    Generic = 1,
    ReturnSpecific = 2,
    SignatureSpecific = 3
}

export enum ProductTypes {
    "General" = 1,
    "SafeSend Returns" = 2,
    "SafeSend Signatures" = 3
}

export enum TaxSoftwareType {
    None = "None",
    ProSystems = "ProSystem",
    UltraTax = "UltraTax",
    GoSystem = "GoSystem",
    UltraTaxM18 = "UltraTaxM18",
    Lacerte = "Lacerte",
    Drake = "Drake"
}

export enum ReportedStep {
    None = 0,
    Assignments = 1,
    Report = 2
}
export enum SFSignatureStatus {
    "Processing" = 1,
    "OutForSignature" = 2,
    "ESigned" = 3,
    "Declined" = 4,
    "Canceled" = 5,
}

export const SignatureStatusOptionsList: SelectOptions[] = [
    { label: 'E-SIGNED', value: "ESigned" },
    { label: 'MANUALLY SIGNED', value: "ManuallySigned" },
    { label: 'UPLOADED', value: "Uploaded" },
    { label: 'AWAITING E-SIGN', value: "AwaitingESign" },
    { label: 'AWAITING UPLOAD', value: "AwaitingUpload" },
    { label: 'SIGNED AND E-SIGNED', value: "SignedAndESigned" },
    { label: 'DELIVERED TO TAXCADDY', value: "DeliveredToTaxCaddy" },
    { label: 'TAXCADDY DELIVERY FAILED', value: "TaxCaddyDeliveryFailed" },
    { label: 'ALTERNATE DELIVERY', value: "AlternateDelivery" },
    { label: 'ALTERNATE AND UPLOADED', value: "AlternateAndUploaded" },
    { label: 'PARTIALLY SIGNED', value: "PartiallySigned" },
    { label: 'E-SIGNED – IN OFFICE', value: "ESignedInOffice" },
];

export const SFSignatureStatusOptionsList = [
    { label: 'Processing', value: "Processing" },
    { label: 'Out For Signature', value: "OutForSignature" },
    { label: 'E-Signed', value: "ESigned" },
    { label: 'Declined', value: "Declined" },
    { label: 'Canceled', value: "Cancelled" },
    { label: 'E-Signed – In Office', value: "ESignedInOffice" },
];

export interface SelectOptions {
    value: any,
    label: string
}

export const DocumentStatusType: SelectOptions[] =
    [
        { label: 'UPLOADED', value: "UPLOADED" },
        { label: 'ERROR', value: "ERROR" },
        { label: 'READY', value: "READY" },
        { label: 'PROCESSING', value: "PROCESSING" },
        { label: 'REVIEW', value: "REVIEW" },
        { label: 'APPROVED FOR DELIVERY', value: "APPROVEDFORDELIVERY" },
        { label: 'PREPARING FOR DELIVERY', value: "PREPARINGFORDELIVERY" },
        { label: 'DELIVERED', value: "DELIVERED" },
        { label: 'DELIVERY FAILED', value: "DELIVERYFAILED" },
        { label: 'USER SIGNED', value: "USERSIGNED" },
        { label: 'NON SUPPORTED', value: "NONSUPPORTED" },
        { label: 'RECALLED', value: "RECALLED" },
        { label: 'REPROCESSING', value: "REPROCESSING" },
        { label: 'TAXCADDY DELIVERY FAILED', value: "TAXCADDYDELIVERYFAILED" },
        { label: 'DUPLICATE', value: "DUPLICATE" },
        { label: 'DOWNLOADING FAILED', value: "DOWNLOADINGFAILED" },
        { label: 'PREPARING FOR PDF DOWNLOAD', value: "PREPARINGFORPDFDOWNLOAD" },
        { label: 'TO BE REPROCESSED', value: "TOBEREPROCESSED" },
        { label: 'DELETED', value: "DELETED" },
        { label: 'READYFORDELIVERY', value: "READYFORDELIVERY" },
        { label: 'DUPLICATE', value: "DUPLICATE" },
    ];

