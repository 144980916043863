let moment = require('moment');
import * as React from 'react';
import { BootstrapTable, CustomFilter, CustomSelectProps, SelectFilter, TableHeaderColumn, TextFilter } from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import * as RecycleReturnsState from '../../../store/reports/RecycleReturns/RecycleReturnsState';
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { CustomMultiSelect } from '../../common/MultipleSelectComponent';
import { DaysRangeOptionsList, SignatureStatus, getFormattedClientNameRecycle, IOfficeLocation } from '../../common/TaxReturn';
import { DeliveredReturnsTableConstants } from '../../helper/Constants';
import * as Helper from '../../helper/HelperFunctions';
import { CustomDateFilter } from '../DeliveredReturns/CustomDateFilter';
import { EngagementList, RenderTypeFilterOptions, SignatureStatusList, SignatureStatusOptionsList, TypeDropdownValues } from '../../../Core/Common/Common';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import * as ReactDom from 'react-dom';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SingleSelectSearchableDropdown from '../../../components/common/SingleSelectSearchableDropdown';
import { IGroupInfo } from '../../../store/groupedreturns/GroupedReturnProcessState';
import { CustomEngagementTypeFilter } from '../../../components/common/CustomEngagementTypeFilter';
const isEqual = require("react-fast-compare");

interface TableData {
    recycleReturns: RecycleReturnsState.RecycleReturnsState,   
    onPageChange: any,
    onSortChange: any,
    onFilterChange: any,
    pageNo: number,   
    loadGrid(): void;
    totalRows: number;
    isLoading: boolean,
    pageSize: number,
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    showBulkSelectionMessage: boolean;
    onBulkSelectionChange: (isEnabled: boolean) => void;
    groupInfo: IGroupInfo[];
}
export interface IRecylebinState{
    officeLocationList: any[];
}

export class RecycleReturnsTable extends React.Component<TableData, IRecylebinState> {

    private filterChanged: boolean = false;
    private customStatusMultiSelect: any;
    private customMultiSelect: any;
    private customTaxYearMultiSelect: any;
    private customOfficeSelect: any;
    private customEngagementTypeFilter: any;
    private taxYearList: any[] = [];
    public isAppiedFilter: boolean = false;
    constructor(props: TableData) {
        super(props);
        this.state = {
            officeLocationList: []
        };
        this.onFilterChange = this.onFilterChange.bind(this);
        this.CustomDateFilter = this.CustomDateFilter.bind(this);
        this.defaultType = this.defaultType.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.taxYearList = Helper.PrepareTaxYear();
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
    }

    shouldComponentUpdate(nextProps: TableData, nextState: {}) {
        return (
            !isEqual(this.props.recycleReturns.recycleReturnTableModel.documents, nextProps.recycleReturns.recycleReturnTableModel.documents)
            || (this.props.isLoading !== nextProps.isLoading)
            || isEqual(this.props.selectedRows, nextProps.selectedRows)
            || !isEqual(this.props.showBulkSelectionMessage, nextProps.showBulkSelectionMessage)
        );
    }

    renderShowsTotal(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    private setNoContent() {
        if (this.props.isLoading) {
            (this.refs.table as BootstrapTable) && (this.refs.table as BootstrapTable).cleanSelected();
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return DeliveredReturnsTableConstants.OtherMessage.NoReturnsFound
        }
    }

    private onFilterChange(dataField: any) {
        if (!this.filterChanged) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    private getStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customStatusMultiSelect = ref)} filterHandler={filterHandler} options={options} placeholder={placeholder}/>
        );
    }

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options} enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    private getOfficeMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customOfficeSelect = ref)} 
                filterHandler={filterHandler} 
                options={options} 
                placeholder={placeholder} 
                handleTextOverflow ={true}
            />
        );
    }

    private CustomDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter onRef={(ref: any) => (this.customMultiSelect = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} calendarContainer={ReactDom.findDOMNode(this.refs.table)} />);
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"recycle-returns-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }
    
    customTypeTooltip = (cell: any, row: any) => {
        return `${row.taxReturnType}`;
    }

    customStatusTooltip = (cell: any, row: any) => {
        const status: "None" | "ESigned" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload" | "Locked" | "MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering" | "Delivered" | "DeliveryFailed" | "UploadInProgress" | "ESignedInOffice" = (typeof row.signatureStatusIdType === "number") ? SignatureStatus[row.signatureStatusIdType] : row.signatureStatusIdType;
        return `${SignatureStatusList[status]}`;
    }

    customDownloadedTooltip = (cell: any, row: any) => {
        return `${(cell > 0 && (row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESigned] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.ESignedInOffice] ||row.signatureStatusIdType === SignatureStatus[SignatureStatus.Uploaded] || row.signatureStatusIdType === SignatureStatus[SignatureStatus.SignedAndESigned])) ? 'Downloaded' : 'To Be Downloaded'}`;
    }

    private showBulkAlertMessage() {
        let documentsInPage = this.props.recycleReturns.recycleReturnTableModel.documents.length;
        if (this.props.totalRows > 20 && this.props.showBulkSelectionMessage)
            return (<div className="col-sm-6 col-lg-6  font14" style={{ display: "inline-block", zIndex: 10, padding: '0px 0px', marginBottom:'-10px' }} >
                <Alert variant="warning" style={{ padding: "6px", width: "fit-content" }}>
                    <span
                        className="fa fa-exclamation-triangle"
                        style={{ marginRight: '5px', paddingLeft: "5px" }}>
                    </span> All {documentsInPage} records on this page are selected. To select the remaining {this.props.totalRows - documentsInPage} filtered records,
                    <Link to={"#"} onClick={() => this.props.onBulkSelectionChange(true)}> click here</Link>
                </Alert>
            </div>);
    }
    
    private signatureStatusIdType = (cell: any, row: any) => {

        let status: any = row.signatureStatusIdType;
        let signatureType: "None" | "ESigned" | "ESignedInOffice" | "ManuallySigned" | "Uploaded" | "AwaitingESign" | "AwaitingUpload" | "Locked" |
            "MailOrFax" | "SignedAndESigned" | "AutoParsed" | "Processing" | "Delivering" | "Delivered" |
            "DeliveryFailed" | "UploadInProgress" = (typeof status == "number") ? SignatureStatus[status] : status;

        return <span>{SignatureStatusList[signatureType].toUpperCase()}</span>;
    }  

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        var list;
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
        }
        else {
            list = [];
        }  
        var blank = { label: "Blanks", value: -100 };
		list.push(blank);
        this.setState({ officeLocationList: list });
    }

    private getGroupNameDropdown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <SingleSelectSearchableDropdown
                id="groupNameFilter"
                onRef={(ref: any) => {}}
                filterHandler={filterHandler}
                options={options}
                customPlaceHolder={placeholder}
                className={"groupNameFilter"}
            />
        );
    };

    private getCustomEngagementTypeFilter = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomEngagementTypeFilter
                onRef={(ref: any) => (this.customEngagementTypeFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
                selectedOptions={`${TypeDropdownValues.All}`}
            />
        );
    };

    private setGroupNameList = (groupNameList: IGroupInfo[]) => {
        const list = groupNameList.map((x: IGroupInfo) => ({ label: x.name, value: x.id }));
        const options = [{ label: "Standard Returns", value: -2 }, { label: "Grouped Returns", value: -3 }, ...list];
        return options;
    };

    public render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };

        const pagination = true;
        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected',
        };
        
        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: "Group Name",
                key: "groupName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: "CustomFilter",
                    getElement: this.getGroupNameDropdown,
                    customFilterParameters: {
                        options: this.setGroupNameList(this.props.groupInfo),
                        enableAllFilter: false,
                        placeholder: "Group Name"
                    }
                }
            },
            {
                header: 'Name',
                key: 'clientName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Name', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Type',
                key: 'taxReturnType',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: this.customTypeTooltip,
                isHidden: false,
                width: '80px',
                filter: {
                    type: "CustomFilter",
                    getElement: this.getCustomEngagementTypeFilter,
                    customFilterParameters: {
                        options: RenderTypeFilterOptions,
                        enableAllFilter: false,
                        placeholder: "Select Type..."
                    }
                }
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '90px',
                filter: {
                    type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown,
                    customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Year...' } as any
                } as CustomFilter
            },
            {
                header: 'ERO / Signer',
                key: 'partner',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: '130px',
                filter: {
                    type: 'CustomFilter', getElement: this.getOfficeMultiSelectDropDown,
                    customFilterParameters: { options: this.state.officeLocationList, enableAllFilter: false, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Sent By',
                key: 'sentBy',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Delivered Date',
                key: 'deliveredOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Delivered Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Status',
                key: 'signatureStatus', // String-based value accessors!
                isKey: false,
                dataFormat: this.signatureStatusIdType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                Placeholder: 'Status',
                filter: {
                    type: 'CustomFilter', getElement: this.getStatusMultiSelectDropDown,
                    customFilterParameters: { options: SignatureStatusOptionsList, placeholder: 'Select Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Deleted By',
                key: 'deletedBy',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Deleted By', style: { font: 'bold' }, delay: 2500 } as TextFilter
            },
            {
                header: 'Deletion Date',
                key: 'deletedOn',
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: '',
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.CustomDateFilter

                } as CustomFilter
            },
        ];


        let data: any = [];

        if (this.props.recycleReturns.recycleReturnTableModel.documents) {
            data = this.props.recycleReturns.recycleReturnTableModel.documents.map((model, index) => {
                return {
                    clientName: getFormattedClientNameRecycle(model.clientName, EngagementList[model.taxReturnType.toString()]),
                    clientId: model.clientId,
                    taxReturnType: EngagementList[model.taxReturnType],
                    partner: model.eroSignerFirstName ? model.eroSignerFirstName + " " + model.eroSignerLastName : "",
                    sentBy: model.deliveredByFirstName ? model.deliveredByFirstName + " " + model.deliveredByLastName : "",
                    deliveredOn: moment(model.deliveredOn).format('MM/DD/YYYY'),
                    signatureStatus: SignatureStatusList[model.signatureStatus],
                    deletedBy: model.deletedByFirstName ? model.deletedByFirstName + " " + model.deletedByLastName : "",
                    deletedOn: moment(model.deletedOn).format('MM/DD/YYYY'),
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    documentId: model.documentId,
                    taxYear: model.taxYear,
                    rowIndex: index,
                    id: model.documentId,
                    locationName: model.locationName,
                    groupName: model.groupName,
                }
            });
        }



        return <div className="pos-relative">
            {this.showBulkAlertMessage()}
            <div className="recycleReturns-table" data-test-auto="B510184C-5041-464B-9904-107F0B3C6F60">
                <BootstrapTable
                    ref='table'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    trStyle={{ height: '27px' }}
                    selectRow={selectRowProp}>
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            columnClassName={value.columnClassName}
                            width={value.width}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}>{
                                (value.width === 'auto') ?
                                    <span title={value.header} className="table-text-sort">{value.header}</span> :
                                    value.header
                            }
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}

export default RecycleReturnsTable
