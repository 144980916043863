import * as React from "react";
import { IUnlockReturnModel } from "@store/reports/DeliveredReturnsState";
import { Modal, Button, Form, Col } from "react-bootstrap";

interface IProps {
    clientTypes: string;
    unlockReturnModelList: IUnlockReturnModel[];
    onCancel(): void;
    show: boolean;
    documentId: number;
    onUnlockReturn(documentId: number, clientType: string): void;
    onUnlockOneHub(emailAddress: string): void;
}

export class UnlockReturnModal extends React.Component<IProps, {}> {
    public render() {
        return (
            <div>
                <Modal className="delivered-resend-access-link" show={this.props.show} onHide={this.props.onCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title className="custom-modal-header">
                            <span
                                className="text-secondary fas fa-unlock ddl-icon"
                                style={{ color: "grey", marginRight: "5px" }}
                            ></span>
                            Unlock Return
                        </Modal.Title>
                    </Modal.Header>
                    <Form>
                        <Modal.Body>
                            <div style={{ padding: "0 5rem" }}>
                                {this.props.unlockReturnModelList.map((value: IUnlockReturnModel) => {
                                    return (
                                        <>
                                            <div className="row">
                                                <Col className="padT07" sm={6}>
                                                    <div>
                                                        <b>
                                                            {value.isOneHubClient
                                                                ? `${value.clientType} Client Portal`
                                                                : value.clientType}
                                                        </b>
                                                    </div>
                                                </Col>
                                                <Col className="marB10" sm={6}>
                                                    <Button
                                                        style={{ width: "150px" }}
                                                        variant="info"
                                                        data-test-auto="2baca3a7-8863-4ae7-a088-f3fc100179a7"
                                                        onClick={() => {
                                                            value.isOneHubClient
                                                                ? this.props.onUnlockOneHub(value.emailAddress)
                                                                : this.props.onUnlockReturn(
                                                                      this.props.documentId,
                                                                      value.clientType
                                                                  );
                                                        }}
                                                    >
                                                        <i className="fas fa-lock-open"></i>
                                                        {value.isOneHubClient ? "Unlock Client Portal" : "Unlock Return"}
                                                    </Button>
                                                </Col>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </Modal.Body>
                    </Form>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            className="btn-white"
                            data-test-auto="d6d0084b-9fbb-4c33-aba2-6d9aa5d0676e"
                            onClick={this.props.onCancel}
                        >
                            <i className="fas fa-times" />
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default UnlockReturnModal;
