import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Modal, Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IEmailMessageDictionary } from '../../../../store/settings/EmailMessageStore';
import { IEmailTemplateMessage, initialEmailMessage } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import * as Constants from '../../../helper/Constants';
import { OverlayLoader } from '../../../helper/OverlayLoader';
import { VariableList } from '../../ClientInstructions/VariableList';
import { getTextLengthInByte,ReplaceHelperVariableForEditor } from '../../../helper/HelperFunctions';
import  TinyMCEComponent  from '../../SavedMessage/TinyMCE';
import { validateName } from '../../../../components/helper/Validations';
var htmlencode = require('htmlencode');

interface IAddClientInstructionProps {
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => void;
    buttonText: string;
    selectedMessageDetail: IEmailTemplateMessage;
    title: string;
    defaultMessageId: number;
    showLoader: boolean;
    loaderText: string;
    isUpdate: boolean;
    emailMessages: IEmailMessageDictionary;
    isGroupReturn?: boolean;
}
interface IAddMessageEmailInstructionState {
    emailMessage: IEmailTemplateMessage;
    alert: string;
    message: string;
    isSetDefault: boolean;
    isToggled: boolean;
    showAlert: boolean;
}

export class EmailMessagePopUp extends React.Component<IAddClientInstructionProps, IAddMessageEmailInstructionState>{
    state: IAddMessageEmailInstructionState = {
        emailMessage: initialEmailMessage,
        alert: "warning",
        message: "Email Message",
        isSetDefault: false,
        isToggled: false,
        showAlert: true
    }

    UNSAFE_componentWillReceiveProps(nextProps: IAddClientInstructionProps) {
        this.setState({
            emailMessage: Object.assign({}, nextProps.selectedMessageDetail),
            alert: "warning",
            message: "Email Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            emailMessage: initialEmailMessage,
            alert: "warning",
            message: "Email Message",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    private onAddButtonClick = () => {
        if (validateName(this.state.emailMessage.name)) {
            this.setState({ alert: "danger", message: Constants.CommonMessages.InvalidCharacters, showAlert: false })
            return false;
        }

        if (this.state.emailMessage.name.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.emailMessage.subject.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateSubject, showAlert: false })
            return false;
        }
        else if (this.state.emailMessage.text.trim() == "") {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.ValidateText, showAlert: false })
            return false;
        }
        if (this.checkDuplicateExistance(this.state.emailMessage) > 0) {
            this.setState({ alert: "danger", message: Constants.SavedMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }
        var byteLength = getTextLengthInByte(this.state.emailMessage.text.trim());
        if (byteLength > Constants.ClientInstructionConstants.MaximumTextSize) {
            this.setState({ alert: "danger", message: Constants.ClientInstructionConstants.TextSizeExceedsLimit, showAlert: false })
            return false;
        }
        this.props.submitButtonClick(this.state.emailMessage, this.state.isSetDefault, this.state.isToggled)
        this.setState({ emailMessage: initialEmailMessage })
    }

    private checkDuplicateExistance = (messageDetail: IEmailTemplateMessage) => {
        let count: number = 0;
        Object.keys(this.props.emailMessages).map((key, index) => {
            if (this.props.emailMessages[parseInt(key)].emailMessage.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() && this.props.emailMessages[parseInt(key)].emailMessage.id != messageDetail.id)
                count++;
        })
        return count;
    }

    private onNameChange = (event: any) => {
        let message = Object.assign({}, this.state.emailMessage);
        message.name = event.target.value;
        this.setState({ emailMessage: message })
    }

    private onTextChange = (value: any) => {
        let message = Object.assign({}, this.state.emailMessage);
        message.text = value;
        this.setState({ emailMessage: message });
    }

    private onSubjectChange = (event: any) => {
        let message = Object.assign({}, this.state.emailMessage);
        message.subject = event.target.value;
        this.setState({ emailMessage: message })
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: Constants.ClientInstructionConstants.SetDefaultMessageWarning, showAlert: false })
        }
        else {
            this.setState({ alert: "warning", message: Constants.ClientInstructionConstants.RemoveDefaultMessageWarning, showAlert: false })
        }
        this.setState({
            isSetDefault: isTrue,
            isToggled: true
        })
    }

    public render() {
        let toggleState: boolean = this.props.defaultMessageId == this.props.selectedMessageDetail?.id && this.props.isUpdate == true && this.state.isToggled == false ?
            true : this.state.isSetDefault == true ? true : false
        return (
            <div style={{ position: 'relative' }}>
                <Modal className='add-edit-message' show={this.props.showState} onHide={this.onCancelButtonClick} enforceFocus={false}>
                    <OverlayLoader show={this.props.showLoader} text={this.props.loaderText} />
                    <Modal.Header closeButton data-test-auto="9D9B8E11-AF73-4BC7-89B1-BEACFB0AF3FE">
                        <Modal.Title className='custom-modal-header'>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert variant={this.state.alert} hidden={this.state.showAlert}><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.message}</Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <div className="float-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={toggleState}
                                        disabled={this.props.defaultMessageId == this.props.selectedMessageDetail?.id && this.props.isUpdate == true ? true : false}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="001E901D-DF89-47DE-AD59-3E92A89ADE89"
                                className="form-control"
                                placeholder="Instruction name"
                                maxLength={200}
                                value={this.state.emailMessage.name}
                                onChange={this.onNameChange}
                                data-lpignore="true">
                            </input>
                        </div>
                        <div className="form-group">
                            <label>Subject</label>
                            <input
                                data-test-auto="95894E38-D3FC-47FE-9591-474A3BF62AB3"
                                className="form-control"
                                placeholder="Email subject"
                                maxLength={200}
                                value={this.state.emailMessage.subject}
                                onChange={this.onSubjectChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label>Text</label>
                            <TinyMCEComponent
                                messageBody={ReplaceHelperVariableForEditor(htmlencode.htmlDecode(this.state.emailMessage.text))}
                                changeStateTinymceBody={this.onTextChange}
                                readOnly={0}
                                canAddLink={1}
                                placeholderText={Constants.CommonMessages.PlaceholderText}
                            />
                        </div>
                        <VariableList isGroupReturn={this.props.isGroupReturn} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-test-auto="4C7EE9C3-D4F1-497D-B1C6-D8B655B17E6E"
                            variant='default'
                            onClick={this.onCancelButtonClick}>
                            <i className='fas fa-times'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onAddButtonClick}
                            data-test-auto="7FF185BF-E5BC-4612-BEBC-6E8827B60AFA">
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
