import { ITaxClient, ITaxpayer, ITaxClientAdditionalInfo, initiaITaxpayerState } from "./TaxClient";
import { IUserBaseModel, IUserModel, initialUserModel } from "../../Core/ViewModels/User/UserViewModel";
import {
    IPaymentVoucherNotificationSettings,
    ISigningReminderNotificationSettings,
    IK1InfoSettings,
    TaxSoftwareType,
    DueDateType,
    IK1Settings,
    ReportingEngineType
} from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { Guid } from "guid-typescript";
import { MessageDetails } from "../../Core/ViewModels/CompanySettings/SavedMessageViewModel";
import { K1MailCycles } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { splitName } from "../helper/HelperFunctions";
import { DefaultSenderInfo, DefaultSenderInfoType } from "../navigation/profile/MySettings";
import { IColumnValues } from "../settings/GeneralSettings";
import { IPdfDocumentFacade } from "../../Core/Utilities/PdfDocumentFacade";
import { IDocumentData } from "../../components/common/ProcessReturnModal/AdditionalEsignDocuments/AdditionalEsignDocument";
import { IPaperFileData } from "./ProcessReturnModal/PaperFile/PaperFile";
import { IK1Attachment } from "./ProcessReturnModal/K1Attachments/IK1Attachment";

export enum StateUSA {
    AL = "Alabama",
    AK = "Alaska",
    AZ = "Arizona",
    AR = "Arkansas",
    CA = "California",
    CO = "Colorado",
    CT = "Connecticut",
    DC = "District of Columbia",
    DE = "Delaware",
    FL = "Florida",
    Fed = "Federal",
    GA = "Georgia",
    HI = "Hawaii",
    ID = "Idaho",
    IL = "Illinois",
    IN = "Indiana",
    IA = "Iowa",
    KS = "Kansas",
    KY = "Kentucky[E]",
    LA = "Louisiana",
    ME = "Maine",
    MD = "Maryland",
    MA = "Massachusetts[E]",
    MI = "Michigan",
    MN = "Minnesota",
    MS = "Mississippi",
    MO = "Missouri",
    MT = "Montana",
    NE = "Nebraska",
    NV = "Nevada",
    NH = "New Hampshire",
    NJ = "New Jersey",
    NM = "New Mexico",
    NY = "New York",
    NC = "North Carolina",
    ND = "North Dakota",
    OH = "Ohio",
    OK = "Oklahoma",
    OR = "Oregon",
    PA = "Pennsylvania[E]",
    PR = "Puerto Rico",
    RI = "Rhode Island[F]",
    SC = "South Carolina",
    SD = "South Dakota",
    TN = "Tennessee",
    TX = "Texas",
    UT = "Utah",
    VT = "Vermont",
    VA = "Virginia[E]",
    VI = "Virgin Islands",
    WA = "Washington",
    WV = "West Virginia",
    WI = "Wisconsin",
    WY = "Wyoming"
}

export interface ITaxReturn {
    formGroups: IGroup[];
    documentSettings: IDocumentSettings;
    id: number;
    clientId: string;
    documentStatus: DocumentStatus;
    uploadedOn: Date;
    partnerId: number;
    partner: IUserModel;
    assignToUserName: IUserModel;
    deliveredByUser: IUserModel;
    signatureStatus: SignatureStatus;
    createdBy: number;
    engagementType: EngagementType;
    arrangement: Arrangement;
    taxYear: number;
    assignTo?: number;
    assignedUser: IUserBaseModel;
    lockedBy: number;
    invoiceAmount: number;
    deliveredOn: Date;
    attachments: IAttachment[];
    documentGuid: string;
    isModified: boolean;
    isfullyLoaded: boolean;
    refundInfo: IRefundInfo[];
    documentAccess: IDocumentAccess;
    clientTracking: IDocumentTransaction[];
    signedDetails: ISignerModel[];
    accessCode: IAccessCodeViewModel;
    downloadableEfileForms: IDownloadableDocuments[];
    downloadableDocuments: IDownloadableDocuments[];
    downloadHistory: IDocumentTransaction[];
    taxCaddyLookupResultModel: TaxCaddyLookupResultModel;
    taxSoftware: TaxSoftwareType;
    deliveredBy?: number;
    additionalEsignDocuments: IDocumentData[];
    paperFiles: IPaperFileData[];
    //getClientInfo: () => IGroup;
    isSelectedForDelivery?: boolean;
    k1Attachments: IK1Attachment[];
    recycleBinStatus: RecycleBinStatus;
    location: IOfficeLocation;
    locationId?: number;
    internalTracking: IUserTransaction[];
    groupId: number;
    groupName: string;
    taxpayerEmail: string;
    spouseEmail: string;
    eFileStatus: CCHEFileStatus;
    isCCHImported: boolean;
    storageAccountId: number;
}

//ITaxReturn.prototype.getClientInfo = function (): IGroup {
export function getClientInfo(model: ITaxReturn) {
    let ret: IGroup = {
        group: DocumentGroups.ClientInfo,
        forms: [] as IFormBase[]
    } as IGroup;

    //Get Client Pages
    model.formGroups[DocumentGroups.TaxReturns].forms.map((f, i) => {
        if (f.formName === "ClientInfo") {
            ret.forms.push(f);
        }
    });
    //Get Payments and refunds
    model.formGroups[DocumentGroups.Vouchers].forms.map((f, i) => {
        let form: IVoucher = f as IVoucher;
        if (form.paymentType && form.paymentType === VoucherTypes.PaymentVoucher) {
            ret.forms.push(form);
        } else {
            let form2: IRefund = f as IRefund;
            if (form2.overPayment) {
                ret.forms.push(form2);
            }
        }
    });

    return ret;
}

export interface IIndividualTaxReturn extends ITaxReturn {
    taxpayer: ITaxpayer;
}

export interface IMarriedJointTaxReturn extends IIndividualTaxReturn {
    spouse: ITaxpayer;
}

export interface ICorporateTaxReturn extends ITaxReturn {
    partnership: ITaxClient;
}
export interface IClientInfoViewModel {
    clientBasicInfoId: number;
    clientId: string;
    name: string;
    emailAddress: string;
    countryCode: string;
    mobileNumber: string;
    type: CMClientType;
    uid: string;
    ero: string;
    dob?: Date | null;
    isDeceased: boolean;
    defaultFirstSigner: boolean;
    spouseName: string;
    spouseSsn: string;
    spouseEmailAddress: string;
    spouseDob?: Date | null;
    spouseCountryCode: string;
    spouseMobileNumber: string;
    spouseIsDeceased: boolean;
    mfj: boolean;
    totalCount: number;
    eroFirstName: string;
    eroLastName: string;
    locationId?: number;
    locationName: string;
}

export interface IPrefilledClientInfo {
    [key: string]: boolean;
}
export interface IGroup {
    group: DocumentGroups;
    forms: IFormBase[];
}

export interface IK1 extends IGroup {
    shareHolder: IShareHolder;
}

export interface IShareHolder {
    name: string;
    ssn: string;
    id: number;
    emailAddress: string;
    k1SendDate: Date;
    consentStatus: ConsentStatus;
    k1DownloadDate: Date;
}

export enum DocumentGroups {
    None,
    Transmittals,
    EFile,
    Vouchers,
    TaxReturns,
    Invoice,
    Deleted,
    K1,
    ClientInfo,
    AdditionalEsignDocument,
    PaperFile
}

export enum DocumentGroupVerticalTab {
    Transmittals = 1,
    EFile = 2,
    Vouchers = 3,
    TaxReturns = 4,
    Invoice = 5,
    Deleted = 6,
    K1 = 7,
    // ClientInfo= 8, // no need to display
    AdditionalEsignDocument = 9,
}

export enum TabOrder {
    None,
    Transmittals,
    TaxReturns,
    EFile,
    Vouchers,
    K1,
    Invoice,
    Deleted
}

export interface IGroup2 {
    groupId: number;
    groupName: string;
    pages: IFormBase[];
}

export interface IFormBase {
    formName: string;
    bookmark: string;
    pageNo: number[];
    formType: TaxFormType;
}

export interface IVoucherFormBase extends IFormBase {
    voucherNo: number;
}

export interface IVoucher extends IFormBase {
    authorityID: number;
    dueDate: Date | undefined | string;
    amount: number;
    voucherNo: VoucherNo;
    paymentType: VoucherTypes;
    customType: CustomType;
    onlinePaymentUri: string;
    customText: string;
    authorityImage: string;
    authorityName: string;
    groupId?: number;
    isMasterPaymentUrl: boolean;
    vocherMode: VoucherMode;
    isFormNameUpdated: boolean;
    voucherDueDateType?: DueDateType | undefined;
    printedDueDate?: Date | undefined;
    voucherStatus?: VoucherStatus;
    isPaymentCompleted?: boolean;
    isPaid?: boolean;
}

export enum VoucherStatus {
    None = 0,
    Existing = 1,
    ExistingUpdated = 2,
    New = 3
}

export interface IK1ShareHolder extends IFormBase {
    shareHolderId: number;
    ssn: string;
}

export interface SelectOptions {
    value: string;
    label: string;
}
export enum CustomType {
    None = 0,
    Select,
    DoNotPayScheduledAutomaticWithdrawal,
    OnlinePaymentRequired,
    AddCustom
}

export enum VoucherProperty {
    authorityID,
    dueDate,
    amount,
    voucherNo,
    voucherTypes,
    customType,
    onlinePaymentUri,
    customText,
    formName,
    bookMark,
    pageNo,
    isMasterPaymentUrl,
    isFormNameUpdated
}

export enum IEFileProperty {
    authorityID,
    formName
}

export enum K1Property {
    shareHolderID,
    formName
}

export enum ConsentStatus {
    None,
    Declined,
    Agreed
}

export enum EntityType {
    None,
    Individual,
    Entity
}
export interface IOfficeLocation {
    locationId?: number;
    locationName?: string;
}

export interface IAttachment {
    fileExtension?: string;
    progressBar?: number;
    fileSize: string;
    status?: string;
    documentId: number;
    fileName: string;
    uploadedTime: Date;
    uploadedBy: number;
    instruction: string;
    id: number;
    uploadedUser: string;
    sasGuid?: string;
    url?: string;
    isDeleted: boolean;
    number?: number;
    selectedInstructionId: number;
}

//Document Settings start
export interface IDeliverySettings {
    deliverTo: ClientTypes;
    contactPerson: number;
    preparerMessage: MessageDetails;
    sender: DefaultSenderInfo;
    notifyUser: number;
    manualAddressId: number;
    isDirectDeliveryToTaxCaddy: boolean;
    deliveryMode: DeliveryMode;
    paperReturnSettings: IPaperReturnSettings;
    watermarkId: number;
    voucherPaymentNotifyUser: number;
    reportingEngine?: ReportingEngineType;
    paperFilePreparerMessage: MessageDetails;
}

export interface IWatermarkSetting {
    includedPages: number[];
    excludedPages: number[];
}

export interface IDocumentSettings {
    documentId: number;
    deliverySettings: IDeliverySettings;
    documentDisplaySetting: IDocumentDisplaySetting;
    documentSignatureSetting: IDocumentSignatureSetting;
    documentNotificationSetting: IDocumentNotificationSetting;
    documentRetentionSetting: IDocumentRetentionSetting;
    isModified: boolean;
    documentK1Setting: IDocumentK1Setting;
    watermarkSettings: IWatermarkSetting;
}

export interface IDocumentDisplaySetting {
    isSendWithoutInvoice: boolean;
    isEnableSendWithoutInvoice: boolean;
    isEnableInvoice: boolean;
    customColumn: IColumnValues;
    priorToReview: boolean;
}

export interface IDocumentSignatureSetting {
    signatureFormSelectionType: SignatureType;
    knowledgeBasedAuthentication: boolean;
    signatureStampUser: IUserModel;
    kbaMode: KBAMode;
    enableSignerDeligation: boolean;
    enableEROStampDeligation: boolean;
    enableDelegateeKbaAuthentication: boolean;
    isDoNotDisplayDatesEnabled: boolean;
    isDoNotDisplayNameAndTitleEnabled: boolean;
    allowDisablingKBAOnReturnLevel: boolean;
    isKBADisabledInReturnLevel: boolean;
}

export interface IPaperReturnSettings {
    deliveryType: PaperReturnDeliveryType;
    documentOrder: DocumentGroups[];
    isNotifyUserEnabled: boolean;
}

export interface IDocumentAccess {
    selectedUsers: IDocumentUserAccess[];
    availableUsers: IDocumentUserAccess[];
    selectedUserGroups: IDocumentUserGroupAccess[];
    availableUserGroups: IDocumentUserGroupAccess[];
}

export interface IDocumentAccessSaveModel {
    documents: number[];
    users: number[];
    userGroups: number[];
}

export interface ITaxDocumentAccessViewModel extends IDocumentAccessSaveModel {
    taxDocuments: ITaxDocumentViewModel[];
}

export interface ITaxDocumentViewModel {
    documentId: number;
    isGroupedReturn: boolean;
    groupId: number;
}

export interface IDocumentUserAccess {
    id: number;
    name: string;
}

export interface IDocumentUserGroupAccess {
    id: number;
    name: string;
    fontColor: string;
    borderColor: string;
    backgroundColor: string;
}

export interface IDownloadableDocuments {
    documentId: number;
    groupId: FormType;
    fileName: string;
    shareholderId: number;
    documentGuid: string;
    taxYear: number;
    createdOn: Date;
    signedDocumentId: number;
}

export enum ClientTypes {
    Undefied = "none",
    Taxpayer = "Taxpayer",
    Spouse = "Spouse",
    Partner = "Partner",
    PartnerShip = "PartnerShip",
    ShareHolder = "ShareHolder",
    CcRecipient = "CcRecipient",
    Delegatee = "Delegatee"
}

export enum ClientTypesNumber {
    Undefied = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    ShareHolder,
    CcRecipient,
    Delegatee,
    Controller,
    OneHub,
}

export enum CMClientType {
    None = 0,
    SSN = 1,
    EIN = 2
}

export enum ClientReturn {
    Signer = "Signer",
    TaxYear = "TaxYear",
    ClientId = "ClientId",
    OfficeLocation = "OfficeLocation"
}

export enum KBAMode {
    Test = 0,
    Live = 1
}

export enum SignatureType {
    None,
    ESign = 1,
    ESignWhenAllIncludedReturnsAllowed,
    ManualSign
}

export enum PaperReturnDeliveryType {
    None = 0,
    MultipleFiles = 1,
    SingleFile = 2
}

export enum DeliveryMode {
    ElectronicFiled = 0,
    PaperFiled = 1
}

export interface IDocumentNotificationSetting {
    paymentVoucherNotificationSettingsModel: IPaymentVoucherNotificationSettings;
    signingReminderNotificationSettingsModel: ISigningReminderNotificationSettings;
    notifyUser: IUserModel;
    voucherPaymentNotifyUser: IUserModel;
}

export interface IDocumentK1Setting {
    k1PartnerInfoSettings: IK1InfoSettings;
    k1ClientInfoSettings: IK1InfoSettings;
    isSSNMaskingEnabled: boolean;
}

export interface IDocumentRetentionSetting {
    retentionPeriod: number;
    allowOverride: boolean;
}

export interface ITaxDocumentRevision {
    documentId: number;
    status: TaxDocumentRevisionStatus;
    revisionDocumentGuid: string;
}

export enum TaxDocumentRevisionStatus {
    Uploaded = 0,
    Ready,
    Merged,
    Error,
    NonSupported,
    Discarded,
    DeliveryInProgress,
    Delivered
}
//Document Settings end

export interface ISignatureControl {
    controlGuid: string;
    type?: SignatureControlTypes;
    top: number;
    left: number;
    signatureControlRole?: SignatureControlRole;
    selectedSignStyle?: string;
    selectedEroImage?: string;
}

export interface IEfileDocumentControl extends ISignatureControl {
    required: boolean;
    tooltip: string;
    isCustomTextControl: boolean;
}

export interface IK1MaskControl extends ISignatureControl {
    width: number;
    height: number;
    maskValue: string;
}

export interface IEFile extends IFormBase {
    authorityID: number;
    signatureControls: IEfileDocumentControl[];
}

export interface IInvoice extends IFormBase {
    signatureControls: ISignatureControl[];
}

export interface ITransmittal extends IFormBase {
    signatureControls: ISignatureControl[];
    transmittalStatus: TransmittalStatus;
}

export enum TransmittalStatus {
    None = 0,
    Existing = 1,
    New = 2
}

export interface ITaxReturnGroup extends IFormBase {
    signatureControls: ISignatureControl[];
}

export interface ITaxClientInfo {
    taxReturnMetadata: ITaxReturnMetadata;
    payments: IPayments[];
}

export interface IPayments extends IFormBase {
    autorityID: number;
    autorityName: string;
    amount: number;
    paymentType: PaymentType;
    voucherDueDate: Date;
}

export enum PaymentType {
    DUE = 0,
    REFUND = 1
}

export enum VoucherTypes {
    None = 0,
    PaymentVoucher = 1,
    EstimatedVoucher
}

export enum VoucherMode {
    None = 0,
    Recognized = 1,
    CustomVoucher = 2,
    AutomatedVoucher = 3
}

export enum TaxFormType {
    FormBase = 0,
    Transmittal = 1,
    EFile,
    Voucher,
    TaxReturn,
    Invoice,
    Refund
}

export interface IRefund extends IFormBase {
    authorityID: number;
    overPayment: number;
    refundAmount?: number;
    penalty?:  number;
    overPaymentApplied: number;
    groupId?: number;
    isMultipart: boolean;
    signatureControls: ISignatureControl[];
}

export interface IRefundInfo {
    id: number;
    documentId: number;
    authorityID: number;
    overPayment: number;
    overPaymentApplied: number;
    penalty:  number;
    customRefundGUID?: Guid;
}

export interface ISignerModel {
    documentId: number;
    signer: string;
    isSigned: boolean;
    signedOn: Date;
    signerType: ClientTypesNumber;
    clientGuid: string;
    order: number;
    name: string;
    clientAccessLink: string;
    isKBAVerified?: boolean;
    kbaFailedCount: number;
}

export interface IAccessCode {
    name: string;
    clientId: string;
    recipientEmail: string;
    clientGuid: string;
    documentId: number;
    clientType: ClientTypes;
    createdOn: Date;
    otp: string;
    retryCount: number;
}

export interface IAccessCodeViewModel {
    accessCodeDetails: IAccessCode[];
    clientEvents: IDocumentTransaction[];
}

export enum DocumentStatus {
    None = "None",
    UPLOADED = "UPLOADED",
    ERROR = "ERROR",
    READY = "READY",
    PROCESSING = "PROCESSING",
    REVIEW = "REVIEW",
    USERSIGNED = "USERSIGNED",
    DELIVERED = "DELIVERED",
    APPROVEDFORDELIVERY = "APPROVEDFORDELIVERY",
    PREPARINGFORDELIVERY = "PREPARINGFORDELIVERY",
    DELIVERYFAILED = "DELIVERYFAILED",
    NONSUPPORTED = "NONSUPPORTED",
    RECALLED = "RECALLED",
    DELETED = "DELETED",
    APPROVEDFORDELIVERYDESCRIPTION = "APPROVED FOR DELIVERY",
    PREPARINGFORDELIVERYDESCRIPTION = "PREPARING FOR DELIVERY",
    DELIVERYFAILEDDESCRIPTION = "DELIVERY FAILED",
    NONSUPPORTEDDESCRIPTION = "NON SUPPORTED",
    REPROCESSING = "REPROCESSING",
    DELIVEREDTOTAXCADDY = "DELIVEREDTOTAXCADDY",
    TAXCADDYDELIVERYFAILED = "TAXCADDYDELIVERYFAILED",
    TAXCADDYDELIVERYFAILEDDESCRIPTION = "TAXCADDY DELIVERY FAILED",
    DUPLICATE = "DUPLICATE",
    DOWNLOADPDF = "DOWNLOADPDF",
    DOWNLOADPDFDESCRIPTION = "ALTERNATE DELIVERY",
    DOWNLOADINGFAILED = "DOWNLOADINGFAILED",
    PREPARINGFORPDFDOWNLOAD = "PREPARINGFORPDFDOWNLOAD",
    DOWNLOADINGFAILEDDESCRIPTION = "DOWNLOADING FAILED",
    PREPARINGFORPDFDOWNLOADDESCRIPTION = "PREPARING FOR PDF DOWNLOAD",
    TOBEREPROCESSED = "TOBEREPROCESSED",
    TOBEREPROCESSEDDESCRIPTION = "TO BE REPROCESSED",
    READYFORDELIVERY = "READYFORDELIVERY",
    READYFORDELIVERYDESCRIPTION = "READY FOR DELIVERY"
}

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    DeliveredToTaxCaddy,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed,
    UploadInProgress,
    TaxCaddyDeliveryFailed,
    AlternateDelivery,
    DownloadingFailed,
    AlternateAndUploaded,
    PartiallySigned,
    SignatureStampingFailed,
    ESignedInOffice
}

export enum EngagementType {
    None = 0,
    E1040,
    E1065,
    E1120,
    E1120S,
    E1041,
    E1040NR,
    E990,
    E990PF,
    E990EZ
}

export enum Arrangement {
    Federal = 0,
    StateOnly = 1,
    SingleMemberLLC = 2
}

export enum RecycleBinStatus {
    None = 0,
    DeletionInProgress = 1,
    DeletionCompleted = 2
}

export enum FormType {
    None = 0,
    Transmittals = 1,
    EFile,
    Vouchers,
    TaxReturns,
    Invoice,
    Deleted,
    K1
}

export enum SignatureControlRole {
    None = 0,
    Taxpayer,
    Spouse,
    ERO,
    PartnerShip
}

export enum SignatureControlTypes {
    None = 0,
    Signature = 1,
    Initial = 2,
    Date = 3,
    NameAndTitle = 4,
    Text = 10,
    Company = 11,
    PrintName = 12,
    Title = 13,
    CheckBox = 14,
    RadioButton = 15
}

export enum SignatureGroupType {
    None = 0,
    Esign,
    Stamp
}

export interface ITaxReturnMetadata {
    taxDocumentName: string;
    clientId: string;
    partner: IUserModel;
    documentStatus: DocumentStatus;
    uploadedOn: Date;
    engagementType: EngagementType;
    taxYear: number;
    assignTo: number;
}

export interface ITaxingAuthority {
    Id: number;
    AuthorityName: string;
    isEsignEnabled: boolean;
    Abbreviation?: string;
    PaymentURL: string;
    PrefixStateCode: boolean;
    Active: boolean;
    CustomAuthority: boolean;
    StateImage?: string;
    InUse: number;
}

export enum VoucherNo {
    None = 0,
    PaymentVoucher,
    Q1,
    Q2,
    Q3,
    Q4
}

export interface IDocumentTransaction {
    documentId: number;
    eventId: DocumentEvent;
    actedOn: Date;
    actedBy: IDocumentTransactionUser;
    eventData: IDocumentEventData;
}

export interface IUserTransaction {
    userId: number;
    documentId: number;
    eventId: UserEvent;
    actedOn: Date;
    actedBy: string;
}

export enum UserEvent {
    None = 0,
    ReturnUploaded,
    ReturnSaved,
    ReturnSentForReview,
    ReturnSentForERO,
    ReturnSentForApproval,
    ReturnDeliveredToCompany,
    ReturnDeliveredToTaxpayer,
    ReturnDeliveredToSpouse,
    DownloadPDF,
    EFileFormsDownloaded,
    RecalledReturn,
    ReprocessedReturn,
    ReturnMovedToArchived,
    DeletedReturn,
    ReturnDeliveredWithKBADisabled,
    ArchivedReturnRestored,
    DeletedReturnRestored,
    MarkReadyForDelivery,
    ReturnDeliveredToController
}

export interface IDocumentTransactionUser {
    firstName: string;
    lastName: string;
    emailAddress: string;
    mobileNumber?: string;
}

export interface IDocumentEventData {
    fileName: string;
    ipAddress: string;
    clientType: ClientTypesNumber;
    assignedValue: string;
    mailEventId: MailEvent;
    recipientInfo: IDocumentTransactionUser;
    token: string;
    dob: Date;
    remarks: string;
    customData: any[];
    actualClientType: ClientTypesNumber;
}

export interface IEROSigner {
    value: number;
    label: string;
    isEnableSignatureDelegation: boolean;
    eroImage: string;
    disabled?: boolean;
}

export enum MailEvent {
    None,
    Click = 1,
    Delivered = 2,
    Opened = 3,
    Processed = 4,
    Dropped = 5,
    Bounce = 6,
    Deferred = 7,
    SpamReport = 8,
    ScheduleFailed = 9,
    unDelivered = 10
}

export enum DocumentEvent {
    None,
    Created = 1,
    DocumentAssignTo = 2,
    DocumentEROStampDecline = 3,
    EROSignDecline = 4,
    DocumentDeliveryApproved = 5,
    Emailed = 6,
    DocumentChangeStatusManual = 7,
    TaxReturnAccessed = 8,
    DocumentAuthenticated = 9,
    AuthenticationFailed = 10,
    AccessLocked = 11,
    OTPAuthenticationFailed = 12,
    OTPAccessLocked = 13,
    TaxReturnReviewed = 14,
    Viewed = 15,
    EfileFormsDownloaded = 16,
    FileUploaded = 17,
    TaxpayerEsigned = 18,
    SpouseEsigned = 19,
    VouchersReviewed = 20,
    VoucherReminderDisabled = 21,
    VoucherReminderEnabled = 22,
    AttachmentDownloaded = 23,
    AllAttachmentDownloaded = 24,
    AllK1DocumentDownloaded = 25,
    DocumentAttachmentsReviewed = 26,
    PaymentSystemAccessed = 27,
    InvoiceReviewed = 28,
    Finished = 29,
    AccessLinkEmailed = 30,
    TransmittalReviewed = 31,
    DocumentTaxSaved = 32,
    K1DistributedTo = 33,
    DocumentDownloadAll = 34,
    DocumentFilingInstructionsDownloaded = 35,
    DocumentTaxReturnDownloaded = 36,
    DocumentVouchersDownloaded = 37,
    DocumentInvoiceDownloaded = 38,
    DocumentSignedEfileDownloaded = 39,
    KBAPassed = 40,
    KBAFailed = 41,
    DocumentKBASuccess = 42,
    DocumentKBAFailed = 43,
    DOBChanged = 44,
    DocumentOptOutESignOptionSelected = 45,
    DocumentSigned = 46,
    SignedDocumentReviewed = 47,
    VoucherReminderUpdated = 48,
    Deleted = 49,
    DocumentFileDeleted = 50,
    ShareholderConsentReceived = 51,
    ShareholderConsentDeclined = 52,
    K1DocumentDownloaded = 53,
    K1InstructionDocumentDownloaded = 54,
    DocumentMailStatus = 55,
    ReturnIsRecalled = 56,
    VoucherRemainderMailStatus = 57,
    DocumentRequestRecallReturn = 58,
    DocumentRequestDeleteReturn = 59,
    Shared = 60,
    Cancelled = 61,
    Filed = 62,
    DocumentRejected = 63,
    AccessCodeEmail = 64,
    PartnershipEsigned = 65,
    DocumentSignedEfileDownloadedByCPA = 66,
    ReminderSendForSigning = 67,
    DownloadLinkSend = 68,
    PaymentCompleted = 69,
    NotDefined = 75,
    UploadInProgress = 76,
    DeliverdToTaxcaddy = 77,
    TaxcaddyDeliveryFailed = 78,
    SigningReminderSend = 79,
    SigningReminderMailStatus = 80,
    K1ShareHolderReminderMailStatus = 81,
    K1TaxpayerReminderMailStatus = 82,
    SendForSignature = 83,
    ResendAccessLink = 87,
    DocumentsForwardedToCCRecipient = 94,
    RestoreReturn = 95,
    NewTaxReturnUploaded = 96,
    TaxReturnReprocessed = 97,
    PaymentDetailsAdded = 100,
    PaymentDetailsUpdated = 101,
    VoucherReminderDisabledByTP = 102,
    StatusChangeToSignedAndEsigned = 110,
    ControllerEsigned = 111,
    ControllerSignedAdditionalDocument = 112,
    InvoicePaymentDetailAdded = 113,
    InvoicePaymentDetailUpdated = 114,
    InvoicePaymentDetailDeleted = 115,
    PaperFileConsented = 116,
    PaperFileDownloaded = 117,
    PaperFileAllDownloaded = 118,
    PaperFilePrinted = 119,
    PaperFileAllPrinted = 120,
    RevisionDocumentUploaded = 121,
    InitialRevisionMail = 122,
    SigningFailed = 123,
    SpouseConsentAccepted = 129,
    SpouseConsentDeclined = 130,
    TaxpayerConsentAccepted = 131,
    TaxPayerConsentDeclined = 132,
    TaxPayerOptedOutSMSNotification = 133,
    TaxPayerOptedInSMSNotification = 134,
    SpouseOptedOutSMSNotification = 135,
    SpouseOptedInSMSNotification = 136,
    TaxPayerConsentExpired = 137,
    SpouseConsentExpired = 138,
    SigningReminderSMSStatus = 139,
    SigningCompletionSMSStatus = 140,
    TaxpayerESignedInOffice = 141,
    SpouseESignedInOffice = 142,
    PartnershipESignedInOffice = 143,
    ControllerESignedInOffice = 144,
}

export function getAllStateImages() {
    let stateImages: { imageName: string; stateUSA: StateUSA }[] = [
        { imageName: "state-federal", stateUSA: StateUSA.Fed },
        { imageName: "state-alaska", stateUSA: StateUSA.AK },
        { imageName: "state-alabama", stateUSA: StateUSA.AL },
        { imageName: "state-arkansas", stateUSA: StateUSA.AR },
        { imageName: "state-arizona", stateUSA: StateUSA.AZ },
        { imageName: "state-california", stateUSA: StateUSA.CA },
        { imageName: "state-colorado", stateUSA: StateUSA.CO },
        { imageName: "state-connecticut", stateUSA: StateUSA.CT },
        { imageName: "state-dc", stateUSA: StateUSA.DC },
        { imageName: "state-delaware", stateUSA: StateUSA.DE },
        { imageName: "state-florida", stateUSA: StateUSA.FL },
        { imageName: "state-georgia", stateUSA: StateUSA.GA },
        { imageName: "state-hawaii", stateUSA: StateUSA.HI },
        { imageName: "state-iowa", stateUSA: StateUSA.IA },
        { imageName: "state-idaho", stateUSA: StateUSA.ID },
        { imageName: "state-illinois", stateUSA: StateUSA.IL },
        { imageName: "state-indiana", stateUSA: StateUSA.IN },
        { imageName: "state-kansas", stateUSA: StateUSA.KS },
        { imageName: "state-kentucky", stateUSA: StateUSA.KY },
        { imageName: "state-kentucky", stateUSA: StateUSA.KY },
        { imageName: "state-louisiana", stateUSA: StateUSA.LA },
        { imageName: "state-massachusetts", stateUSA: StateUSA.MA },
        { imageName: "state-maryland", stateUSA: StateUSA.MD },
        { imageName: "state-maine", stateUSA: StateUSA.ME },
        { imageName: "state-michigan", stateUSA: StateUSA.MI },
        { imageName: "state-minnesota", stateUSA: StateUSA.MN },
        { imageName: "state-missouri", stateUSA: StateUSA.MO },
        { imageName: "state-mississippi", stateUSA: StateUSA.MS },
        { imageName: "state-montana", stateUSA: StateUSA.MT },
        { imageName: "state-north-carolina", stateUSA: StateUSA.NC },
        { imageName: "state-north-dakota", stateUSA: StateUSA.ND },
        { imageName: "state-nebraska", stateUSA: StateUSA.NE },
        { imageName: "state-new-hampshire", stateUSA: StateUSA.NH },
        { imageName: "state-new-jersey", stateUSA: StateUSA.NJ },
        { imageName: "state-new-mexico", stateUSA: StateUSA.NM },
        { imageName: "state-nevada", stateUSA: StateUSA.NV },
        { imageName: "state-new-york", stateUSA: StateUSA.NY },
        { imageName: "state-ohio", stateUSA: StateUSA.OH },
        { imageName: "state-oklahoma", stateUSA: StateUSA.OK },
        { imageName: "state-oregon", stateUSA: StateUSA.OR },
        { imageName: "state-pennsylvania", stateUSA: StateUSA.PA },
        { imageName: "state-puerto-rico", stateUSA: StateUSA.PR },
        { imageName: "state-rhode-island", stateUSA: StateUSA.RI },
        { imageName: "state-south-carolina", stateUSA: StateUSA.SC },
        { imageName: "state-south-dakota", stateUSA: StateUSA.SD },
        { imageName: "state-tennessee", stateUSA: StateUSA.TN },
        { imageName: "state-texas", stateUSA: StateUSA.TX },
        { imageName: "state-utah", stateUSA: StateUSA.UT },
        { imageName: "state-virginia", stateUSA: StateUSA.VA },
        { imageName: "state-virgin-islands", stateUSA: StateUSA.VI },
        { imageName: "state-vermont", stateUSA: StateUSA.VT },
        { imageName: "state-washington", stateUSA: StateUSA.WA },
        { imageName: "state-wisconsin", stateUSA: StateUSA.WI },
        { imageName: "state-west-virginia", stateUSA: StateUSA.WV },
        { imageName: "state-wyoming", stateUSA: StateUSA.WY }
    ];
    return stateImages;
}

export enum LinkStatus { //For QuickReports
    None = 0,
    NotAccessedByTaxpayer = 1,
    NotAccessedBySpouse = 2,
    NotAccessed = 3
}

export enum KBAStatus { //For QuickReports
    None = 0,
    TaxpayerFailed = 1,
    SpouseFailed = 2
}

export function isIndividual(arg: any): arg is IIndividualTaxReturn {
    return arg.taxpayer !== undefined && (arg.spouse === undefined || arg.spouse === null) && arg.partnership === undefined;
}

export function isMutual(arg: any): arg is IMarriedJointTaxReturn {
    return (
        arg.taxpayer !== undefined &&
        arg.spouse !== undefined &&
        //&& arg.spouse !== null
        arg.partnership === undefined
    );
}

export function isPartnership(arg: any): arg is ICorporateTaxReturn {
    return arg && arg.partnership !== undefined && arg.taxpayer === undefined && arg.spouse === undefined;
}

export function getClientName(taxReturn: any) {
    if (isPartnership(taxReturn)) {
        return taxReturn.partnership == null ? " " : taxReturn.partnership.name;
    }
    return taxReturn.taxpayer == null ? " " : splitName(taxReturn.taxpayer.name);
}

export function getFormattedClientName(name: string, engagementType: EngagementType) {
    if (engagementType != EngagementType.E1040) {
        return name == null ? " " : name;
    }
    return name == null ? " " : splitName(name);
}
export function getFormattedClientNameRecycle(name: string, taxretruntype: string) {
    if (taxretruntype != "1040") {
        return name == null ? " " : name;
    }
    return name == null ? " " : splitName(name);
}
export function engagementType(engagementType: EngagementType) {
    if (engagementType.toString() == EngagementType[EngagementType.None]) {
        return engagementType.toString();
    } else if (engagementType.toString() == EngagementType[EngagementType.E990]) {
        return "990/T";
    } else {
        return engagementType.toString().slice(1, 10);
    }
}

export function dynamicCast(arg: ITaxReturn) {
    if (isIndividual(arg)) {
        return arg as IIndividualTaxReturn;
    }
    if (isPartnership(arg)) {
        return arg as ICorporateTaxReturn;
    }
    if (isMutual(arg)) {
        return arg as IMarriedJointTaxReturn;
    }
    return arg;
}

export function isK1ShareHolder(arg: ITaxReturn) {
    const llc: any = Arrangement[Arrangement.SingleMemberLLC];
    const e1065: any = EngagementType[EngagementType.E1065];
    const e1120s: any = EngagementType[EngagementType.E1120S];
    const e1041: any = EngagementType[EngagementType.E1041];
    return arg.arrangement != llc && (arg.engagementType == e1065 || arg.engagementType == e1120s || arg.engagementType == e1041);
}

export function isInvoice(arg: Arrangement, isInvoiceEnabled: boolean): boolean {
    const llc: any = Arrangement[Arrangement.SingleMemberLLC];
    return arg != llc && isInvoiceEnabled;
}

export function isJointFilingAllowed(arg: ITaxReturn) {
    let e1040: any = EngagementType[EngagementType.E1040];
    return arg.engagementType == e1040;
}

export function getVoucherTypes(voucherNo: number): VoucherTypes {
    if (voucherNo >= VoucherTypes.EstimatedVoucher) {
        return VoucherTypes.EstimatedVoucher;
    } else if (voucherNo == VoucherTypes.PaymentVoucher) {
        return VoucherTypes.PaymentVoucher;
    } else {
        return VoucherTypes.None;
    }
}
export function getUnformattedClientName(taxReturn: any) {
    if (isPartnership(taxReturn)) {
        return taxReturn.partnership == null ? " " : taxReturn.partnership.name;
    }
    return taxReturn.taxpayer == null ? " " : taxReturn.taxpayer.name;
}

export function isInvoiceAllowed(arg: ITaxReturn) {
    const llc: any = Arrangement[Arrangement.SingleMemberLLC];
    return arg.arrangement != llc;
}

export interface TaxCaddyLookupResult {
    isTaxCaddyDelivery: boolean;
    isTaxCaddyLookupAccepted: boolean;
    taxpayerUniqueId: number;
    taxCaddyTransactionEventdata: TaxCaddyLookupResultObjectModel;
}

export interface TaxCaddyLookupResultModel {
    isSuccess: boolean;
    result: string;
    resultObjectModel: TaxCaddyLookupResultObjectModel;
}

export interface TaxCaddyLookupResultObjectModel {
    taxPayerUniqueId: number;
    taxpayerName: string;
    emailAddress: string;
    ssn: string;
    address: string;
    taxClientId: string;
    taxYear: number[];
}

export const initialTaxCaddyDeliveryViewModel: TaxCaddyLookupResult = {
    isTaxCaddyDelivery: false,
    isTaxCaddyLookupAccepted: false,
    taxpayerUniqueId: 0,
    taxCaddyTransactionEventdata: {
        address: "",
        emailAddress: "",
        ssn: "",
        taxClientId: "",
        taxpayerName: "",
        taxPayerUniqueId: 0,
        taxYear: []
    }
};

export const initailTaxpayerModel: ITaxpayer = {
    address: "",
    city: "",
    clientGuid: Guid.create().toString(),
    dob: new Date(),
    email: "",
    id: 0,
    isModified: false,
    name: "",
    ssn: "",
    state: "",
    zip: "",
    isDeceased: false,
    additionalInfo: {
        isAttested: false
    },
    countryCode: "+1",
    mobileNumber: ""
};

export const DaysRangeOptionsList = {
    One_Day: "24 Hours",
    Two_Days: "2 Days",
    Three_Days: "3 Days",
    Five_Days: "5 Days",
    Seven_Days: "1 Week",
    Fifteen_Days: "15 Days",
    Thirty_Days: "30 Days"
};

export const initialTaxReturnState: Readonly<ITaxReturn> = {
    formGroups: [],
    documentSettings: {
        documentId: 0,
        deliverySettings: {
            deliverTo: ClientTypes.Undefied,
            contactPerson: 0,
            preparerMessage: {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            },
            sender: {
                senderType: DefaultSenderInfoType?.None,
                senderId: 0
            },
            notifyUser: 0,
            manualAddressId: 0,
            isDirectDeliveryToTaxCaddy: false,
            deliveryMode: DeliveryMode.ElectronicFiled,
            paperReturnSettings: {
                documentOrder: [],
                deliveryType: PaperReturnDeliveryType.MultipleFiles,
                isNotifyUserEnabled: false
            },
            watermarkId: 0,
            voucherPaymentNotifyUser: 0,
            paperFilePreparerMessage: {
                id: 0,
                name: "",
                body: "",
                isAllowToEdit: false
            },
            reportingEngine: undefined
        },

        documentDisplaySetting: {
            isSendWithoutInvoice: false,
            isEnableInvoice: false,
            isEnableSendWithoutInvoice: false,
            customColumn: {
                id: 0,
                value: "",
                customColumn: 0,
                inUseCount: 0
            },
            priorToReview: false
        },
        documentSignatureSetting: {
            signatureFormSelectionType: SignatureType.None,
            knowledgeBasedAuthentication: false,
            signatureStampUser: initialUserModel,
            kbaMode: KBAMode.Test,
            enableDelegateeKbaAuthentication: false,
            enableSignerDeligation: false,
            isDoNotDisplayDatesEnabled: false,
            isDoNotDisplayNameAndTitleEnabled: false,
            enableEROStampDeligation: false,
            allowDisablingKBAOnReturnLevel: false,
            isKBADisabledInReturnLevel: false
        },
        documentNotificationSetting: {
            paymentVoucherNotificationSettingsModel: {
                enablePaymentVoucherReminder: false,
                noOfDaysForVoucherReminder: 0,
                paymentNotification: true
            },
            signingReminderNotificationSettingsModel: {
                enableSigningReminder: false,
                noOfDaysForSigningReminder: 0
            },
            notifyUser: initialUserModel,
            voucherPaymentNotifyUser: initialUserModel
        },
        documentRetentionSetting: {
            retentionPeriod: 2520
        },
        documentK1Setting: {
            k1PartnerInfoSettings: {
                k1MailCycle: K1MailCycles.After,
                noOfDays: 30
            },
            k1ClientInfoSettings: {
                k1MailCycle: K1MailCycles.After,
                noOfDays: 30
            }
        },
        isModified: false,
        watermarkSettings: {
            includedPages: [],
            excludedPages: []
        }
    },
    id: 0,
    clientId: "",
    documentStatus: DocumentStatus.None,
    uploadedOn: new Date(),
    partnerId: 0,
    partner: initialUserModel,
    assignToUserName: initialUserModel,
    deliveredByUser: initialUserModel,
    signatureStatus: SignatureStatus.None,
    createdBy: 0,
    engagementType: EngagementType.None,
    arrangement: Arrangement.Federal,
    taxYear: 0,
    assignTo: 0,
    assignedUser: {
        userId: 0,
        firstName: "",
        lastName: "",
        email: ""
    },
    lockedBy: 0,
    invoiceAmount: 0,
    deliveredOn: new Date(),
    attachments: [],
    documentGuid: "",
    isModified: false,
    isfullyLoaded: false,
    refundInfo: [],
    documentAccess: {
        selectedUsers: [],
        availableUsers: [],
        availableUserGroups: [],
        selectedUserGroups: []
    },
    clientTracking: [],
    signedDetails: [],
    accessCode: {
        accessCodeDetails: [],
        clientEvents: []
    },
    downloadableEfileForms: [],
    downloadableDocuments: [],
    downloadHistory: [],
    taxCaddyLookupResultModel: {
        isSuccess: false,
        result: "",
        resultObjectModel: {
            taxPayerUniqueId: 0,
            taxpayerName: "",
            emailAddress: "",
            ssn: "",
            address: "",
            taxClientId: "",
            taxYear: []
        }
    },
    taxSoftware: TaxSoftwareType.ProSystems,
    additionalEsignDocuments: [],
    paperFiles: [],
    k1Attachments: [],
    recycleBinStatus: RecycleBinStatus.None,
    location: { locationId: 0, locationName: "" },
    internalTracking: []
};

export const initialClientInfoViewModel: IClientInfoViewModel = {
    clientBasicInfoId: 0,
    clientId: "",
    name: "",
    emailAddress: "",
    countryCode: "",
    mobileNumber: "",
    type: CMClientType.None,
    uid: "",
    ero: "",
    dob: new Date(),
    spouseName: "",
    spouseSsn: "",
    spouseEmailAddress: "",
    spouseDob: new Date(),
    spouseCountryCode: "",
    spouseMobileNumber: "",
    isDeceased: false,
    spouseIsDeceased: false,
    mfj: false,
    totalCount: 0,
    eroFirstName: "",
    eroLastName: "",
    defaultFirstSigner: false,
    locationName: ""
};

export const initialTaxCaddyLookupResultModel: TaxCaddyLookupResultModel = {
    isSuccess: true,
    result: "",
    resultObjectModel: {
        address: "",
        emailAddress: "",
        ssn: "",
        taxClientId: "",
        taxpayerName: "",
        taxPayerUniqueId: 0,
        taxYear: []
    }
};

export const initialVoucher: Readonly<IVoucher> = {
    authorityID: 0,
    dueDate: undefined,
    amount: 0,
    voucherNo: VoucherNo.None,
    paymentType: VoucherTypes.None,
    customType: CustomType.None,
    onlinePaymentUri: "",
    customText: "",
    authorityImage: "",
    authorityName: "",
    groupId: undefined,
    formName: "",
    bookmark: "",
    pageNo: [],
    isMasterPaymentUrl: false,
    vocherMode: VoucherMode.None,
    isFormNameUpdated: false,
    formType: TaxFormType.Voucher,
    voucherDueDateType: DueDateType.SSRDefault,
    printedDueDate: undefined,
    isPaymentCompleted: false
};

export const initialEfile: Readonly<IEFile> = {
    authorityID: 0,
    formName: "",
    bookmark: "",
    pageNo: [],
    signatureControls: [],
    formType: TaxFormType.EFile
};
export const initialIndividualTaxReturnState: Readonly<IIndividualTaxReturn> = {
    formGroups: initialTaxReturnState.formGroups,
    documentSettings: initialTaxReturnState.documentSettings,
    id: initialTaxReturnState.id,
    clientId: initialTaxReturnState.clientId,
    documentStatus: initialTaxReturnState.documentStatus,
    uploadedOn: initialTaxReturnState.uploadedOn,
    partnerId: initialTaxReturnState.partnerId,
    partner: initialUserModel,
    assignToUserName: initialUserModel,
    deliveredByUser: initialUserModel,
    signatureStatus: initialTaxReturnState.signatureStatus,
    createdBy: initialTaxReturnState.createdBy,
    engagementType: initialTaxReturnState.engagementType,
    arrangement: initialTaxReturnState.arrangement,
    taxYear: initialTaxReturnState.taxYear,
    assignTo: initialTaxReturnState.assignTo,
    assignedUser: initialTaxReturnState.assignedUser,
    lockedBy: initialTaxReturnState.lockedBy,
    invoiceAmount: initialTaxReturnState.invoiceAmount,
    deliveredOn: initialTaxReturnState.deliveredOn,
    attachments: initialTaxReturnState.attachments,
    documentGuid: initialTaxReturnState.documentGuid,
    isModified: initialTaxReturnState.isModified,
    isfullyLoaded: initialTaxReturnState.isfullyLoaded,
    refundInfo: initialTaxReturnState.refundInfo,
    documentAccess: initialTaxReturnState.documentAccess,
    clientTracking: initialTaxReturnState.clientTracking,
    signedDetails: initialTaxReturnState.signedDetails,
    accessCode: initialTaxReturnState.accessCode,
    downloadableEfileForms: initialTaxReturnState.downloadableEfileForms,
    downloadableDocuments: initialTaxReturnState.downloadableDocuments,
    downloadHistory: initialTaxReturnState.downloadHistory,
    taxCaddyLookupResultModel: initialTaxReturnState.taxCaddyLookupResultModel,
    taxSoftware: initialTaxReturnState.taxSoftware,
    additionalEsignDocuments: initialTaxReturnState.additionalEsignDocuments,
    paperFiles: initialTaxReturnState.paperFiles,
    taxpayer: initiaITaxpayerState,
    k1Attachments: initialTaxReturnState.k1Attachments,
    recycleBinStatus: initialTaxReturnState.recycleBinStatus,
    location: initialTaxReturnState.location,
    internalTracking: initialTaxReturnState.internalTracking
};
export const initialMarriedJointTaxReturnState: Readonly<IMarriedJointTaxReturn> = {
    formGroups: initialTaxReturnState.formGroups,
    documentSettings: initialTaxReturnState.documentSettings,
    id: initialTaxReturnState.id,
    clientId: initialTaxReturnState.clientId,
    documentStatus: initialTaxReturnState.documentStatus,
    uploadedOn: initialTaxReturnState.uploadedOn,
    partnerId: initialTaxReturnState.partnerId,
    partner: initialUserModel,
    assignToUserName: initialUserModel,
    deliveredByUser: initialUserModel,
    signatureStatus: initialTaxReturnState.signatureStatus,
    createdBy: initialTaxReturnState.createdBy,
    engagementType: initialTaxReturnState.engagementType,
    arrangement: initialTaxReturnState.arrangement,
    taxYear: initialTaxReturnState.taxYear,
    assignTo: initialTaxReturnState.assignTo,
    assignedUser: initialTaxReturnState.assignedUser,
    lockedBy: initialTaxReturnState.lockedBy,
    invoiceAmount: initialTaxReturnState.invoiceAmount,
    deliveredOn: initialTaxReturnState.deliveredOn,
    attachments: initialTaxReturnState.attachments,
    documentGuid: initialTaxReturnState.documentGuid,
    isModified: initialTaxReturnState.isModified,
    isfullyLoaded: initialTaxReturnState.isfullyLoaded,
    refundInfo: initialTaxReturnState.refundInfo,
    documentAccess: initialTaxReturnState.documentAccess,
    clientTracking: initialTaxReturnState.clientTracking,
    signedDetails: initialTaxReturnState.signedDetails,
    accessCode: initialTaxReturnState.accessCode,
    downloadableEfileForms: initialTaxReturnState.downloadableEfileForms,
    downloadableDocuments: initialTaxReturnState.downloadableDocuments,
    downloadHistory: initialTaxReturnState.downloadHistory,
    taxCaddyLookupResultModel: initialTaxReturnState.taxCaddyLookupResultModel,
    taxSoftware: initialTaxReturnState.taxSoftware,
    additionalEsignDocuments: initialTaxReturnState.additionalEsignDocuments,
    paperFiles: initialTaxReturnState.paperFiles,
    taxpayer: initiaITaxpayerState,
    spouse: initiaITaxpayerState,
    k1Attachments: initialTaxReturnState.k1Attachments,
    recycleBinStatus: initialTaxReturnState.recycleBinStatus,
    location: initialTaxReturnState.location,
    internalTracking: initialTaxReturnState.internalTracking
};
export function updateTaxFormType(model: ITaxReturn) {
    let index = model.formGroups.findIndex((x) => x.group == DocumentGroups.TaxReturns);
    if (model.formGroups[index] && model.formGroups[index].forms) {
        model.formGroups[index].forms.map((f: IFormBase) => {
            let refund: IRefund = f as IRefund;

            if (refund && (refund.overPayment || refund.refundAmount)) {
                refund.formType = TaxFormType.Refund;
            } else {
                let form: ITaxReturnGroup = f as ITaxReturnGroup;
                form.formType = TaxFormType.TaxReturn;
            }
        });
    }

    index = model.formGroups.findIndex((x) => x.group == DocumentGroups.Transmittals);
    if (model.formGroups[index] && model.formGroups[index].forms) {
        model.formGroups[index].forms.map((f: IFormBase) => {
            let form: ITransmittal = f as ITransmittal;
            form.formType = TaxFormType.Transmittal;
        });
    }

    index = model.formGroups.findIndex((x) => x.group == DocumentGroups.Invoice);
    if (model.formGroups[index] && model.formGroups[index].forms) {
        model.formGroups[index].forms.map((f: IFormBase) => {
            let form: IInvoice = f as IInvoice;
            form.formType = TaxFormType.Invoice;
        });
    }
}

export const initialControl: IEfileDocumentControl = {
    type: SignatureControlTypes.None,
    controlGuid: "",
    top: 0,
    left: 0,
    signatureControlRole: SignatureControlRole.None,
    selectedSignStyle: "",
    selectedEroImage: "",
    tooltip: "",
    required: false,
    isCustomTextControl: false
};

export enum DocumentFilingType {
    None = 0,
    EFiled = 1,
    Both = 2
}

export interface ITaxDocumentStatusViewModel {
    documentGuid: string;
    documentStatus: DocumentStatus;
    signatureStatus: SignatureStatus;
    documentId: number;
    assignedEmail: string;
    eroEmailAddress: string;
    engagementType: EngagementType;
    taxYear: number;
    clientId: string;
    isDocStatus: boolean;
}

export interface ITaxReturnExtension {
    taxIdentifier: string;
    taxYear: number;
    engagementType: number;
}
export interface IDisableExtensionReminders extends ITaxReturnExtension {
    ssrId: number;
}
export interface ITaxReturnExtensionResponse extends ITaxReturnExtension {
    status: string;
    statuscode: number;
    extensionId: number;
}

export enum TaxReturnScreen {
    SendTaxReturn = 0,
    SendGroupTaxReturn = 1
}

export interface IK1SSNDetails {
    pageNo: number;
    width: number;
    height: number;
    top: number;
    left: number;
    maskValue: string;
    controlGuid: string;
    searchText: string;
    pageHeight: number;
}

export enum TaxClients {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    PartnerShip,
    ShareHolder,
    CcRecipient,
    Delegatee,
    Controller
}

export enum CCHEFileStatus{
    None = 0,
    ReadyToRelease = 1,
    ReleaseInProgress = 2,
    Released = 3,
    ReleaseFailed = 4
}
