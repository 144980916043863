import { connect } from "react-redux";
import { ApplicationState } from "../../../store";
import { LayoutContainer } from "./LayoutContainer";
import * as LayoutStore from "../../../store/Navigation/LayoutStore";
import * as userStore from "../../../store/userManagement/UserStore";
import * as UserProfileStore from "../../../store/userManagement/UserProfileStore";
import * as SuiteTaxSoftwareSettingStore from "../../../store/company/SuiteTaxSoftwareSettingStore";
import * as authAction from "src/store/auth/actions";

export default connect(
    (state: ApplicationState) => ({
        leftMenuData: state.LeftMenu,
        accessibleProductData: state.AccessibleProductData,
        userPrivilege: state.userPrivilege,
        userResource: state.userResources,
        userProfile: state.userProfile,
        auth: state.auth,
        companyData: state.companyData,
        pendoData: state.pendoData,
    }),
    {
        ...LayoutStore.actionCreators,
        ...userStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...SuiteTaxSoftwareSettingStore.actionCreators,
        ...authAction.actionCreators
    }
)(LayoutContainer as any);
