import {
	IFormBase, ITaxReturn, DocumentGroups,
	IVoucher, VoucherTypes, IRefund, IEFile,
	IGroup, ISignatureControl, SignatureControlRole, ITaxingAuthority,
	SignatureGroupType, SignatureType, VoucherNo, EntityType, ConsentStatus, ITaxReturnGroup, ITransmittal, SignatureControlTypes, IEfileDocumentControl,
	VoucherStatus, TransmittalStatus
} from '../../common/TaxReturn';
import { IAuthorityDictionary, ICompanyData } from '../../../store/company/CompanyStore';
import * as PdfStore from '../../../store/pdf/PdfStore';
import { SignatureFormSelectionType, IconColor } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as Helper from '../../helper/HelperFunctions';
import { IUserProfile } from '../../navigation/profile/ProfileObjects';
import { UserSettings } from '../../../store/userManagement/UserSettingStore';
import * as TabEFile from './Tabs/TabEFile';
import { IUserGroup } from '../../../Core/ViewModels/User/UserViewModel';
import { IUserSignatures } from '../../../store/common/UserSignatureStore';
export const NO_INDEX = -1;

export interface IFormGroupViewProps {
	bookmarks: IBookmarkSection[];
	pages: number[];
	taxReturn: ITaxReturn;
	updateTaxDocument: (taxDocument: ITaxReturn, callBack?: () => void) => void;
	isAssignedToLoggedinUser: boolean;
	reGroupPreview: (pages: number[], destinationGroup: DocumentGroups, sourceGroup?: DocumentGroups, callback?: () => void) => void;
	docId: number;
	pdfDocuments: PdfStore.IPdfDocumentDictionary;
	deletePages: (pages: number[]) => void;
	renderTrigger: string;
	tabType?: number;
	getAllTaxingAuthorities: ITaxingAuthority[];
}

export interface IFormGroupViewState {
	currentPage: number;
	actionCardShow: boolean;
	zoomEnabled: boolean;
}

interface IPageItemSort {
	pageTitle: string;
	pageNo: number;
  }

export function toPageItem(this: IFormBase) {
	return {
		pageNo: this.pageNo[0],
		pageTitle: this.formName,
		icon: ''
	} as IPageItem;
}

//IFormBase.prototype.toPageItem = toPageItem;

export interface IPageItem {
	pageNo: number;
	pageTitle: string;
	icon: string;
	color?: string;
	className?: string;
	id: number;
	isDeleteEnabled: boolean;
	displayIconAsImage: boolean;
	isClientInfo?: boolean;
	isMultipart: boolean;
	voucherStatus?: VoucherStatus;
	transmittalStatus?: TransmittalStatus;
	formName?: string;
    refundType?: RefundType;
    voucherNo: VoucherNo
}

export enum RefundType {
	None = 0,
	Refund = 1,
	Voucher = 2,
}

export interface IVoucherPageItem extends IPageItem {
	voucherNo: VoucherNo;
}

export interface IBookmarkSection {
	heading: string;
	pages: IPageItem[];
}

export enum DocumentFilterType {
	All = -1,
    StandardReturns = 0,
    GroupedReturn = 1
}

export enum ProcessReturnTabs {
	ClientInfo = 1,
	Grouping,
	Transmittals,
	TaxReturns,
	EFile,
	PaperFile,
	AdditionalEsignDocuments,
	Vouchers,
	K1,
	InVoice,
	Attachments,
	DeliveryOptions,
	GroupDelivery
}

export interface IShareHolder {
	k1StatusId: number,
	name: string;
	ssn: string;
	id: number;
	emailAddress: string;
	k1SendDate: string;
	consentStatus: ConsentStatus;
	k1DownloadDate: string;
	partnerAddress: string;
	entityType: EntityType;
	financialYearEnd: Date | undefined;
	countryCode: string;
	mobileNumber: string;
	isK1ReminderEnabled: boolean;
}


export const ICONS = {
	clientInfo: 'fa fa-id-card-alt',
	refund: 'fas fa-hand-holding-usd',
	efile: 'fas fa-pen-nib',
	additionalEsign: 'fas fa-pen',
	transmittal: 'fas fa-list-alt',
	taxreturn: 'fas fa-copy',
	k1: 'fas fa-id-card',
	invoice: 'file-invoice-dollar'
}

export const ClientInfoIcons = {
	clientInfoPayments: 'client-info-payments',
	clientInfoRefunds: 'client-info-refunds'
}

export interface ISigner {
	role: SignatureControlRole,
	id: number,
}

export interface IEROSigner {
	id: number,
	signPath: string,
}

export interface PdfPageSignatureControls {
	signatureControls: ISignatureControl[],
	signer: ISigner,
	eroSigner: IEROSigner,
	focusedGroup: SignatureGroupType
}

export const processReturnActionEndPoints = {
	sendForReviewAsync: 'SendForReviewAsync',
	approveForDeliveryAsync: 'ApproveForDeliveryAsync',
	sendToEROAsync: 'SendToEROAsync',
	returnToProcessorAsync: 'ReturnToProcessorAsync',
	deliverAsync: 'DeliverAsync',
	generateTaxpayerViewAsync: 'GenerateTaxpayerViewAsync'
}
export const userErrorKey = {
	ero: "ero",
	contactperson: "contactperson",
	notify: "notify"
}

export const SEPARATOR = "###";
export const INVALID_PAGE = -1;

class TaxReturnView<T> {
	constructor(taxReturn: ITaxReturn) {
		this.model = taxReturn;
	}
	protected model: ITaxReturn;

	public getFormIndex = (group: number) => {
		return this.model.formGroups.findIndex(x => x.group === group);
	}
	public getAuthorityName = (authorities: IAuthorityDictionary, authorityID: number, formName: string) => {
		for (let i in authorities) {
			if (authorities[i] && authorities[i].Id === authorityID) {
				return authorities[i].AuthorityName;
			}
		}
		return formName;
	}

	public getPages(bookmarks: IBookmarkSection[]): number[] {
		let ret: number[] = [];
		bookmarks.map((f, i) => {
			f.pages.map((p, j) => {
				if (p.pageNo !== INVALID_PAGE && ret.indexOf(p.pageNo) === -1) {
					ret.push(p.pageNo);
				}
			});
		});
		return ret;
	}

	public getVoucherAuthorities(authorities: IVoucher[]): number[] {
		let ret: number[] = [];

		authorities.map((f, i) => {
			ret.push(f.authorityID);
		});

		return ret;
	}

	public getAllAuthorities(authorities: ITaxingAuthority[]): number[] {
		let ret: number[] = [];
        authorities.map((f, i) => {
			ret.push(f.Id);
		});

		return ret;
	}

	public sortGroup = (pages: number[], group: DocumentGroups): ITaxReturn => {

		let taxDocument = this.model;
		let forms = taxDocument.formGroups.find(m => m.group === group) as IGroup;

		if (forms) {

			let groupIndex = taxDocument.formGroups.indexOf(forms);

			let sortedForms: IGroup = {
				forms: [],
				group: forms.group
			};

			pages.forEach(function (pageNumber) {
				let form = forms.forms.find(m => m.pageNo[0] === pageNumber);
				if (form) {
					sortedForms.forms.push(form);
				}
			})

			taxDocument.formGroups.splice(groupIndex, 1, sortedForms);
		}

		return taxDocument;
	}
};

export class ClientInfoView extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	private pageTitle = (authority: string, form: any) => {
		return authority + SEPARATOR + '$' + Helper.formatCurency(form.amount);
	}

	public getBookmarks(authorities: IAuthorityDictionary, getAllTaxingAuthorities: ITaxingAuthority[]): IBookmarkSection[] {
		return this.getBookmarksDetails(authorities, getAllTaxingAuthorities, false);
	}

	public getBookmarksWithZeroRefund(authorities: IAuthorityDictionary, getAllTaxingAuthorities: ITaxingAuthority[]): IBookmarkSection[] {
		return this.getBookmarksDetails(authorities, getAllTaxingAuthorities, true);
	}

	private getBookmarksDetails(authorities: IAuthorityDictionary, getAllTaxingAuthorities: ITaxingAuthority[], includeZeroRefund: boolean): IBookmarkSection[] {
		let ret: IBookmarkSection[] = [];

		//Get Client Pages
		let clientInfo: IBookmarkSection = {
			heading: 'Client Details',
			pages: [],
		};

		let taxReturnIndex = this.getFormIndex(DocumentGroups.TaxReturns);
		if (this.model.formGroups[taxReturnIndex] && this.model.formGroups[taxReturnIndex].forms) {
			this.model.formGroups[taxReturnIndex].forms.map((f, i) => {
				if (f.formName === 'ClientInfo') {
					f.pageNo.map((p, j) => {
						clientInfo.pages.push({
							pageNo: p,
							pageTitle: f.bookmark,
							icon: ICONS.clientInfo,
							isDeleteEnabled: false,
							isClientInfo: true
						} as IPageItem);
					});
				}
			});
		}
		ret.push(clientInfo);


		//Get Payments
		const payments: IBookmarkSection = {
			heading: 'Refunds & Payments Due',
			pages: [],
		};
		const authority = getAllTaxingAuthorities;
		const voucherIndex = this.getFormIndex(DocumentGroups.Vouchers);
		if (this.model.formGroups[voucherIndex] && this.model.formGroups[voucherIndex].forms) {
			this.model.formGroups[voucherIndex].forms.map((f, i) => {
				const form: IVoucher = f as IVoucher;
				if (form.paymentType && form.paymentType === VoucherTypes.PaymentVoucher) {
					f.pageNo.map((p, j) => {
						payments.pages.push({
							pageNo: p,
							pageTitle: authority?.filter(x => x?.Id == form?.authorityID).length == 0 ? "" :
								authority.filter(x => x?.Id == form?.authorityID)[0].AuthorityName + SEPARATOR + '($' + Helper.formatCurency(form.amount) + ')',
							icon: VoucherTypes[form.paymentType],
							color: "text-danger",
							className: ClientInfoIcons.clientInfoPayments,
							isDeleteEnabled: true,
							displayIconAsImage: true,
							voucherStatus: form.voucherStatus,
							formName: form.formName,
							refundType: RefundType.Voucher
						} as IPageItem);
					});
				}
			});
		}

		this.setRefund(taxReturnIndex, payments, authority, includeZeroRefund);

		let sortFunction = (a: any, b: any) => {
			if (a.pageTitle === "" || b.pageTitle === "") {
				return a.pageTitle > b.pageTitle ? 1 : -1;
			}
			let _aPageTitle = a.pageTitle.split('###');
			let _bPageTitle = b.pageTitle.split('###');
			let aAmount = (_aPageTitle[1].includes('$') ? _aPageTitle[1].split('$') : _aPageTitle[1]);
			let bAmount = (_bPageTitle[1].includes('$') ? _bPageTitle[1].split('$') : _bPageTitle[1]);
			let _aAmount = (aAmount[1].includes('.') ? aAmount[1].split('.') : aAmount[1]);
			let _bAmount = (bAmount[1].includes('.') ? bAmount[1].split('.') : bAmount[1]);
			return ((_aPageTitle[0] == "Federal" || _bPageTitle[0] == "Federal") ?
				((_aPageTitle[0] == _bPageTitle[0]) ? (Number(_aAmount[0]) < Number(_bAmount[0])) ? 1 : -1 : ((_aPageTitle[0] === "Federal") ? -1 : 1)) :
				((_aPageTitle[0] == _bPageTitle[0]) ? (Number(_aAmount[0]) < Number(_bAmount[0])) ? 1 : -1 : ((_aPageTitle[0] > _bPageTitle[0]) ? 1 : -1)));
		}

		payments.pages = payments.pages.sort((a, b) => (a.pageTitle > b.pageTitle) ? 1 : -1);
		let sortedPayments: IPageItem[] = [];
		let paymentVouchers = payments.pages
			.filter(x => x.className == ClientInfoIcons.clientInfoPayments)
			.reduce((acc, element) => {
				if (element.pageTitle.startsWith('Federal')) {
					return [element, ...acc];
				}
				return [...acc, element];
			}, [] as IPageItem[]);
		paymentVouchers = paymentVouchers.sort((a: any, b: any) => sortFunction(a, b));
		sortedPayments = sortedPayments.concat(paymentVouchers);
		let refundVouchers = payments.pages
			.filter(x => x.className == ClientInfoIcons.clientInfoRefunds)
			.reduce((acc, element) => {
				if (element.pageTitle.startsWith('Federal')) {
					return [element, ...acc];
				}
				return [...acc, element];
			}, [] as IPageItem[]);
		refundVouchers = refundVouchers.sort((a: any, b: any) => sortFunction(a, b));
		sortedPayments = sortedPayments.concat(refundVouchers);
		payments.pages = sortedPayments;

		ret.push(payments);
		return ret;
	}

	private setRefund(taxReturnIndex: number, payments: IBookmarkSection, authority: ITaxingAuthority[], includeZereRefund: boolean) {
		//Get Refunds
		if (this.model.formGroups[taxReturnIndex] && this.model.formGroups[taxReturnIndex].forms) {
			this.model.formGroups[taxReturnIndex].forms.map((f, i) => {
				const form: IRefund = f as IRefund;
				if (form.overPayment || form.overPaymentApplied) {
					f.pageNo.map((p, j) => {
						const penaltyAmount = form.penalty || 0;
						let refund: number = form.refundAmount !== undefined ? form.refundAmount : (form.overPayment - form.overPaymentApplied - penaltyAmount);
						if (refund != 0) {
							payments.pages.push({
								pageNo: p,
								pageTitle: authority.filter(x => x.Id == form.authorityID).length == 0 ? "" :
									authority.filter(x => x.Id == form.authorityID)[0].AuthorityName + SEPARATOR + '$' + Helper.formatCurency(form.overPayment - form.overPaymentApplied - penaltyAmount),
								icon: ICONS.refund,
								color: "text-success",
								className: ClientInfoIcons.clientInfoRefunds,
								isDeleteEnabled: true,
								isMultipart: form.isMultipart,
								refundType: RefundType.Refund
							} as IPageItem);
						}
						else if (includeZereRefund === true) {
							payments.pages.push({
								pageNo: p,
								pageTitle: authority.filter(x => x.Id == form.authorityID).length == 0 ? "" :
									authority.filter(x => x.Id == form.authorityID)[0].AuthorityName + SEPARATOR + '$' + Helper.formatCurency(form.overPayment - form.overPaymentApplied - penaltyAmount),
								icon: ICONS.refund,
								color: "text-success",
								className: ClientInfoIcons.clientInfoRefunds,
								isDeleteEnabled: true,
								isMultipart: form.isMultipart,
								refundType: RefundType.Refund
							} as IPageItem);
						}
					});
				}
			});
		}
	}
}

export class K1sView extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	public getK1Pages(bookmarkId: number) {
		let ret: number[] = [];
		ret = this.model.formGroups.filter((i: any) => i.group == DocumentGroups.K1 && i?.shareHolder.id == bookmarkId)[0].forms.map(x => { return x.pageNo }).reduce((r, e) => (r.push(...e), r), [])
		return ret.sort((a: number, b: number) => {
			if (a < b) return -1;
			else return 1;
		});
	}

	public getBookmarks(): IBookmarkSection[] {
		let ret: IBookmarkSection[] = [];

		let k1Bookmarks: IBookmarkSection = {
			heading: 'K1 Bookmarks',
			pages: [],
		};

		if (this.model.formGroups.some(i => i.group == DocumentGroups.K1)) {
			this.model.formGroups.filter(i => i.group == DocumentGroups.K1).map((x: any) => {
				if (x.forms.length > 0) {
					k1Bookmarks.pages.push({
						pageNo: x.forms[0] != undefined ? x.forms[0].pageNo[0] : "",
						pageTitle: x.shareHolder ? x.shareHolder.name : "",
						id: x.shareHolder ? x.shareHolder.id : 0,
						icon: ICONS.k1,
						isDeleteEnabled: false
					} as IPageItem);
				}
			})

		}

		if(k1Bookmarks.pages.length > 1)
		{
			k1Bookmarks.pages.sort((a, b) => { return (a.pageTitle === undefined ? 1 :  b.pageTitle === undefined ? -1 : a.pageTitle.localeCompare(b.pageTitle)); });
		}

		ret.push(k1Bookmarks);

		return ret;
	}
}

export class Transmittalsview extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	public getBookmarks(): IBookmarkSection[] {
		let ret: IBookmarkSection[] = [];
		let bookmarks: IBookmarkSection = {
			heading: 'Transmittals',
			pages: [],
		};
		let transmittalIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);

		if (this.model.formGroups[transmittalIndex] && this.model.formGroups[transmittalIndex].forms) {
			this.model.formGroups[transmittalIndex].forms.sort((a: IFormBase, b: IFormBase) => a.pageNo[0] - b.pageNo[0])
			this.model.formGroups[transmittalIndex].forms.map((f, i) => {
				f.pageNo.map((p, j) => {
					bookmarks.pages.push({
						pageNo: p,
						pageTitle: 'Transmittal [ ' + f.pageNo + ' ]',
						icon: ICONS.transmittal,
						isDeleteEnabled: false
					} as IPageItem);
				});
			});
		}
		ret.push(bookmarks);

		return ret;
	}

	public getSignatureControls(): ISignatureControlsDictionary {

		let controls: ISignatureControlsDictionary = {};

		let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
		if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
			this.model.formGroups[index].forms.map((f: IFormBase) => {
				let form: ITransmittal = f as ITransmittal;
				controls[form.pageNo[0]] = form.signatureControls;
			});
		}
		return controls;
	}

	public addSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
			if (this.model.formGroups[index]) {

				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);
					if (found != NO_INDEX) {
						if (!form.signatureControls) {
							form.signatureControls = [];
						}
						form.signatureControls.push(control);
					}
				});
			}
		}
	}

	public removeSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {
						form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
					}
				});
			}
		}
	}

	public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {

						for (let i = 0; i < form.signatureControls.length; i++) {

							let signControl = form.signatureControls[i];

							if (signControl.controlGuid === oldControl.controlGuid) {

								signControl.left = newControl.left;
								signControl.top = newControl.top;
								signControl.signatureControlRole = newControl.signatureControlRole;
								signControl.type = newControl.type;

								break;
							}
						}
					}
				});
			}
		}
	}
}

export interface IReGroupTaxReturn {
	sourceGroup: DocumentGroups;
	destinationGroup: DocumentGroups;
	form: IFormBase;
}

export interface IReGroupPreviewTaxReturn {
	sourceGroup: DocumentGroups;
	destinationGroup: DocumentGroups;
	pageNo: number[];
}

export interface ITaxReturnBookMarksView {
	bookmarks: string[];
	pageNo: number[];
}

export interface ISignatureControlsDictionary {
	[pageNo: number]: ISignatureControl[]
}

export interface IEfileSignatureControlsDictionary {
	[pageNo: number]: IEfileDocumentControl[]
}

export class TaxReturnInfoView extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	public getTaxReturnInfoBookmarks(taxDoc: ITaxReturn): IBookmarkSection[] {
		let bookmarks: IBookmarkSection[] = [];
		let tmpBookMark: IBookmarkSection = {
			heading: 'Tax Returns',
			pages: []
		};
		let taxReturnIndex = this.getFormIndex(DocumentGroups.TaxReturns);
		this.model.formGroups[taxReturnIndex]?.forms?.sort((a: IFormBase, b: IFormBase) => a.pageNo[0] - b.pageNo[0]);
		this.model.formGroups[taxReturnIndex] && this.model.formGroups[taxReturnIndex].forms
			&& this.model.formGroups[taxReturnIndex].forms.map((form, index) => {
				form.pageNo.map((page, pageIndex) => {

					tmpBookMark.pages.push({
						pageNo: page,
						// pageTitle: 'Page - ' + form.pageNo,
						pageTitle: form.bookmark,
						icon: ICONS.taxreturn,
						isDeleteEnabled: false
					} as IPageItem);

				});
			});

		bookmarks.push(tmpBookMark);

		return bookmarks;
	}

	public getSignatureControls(): ISignatureControlsDictionary {

		let controls: ISignatureControlsDictionary = {};

		let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.TaxReturns);
		if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
			this.model.formGroups[index].forms.map((f: IFormBase) => {
				let form: ITaxReturnGroup = f as ITaxReturnGroup;
				controls[form.pageNo[0]] = form.signatureControls;
			});
		}
		return controls;
	}

	public addSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.TaxReturns);
			if (this.model.formGroups[index]) {

				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITaxReturnGroup = f as ITaxReturnGroup;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);
					if (found != NO_INDEX) {
						if (!form.signatureControls) {
							form.signatureControls = [];
						}
						form.signatureControls.push(control);
					}
				});
			}
		}
	}

	public removeSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.TaxReturns);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITaxReturnGroup = f as ITaxReturnGroup;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {
						form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
					}
				});
			}
		}
	}

	public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.TaxReturns);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITaxReturnGroup = f as ITaxReturnGroup;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {

						for (let i = 0; i < form.signatureControls.length; i++) {

							let signControl = form.signatureControls[i];

							if (signControl.controlGuid === oldControl.controlGuid) {

								signControl.left = newControl.left;
								signControl.top = newControl.top;
								signControl.signatureControlRole = newControl.signatureControlRole;
								signControl.type = newControl.type;

								break;
							}
						}
					}
				});
			}
		}
	}
}

export class EfileInfoView extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	public getBookmarks(authorities: IAuthorityDictionary, signatureType: any): IBookmarkSection[] {
		signatureType = (typeof signatureType == "number") ? SignatureType[signatureType] : signatureType;
		let section: IBookmarkSection[] = [];
		let bookmarks: IBookmarkSection = {
			heading: 'E-File Forms',
			pages: [],
		};
		let iconColor: string;
		if (signatureType === SignatureType[SignatureType.ESign] || signatureType === SignatureType[SignatureType.ESignWhenAllIncludedReturnsAllowed])
			iconColor = "text-green";
		else if (signatureType === SignatureType[SignatureType.ManualSign])
			iconColor = "text-yellow";
		let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
		if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
			let pageItemsFederal: IPageItem[] = [];
			let pageItemsNonFederal: IPageItem[] = [];
			this.model.formGroups[index].forms.map((f: IFormBase) => {
				let form: IEFile = f as IEFile;
				form.pageNo.map((p, j) => {
					const authorityName = this.getAuthorityName(authorities, form.authorityID, form.formName);
					if (authorityName == "Federal") {
						pageItemsFederal.push({
							pageNo: p,
							color: iconColor,
							pageTitle: form.formName.startsWith(authorityName) ? form.formName : authorityName + " " + form.formName,
							icon: ICONS.efile,
							isDeleteEnabled: false
						} as IPageItem);
					}
					else {
						pageItemsNonFederal.push({
							pageNo: p,
							color: iconColor,
							pageTitle: form.formName.startsWith(authorityName) ? form.formName : authorityName + " " + form.formName,
							icon: ICONS.efile,
							isDeleteEnabled: false
						} as IPageItem);
					}
				});
			});

			pageItemsFederal = pageItemsFederal.sort((a: IPageItem, b: IPageItem) => {
				var result = 0;
				let aTitle = a.pageTitle.toLowerCase();
				let bTitle = b.pageTitle.toLowerCase();
				if (aTitle < bTitle) {
					result = -1;
				} else if (aTitle > bTitle) {
					result = 1;
				} else {
					if (a.pageNo < b.pageNo) {
						result = -1
					} else if (a.pageNo > b.pageNo) {
						result = 1;
					}
				}
				return result;
			});
			pageItemsNonFederal = pageItemsNonFederal.sort((a: IPageItem, b: IPageItem) => {
				var result = 0;
				let aTitle = a.pageTitle.toLowerCase();
				let bTitle = b.pageTitle.toLowerCase();
				if (aTitle < bTitle) {
					result = -1;
				} else if (aTitle > bTitle) {
					result = 1;
				} else {
					if (a.pageNo < b.pageNo) {
						result = -1
					} else if (a.pageNo > b.pageNo) {
						result = 1;
					}
				}
				return result;
			});
			pageItemsFederal = pageItemsFederal.concat(pageItemsNonFederal);
			bookmarks.pages = pageItemsFederal;
		}
		section.push(bookmarks);
		return section;
	}

	public getSignatureControls(): IEfileSignatureControlsDictionary {

		let controls: IEfileSignatureControlsDictionary = {};

		let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
		if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
			this.model.formGroups[index].forms.map((f: IFormBase) => {
				let form: IEFile = f as IEFile;
				controls[form.pageNo[0]] = form.signatureControls.map((control, index) => {
					let controlData = control;
					controlData.required = controlData.type != SignatureControlTypes.Text ? true : controlData.isCustomTextControl ? controlData.required : true;
					controlData.tooltip = !controlData.tooltip ? "" : controlData.tooltip;
					return controlData;
				});
			});
		}
		return controls;
	}

	public addSignatureControl(control: IEfileDocumentControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
			if (this.model.formGroups[index]) {

				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: IEFile = f as IEFile;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);
					if (found != NO_INDEX) {
						if (!form.signatureControls) {
							form.signatureControls = [];
						}
						form.signatureControls.push(control);
					}
				});
			}
		}
	}

	public removeSignatureControl(control: IEfileDocumentControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: IEFile = f as IEFile;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {
						form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
					}
				});
			}
		}
	}

	public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: IEFile = f as IEFile;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {

						for (let i = 0; i < form.signatureControls.length; i++) {

							let signControl = form.signatureControls[i];

							if (signControl.controlGuid === oldControl.controlGuid) {

								signControl.left = newControl.left;
								signControl.top = newControl.top;
								signControl.signatureControlRole = newControl.signatureControlRole;
								signControl.type = newControl.type;

								break;
							}
						}
					}
				});
			}
		}
	}

	public removeDateControls(): void {
		if (this.model && this.model.formGroups) {
			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {
					let form: IEFile = f as IEFile;
					form.signatureControls = form.signatureControls.filter(x => x.type !== SignatureControlTypes.Date);
				});
			}
		}
	}

	public addDateControls(taxReturn: ITaxReturn): void {

		if (this.model && this.model.formGroups) {
			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.EFile);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase,formIndex) => {
					let form: IEFile = f as IEFile;
					let tempform: IEFile = { ...taxReturn.formGroups[index].forms[formIndex] } as IEFile;
					tempform.signatureControls.filter(x => x.type == SignatureControlTypes.Date).map(x => { form.signatureControls.push(x) });
				});
			}
		}
	}
}


const sortByTitle = (a, b) => {
	const aTitle = a.pageTitle.toLowerCase();
	const bTitle = b.pageTitle.toLowerCase();

	if (aTitle < bTitle) return -1;
	if (aTitle > bTitle) return 1;

	return a.pageNo - b.pageNo;
}


type IPageItemWithAuthority = IPageItem & { authorityName: string };
	
export class AdditionalESignView extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	public get additionalESignForms(): IEFile[] {
		if (!this.model || !this.model.formGroups) {
			return [];
		}
		return this.model.formGroups.find(x => x.group == DocumentGroups.AdditionalEsignDocument)?.forms as IEFile[] ?? [];
	}

	public getBookmarks(authorities: IAuthorityDictionary, signatureType: SignatureType): IBookmarkSection[] {
		let section: IBookmarkSection[] = [];
		let bookmarks: IBookmarkSection = {
			heading: 'Recognized Additional E-Sign',
			pages: [],
		};
	
		let iconColor: string;
	
		if (signatureType === SignatureType.ESign || signatureType === SignatureType.ESignWhenAllIncludedReturnsAllowed) {
			iconColor = "text-green";
		} else if (signatureType === SignatureType.ManualSign) {
			iconColor = "text-yellow";
		}
		
		if (this.additionalESignForms.length > 0) {
			const pageItems = this.additionalESignForms.flatMap((eFileForm: IEFile) => {
				return eFileForm.pageNo.map(p => {
					const authorityName = this.getAuthorityName(authorities, eFileForm.authorityID, eFileForm.formName) || "";
					const pageItem: IPageItemWithAuthority = {
						pageNo: p,
						color: iconColor,
						pageTitle: eFileForm.formName.startsWith(authorityName) ? eFileForm.formName : authorityName + " " + eFileForm.formName,
						icon: ICONS.additionalEsign,
						isDeleteEnabled: false,
						id: 0,
						displayIconAsImage: false,
						isMultipart: false,
						voucherNo: 0,
						authorityName
					};
					return pageItem;
				});
			});

			const pageItemsGroupedbyAuthority = Object.groupBy(pageItems, ({ authorityName }) => authorityName === "Federal" ? "federal" : "nonFederal")
			bookmarks.pages = [
				...(pageItemsGroupedbyAuthority.federal || []).toSorted(sortByTitle),
				...(pageItemsGroupedbyAuthority.nonFederal || []).toSorted(sortByTitle)
			].toSorted(sortByTitle);
		}
		section.push(bookmarks);
		return section;
	}

	public getSignatureControls(): IEfileSignatureControlsDictionary {
		const signatureControls = this.additionalESignForms
			.map(f => {
				const controls = (f.signatureControls || []).map(control => ({
					...control,
					required: control.type != SignatureControlTypes.Text ? true : control.isCustomTextControl ? control.required : true,
					tooltip: control.tooltip || ""
				}))
				return { pageNo: f.pageNo[0], controls }
			})
			.reduce((obj, { pageNo, controls }) => {
				obj[pageNo] = controls;
				return obj
			},{})
		return signatureControls;
	}

	public addSignatureControl(control: IEfileDocumentControl, pageNo: number): void {
		for (const form of this.additionalESignForms.filter(f => f.pageNo.includes(pageNo))) {
			if (!form.signatureControls) {
				form.signatureControls = [];
			}
			form.signatureControls = [...form.signatureControls, control];
		}
	}

	public removeSignatureControl(control: IEfileDocumentControl, pageNo: number): void {
		for (const form of this.additionalESignForms
								.filter((form: IEFile) => form.pageNo.find((n) => n === pageNo))) {
			form.signatureControls = form.signatureControls.filter(x => x.controlGuid !== control.controlGuid);
		}
	}

	public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
		for (const form of this.additionalESignForms.filter((form: IEFile) => form.pageNo.find((n) => n === pageNo))) {
			form.signatureControls = form.signatureControls.map(signControl => {
				if (signControl.controlGuid === oldControl.controlGuid) {
					return { ...signControl, ...newControl };
				}
				return signControl;
			});
		}
	}
}

export interface IDelegatedEROSigner {
	userId: number,
	firstName: string,
	lastName: string,
	isEnableSignatureDelegation: boolean,
	signaturePath: string,
}

export class VoucherInfoView extends TaxReturnView<ITaxReturn>{

	public static getInitialVoucherBookmark(): IBookmarkSection {
		return {
			heading: 'Vouchers',
			pages: []
		};
	}

	public getBookmarks(taxDoc: ITaxReturn): IBookmarkSection[] {
		let bookmarks: IBookmarkSection[] = [];

		//Get Voucher Pages
		let voucherBookmark: IBookmarkSection = VoucherInfoView.getInitialVoucherBookmark();
		let voucherIndex = taxDoc.formGroups.findIndex(x => x.group == DocumentGroups.Vouchers);
		if (taxDoc.formGroups[voucherIndex] && taxDoc.formGroups[voucherIndex].forms) {
			taxDoc.formGroups[voucherIndex].forms.map((f, i) => {
				let form: IVoucher = f as IVoucher;
				form.pageNo.map((page, pageIndex) => {
					voucherBookmark.pages.push({
						pageNo: page,
						pageTitle: form.bookmark + (form.amount == 0 ? SEPARATOR + '$' + form.amount : ""),
						icon: VoucherTypes[form.paymentType],
						isDeleteEnabled: false,
						displayIconAsImage: true,
						voucherStatus: form.voucherStatus
					} as IPageItem);

				});
			});
		}
		bookmarks.push(voucherBookmark);
		return bookmarks;
	}

}

export class InvoiceInfoView extends TaxReturnView<ITaxReturn>{
	public getInvoiceBookmarks(authorities: IAuthorityDictionary): IBookmarkSection[] {
		let ret: IBookmarkSection[] = [];
		//Get Client Pages
		let Invoictals: IBookmarkSection = {
			heading: 'Invoices',
			pages: [],
		};
		let InvoiceIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Invoice);
		if (this.model.formGroups[InvoiceIndex] && this.model.formGroups[InvoiceIndex].forms) {
			this.model.formGroups[InvoiceIndex].forms.map((f, i) => {
				f.pageNo.map((p, j) => {
					Invoictals.pages.push({
						pageNo: p,
						pageTitle: f.bookmark + ' [' + (i + 1) + ']',
						icon: ICONS.invoice,
						isDeleteEnabled: false
					} as IPageItem);
				});
			});
		}
		ret.push(Invoictals);
		return ret;
	}
	public getInvoicePages(bookmarks: IBookmarkSection[]): number[] {
		let ret: number[] = [];
		bookmarks.map((f, i) => {
			f.pages.map((p, j) => {
				if (p.pageNo !== INVALID_PAGE) {
					ret.push(p.pageNo);
				}
			});
		});
		return ret;
	}

	public getSignatureControls(): ISignatureControlsDictionary {

		let controls: ISignatureControlsDictionary = {};

		let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Invoice);
		if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
			this.model.formGroups[index].forms.map((f: IFormBase) => {
				let form: ITransmittal = f as ITransmittal;
				controls[form.pageNo[0]] = form.signatureControls;
			});
		}
		return controls;
	}

	public addSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Invoice);
			if (this.model.formGroups[index]) {

				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);
					if (found != NO_INDEX) {
						if (!form.signatureControls) {
							form.signatureControls = [];
						}
						form.signatureControls.push(control);
					}
				});
			}
		}
	}

	public removeSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Invoice);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {
						form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
					}
				});
			}
		}
	}

	public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Invoice);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {

						for (let i = 0; i < form.signatureControls.length; i++) {

							let signControl = form.signatureControls[i];

							if (signControl.controlGuid === oldControl.controlGuid) {

								signControl.left = newControl.left;
								signControl.top = newControl.top;
								signControl.signatureControlRole = newControl.signatureControlRole;
								signControl.type = newControl.type;

								break;
							}
						}
					}
				});
			}
		}
	}
}

export function getAllEroSigners(company: ICompanyData, userProfile: any,
	taxReturn: ITaxReturn, userSettings: UserSettings,
	userSignatures: IUserSignatures): TabEFile.IEROSigner[] {
	let eroSigners: TabEFile.IEROSigner[] = [];

	if (company != undefined) {
		let companyAsSigner = {
			label: company.companyProfile.companyInfo.companyName,
			value: 0,
			isEnableSignatureDelegation: true,
			eroImage: company.signatureUploadLink,
		}
		addEroSigner(eroSigners, companyAsSigner);
	}

	if (userProfile != undefined
		&& userProfile.profile.role.split(',').includes('Partner')) {
		const currentUser = {
			label: userProfile.profile.name,
			value: userProfile.profile.user_id as number,
			isEnableSignatureDelegation: true,
			eroImage: userSettings.settings.signatureSettings.signaturePath || "",
		}
		addEroSigner(eroSigners, currentUser);
	}

	if (taxReturn.partner != undefined) {
		const partner = taxReturn.partner;
		const partnerEro = {
			label: partner.firstName + " " + partner.lastName,
			value: partner.userId,
			isEnableSignatureDelegation: true,
			eroImage: getEroSignerPath(partner.userId, userSignatures)
		}
		addEroSigner(eroSigners, partnerEro);
	}

	if (userSettings.delegatedSigners.length > 0) {
		userSettings.delegatedSigners.map(eroSigner => {
			let signer = {
				label: eroSigner.firstName + " " + eroSigner.lastName,
				value: eroSigner.userId,
				isEnableSignatureDelegation: eroSigner.isEnableSignatureDelegation,
				eroImage: eroSigner.signaturePath,
			}
			addEroSigner(eroSigners, signer);
		});
	}

	return eroSigners;
}

export function addEroSigner(eroSignersArray: TabEFile.IEROSigner[], eroSigner: TabEFile.IEROSigner) {
	if (eroSignersArray.filter(e => e.value == eroSigner.value).length === 0) {
		eroSignersArray.push(eroSigner);
	}
}

export function getEroSignerPath(userId: number, userSignatures: IUserSignatures): string {
	let eroImagePath = "";
	if (userSignatures[userId] && userSignatures[userId].signatureDownloadPath) {
		eroImagePath = userSignatures[userId].signatureDownloadPath;
	}
	return eroImagePath;
}

export class RevisedTransmittalsview extends TaxReturnView<ITaxReturn> {
	constructor(taxReturn: ITaxReturn) {
		super(taxReturn);
	}

	public getBookmarks(): IBookmarkSection[] {
		let ret: IBookmarkSection[] = [];
		let bookmarks: IBookmarkSection = {
			heading: 'Transmittals',
			pages: [],
		};
		let transmittalIndex = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);

		if (this.model.formGroups[transmittalIndex] && this.model.formGroups[transmittalIndex].forms) {
			this.model.formGroups[transmittalIndex].forms.sort((a: IFormBase, b: IFormBase) => a.pageNo[0] - b.pageNo[0])
			this.model.formGroups[transmittalIndex].forms.map((f, i) => {
				let form: ITransmittal = f as ITransmittal;
				if (form.transmittalStatus != TransmittalStatus.Existing) {
					form.pageNo.map((p, j) => {
						bookmarks.pages.push({
							pageNo: p,
							pageTitle: 'Transmittal [ ' + form.pageNo + ' ]',
							icon: ICONS.transmittal,
							isDeleteEnabled: false,
							transmittalStatus: form.transmittalStatus
						} as IPageItem);
					});
				}
			});
		}
		ret.push(bookmarks);

		return ret;
	}

	public getSignatureControls(): ISignatureControlsDictionary {

		let controls: ISignatureControlsDictionary = {};

		let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
		if (this.model.formGroups[index] && this.model.formGroups[index].forms) {
			this.model.formGroups[index].forms.map((f: IFormBase) => {
				let form: ITransmittal = f as ITransmittal;
				controls[form.pageNo[0]] = form.signatureControls;
			});
		}
		return controls;
	}

	public addSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
			if (this.model.formGroups[index]) {

				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);
					if (found != NO_INDEX) {
						if (!form.signatureControls) {
							form.signatureControls = [];
						}
						form.signatureControls.push(control);
					}
				});
			}
		}
	}

	public removeSignatureControl(control: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {
						form.signatureControls = form.signatureControls.filter(x => x.controlGuid != control.controlGuid);
					}
				});
			}
		}
	}

	public replaceSignatureControl(oldControl: ISignatureControl, newControl: ISignatureControl, pageNo: number): void {
		if (this.model && this.model.formGroups) {

			let index = this.model.formGroups.findIndex(x => x.group == DocumentGroups.Transmittals);
			if (this.model.formGroups[index]) {
				this.model.formGroups[index].forms.map((f: IFormBase) => {

					let form: ITransmittal = f as ITransmittal;
					let found = NO_INDEX;
					found = form.pageNo.findIndex((n) => n === pageNo);

					if (found != NO_INDEX) {

						for (let i = 0; i < form.signatureControls.length; i++) {

							let signControl = form.signatureControls[i];

							if (signControl.controlGuid === oldControl.controlGuid) {

								signControl.left = newControl.left;
								signControl.top = newControl.top;
								signControl.signatureControlRole = newControl.signatureControlRole;
								signControl.type = newControl.type;

								break;
							}
						}
					}
				});
			}
		}
	}
}
