import * as React from "react";
import { IClientInfoProps } from "./ClientInfo";
import { IGroupInfo, IControllerInfo } from "../../store/groupedreturns/GroupedReturnProcessState";
import { Tabs, Button, Modal, Tab, OverlayTrigger, Popover } from "react-bootstrap";
import { EditControllerInfo } from "./EditControllerInfo";
import { ITaxReturn, EngagementType, DocumentStatus, ISignerModel } from "../common/TaxReturn";
import { EditClientInfo } from "./EditClientInfo";
import { ClientInfoConstants } from "../helper/Constants";
import {
    validateClientInfo,
    validateDocumentLocation,
    validateEditClientInfoEmails,
    validateControllerInfo
} from "../helper/HelperFunctions";
import { Loader, LoadingOverlay } from "react-overlay-loader";
interface IGroupReturnClientInfoProps extends IClientInfoProps {
    groupInfo: IGroupInfo;
    updateControllerInfoData: (data: IControllerInfo) => void;
    onEditInfoSave: (
        taxReturn: ITaxReturn,
        controllerInfo: IControllerInfo,
        canSendControllerEmailUpdateMail: boolean,
        callback: () => void
    ) => void;
    sendClientEmailUpdateMail: (id: number, isMailSend: boolean, clientGUIDs: string[], signerInfo?: ISignerModel) => void;
}

interface IGroupReturnClientInfoState {
    loadingMessage: string | undefined;
    isControllerEmailUpdated: boolean;
    canSendSpouseEmailUpdateMail: boolean;
    clientGuidToSendMail: string;
}

const msg = {
    saving: "Saving Info ,Please Wait...",
    loading: "Loading, Please Wait..."
};
export class GroupReturnClientInfo extends React.Component<IGroupReturnClientInfoProps, IGroupReturnClientInfoState> {
    constructor(props: IGroupReturnClientInfoProps) {
        super(props);
        this.state = {
            loadingMessage: "",
            isControllerEmailUpdated: false,
            canSendSpouseEmailUpdateMail: false,
            clientGuidToSendMail: ""
        };
    }

    private onConfirm = (sendEmailUpdateMail) => {
        document.body.click();
        const taxReturn: ITaxReturn = this.props.model as ITaxReturn;
        const GroupInfo: IGroupInfo = this.props.groupInfo as IGroupInfo;
        let returnValue = true;
        if (this.props.isDeliveredReturn && !this.props.isGroupedReturn) {
            returnValue = validateDocumentLocation(taxReturn);
        }
        if (
            validateClientInfo(taxReturn, true) &&
            validateEditClientInfoEmails(taxReturn, true) &&
            returnValue &&
            validateControllerInfo(GroupInfo.controllerInfo)
        ) {
            const useThis = this;
            useThis.setState({ loadingMessage: msg.saving }, () => {
                const CanSendControllerEmailUpdateMail = useThis.state.isControllerEmailUpdated && sendEmailUpdateMail;
                useThis.props.onEditInfoSave(taxReturn, GroupInfo.controllerInfo, CanSendControllerEmailUpdateMail, () => {
                    if (useThis.state.canSendSpouseEmailUpdateMail) {
                        useThis.props.sendClientEmailUpdateMail(taxReturn.id, sendEmailUpdateMail, [
                            useThis.state.clientGuidToSendMail
                        ]);
                    }
                    useThis.setState({
                        loadingMessage: ""
                    });
                });
            });
        }
    };

    onPopupClose = () => {
        this.setState(
            {
                loadingMessage: ""
            },
            () => {
                this.props.model && this.props.onCancel(this.props.model.id);
            }
        );
    };

    public render() {
        const popover = (
            <Popover id="email-updated-confirmation">
                <Popover.Title as="h3">Confirmation</Popover.Title>
                <Popover.Content>
                    {ClientInfoConstants.emailUpdatedConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={() => {
                                this.onConfirm(true);
                            }}
                            title="OK"
                            data-test-auto="BCDBF6AB-57E0-4AB7-BAB2-580203E088C8"
                            style={{ marginLeft: "5px" }}
                        >
                            Yes
                        </button>
                        <button
                            onClick={() => {
                                this.onConfirm(false);
                            }}
                            title="Cancel"
                            data-test-auto="8AAB3A64-BD99-411A-9F63-E627D51F1F59"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}
                        >
                            No
                        </button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        return (
            <Modal show={this.props.show} className="client-info" onHide={this.onPopupClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay style={{ height: "100%" }}>
                        <Tabs defaultActiveKey="controllerInfo" fill>
                            <Tab
                                eventKey="controllerInfo"
                                title={
                                    <div>
                                        <span className="ml-2">Controller</span>
                                    </div>
                                }
                            >
                                <EditControllerInfo
                                    groupInfo={this.props.groupInfo}
                                    updateControllerInfoData={this.props.updateControllerInfoData}
                                    markControllerEmailAsUpdated={() => {
                                        this.setState({
                                            isControllerEmailUpdated: true
                                        });
                                    }}
                                />
                            </Tab>

                            <Tab
                                eventKey="clientInfo"
                                title={
                                    <div>
                                        <span className="ml-2">
                                            {this.props.model &&
                                            this.props.model?.engagementType.toString() === EngagementType[EngagementType.E1040]
                                                ? "Client"
                                                : this.props.model?.engagementType.toString() ===
                                                      EngagementType[EngagementType.E1065] ||
                                                  this.props.model?.engagementType.toString() ===
                                                      EngagementType[EngagementType.E1120] ||
                                                  this.props.model?.engagementType.toString() ===
                                                      EngagementType[EngagementType.E1120S]
                                                ? "Entity"
                                                : "Trust"}
                                        </span>
                                    </div>
                                }
                            >
                                <EditClientInfo
                                    model={this.props.model}
                                    isDeliveredReturn={this.props.isDeliveredReturn}
                                    locationDropdown={this.props.locationDropdown}
                                    isGroupedReturn={this.props.isGroupedReturn}
                                    showSMSTextNotifications={this.props.showSMSTextNotifications}
                                    isTPOpted={this.props.isTPOpted}
                                    isSpouseOpted={this.props.isSpouseOpted}
                                    smsNotificationDetails={this.props.smsNotificationDetails}
                                    updateTPOpted={this.props.updateTPOpted}
                                    states={this.props.states}
                                    nextSignerDetails={this.props.nextSignerDetails}
                                    updateSpouseOpted={this.props.updateSpouseOpted}
                                    updateTaxDocument={this.props.updateTaxDocument}
                                    mobileNumberChanged={this.props.mobileNumberChanged}
                                    markSpouseEmailUpdateEligibility={(clientGuid) => {
                                        this.setState({
                                            canSendSpouseEmailUpdateMail: true,
                                            clientGuidToSendMail: clientGuid
                                        });
                                    }}
                                />
                            </Tab>
                        </Tabs>
                        <Loader loading={this.state.loadingMessage} text={this.state.loadingMessage} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.onPopupClose} data-test-auto="3C093829-5A20-4488-84B7-5F8683CE233C">
                        <i className="fas fa-times"></i> Cancel
                    </Button>
                    {this.props.isDeliveredReturn &&
                    (this.state.isControllerEmailUpdated || this.state.canSendSpouseEmailUpdateMail) ? (
                        <OverlayTrigger
                            rootClose
                            trigger="click"
                            onEnter={() => document.body.click()}
                            placement="top"
                            overlay={popover}
                        >
                            <Button
                                variant="info"
                                onClick={() => {}}
                                disabled={
                                    this.state.loadingMessage
                                        ? true
                                        : false ||
                                          (this.props.model &&
                                              this.props.model.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY)
                                }
                                data-test-auto="F407B6C6-AA03-4B3A-BC21-5CC17D5D0EC8"
                            >
                                <i className="fas fa-save"></i>Save
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <Button
                            variant="info"
                            onClick={this.onConfirm}
                            disabled={
                                this.state.loadingMessage
                                    ? true
                                    : false ||
                                      (this.props.model && this.props.model.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY)
                            }
                            data-test-auto="F407B6C6-AA03-4B3A-BC21-5CC17D5D0EC8"
                        >
                            <i className="fas fa-save"></i>Save
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}
