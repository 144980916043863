import * as React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import * as TaxDocument from "../../../common/TaxReturn";
import { RadioButtonComponent } from "../../../common/RadioButtonComponent";
import { ITaxpayer } from "../../../common/TaxClient";
import {
    phoneNumberDisplay,
    validateSpouseEmail,
    validateTaxPayerEmail,
    isIndividualEnagagementType
} from "../../../helper/HelperFunctions";
import { FinishProcessReturn } from "../../../helper/Constants";
import * as bootbox from "bootbox";
import { EngagementType } from "../../../common/TaxReturn";
import { disableExtensionReminders, IsDuplicateExtension } from "../../../../store/common/ExtensionStore";
import { SendTaxReturnResources } from "../../../helper/ResourceIdConstants";
import * as Helper from "../../../helper/HelperFunctions";

interface IDeliverToClient {
    taxreturn: any;
    cancelFinish: () => void;
    deliverToClient: (resourceID?: string) => void;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    deliverFirstToUpdated: (updated: boolean) => void;
}

interface IDeliverToClientState {
    taxpayerChecked: boolean;
    spouseChecked: boolean;
    sendButtonClicked: boolean;
    popupButtonClicked: boolean;
}

export class DeliverToClient extends React.Component<IDeliverToClient, IDeliverToClientState> {
    constructor(props: IDeliverToClient) {
        super(props);
        this.state = {
            taxpayerChecked: true,
            spouseChecked: false,
            sendButtonClicked: false,
            popupButtonClicked: false
        };
        this.onChangeTaxpayer = this.onChangeTaxpayer.bind(this);
        this.onChangeSpouse = this.onChangeSpouse.bind(this);
    }
    public onChangeTaxpayerType(event: any, taxpayerType: TaxDocument.ClientTypes) {
        this.props.taxreturn.documentSettings.deliverySettings.deliverTo = taxpayerType;
        this.props.updateTaxDocument(this.props.taxreturn);
        this.props.deliverFirstToUpdated(false);
        this.setState({
            taxpayerChecked: taxpayerType == TaxDocument.ClientTypes.Taxpayer ? true : false,
            spouseChecked: taxpayerType == TaxDocument.ClientTypes.Spouse ? true : false
        });
    }
    public onChangeTaxpayer() {
        this.setState({
            taxpayerChecked: true,
            spouseChecked: false
        });
    }
    public onChangeSpouse() {
        this.setState({
            taxpayerChecked: false,
            spouseChecked: true
        });
    }

    deliveryActionButton = (callback: any) => { 
        if (this.state.popupButtonClicked == false) {
            this.setState({
                popupButtonClicked: true
            }, () => { 
                callback();
            })
        }
    }

    private validateDeliverToClient = (): boolean => {
        if (this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Spouse) {
            if (validateSpouseEmail(this.props.taxreturn)) {
                return true;
            }
        } else if (this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Taxpayer) {
            if (validateTaxPayerEmail(this.props.taxreturn)) {
                return true;
            }
        } else {
            return true;
        }
    };
    public onDeliverToClient = () => {
        if (this.validateDeliverToClient() && Helper.validateDocumentLocation(this.props.taxreturn)) {
            let tempThis = this;
            this.setState({ sendButtonClicked: true });
            console.log(this.props.taxreturn);
            let taxIdentifier = isIndividualEnagagementType(this.props.taxreturn.engagementType)
                ? this.props.taxreturn.taxpayer.ssn
                : this.props.taxreturn.partner.ssn;
            IsDuplicateExtension(taxIdentifier, EngagementType[this.props.taxreturn.engagementType], this.props.taxreturn.taxYear)
                .then((isDuplicate) => {
                    if (isDuplicate) {
                        bootbox.dialog({
                            message: FinishProcessReturn.StatusMessage.DisableExtensionRemindersConfirmation.replace(
                                "<<taxyear>>",
                                this.props.taxreturn.taxYear
                            ),
                            className: "extensions-modal",
                            buttons: {
                                cancel: {
                                    label: "No",
                                    className: "btn-cancel btn-default",
                                    callback: function () {
                                        tempThis.deliveryActionButton(() =>
                                        tempThis.props.deliverToClient(
                                            SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToClientMenu
                                        ));
                                    }
                                },
                                confirm: {
                                    label: "Yes, turn it off",
                                    className: "btn-confirm",
                                    callback: function () {
                                        tempThis.deliveryActionButton(() => {
                                            disableExtensionReminders(
                                                taxIdentifier,
                                                EngagementType[tempThis.props.taxreturn.engagementType],
                                                tempThis.props.taxreturn.taxYear,
                                                tempThis.props.taxreturn.id
                                            );
                                            tempThis.props.deliverToClient(
                                                SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToClientMenu
                                            );
                                        });
                                    }
                                }
                            }
                        });
                    } else {
                        this.props.deliverToClient(SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToClientMenu);
                    }
                })
                .catch((error) => {
                    this.props.deliverToClient(SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToClientMenu);
                });
        }
    };

    public render() {
        let deceasedCount = 0;

        let taxpayer: ITaxpayer = {} as ITaxpayer;
        if (TaxDocument.isMutual(this.props.taxreturn)) {
            if (this.props.taxreturn.documentSettings.deliverySettings.deliverTo === TaxDocument.ClientTypes.Spouse) {
                taxpayer = this.props.taxreturn.spouse;
            } else {
                taxpayer = this.props.taxreturn.taxpayer;
            }
            if (this.props.taxreturn.taxpayer.isDeceased) {
                deceasedCount += 1;
            }
            if (this.props.taxreturn.spouse && this.props.taxreturn.spouse.isDeceased) {
                deceasedCount += 1;
            }
        } else if (TaxDocument.isIndividual(this.props.taxreturn)) {
            taxpayer = this.props.taxreturn.taxpayer;
        }
        return (
            <Card>
                <Card.Body>
                    {TaxDocument.isMutual(this.props.taxreturn) ? (
                        <div>
                            <Row className="marB15">
                                <Col sm={6}>
                                    <RadioButtonComponent
                                        text="Taxpayer"
                                        id="taxpayer"
                                        checked={
                                            this.props.taxreturn?.documentSettings?.deliverySettings.deliverTo ===
                                            TaxDocument.ClientTypes.Taxpayer
                                                ? true
                                                : false
                                        }
                                        onClick={(event) => {
                                            this.onChangeTaxpayerType(event, TaxDocument.ClientTypes.Taxpayer);
                                        }}
                                        disabled={deceasedCount != 2 && this.props.taxreturn.taxpayer.isDeceased}
                                        dataTestAuto={"2C32A8F2-43EB-4D6A-AEC5-65D819597059"}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <RadioButtonComponent
                                        text="Spouse"
                                        id="spouse"
                                        checked={
                                            this.props.taxreturn?.documentSettings?.deliverySettings.deliverTo ===
                                            TaxDocument.ClientTypes.Spouse
                                                ? true
                                                : false
                                        }
                                        onClick={(event) => {
                                            this.onChangeTaxpayerType(event, TaxDocument.ClientTypes.Spouse);
                                        }}
                                        disabled={deceasedCount != 2 && this.props.taxreturn.spouse.isDeceased}
                                        dataTestAuto={"85C0D749-1CF3-4AC6-AD31-E2C31297AEB5"}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : TaxDocument.isIndividual(this.props.taxreturn) ? (
                        <div>
                            <Row className="marB15">
                                <Col sm={12}>
                                    <RadioButtonComponent text="Taxpayer" id="taxpayer" checked={true} />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        ""
                    )}
                    <div>
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Name
                            </Col>
                            <Col sm={8} as={Col}>
                                {taxpayer.name}
                            </Col>
                        </Row>
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                SSN
                            </Col>
                            <Col sm={8} as={Col}>
                                {taxpayer.ssn}
                            </Col>
                        </Row>
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Address
                            </Col>
                            <Col sm={8} as={Col}>
                                {taxpayer.address}
                            </Col>
                        </Row>
                        <Row className="marB15">
                            <Col className="text-right" sm={4} as={Col}>
                                Email
                            </Col>
                            <Col sm={8} as={Col}>
                                {taxpayer.email}
                            </Col>
                        </Row>
                        {taxpayer.mobileNumber && (
                            <Row className="marB15">
                                <Col className="text-right" sm={4} as={Col}>
                                    Mobile Number
                                </Col>
                                <Col sm={8} as={Col}>
                                    {taxpayer.countryCode} {phoneNumberDisplay(taxpayer.mobileNumber)}
                                </Col>
                            </Row>
                        )}
                    </div>

                    <div>
                        <Col sm={12}>
                            <Button
                                type="button"
                                variant="info"
                                className="float-right"
                                data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33883"
                                onClick={this.onDeliverToClient}
                                disabled={this.state.sendButtonClicked}
                            >
                                <i className="fa fa-paper-plane"></i>Send
                            </Button>
                            <Button
                                type="button"
                                variant="default"
                                className="float-right marR05 btn-white btn-default"
                                data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5002"
                                onClick={this.props.cancelFinish}
                            >
                                <i className="fas fa-times"></i>Cancel
                            </Button>
                        </Col>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

export default DeliverToClient;
