import React from "react";

const TickIcon: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 16 12">
            <path
                fill="#0973BA"
                d="M15.294 1.251a1.26 1.26 0 00-1.769.003L6.504 8.307 3.167 4.814a1.25 1.25 0 10-1.828 1.702l4.172 4.485a1.258 1.258 0 001.806.023l7.98-8.004a1.25 1.25 0 00-.003-1.769z"
            ></path>
        </svg>
    );
};

export default TickIcon;
