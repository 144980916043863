import * as React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { GetFormatedDateTime, insertWhiteSpace, capitaliseString, capitaliseOnlyFirstLetter } from "../../helper/HelperFunctions";
import { OverlayLoader } from "../../helper/OverlayLoader";
import { ClientTrackingEvents, DocumentEventValue, VoucherPaymentConstants, ConsentTrackingEvents } from "../../helper/Constants";
import { ITaxReturn, MailEvent, DocumentEvent, ClientTypesNumber } from "../../common/TaxReturn";

interface IBaseClientTrackingReportProps {
    taxReturn: ITaxReturn;
    show: boolean;
}

export class BaseClientTrackingReport extends React.Component<IBaseClientTrackingReportProps, {}> {
    public render() {
        this.state = {
            loading: false
        };

        const columns = [
            {
                header: "Events",
                key: "eventId",
                isKey: true,
                dataFormat: this.eventIdType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "200px"
            },
            {
                header: "By",
                key: "actedBy",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "125px"
            },
            {
                header: "Event Date",
                key: "actedOn",
                isKey: false,
                dataFormat: this.actedOnType,
                columnClassName: "overflowTextClientTracking",
                dataSort: true,
                toolTip: true,
                hidden: false,
                width: "75px"
            },
            {
                header: "Event Data",
                key: "eventData",
                isKey: false,
                hidden: true,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: false,
                toolTip: false,
                width: "75px"
            }
        ];

        const options = {
            sizePerPage: 20,
            paginationShowsTotal: this.createTotalPages
        };
        const data =
            this.props.taxReturn && this.props.taxReturn.clientTracking
                ? this.props.taxReturn.clientTracking
                      .map((model, index) => {
                          return {
                              eventId: model.eventId,
                              actedBy: model.actedBy && model.actedBy.firstName + " " + model.actedBy.lastName,
                              actedOn: model.actedOn,
                              eventData: model.eventData
                          };
                      })
                      .filter((event) => event.eventId != DocumentEvent.Filed && event.eventId != DocumentEvent.SendForSignature)
                : [];

        let modalBody = data ? (
            <BootstrapTable data={data} options={options} striped pagination={true}>
                {columns.map((value, index) => {
                    return (
                        <TableHeaderColumn
                            key={index}
                            width={value.width}
                            hidden={value.hidden}
                            isKey={value.isKey}
                            dataField={value.key}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            dataSort={true}
                            columnTitle={value.toolTip}
                        >
                            {" "}
                            {value.header}
                        </TableHeaderColumn>
                    );
                })}
            </BootstrapTable>
        ) : (
            <OverlayLoader show={this.props.show} text={"Loading, please wait..."} />
        );

        return <div>{modalBody}</div>;
    }

    defaultType(cell: any, row: any) {
        return cell;
    }

    actedOnType(cell: any, row: any) {
        return GetFormatedDateTime(cell);
    }

    eventResponseType(cell: any, row: any) {
        return MailEvent[cell.mailEventId];
    }

    createTotalPages(start: number, to: number, total: number) {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    actedByType(cell: any, row: any) {
        return cell.firstName + " " + cell.lastName;
    }

    eventIdType(cell: any, row: any) {
        let eventName = "";
        let mailEvent = row.eventData.mailEventId;
        switch (cell) {
            case DocumentEvent.DocumentsForwardedToCCRecipient:
            case DocumentEvent.K1DistributedTo:
                eventName = DocumentEventValue[cell] + " " + insertWhiteSpace(row.eventData.recipientInfo.firstName);
                break;
            case DocumentEvent.ReturnIsRecalled:
            case DocumentEvent.NewTaxReturnUploaded:
            case DocumentEvent.TaxReturnReprocessed:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.FileUploaded:
            case DocumentEvent.AttachmentDownloaded:
                eventName = DocumentEventValue[cell] + " (" + row.eventData.fileName + ")";
                break;
            case DocumentEvent.DocumentMailStatus:
                eventName = ClientTrackingEvents.InitialEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.VoucherRemainderMailStatus:
                eventName = ClientTrackingEvents.VoucherReminderEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.SigningReminderMailStatus:
                eventName = ClientTrackingEvents.SigningReminderEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.AccessCodeEmail:
                eventName = ClientTrackingEvents.AccessCodeEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.AccessLinkEmailed:
                eventName = DocumentEventValue[cell] + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.DocumentKBASuccess:
                if (row.eventData.clientType === ClientTypesNumber.Taxpayer) {
                    eventName = ClientTrackingEvents.TaxPayerKBASuccess;
                } else if (row.eventData.clientType === ClientTypesNumber.Spouse) {
                    eventName = ClientTrackingEvents.SpouseKBASuccess;
                } else if (row.eventData.clientType === ClientTypesNumber.OneHub) {
                    if (row.eventData.actualClientType === ClientTypesNumber.Taxpayer) {
                        eventName = ClientTrackingEvents.TaxPayerKBASuccess;
                    } else if (row.eventData.actualClientType === ClientTypesNumber.Spouse) {
                        eventName = ClientTrackingEvents.SpouseKBASuccess;
                    }
                } else {
                    eventName = ClientTrackingEvents.DelegateeKBASuccess;
                }
                break;
            case DocumentEvent.DocumentKBAFailed:
                if (row.eventData.clientType === ClientTypesNumber.Taxpayer) {
                    eventName = ClientTrackingEvents.TaxPayerKBAFailed;
                } else if (row.eventData.clientType === ClientTypesNumber.Spouse) {
                    eventName = ClientTrackingEvents.SpouseKBAFailed;
                } else if (row.eventData.clientType === ClientTypesNumber.OneHub) {
                    if (row.eventData.actualClientType === ClientTypesNumber.Taxpayer) {
                        eventName = ClientTrackingEvents.TaxPayerKBAFailed;
                    } else if (row.eventData.actualClientType === ClientTypesNumber.Spouse) {
                        eventName = ClientTrackingEvents.SpouseKBAFailed;
                    }
                } else {
                    eventName = ClientTrackingEvents.DelegateeKBAFailed;
                }
                break;
            case DocumentEvent.ResendAccessLink:
                eventName = DocumentEventValue[cell] + " (" + row.eventData.resentAccessLinkTo + ")";
                break;
            case DocumentEvent.RestoreReturn:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.ControllerEsigned:
            case DocumentEvent.ControllerSignedAdditionalDocument:
                eventName = DocumentEventValue[cell]
                    .replace("<Taxpayer>", row.eventData.recipientInfo.firstName)
                    .replace("<TaxpayerEmail>", row.eventData.recipientInfo.emailAddress);
                break;
            case DocumentEvent.PaymentDetailsAdded:
                eventName =
                    DocumentEventValue[cell] +
                    " " +
                    row.eventData.fileName +
                    " " +
                    VoucherPaymentConstants[row.eventData.voucherNo[0]];
                break;
            case DocumentEvent.PaymentDetailsUpdated:
                eventName =
                    DocumentEventValue[cell] +
                    " " +
                    row.eventData.fileName +
                    " " +
                    VoucherPaymentConstants[row.eventData.voucherNo[0]];
                break;
            case DocumentEvent.StatusChangeToSignedAndEsigned:
                eventName = ClientTrackingEvents.StatusChangeToSignedAndEsigned + row.eventData.remarks;
                break;
            case DocumentEvent.InvoicePaymentDetailAdded:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.InvoicePaymentDetailUpdated:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.InvoicePaymentDetailDeleted:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.PaperFileAllDownloaded:
            case DocumentEvent.PaperFileAllPrinted:
                eventName = DocumentEventValue[cell] + " (" + row.eventData.fileName.substring(0, 4) + ")";
                break;
            case DocumentEvent.PaperFileDownloaded:
            case DocumentEvent.PaperFilePrinted:
                eventName =
                    DocumentEventValue[cell] +
                    " (" +
                    row.eventData.fileName.replace(
                        row.eventData.fileName.substring(
                            row.eventData.fileName.lastIndexOf("."),
                            row.eventData.fileName.length
                        ) || row.eventData.fileName,
                        ""
                    ) +
                    ")";
                break;
            case DocumentEvent.InitialRevisionMail:
                eventName = ClientTrackingEvents.RevisionInitialEmail + capitaliseString(MailEvent[row.eventData.mailEventId]);
                break;
            case DocumentEvent.SigningFailed:
                eventName = DocumentEventValue[cell];
                break;
            case DocumentEvent.SendForSignature:
                eventName = DocumentEventValue[cell];
                break;

            case DocumentEvent.TaxpayerConsentAccepted:
            case DocumentEvent.SpouseConsentAccepted:
                eventName = `${ConsentTrackingEvents.ConsentReceived} -> ${row.eventData.recipientInfo.emailAddress} -> ${row.eventData.recipientInfo.mobileNumber}`;
                break;

            case DocumentEvent.TaxPayerConsentDeclined:
            case DocumentEvent.SpouseConsentDeclined:
                eventName = `${ConsentTrackingEvents.ConsentDeclined} -> ${row.eventData.recipientInfo.emailAddress} -> ${row.eventData.recipientInfo.mobileNumber}`;
                break;

            case DocumentEvent.TaxPayerConsentExpired:
            case DocumentEvent.SpouseConsentExpired:
                eventName = `${ConsentTrackingEvents.ConsentExpired} -> ${row.eventData.recipientInfo.emailAddress} -> ${row.eventData.recipientInfo.mobileNumber}`;
                break;

            case DocumentEvent.TaxPayerOptedInSMSNotification:
            case DocumentEvent.SpouseOptedInSMSNotification:
                eventName = `${ConsentTrackingEvents.OptedInSMSNotification} -> ${row.eventData.recipientInfo.emailAddress} -> ${row.eventData.recipientInfo.mobileNumber}`;
                break;

            case DocumentEvent.TaxPayerOptedOutSMSNotification:
            case DocumentEvent.SpouseOptedOutSMSNotification:
                eventName = `${ConsentTrackingEvents.OptedOutSMSNotification} -> ${row.eventData.recipientInfo.emailAddress} -> ${row.eventData.recipientInfo.mobileNumber}`;
                break;

            case DocumentEvent.SigningReminderSMSStatus:
            case DocumentEvent.SigningCompletionSMSStatus:
                eventName = `${DocumentEventValue[cell]} ${capitaliseOnlyFirstLetter(MailEvent[row.eventData.mailEventId])} -> ${
                    row.eventData.recipientInfo.emailAddress
                } -> ${row.eventData.recipientInfo.mobileNumber}`;
                break;

            default:
                eventName =
                    DocumentEventValue[cell] +
                    (mailEvent == 2 || mailEvent == 3 || mailEvent == 4 ? " - " + MailEvent[row.eventData.mailEventId] : "");
                break;
        }

        if (
            !DocumentEvent.TaxpayerConsentAccepted &&
            !DocumentEvent.TaxPayerConsentDeclined &&
            !DocumentEvent.TaxPayerOptedOutSMSNotification &&
            !DocumentEvent.TaxPayerOptedInSMSNotification &&
            !DocumentEvent.TaxPayerConsentExpired &&
            !DocumentEvent.SpouseConsentAccepted &&
            !DocumentEvent.SpouseConsentDeclined &&
            !DocumentEvent.SpouseOptedOutSMSNotification &&
            !DocumentEvent.SpouseOptedInSMSNotification &&
            !DocumentEvent.SpouseConsentExpired &&
            !DocumentEvent.SigningReminderSMSStatus &&
            !DocumentEvent.SigningCompletionSMSStatus
        ) {
            eventName = row.eventData.recipientInfo?.emailAddress
                ? eventName + " > " + row.eventData.recipientInfo?.emailAddress
                : eventName;
        }

        switch (row.eventData.clientType) {
            case ClientTypesNumber.Taxpayer:
                return ClientTypesNumber[ClientTypesNumber.Taxpayer] + ": " + eventName;
            case ClientTypesNumber.Spouse:
                return ClientTypesNumber[ClientTypesNumber.Spouse] + ": " + eventName;
            case ClientTypesNumber.CcRecipient:
                return ClientTypesNumber[ClientTypesNumber.CcRecipient] + ": " + eventName;
            case ClientTypesNumber.Controller:
                return ClientTypesNumber[ClientTypesNumber.Controller] + ": " + eventName;
            case ClientTypesNumber.Delegatee:
                return ClientTypesNumber[ClientTypesNumber.Delegatee] + ": " + eventName;
            case ClientTypesNumber.Partner:
                return ClientTypesNumber[ClientTypesNumber.Partner] + ": " + eventName;
            case ClientTypesNumber.Partnership:
                return ClientTypesNumber[ClientTypesNumber.Partnership] + ": " + eventName;
            case ClientTypesNumber.ShareHolder:
                return ClientTypesNumber[ClientTypesNumber.ShareHolder] + ": " + eventName;
            case ClientTypesNumber.OneHub:
                return "Client Portal" + ": " + eventName;
            default:
                return eventName;
        }
    }
}
