import * as React from 'react';
import { Modal } from 'react-bootstrap';
import  TinyMCEComponent  from './../TinyMCE';
import { Link } from 'react-router-dom';
import * as  Bootstrap from 'react-bootstrap';
import { MessageDetails, initialMessageDetails } from '../../../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { SavedMessageConstants, ClientInstructionConstants, CommonMessages } from '../../../../components/helper/Constants';
import * as  PaperFiledMessageStore from '../../../../store/settings/PaperFiledMessageStore';
import { CheckBoxComponent } from '../../../common/CheckBoxComponent';
import { ReplaceHelperVariableForEditor, getTextLengthInByte, fetchHelpText } from '../../../helper/HelperFunctions';
import { VariableList } from '../../ClientInstructions/VariableList';
import { IHelptextModel } from '../../../../Core/ViewModels/Common/IHelptextModel';
import { validateName } from '../../../../components/helper/Validations';
var htmlencode = require('htmlencode');

interface IAddMessageProps {
    paperFiledMessages: PaperFiledMessageStore.IPaperFiledMessageDictionary;
    selectedMessage: MessageDetails;
    show: boolean;
    onHide: () => void;
    submitButtonClick: (message: MessageDetails, isSetDefault: boolean) => void;
    buttonText: string;
    title: string;
    defaultMessageId: number;
    isUpdate: boolean;
    helptexts?: IHelptextModel[];
}

interface IAddMessageState {
    paperFiledMessage: MessageDetails;
    alert: string;
    message: string;
    isSetDefault: boolean;
    showAlert: boolean;
    helptextName: string;
    helptextBody: string;
}

export class PaperFiledPopUp extends React.Component<IAddMessageProps, IAddMessageState> {
    state = {
        paperFiledMessage: initialMessageDetails,
        alert: "warning",
        message: "",
        isSetDefault: false,
        showAlert: true,
        helptextName: "",
        helptextBody: ""
    }

    UNSAFE_componentWillReceiveProps(props: IAddMessageProps) {
        this.setState({
            paperFiledMessage: Object.assign({}, props.selectedMessage)
        })
        this.setState({
            alert: "warning",
            message: "",
            isSetDefault: props.defaultMessageId != 0 && props.defaultMessageId == props.selectedMessage?.id,
            showAlert: true,
            helptextName: fetchHelpText(props.helptexts, "E9CB2316-2E51-4E6C-9CAF-90CA31633CED"),
            helptextBody: fetchHelpText(props.helptexts, "731D0EF2-9595-4EB2-9A00-9B209AB041E5")
        })
    }

    private checkDuplicateExistance = (paperFiledMessage: MessageDetails) => {
        let count: number = 0;
        Object.keys(this.props.paperFiledMessages).map((key, index) => {
            if (this.props.paperFiledMessages[parseInt(key)].messageDetails.name.trim().toLowerCase() == paperFiledMessage.name.trim().toLowerCase() && this.props.paperFiledMessages[parseInt(key)].messageDetails.id != paperFiledMessage.id)
                count++;
        })
        return count;
    }

    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            paperFiledMessage: initialMessageDetails,
            alert: "warning",
            message: "",
            isSetDefault: false,
            showAlert: true
        })
    }

    private onAddButtonClick = () => {
        if (validateName(this.state.paperFiledMessage.name)) {
            this.setState({ alert: "danger", message: CommonMessages.InvalidCharacters, showAlert: false })
            return false;
        }

        if (this.state.paperFiledMessage.name.trim() == "") {
            this.setState({ alert: "danger", message: SavedMessageConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.paperFiledMessage.body == "" || this.state.paperFiledMessage.body.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            this.setState({ alert: "danger", message: SavedMessageConstants.ValidateBody, showAlert: false })
            return false;
        }

        if (this.checkDuplicateExistance(this.state.paperFiledMessage) > 0) {
            this.setState({ alert: "danger", message: SavedMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }

        var byteLength = getTextLengthInByte(this.state.paperFiledMessage.body);
        if (byteLength > ClientInstructionConstants.MaximumTextSize) {
            this.setState({ alert: "danger", message: ClientInstructionConstants.TextSizeExceedsLimit, showAlert: false })
            return false;
        }
        console.log("Message:" + JSON.stringify(this.state.paperFiledMessage));
        this.props.submitButtonClick(this.state.paperFiledMessage, this.state.isSetDefault)
        this.setState({ paperFiledMessage: initialMessageDetails })
    }

    private onPaperFiledMessageNameChange = (event: any) => {
        let message = this.state.paperFiledMessage
        message.name = event.target.value
        this.setState({ paperFiledMessage: message })
    }

    private onPaperFiledMessageBodyChange = (value: any) => {
        let message = this.state.paperFiledMessage
        message.body = value
        this.setState({ paperFiledMessage: message })
    }

    private onCheckboxChange = (event: any) => {
        let message = this.state.paperFiledMessage
        message.isAllowToEdit = event.target.checked
        this.setState({ paperFiledMessage: message })
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: SavedMessageConstants.SetDefaultMessageWarning, showAlert: false })
        }
        else {
            this.setState({ alert: "warning", message: SavedMessageConstants.RemoveDefaultMessageWarning, showAlert: false })
        }
        this.setState({
            isSetDefault: isTrue,
        })
    }

    public render() {
        return (
            <div style={{ position: 'relative' }}>
                <Modal className='add-edit-message' show={this.props.show} onHide={this.onCancelButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Bootstrap.Alert variant={this.state.alert} hidden={this.state.showAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.message}</Bootstrap.Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title={this.state.helptextName}>
                                <i className="fas fa-question-circle"></i>
                            </Link>
                            <div className="float-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={this.state.isSetDefault}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="A72EA5D2-E0E7-40A8-814E-668D871D2C1F"
                                className="form-control"
                                placeholder="Message Name"
                                value={this.state.paperFiledMessage.name}
                                onChange={this.onPaperFiledMessageNameChange}
                                data-lpignore="true">
                            </input>
                        </div>

                        <div className="form-group">
                            <label>Body</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title={this.state.helptextBody}>
                                <i className="fas fa-question-circle"></i>
                            </Link>
                            <TinyMCEComponent
                                messageBody={ReplaceHelperVariableForEditor(htmlencode.htmlDecode(this.state.paperFiledMessage.body))}
                                changeStateTinymceBody={this.onPaperFiledMessageBodyChange}
                                readOnly={0}
                                placeholderText={CommonMessages.PlaceholderText}
                            />
                        </div>
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"allowUserToEdit"}
                                text={"Allow users to edit before sending"}
                                checked={this.state.paperFiledMessage.isAllowToEdit}
                                onChange={this.onCheckboxChange}
                            />
                        </div>
                        <VariableList />
                    </Modal.Body>
                    <Modal.Footer>
                        <Bootstrap.Button
                            variant="default"
                            data-test-auto="AA64499F-AFE3-4C43-A5C0-5F83FCB04F9D"
                            onClick={this.onCancelButtonClick}>
                            <i className='fas fa-times'></i>Cancel
                                </Bootstrap.Button>
                        <Bootstrap.Button
                            variant="info"
                            data-test-auto="177F2651-3DBF-4770-9267-ABC1D82D4527"
                            onClick={this.onAddButtonClick}>
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Bootstrap.Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}