import * as React from 'react';
import { BootstrapTable, TableHeaderColumn, ToolBarProps } from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import * as bootbox from 'bootbox';
import { ITaxReturn, DocumentStatus, IOfficeLocation } from '../common/TaxReturn';
import { Filters, IFilters, ReportFilterType } from '../reports/Filters';
import * as Helper from '../helper/HelperFunctions';
import { VenusNotifier } from '../helper/VenusNotifier';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from '../helper/Constants';
import { Button } from 'react-bootstrap';
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import { SendTaxReturnResources } from '../helper/ResourceIdConstants';


interface TableData {
    key: string;
    data: ColumnValues[];
    column: ColumnNames[];
    pagination: boolean;
    selectRow: any;
    search: boolean;
    onPageChange: any;
    onSortChange: any;
    onFilterChange: any;
    pageNo: number;
    totalRows: number;
    tableAutomationId: string;
    pageSize: number;
    isLoading: boolean;
    models: ITaxReturn[];
    onProcessReturnOpen: (rowIndex: number, resourceId?: string) => void;
    onEditClientInfoOpen: (rowIndex: number, resourceId?: string) => void;
    onSetAccessOpen: (rowIndex: number, resourceId?: string) => void;
    onChangeStatusOpen: (rowIndex: number, resourceId?: string) => void;
    onReportProblemOpen: (rowIndex: number, resourceId?: string) => void;
    onDeleteTaxReturnOpen: (rowIndex: number, resourceId?: string) => void;
    onRecallReturnOpen: (rowIndex: number, resourceId?: string) => void;
    onClientTrackingOpen: (rowIndex: number, resourceId?: string) => void;
    onRowDoubleClick: (rowIndex: number, resourceId?: string, editable?: boolean) => void;


    onFilterNameChange(event: any): void;
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onFilterUpdate(filterName: string): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    filterList: IFilters[],
    currentFilter: IFilters,
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    defaultFilter: string | undefined;
    onSaveFilterShow(): void;
    onSaveFilterHide(): void;
    proxyFilter: string;
    filterType: ReportFilterType;
    onExportToExcel(onExportToComplete: () => void, resourceId: string): void;
    saveFilterShow: boolean;
    loadGrid(): void;
    customMultiSelect: any,
    customEngagementTypeFilter: any,
    customOfficeSelect: any,
    customGroupNameFilter: any,
    id: string,
    refreshDelay?: boolean;
    onPageReload: () => void;
    loadSpinner?: boolean;
    profile: IUserProfile;
    checkAssignmentProperty?: (documentId: Number, currentAssign: boolean, rowIndex: any, callBack: any) => void,
}

export interface ColumnValues {
    taxDocumentName: string,
    clientId: string,
    partner: string,
    documentStatus: DocumentStatus,
    locationName : string,
    uploadedOn: Date,
    engagementType: string,
    taxYear: number
    button: string,
    index: number,
    assignToUserName: string,
    assignTo: number,
    rowIndex: number
}

interface ColumnNames {
    header: string;
    key: string;
    isKey: boolean;
    dataFormat: any;
    columnClassName: string
    dataSort: boolean,
    toolTip: boolean,
    isHidden: boolean,
    width?: string;
    filter: any
}

interface IAssignmentStates {
    isHiddenExportExcel: boolean;
    hideLink: boolean;
}

const isEqual = require("react-fast-compare");
export class AssignmentTable extends React.Component<TableData, IAssignmentStates> {
    private filterChanged: boolean = false;
    public isAppiedFilter: boolean = false;

    constructor(props: TableData) {
        super(props);

        this.state = {
            hideLink: false,
            isHiddenExportExcel: true
        }
        this.onClearFilter = this.onClearFilter.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onLoadSelectedFilter = this.onLoadSelectedFilter.bind(this);
        this.onFilterSaveClick = this.onFilterSaveClick.bind(this);


    }

    renderShowsTotal(start: number, to: number, total: number) {
        return <p>
            Showing <b>{start}</b> to <b>{to}</b> of <b>{total}</b> entries
        </p>
    }

    private setNoContent() {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No assignments found"
        }
    }
    public onClearFilter = (clearAll?: boolean) => {
        this.filterChanged = true;
        (this.refs.taxDocumentName as TableHeaderColumn)?.cleanFiltered();
        (this.refs.clientId as TableHeaderColumn)?.cleanFiltered();
        (this.refs.partner as TableHeaderColumn)?.cleanFiltered();
        this.props.customOfficeSelect?.cleanFiltered(clearAll);
        this.props.customGroupNameFilter?.cleanFiltered && this.props.customGroupNameFilter?.cleanFiltered(clearAll);
        (this.refs.assignToUserName as TableHeaderColumn)?.cleanFiltered();
        this.props.customMultiSelect?.cleanFiltered(clearAll);//Documentstatus
        this.props.customEngagementTypeFilter?.cleanFiltered(clearAll);
        (this.refs.uploadedOn as TableHeaderColumn)?.cleanFiltered();
        (this.refs.engagementType as TableHeaderColumn)?.cleanFiltered();
        (this.refs.taxYear as TableHeaderColumn)?.cleanFiltered();
        this.filterChanged = false;
    }

    shouldComponentUpdate(nextProps: TableData, nextState: IAssignmentStates) {
        return (!isEqual(this.props.data, nextProps.data) || (this.props.isLoading !== nextProps.isLoading) ||
            !(this.props.selectRow === nextProps.selectRow));
    }
    private onFilterSaveClick(filterName: string) {
        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }

        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i>' + DeliveredReturnsTableConstants.FormLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i>' + DeliveredReturnsTableConstants.FormLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                }
            });
        }
    }
    private onFilterChange(dataField: any) {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }
    public onLoadSelectedFilter(filter?: IFilters) {
        this.isAppiedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;

            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxDocumentName':
                        (this.refs.taxDocumentName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'partner':
                        (this.refs.partner as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'groupName':
                        (this.props.customGroupNameFilter as TableHeaderColumn)?.applyFilter && (this.props.customGroupNameFilter as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'locationName':
                        (this.props.customOfficeSelect as TableHeaderColumn)?.applyFilter(filter.fields[field]);                       
                        break;
                    case 'assignToUserName':
                        (this.refs.assignToUserName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'documentStatus':
                        (this.props.customMultiSelect as TableHeaderColumn)?.applyFilter(filter.fields[field]);//Multi select Documentstatus                         
                        break;
                    case 'uploadedOn':
                        (this.refs.uploadedOn as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'engagementType':
                        (this.props.customEngagementTypeFilter as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        let taxYear: any = filter.fields[field];
                        if (taxYear)
                            (this.refs.taxYear as TableHeaderColumn)?.applyFilter(taxYear);
                        break;
                }
                this.filterChanged = false;
            }

            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
            (this.refs.table as BootstrapTable)?.handleSearch(filter.searchText);
        }
    }
    private createCustomToolBar = () => {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: 33, position: 'unset' }}>
                <Filters ref='Filters'
                    parentResourceIdentifier={SendTaxReturnResources.HeaderButtonPrefix}
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showExportExcel={this.props.id === 'company' ? false : true}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                    loadSpinner={this.props.loadSpinner}
                    totalRows={this.props.totalRows}
                />
            </div>
        );
    }


    public render() {
        let pagingationPos: "both" | "top" | "bottom" | undefined = "bottom";
        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick:
                (row: any) => {
                    this.props.onRowDoubleClick(row.rowIndex, SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridDoubleClickProcessButton);
                },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            paginationPosition: pagingationPos, // default is bottom, top and both is all available
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />,
            clearSearch: true,
            toolBar: this.createCustomToolBar,
        };

        return <div className="pos-relative">

            <div className='assignment-table' data-test-auto={this.props.tableAutomationId}>
                <BootstrapTable
                    ref='table'
                    data={this.props.data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={this.props.pagination}
                    selectRow={this.props.selectRow}
                >
                    {this.props.column.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                        >
                            {
                                (value.width === 'auto') ?
                                    <span title={value.header} className="table-text-sort">{value.header}</span> :
                                    value.header
                            }
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        </div >
    }
}
export default AssignmentTable;
