import * as React from 'react';
import { Modal } from 'react-bootstrap';
import  TinyMCEComponent  from './../TinyMCE';
import { Link } from 'react-router-dom';
import * as  Bootstrap from 'react-bootstrap';
import { IAttachmentInstruction, initialAttachmentInstruction } from '../../../../Core/ViewModels/CompanySettings/ClientInstructionViewModel';
import { AttachmentInstructionsConstants, ClientInstructionConstants, CommonMessages } from '../../../../components/helper/Constants';
import * as  AttachmentInstructionStore from '../../../../store/settings/AttachmentInstructionStore';
import { CheckBoxComponent } from '../../../common/CheckBoxComponent';
import { ReplaceHelperVariableForEditor, getTextLengthInByte, fetchHelpText } from '../../../helper/HelperFunctions';
import { VariableList } from '../../ClientInstructions/VariableList';
import { IHelptextModel } from '../../../../Core/ViewModels/Common/IHelptextModel';
import { validateName } from '../../../../components/helper/Validations';
var htmlencode = require('htmlencode');

interface IAddMessageProps {
    attachmentInstructions: AttachmentInstructionStore.IAttachmentInstructionDictionary;
    selectedInstruction: IAttachmentInstruction;
    show: boolean;
    onHide: () => void;
    submitButtonClick: (attachmentInstruction: IAttachmentInstruction, isSetDefault: boolean) => void;
    buttonText: string;
    title: string;
    defaultInstructionId: number;
    isUpdate: boolean;
    helptexts?: IHelptextModel[];
}

interface IAddMessageState {
    attachmentInstruction: IAttachmentInstruction;
    alert: string;
    message: string;
    isSetDefault: boolean;
    showAlert: boolean;
    helptextName: string;
    helptextBody: string;
}

export class AttachmentInstructionPopUp extends React.Component<IAddMessageProps, IAddMessageState> {
    state = {
        attachmentInstruction: initialAttachmentInstruction,
        alert: "warning",
        message: "",
        isSetDefault: false,
        showAlert: true,
        helptextName: "",
        helptextBody:""
    }

    UNSAFE_componentWillReceiveProps(props: IAddMessageProps) {
        this.setState({
            attachmentInstruction: Object.assign({}, props.selectedInstruction)
        })
        this.setState({
            alert: "warning",
            message: "",
            isSetDefault: props.defaultInstructionId != 0 && props.defaultInstructionId == props.selectedInstruction?.id,
            showAlert: true,
            helptextName: fetchHelpText(props.helptexts, "E9CB2316-2E51-4E6C-9CAF-90CA31633CED"),
            helptextBody: fetchHelpText(props.helptexts,"731D0EF2-9595-4EB2-9A00-9B209AB041E5")
        })
    }

    private checkDuplicateExistance = (attachmentInstruction: IAttachmentInstruction) => {
        let count: number = 0;
        Object.keys(this.props.attachmentInstructions).map((key, index) => {
            if (this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.name.trim().toLowerCase() == attachmentInstruction.name.trim().toLowerCase() && this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.id != attachmentInstruction.id)
                count++;
        })
        return count;
    }

    private onCancelButtonClick = () => {
        this.props.onHide()
        this.setState({
            attachmentInstruction: initialAttachmentInstruction,
            alert: "warning",
            message: "",
            isSetDefault: false,
            showAlert: true
        })
    }

    private onAddButtonClick = () => {
        if (validateName(this.state.attachmentInstruction.name)) {
            this.setState({ alert: "danger", message: CommonMessages.InvalidCharacters, showAlert: false })
            return false;
        }

        if (this.state.attachmentInstruction.name.trim() == "") {
            this.setState({ alert: "danger", message: AttachmentInstructionsConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.attachmentInstruction.text == "" || this.state.attachmentInstruction.text.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            this.setState({ alert: "danger", message: AttachmentInstructionsConstants.ValidateText, showAlert: false })
            return false;
        }

        if (this.checkDuplicateExistance(this.state.attachmentInstruction) > 0) {
            this.setState({ alert: "danger", message: AttachmentInstructionsConstants.NameError, showAlert: false })
            return false;
        }

        var byteLength = getTextLengthInByte(this.state.attachmentInstruction.text);
        if (byteLength > ClientInstructionConstants.MaximumTextSize) {
            this.setState({ alert: "danger", message: ClientInstructionConstants.TextSizeExceedsLimit, showAlert: false })
            return false;
        }

        this.props.submitButtonClick(this.state.attachmentInstruction, this.state.isSetDefault)
        this.setState({ attachmentInstruction: initialAttachmentInstruction })
    }

    private onAttachmentInstructionNameChange = (event: any) => {
        let attachmentInstruction = this.state.attachmentInstruction
        attachmentInstruction.name = event.target.value
        this.setState({ attachmentInstruction: attachmentInstruction })
    }

    private onAttachmentInstructionBodyChange = (value: any) => {
        let attachmentInstruction = this.state.attachmentInstruction
        attachmentInstruction.text = value
        this.setState({ attachmentInstruction: attachmentInstruction })
    }

    private onCheckboxChange = (event: any) => {
        let attachmentInstruction = this.state.attachmentInstruction
        attachmentInstruction.isEditable = event.target.checked
        this.setState({ attachmentInstruction: attachmentInstruction })
    }

    private onSetDefaultClick = (isTrue: boolean) => {
        if (isTrue == true) {
            this.setState({ alert: "warning", message: AttachmentInstructionsConstants.SetDefaultWarning, showAlert: false })
        }
        else {
            this.setState({ alert: "warning", message: AttachmentInstructionsConstants.RemoveDefaultWarning, showAlert: false })
        }
        this.setState({
            isSetDefault: isTrue,
        })
    }

    public render() {
        return (
            <div style={{ position: 'relative' }}>
                <Modal className='add-edit-message' show={this.props.show} onHide={this.onCancelButtonClick}>
                    <Modal.Header closeButton>
                        <Modal.Title className='custom-modal-header'>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{this.props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Bootstrap.Alert variant={this.state.alert} hidden={this.state.showAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.message}</Bootstrap.Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title={this.state.helptextName}>
                                <i className="fas fa-question-circle"></i>
                            </Link>
                            <div className="float-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={this.state.isSetDefault}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="23566D10-E795-45EB-BBDD-73842676A88C"
                                className="form-control"
                                placeholder="Message Name"
                                value={this.state.attachmentInstruction.name}
                                onChange={this.onAttachmentInstructionNameChange}
                                data-lpignore="true">
                            </input>
                        </div>

                        <div className="form-group">
                            <label>Body</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title={this.state.helptextBody}>
                                <i className="fas fa-question-circle"></i>
                            </Link>
                            <TinyMCEComponent
                                messageBody={ReplaceHelperVariableForEditor(htmlencode.htmlDecode(this.state.attachmentInstruction.text))}
                                changeStateTinymceBody={this.onAttachmentInstructionBodyChange}
                                readOnly={0}
                                placeholderText={CommonMessages.PlaceholderText}
                            />
                        </div>
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"allowUserToEdit"}
                                text={"Allow users to edit before sending"}
                                checked={this.state.attachmentInstruction.isEditable}
                                onChange={this.onCheckboxChange}
                            />
                        </div>
                        <VariableList />
                    </Modal.Body>
                    <Modal.Footer>
                        <Bootstrap.Button
                            variant="default"
                            data-test-auto="80FA18FD-ED80-4D83-94CC-4FE05B57661B"
                            onClick={this.onCancelButtonClick}>
                            <i className='fas fa-times'></i>Cancel
                                </Bootstrap.Button>
                        <Bootstrap.Button
                            variant="info"
                            data-test-auto="2E2752F0-5331-4641-A105-DF12DDEBD4F9"
                            onClick={this.onAddButtonClick}>
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Bootstrap.Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}