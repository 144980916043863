import * as React from "react";
import { Col, FormLabel, FormControl, FormGroup, Card, Accordion } from "react-bootstrap";
import Select from "react-select";
import { ClientInfoTab, CompanySettingsConstants } from "../../../helper/Constants";
import { CheckIfStringHasValue, FilterSSN, handleInvalidCountryCodeEntered } from "../../../helper/HelperFunctions";
import { isDigit } from "../../../helper/Validations";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import { ClientInfoProperty } from "../../ClientInfoEnum";
import { ITaxpayer } from "../../TaxClient";
import { ClientTypes, IClientInfoViewModel, IPrefilledClientInfo } from "../../TaxReturn";
import { CustomDatePicker } from "../../controls/CustomDatePicker";
import { ClientInfoRightSideCardType } from "../Tabs/TabClientInfo";
import { ICountryState } from "../../../../Core/ViewModels/Company/CompanyViewModel";
import { TaxIdentificationNumber } from "../../../common/controls/TaxIdentificationNumber";
import { CheckBoxComponent } from "../../CheckBoxComponent";
import { isEqual } from "lodash";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, { CountryData } from "@sssuite-component-ui/react-phone-input-2";
let dateMoment = require("moment");

export interface ITaxpayerProp {
    title: string;
    model: ITaxpayer;
    clientInfo?: IClientInfoViewModel;
    handleValueChange: (item: ClientInfoProperty, value: string) => void;
    states: ICountryState[];
    isAssignedToLoggedinUser?: boolean;
    expandTaxpayerCard: boolean;
    expandSpouseCard: boolean;
    onCardToggle: (CardType: ClientInfoRightSideCardType) => void;
    prefilledClientInfo?: IPrefilledClientInfo;
    resetPrefillInfo?: (clientInfoProperty: ClientInfoProperty) => void;
}

export interface ITaxpayerState {
    name: string;
    email: string;
    dob: Date;
    address: string;
    city: string;
    state: string;
    zip: string;
    ssn: string;
    isDeceased: boolean;
    mobileNumber: string;
    countryCode: string;
    mobileNumberFullValue: string;
}
export function prepareCountryStateDropdown(states: ICountryState[]) {
    var columnValues: any = [];
    states.map((state: any) => {
        columnValues.push({
            value: state.id,
            label: state.name
        });
    });
    return columnValues;
}

export class TaxpayerSidebar extends React.Component<ITaxpayerProp, ITaxpayerState> {
    state: ITaxpayerState = {
        name: "",
        dob: new Date(),
        email: "",
        ssn: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        isDeceased: false,
        mobileNumber: "",
        countryCode: "",
        mobileNumberFullValue: ""
    };
    constructor(props: ITaxpayerProp) {
        super(props);
        if (props.model) {
            this.state = {
                name: props.model.name || "",
                dob: props.model.dob || new Date(),
                email: props.model.email || "",
                ssn: props.model.ssn || "",
                address: props.model.address || "",
                city: props.model.city || "",
                state: props.model.state || "",
                zip: props.model.zip || "",
                isDeceased: props.model.isDeceased || false,
                mobileNumber: props.model.mobileNumber || "",
                countryCode: props.model.countryCode || "+1",
                mobileNumberFullValue: `${props.model.countryCode || "+1"}${props.model.mobileNumber || ""}`
            };
        }
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleBlurName = this.handleBlurName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleBlurEmail = this.handleBlurEmail.bind(this);
        this.handleChangeDoB = this.handleChangeDoB.bind(this);
        this.handleChangeAddress = this.handleChangeAddress.bind(this);
        this.handleBlurAddress = this.handleBlurAddress.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleBlurCity = this.handleBlurCity.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleChangeZip = this.handleChangeZip.bind(this);
        this.handleBlurZip = this.handleBlurZip.bind(this);
        this.handleChangeSSN = this.handleChangeSSN.bind(this);
        this.onDeceasedChange = this.onDeceasedChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ITaxpayerProp) {
        if (nextProps.model && isEqual(this.props.prefilledClientInfo, nextProps.prefilledClientInfo)) {
            this.state = {
                name: nextProps.model.name,
                dob: nextProps.model.dob,
                email: nextProps.model.email,
                ssn: nextProps.model.ssn,
                address: nextProps.model.address,
                city: nextProps.model.city,
                state: nextProps.model.state,
                zip: nextProps.model.zip,
                isDeceased: nextProps.model.isDeceased,
                mobileNumber: nextProps.model.mobileNumber,
                countryCode: nextProps.model.countryCode,
                mobileNumberFullValue: `${nextProps.model.countryCode || "+1"}${nextProps.model.mobileNumber || ""}`
            };
        }
    }

    handleResetPrefillInfo = (item: ClientInfoProperty) => {
        this.props.resetPrefillInfo && this.props.resetPrefillInfo(item);
    };

    handleChangeName(event: any) {
        this.setState({ name: event.target.value });
        this.handleResetPrefillInfo(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseName : ClientInfoProperty.taxpayerName
        );
    }

    handleBlurName(event: any) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseName : ClientInfoProperty.taxpayerName,
            this.state.name
        );
    }

    handleChangeEmail(event: any) {
        this.setState({ email: event.target.value });
        this.handleResetPrefillInfo(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseEmail : ClientInfoProperty.taxpayerEmail
        );
    }

    handleBlurEmail(event: any) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseEmail : ClientInfoProperty.taxpayerEmail,
            this.state.email
        );
    }

    handleChangeDoB(event: any) {
        let now = new Date();
        if (dateMoment(event) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        } else {
            this.handleResetPrefillInfo(
                this.props.title == ClientTypes.Spouse
                    ? ClientInfoProperty.spouseDateOfBirth
                    : ClientInfoProperty.taxpayerDateOfBirth
            );
            this.props.handleValueChange(
                this.props.title == ClientTypes.Spouse
                    ? ClientInfoProperty.spouseDateOfBirth
                    : ClientInfoProperty.taxpayerDateOfBirth,
                event
            );
        }
    }

    handleChangeAddress(event: any) {
        this.setState({ address: event.target.value });
    }

    handleBlurAddress(event: any) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseAddress : ClientInfoProperty.taxpayerAddress,
            event.target.value
        );
    }

    handleChangeCity(event: any) {
        this.setState({ city: event.target.value });
    }

    handleBlurCity(event: any) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseCity : ClientInfoProperty.taxpayerCity,
            event.target.value
        );
    }

    handleChangeState(event: any) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseState : ClientInfoProperty.taxpayerState,
            event?.label
        );
    }

    handleChangeZip(event: any) {
        if (isDigit(event.target.value) == true || event.target.value?.trim() == "") this.setState({ zip: event.target.value });
    }

    handleBlurZip(event: any) {
        if (isDigit(event.target.value) == true || event.target.value?.trim() == "")
            this.props.handleValueChange(
                this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseZip : ClientInfoProperty.taxpayerZip,
                event.target.value
            );
    }

    handleBlurMobileNumber = (event: any) => {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse
                ? ClientInfoProperty.spouseMobileNumber
                : ClientInfoProperty.taxpayerMobileNumber,
            this.state.mobileNumber
        );
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse
                ? ClientInfoProperty.spouseCountryCode
                : ClientInfoProperty.taxpayerCountryCode,
            this.state.countryCode
        );
    };

    onMobileChange = (fullValue: string, countryObject: CountryData): void => {
        fullValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
        this.setState({
            mobileNumber: fullValue.slice(countryObject.dialCode.length),
            countryCode: fullValue ? `+${countryObject.dialCode}` : "",
            mobileNumberFullValue: fullValue
        });

        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse
                ? ClientInfoProperty.spouseMobileNumber
                : ClientInfoProperty.taxpayerMobileNumber,
            fullValue.slice(countryObject.dialCode.length)
        );
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse
                ? ClientInfoProperty.spouseCountryCode
                : ClientInfoProperty.taxpayerCountryCode,
            fullValue ? `+${countryObject.dialCode}` : ""
        );

        this.handleResetPrefillInfo(
            this.props.title == ClientTypes.Spouse
                ? ClientInfoProperty.spouseMobileNumber
                : ClientInfoProperty.taxpayerMobileNumber
        );
        this.handleResetPrefillInfo(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseCountryCode : ClientInfoProperty.taxpayerCountryCode
        );
    };

    handleChangeSSN(value: string) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseSSN : ClientInfoProperty.taxpayerSSN,
            value
        );
        this.handleResetPrefillInfo(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseSSN : ClientInfoProperty.taxpayerSSN
        );
    }

    onDeceasedChange(event: any) {
        this.props.handleValueChange(
            this.props.title == ClientTypes.Spouse ? ClientInfoProperty.spouseDeceased : ClientInfoProperty.taxpayerDeceased,
            event.target.checked
        );
    }

    getpreFilledClassName = (clientInfoProperty: ClientInfoProperty): string => {
        let isPrefilled = false;
        if (this.props.prefilledClientInfo) {
            switch (clientInfoProperty) {
                case ClientInfoProperty.taxpayerName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerName];
                    break;
                case ClientInfoProperty.taxpayerDateOfBirth:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerDateOfBirth];
                    break;
                case ClientInfoProperty.taxpayerEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerEmail];
                    break;
                case ClientInfoProperty.taxpayerSSN:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerSSN];
                    break;
                case ClientInfoProperty.taxpayerCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerCountryCode];
                    break;
                case ClientInfoProperty.taxpayerMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.taxpayerMobileNumber];
                    break;
                case ClientInfoProperty.spouseName:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseName];
                    break;
                case ClientInfoProperty.spouseDateOfBirth:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseDateOfBirth];
                    break;
                case ClientInfoProperty.spouseEmail:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseEmail];
                    break;
                case ClientInfoProperty.spouseCountryCode:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseCountryCode];
                    break;
                case ClientInfoProperty.spouseMobileNumber:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseMobileNumber];
                    break;
                case ClientInfoProperty.spouseSSN:
                    isPrefilled = this.props.prefilledClientInfo[ClientInfoProperty.spouseSSN];
            }
        }
        return isPrefilled ? "prefilledField" : "";
    };

    public render() {
        let CardType = this.props.title == ClientTypes.Taxpayer ? this.props.expandTaxpayerCard : this.props.expandSpouseCard;
        let icon = CardType ? "fas fa-minus" : "fas fa-plus";
        return (
            <Accordion
                activeKey={CardType ? "0" : ""}
                onSelect={() => {
                    this.props.onCardToggle(
                        this.props.title == ClientTypes.Taxpayer
                            ? ClientInfoRightSideCardType.TaxperInformationCard
                            : ClientInfoRightSideCardType.SpouseInformationCard
                    );
                }}
            >
                <Card>
                    <Accordion.Toggle eventKey="0">
                        <Card.Header style={{ padding: "10px" }}>
                            <Card.Title as="h3">
                                <i className={icon}></i> {this.props.title}
                            </Card.Title>
                        </Card.Header>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Name
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        className={this.getpreFilledClassName(
                                            this.props.title == ClientTypes.Spouse
                                                ? ClientInfoProperty.spouseName
                                                : ClientInfoProperty.taxpayerName
                                        )}
                                        value={this.state.name}
                                        placeholder="Enter Name"
                                        onChange={this.handleChangeName}
                                        onBlur={this.handleBlurName}
                                        data-test-auto="A8FE0A0F-49BC-4D41-AA55-D6E9DC47EBE1"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    SSN
                                </Col>
                                <Col sm={9} className={"zeroborderRadius"}>
                                    <TaxIdentificationNumber
                                        className={this.getpreFilledClassName(
                                            this.props.title == ClientTypes.Spouse
                                                ? ClientInfoProperty.spouseSSN
                                                : ClientInfoProperty.taxpayerSSN
                                        )}
                                        value={this.props.model ? FilterSSN(this.props.model.ssn) : ""}
                                        onChange={this.handleChangeSSN}
                                        placeholder={"Enter SSN"}
                                        mask="000-00-0000"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Address
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        value={this.state.address}
                                        placeholder="Enter Address"
                                        onChange={this.handleChangeAddress}
                                        onBlur={this.handleBlurAddress}
                                        data-test-auto="617DCDA2-B8C3-45D1-BFDE-7106C7911C38"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    City
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        value={this.state.city}
                                        placeholder="Enter City"
                                        onChange={this.handleChangeCity}
                                        onBlur={this.handleBlurCity}
                                        data-test-auto="9E82F9BD-C071-4DB7-97C0-193D3010B07F"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    State
                                </Col>
                                <Col sm={9}>
                                    <Select
                                        name="form-field-state"
                                        value={
                                            this.props.model && CheckIfStringHasValue(this.props.model.state)
                                                ? this.props.states[
                                                      this.props.states.findIndex(
                                                          (x) => x.name.toLowerCase() == this.props.model.state.toLowerCase()
                                                      )
                                                  ]?.id
                                                : ""
                                        }
                                        onChange={this.handleChangeState}
                                        options={prepareCountryStateDropdown(this.props.states)}
                                        data-test-auto="EB1E7C0C-1427-444A-A3B6-4815820DA04C"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                        clearable={false}
                                    ></Select>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Zip
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="text"
                                        value={this.state.zip}
                                        placeholder="Enter Zip"
                                        onChange={this.handleChangeZip}
                                        onBlur={this.handleBlurZip}
                                        maxLength={5}
                                        data-test-auto="65A21CBF-51A4-4015-A106-F720025EC1B3"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    DOB
                                </Col>
                                <Col sm={9} className={"zindexzero"}>
                                    <CustomDatePicker
                                        value={this.props.model.dob}
                                        customClassName={this.getpreFilledClassName(
                                            this.props.title == ClientTypes.Spouse
                                                ? ClientInfoProperty.spouseDateOfBirth
                                                : ClientInfoProperty.taxpayerDateOfBirth
                                        )}
                                        isPrefilled={
                                            this.getpreFilledClassName(
                                                this.props.title == ClientTypes.Spouse
                                                    ? ClientInfoProperty.spouseDateOfBirth
                                                    : ClientInfoProperty.taxpayerDateOfBirth
                                            ) != ""
                                                ? true
                                                : false
                                        }
                                        onChange={this.handleChangeDoB}
                                        maxDate={new Date()}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08 padR00 div-flex" sm={3} as={FormLabel}>
                                    <Col sm={9} className="padL00" style={{ whiteSpace: "nowrap" }}>
                                        Mobile
                                    </Col>
                                    <Col sm={3} className="padR00">
                                        <i
                                            className="help-icon fas fa-question-circle padR00 fontS15"
                                            data-toggle="tooltip"
                                            title={CompanySettingsConstants.MFASettings.HelpText}
                                        ></i>
                                    </Col>
                                </Col>
                                <Col sm={9} className="div-flex">
                                    <PhoneInput
                                        specialLabel=""
                                        className={this.getpreFilledClassName(this.props.title == ClientTypes.Spouse
                                            ? ClientInfoProperty.spouseMobileNumber
                                            : ClientInfoProperty.taxpayerMobileNumber)}
                                        country={"us"}
                                        autoFormat={true}
                                        value={this.state.mobileNumberFullValue}
                                        onChange={this.onMobileChange}
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                        onBlur={this.handleBlurMobileNumber}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Email
                                </Col>
                                <Col sm={9}>
                                    <FormControl
                                        type="email"
                                        className={this.getpreFilledClassName(
                                            this.props.title == ClientTypes.Spouse
                                                ? ClientInfoProperty.spouseEmail
                                                : ClientInfoProperty.taxpayerEmail
                                        )}
                                        placeholder="Enter Email"
                                        value={this.state.email}
                                        onChange={this.handleChangeEmail}
                                        onBlur={this.handleBlurEmail}
                                        data-test-auto="B23A8320-C234-4F91-8F37-8C431E2FB41A"
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Col className="text-left padT08" sm={3} as={FormLabel}>
                                    Deceased
                                </Col>
                                <Col sm={9} className={"marTNeg03"}>
                                    <CheckBoxComponent
                                        id={
                                            this.props.title == ClientTypes.Spouse
                                                ? "FDE1D4B7-FO08-45E9-9A3D-E62A99D2493D"
                                                : "FDE1D4B7-FO08-45E9-9A3D-E62A99D249YT"
                                        }
                                        text={""}
                                        checked={this.state.isDeceased}
                                        datatestAuto={"1189D6C6-93FB-4846-8D37-859AC9D833E5"}
                                        onChange={this.onDeceasedChange}
                                        disabled={!this.props.isAssignedToLoggedinUser}
                                    />
                                </Col>
                            </FormGroup>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        );
    }
}
export default TaxpayerSidebar;
